<template>
    <div class="overlay overlay--patient" id="viewInvoice" v-if="invoice">
        <bar :title="$t('viewPatientInvoice.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="paid">{{$t('viewPatientInvoice.paid')}}</div>
                <div class="col-xs-12 right-left">
                    <div class="invoiceData">
                        <div>
                            {{$t('viewPatientInvoice.patientHeader')}} : {{invoice.patientGivenNames}} {{invoice.patientFamilyName}}
                        </div>
                        <div>
                            {{$t('viewPatientInvoice.clinicianLabel')}}:
                            {{invoice.clinicianGivenNames}} {{invoice.clinicianFamilyName}}
                        </div>
                    </div>
                    <div class="invoiceData">
                        <div class="clininc"><b>{{invoice.clinicName}}</b></div>
                        <div>{{invoice.clinicAddress}}</div>
                        <div>{{invoice.clinicZipCode}} {{invoice.clinicCity}}, {{invoice.clinicCountry}}</div>
                        <div>{{$t('viewPatientInvoice.emailLabel')}}: <a :href="'mailto:'+invoice.clinicEmail">{{invoice.clinicEmail}}</a></div>
                        <div>{{$t('viewPatientInvoice.phoneLabel')}}: <a :href="'tel:'+invoice.clinicPhone">{{invoice.clinicPhone}}</a></div>
                    </div>
                    <div class="invoiceData">
                        <div>{{$t('viewPatientInvoice.dateLabel')}}: <b><date-display :iso-date="invoice.date" display-as="date" /></b></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head row">
                            <div class="col-xs-8"><span><b>{{$t('viewPatientInvoice.itemTextHeader')}}</b></span></div>
                            <div class="col-xs-4 right-text"><span><b>{{$t('viewPatientInvoice.priceHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <div class="item row" v-for="(item,index) in invoice.invoiceItems" :key="index" data-test="invoice-item">
                                <div class="col-xs-8"><span>{{item.text}}</span></div>
                                <div class="col-xs-4 right-text"><span data-test="invoice-item-total">{{$n(item.price, 'currency', invoice.currencyLocale)}}</span></div>
                            </div>
                            <div class="item row total">
                                <div class="col-xs-8">
                                    <span>{{$t('viewPatientInvoice.totalPriceLabel')}}</span>
                                </div>
                                <div class="col-xs-4 right-text">
                                    <span data-test="invoice-total">{{$n(total, 'currency', invoice.currencyLocale)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="col-xs-12">
                    <create-invoice-item v-if="addingNewItem" @save="invoiceItemSaved" @cancel="invoiceItemCanceled" />
                </div>-->
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-xs-12 right-text">
                    <button type="button"
                            @click="closeDialog"
                            class="btn btn__text btn__text--green-border right"
                            data-test="close-button">
                        <h4>{{$t('general.close')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { computed, defineProps } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        invoiceId: String,
    });

    const store = useStore();
    const router = useRouter();

    const invoice = computed(() => store.getters.getPatientInvoice(props.invoiceId));
    const total = computed(() => invoice.value.invoiceItems.reduce((p, c) => p + c.price, 0.0));

    const closeDialog = () => router.push({ name: 'patientbilling' });
</script>
