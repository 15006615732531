import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

const fnCreateBookingRequest = async (patientId, clinicId, message) => {
    let bookingRequest = {
        clinicId,
        message,
    };

    let result = await dispatcher.postToApi(`patients/${patientId}/bookingrequests`, bookingRequest);
    handleApiError(result, 'Error while creating booking request.');

    return result.data.bookingRequestId;
}

const fnGetBookingRequest = async (clinicId, bookingRequestId) => {
    let result = await dispatcher.getFromApi(`clinics/${clinicId}/bookingrequests/${bookingRequestId}`);
    handleApiError(result, 'Error while reading booking request.');

    return result.data;
}

const fnGetClinicBookingRequests = async (clinicId) => {
    let result = await dispatcher.getFromApi(`clinics/${clinicId}/bookingrequests`);
    handleApiError(result, 'Error while reading clinic\'s booking requests.');

    return result.data.bookingRequests;
}

const fnGetPatientBookingRequests = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/bookingrequests`);
    handleApiError(result, 'Error while reading patient\'s booking requests.');

    return result.data.bookingRequests;
}

const fnRejectBookingRequest = async (clinicId, bookingRequestId, reason) => {
    let rejection = {
        rejectionReason: reason,
    }
    let result = await dispatcher.putToApi(`clinics/${clinicId}/bookingrequests/${bookingRequestId}/reject`, rejection);
    handleApiError(result, 'Error while rejecting booking request.');
}


import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useBookingRequestService = () => {
    const store = useStore();

    const createBookingRequest = async (clinicId, message) => {
        const patientId = store.getters.getPatientId;
        return await serviceCall(
            async () => {
                return await fnCreateBookingRequest(patientId, clinicId, message);
                //store.commit('addBookingRequest')
                //return bookingRequestId;
            }
        );
    }

    const getBookingRequest = async (clinicId, bookingRequestId) => {
        return await serviceCall(
            async () => {
                return await fnGetBookingRequest(clinicId, bookingRequestId);
            }
        );
    }

    const refreshClinicBookingRequests = async () => {
        const clinicId = store.getters.getClinicianClinicId;
        await serviceCall(
            async () => {
                const requests = await fnGetClinicBookingRequests(clinicId);
                store.commit('setClinicBookingRequests', requests);
            }
        );
    }

    const getPatientBookingRequests = async () => {
        const patientId = store.getters.getPatientId;
        return await serviceCall(
            async () => {
                return await fnGetPatientBookingRequests(patientId);
            }
        );
    }

    const rejectBookingRequest = async (clinicId, bookingRequestId, reason) => {
        await serviceCall(
            async () => {
                await fnRejectBookingRequest(clinicId, bookingRequestId, reason);
            }
        );
    }

    return {
        createBookingRequest,
        getBookingRequest,
        refreshClinicBookingRequests,
        getPatientBookingRequests,
        rejectBookingRequest,
    }
}
