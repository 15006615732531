<template>
    <div class="container" id="codedConcept">
        <div class="row">
            <div class="col-xs-12">
                <div class="search" :class="{searching: filtering}">
                    <span class="error" v-if="noResults">{{$t(`${codeType}Filter.zeroResults`)}}</span>
                    <input v-model="filterInput" autocomplete="off" :placeholder="$t(`${codeType}Filter.searchInputPlaceholder`)" />
                    <svg-icon icon-name="search" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="searching" :class="{filled: filterInput.length}">
                    <span></span>
                </div>
                <div class="table-layout folded" :class="{open: filteredCodes.length}">
                    <div class="head">
                        <div class="col-xs-3">
                            <span><b>{{$t(`${codeType}Filter.codeHeader`)}}</b></span>
                        </div>
                        <div class="col-xs-9">
                            <span><b>{{$t(`${codeType}Filter.descriptionHeader`)}}</b></span>
                        </div>
                    </div>
                    <div class="body" v-if="filteredCodes.length" :class="{expand : filteredCodes.length}">
                        <div class="item" v-for="item in filteredCodes"
                             :class="{ selected: item.code === selectedCode }"
                             :key="item.code"
                             @click="selectCode(item)">
                            <div class="col-xs-3 code" :title="item.code">
                                <span>{{ item.code }}</span>
                            </div>
                            <div class="col-xs-9">
                                <span>{{ item.text }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import service from './CodedConceptSearchService.js';

    const NUMBER_OF_CODES = 50;
    const SEARCHDELAY_TIMEOUT_MS = 800;

    export default {
        props: {
            codeType: {
                type: String,
                required: true,
            },
            navigateOnSelect: {
                type: String,
                required: true,
            },
        },
        emits: ["codeselected"],
        setup(props, { emit }) {
            const router = useRouter();
            let codes = ref([]);
            let cachedCode = ref('');
            let filterInput = ref('');
            let selectedCode = ref(null);
            let filtering = ref(false);
            let typing = ref(false);
            let typingTimer = ref(null);
            let noResults = ref(false);

            // Detect input changes, and time lock typing/typingTimer
            // ToDo refactor, mabye button instead? (check timer live)
            watch(() => filterInput.value, () => {
                typing.value = true;
                filtering.value = true;
                console.log('typing detected..');
                clearTimeout(typingTimer.value);
                typingTimer.value = setTimeout(() => {
                    typing.value = false
                    console.log('done typing!');
                }, SEARCHDELAY_TIMEOUT_MS);
            });

            watch(() => typing.value, async (isTyping) => {
                filtering.value = true;
                if (!isTyping && filterInput.value.length > 1) {
                    console.log('code changed from the list cached, so update the cached list');
                    // lookup code...
                    try {
                        codes.value = await service.getCodes(props.codeType, filterInput.value);
                        console.log(codes.value);
                        noResults.value = codes.value.length < 1;
                    }
                    catch (e) {
                        console.error(e.message);
                    }
                }
                else {
                    codes.value = [];
                }
                cachedCode.value = filterInput.value;
                filtering.value = false;
            });

            const filteredCodes = computed(() => {
                console.log('refiltering the list for display');
                let input = cachedCode.value.toLowerCase();

                if (input != '' && codes.value instanceof Array)
                    return codes.value.slice(0, NUMBER_OF_CODES);
                else
                    return [];
            });

            const selectCode = (selection) => {
                console.log("Code selected", selection.code, selection.text, selection.system);
                //selectedCode.value = selection.code;
                filterInput.value = '';
                if (props.navigateOnSelect) {
                    router.push({
                        name: props.navigateOnSelect,
                        params: {
                            code: selection.code,
                            text: selection.text,
                            system: selection.system,
                        }
                    });
                }
                else {
                    emit('codeselected', selection);
                }
            };

            return {
                noResults,
                typing,
                typingTimer,
                filtering,
                filterInput,
                filteredCodes,
                selectedCode,
                selectCode
            }
        }
    }
</script>