import { computed } from 'vue';
import { useStore } from 'vuex';


export const BLOODTYPEKEY = 'bloodType';
export const BLOODTYPEKEY2 = '080304C1-A9D0-400B-BFF3-5291CE5E3743';
export const BLOODTYPERHESUSKEY = 'bloodTypeRhesus';
export const BLOODTYPEGROUPKEY = 'bloodTypeGroup';

export const BLOODPRESSUREKEY = '75367002';
export const BLOODPRESSURESYSTOLICKEY = 'bloodPressureSystolic';
export const BLOODPRESSUREDIASTOLICKEY = 'bloodPressureDiastolic';

export const BLOODSUGARKEY = '33747003';
export const BLOODSUGARKEY2 = 'bloodSugar';

export const TEMPERATUREKEY = 'temperature';
export const TEMPERATUREKEY2 = '0C3D2564-B7A7-4328-BFF0-CA2F6973B37C';

export const WEIGHTKEY = 'weight';
export const WEIGHTKEY2 = '1ABD3F31-968D-49F0-8EBB-812C2BB687CC';

export const HEIGHTKEY = 'height';
export const HEIGHTKEY2 = 'E7A971C8-FE4E-4DFA-92C5-B3A7A9791A60';

export const PULSERATEKEY = '10675001';

export const RESPIRATORYRATEKEY = '301283003';

export const vitalSignsMeasurements = [
    HEIGHTKEY,
    HEIGHTKEY2,
    WEIGHTKEY,
    WEIGHTKEY2,
    BLOODPRESSUREKEY,
    BLOODSUGARKEY,
    BLOODSUGARKEY2,
    TEMPERATUREKEY,
    TEMPERATUREKEY2,
    PULSERATEKEY,
    RESPIRATORYRATEKEY
];

export const useMeasurementHelperService = () => {

    const store = useStore();

    const findMeasurementComponent = (measurement, key) => {
        if (!measurement.value) return null;
        let cmps = measurement.value.measurementValue.components.filter((c) => c.definition.codingCode == key);
        if (!cmps.length) return null;
        return cmps[0];
    }

    // blood type
    const newestBloodType = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: BLOODTYPEKEY }, { code: BLOODTYPEKEY2 }]));
    const newestBloodTypeGroupComponent = computed(() => findMeasurementComponent(newestBloodType, BLOODTYPEGROUPKEY));
    const newestBloodTypeRhesusComponent = computed(() => findMeasurementComponent(newestBloodType, BLOODTYPERHESUSKEY));


    // blood pressure
    const newestBloodPressure = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: BLOODPRESSUREKEY }]));
    const newestBloodPressureSystolicComponent = computed(() => findMeasurementComponent(newestBloodPressure, BLOODPRESSURESYSTOLICKEY));
    const newestBloodPressureDiastolicComponent = computed(() => findMeasurementComponent(newestBloodPressure, BLOODPRESSUREDIASTOLICKEY));


    // bloodsugar
    const newestBloodSugar = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: BLOODSUGARKEY }]));


    // temperature
    const newestTemperature = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: TEMPERATUREKEY }, { code: TEMPERATUREKEY2 }]));


    // weight
    const newestWeight = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: WEIGHTKEY }, { code: WEIGHTKEY2 }]));


    // height
    const newestHeight = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: HEIGHTKEY }, { code: HEIGHTKEY2 }]));



    return {
        newestBloodType,
        newestBloodTypeGroupComponent,
        newestBloodTypeRhesusComponent,

        newestBloodPressure,
        newestBloodPressureSystolicComponent,
        newestBloodPressureDiastolicComponent,

        newestBloodSugar,

        newestTemperature,

        newestWeight,

        newestHeight,
    }
}