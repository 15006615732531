<template>
    <div id="patientConsent" class="overlay overlay--patient">
        <bar :title="$t('patientConsent.title')" @close="closeDialog" />
        <div class="container" data-test="patient-consent-container">
            <div class="row">
                <template v-for="consent in consents" :key="consent.consentType">
                    <div class="col-xs-12" :data-test="'patient-consent-' + consent.consentType">
                        <check-box v-model="consent.consentState"
                                   :trueValue="CONSENTSTATES_PERMIT"
                                   :falseValue="CONSENTSTATES_DENY"
                                   :label="consent.definition.title"
                                   type="switch"
                                   @updated="updateConsent(consent.consentType, consent.consentState)"
                                   class="online-only"></check-box>
                        <div style="margin-left: 75px;margin-top:-20px;margin-bottom:20px">
                            <div class="pb-small">{{consent.definition.description}}</div>
                            <template v-if="consent.date">
                                {{$t('patientConsent.recordedBy')}} {{getFullRecorderName(consent)}},
                                {{$t('patientConsent.recordedDate')}}
                                <date-display :iso-date="consent.date" display-as="date"></date-display>
                            </template>
                            <template v-else>
                                {{$t('patientConsent.consentNotDecidedYetText')}}
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
    import CheckBox from '@/Components/Shared/Form/CheckBox';

    import { onMounted, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import {
        usePatientConsentService,
        CONSENTTYPES_BLOODDONORREQUESTS,
        CONSENTSTATES_DENY,
        CONSENTSTATES_PERMIT
    } from '@/Components/Patients/PatientConsent/PatientConsentService.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const service = usePatientConsentService();
    const caller = useExceptionwrappedCaller();

    let consentDefinitions = ref([]);

    onMounted(() => {
        consentDefinitions.value = [
            {
                consentType: CONSENTTYPES_BLOODDONORREQUESTS,
                title: t('consentTypes.bloodDonorRequestTitle'),
                description: t('consentTypes.bloodDonorRequestHelper')
            },
         ];
    });

    const getFullRecorderName = (consent) => {
        return [consent.recorderGivenNames, consent.recorderFamilyName].join(' ').replace(',', ' ');
    }

    let consents = ref([]);

    watchEffect(() => {
        let patientConsents = store.getters.getPatientConsents;
        consents.value = consentDefinitions.value.map((cd) => {
            let pcs = patientConsents.filter((c) => c.consentType == cd.consentType);
            if (pcs.length) {
                let pc = pcs[0];
                return {
                    consentState: pc.consentState,
                    consentType: pc.consentType,
                    date: pc.date,
                    recorderGivenNames: pc.recorderGivenNames,
                    recorderFamilyName: pc.recorderFamilyName,
                    definition: cd
                }
            }

            return {
                consentState: CONSENTSTATES_DENY,
                consentType: cd.consentType,
                date: null,
                definition: cd
            }
        });
    });

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;

        await caller.call(async () => {
            await service.loadPatientConsents();
        });
    });

    const updateConsent = async (consentType, consentState) => {
        let pc = store.getters.getPatientConsents.filter((c) => c.consentType == consentType);
        if (
            (pc.length == 0 && consentState == CONSENTSTATES_PERMIT)
            || (pc.length > 0 && pc[0].consentState != consentState)
        ) {
            await caller.call(async () => {
                if (consentState == CONSENTSTATES_DENY) {
                    await service.denyConsent(consentType);
                }
                else if (consentState == CONSENTSTATES_PERMIT) {
                    await service.permitConsent(consentType);
                }
            });
        }
    }

    const closeDialog = () => router.push({ name: 'patient' });
</script>
