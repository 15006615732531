<template>
    <footer id="navigation-bottom">
        <div class="container full-height">
            <div class="row full-height">
                <div class="col-xs-3 full-height">
                    <button @click="navigateTo('dashboard')" data-test="dashboard">
                        <div class="svg-container">
                            <svg-icon icon-name="home" />
                        </div>
                        <span>{{$t('footerMenu.dashboardLabel')}}</span>
                    </button>
                </div>
                <div class="col-xs-3 full-height" v-if="isClinician">
                    <button @click="navigateTo('mypatients')" data-test="my-patients">
                        <div class="svg-container">
                            <svg-icon icon-name="patient-data" />
                        </div>
                        <span>{{$t('footerMenu.myPatientsLabel')}}</span>
                    </button>
                </div>
                <div class="col-xs-3 full-height" v-if="isClinician">
                    <button @click="navigateTo('myschedule')" data-test="my-schedule">
                        <div class="svg-container">
                            <svg-icon icon-name="calender" />
                        </div>
                        <span>{{$t('footerMenu.myScheduleLabel')}}</span>
                    </button>
                </div>
                <div class="col-xs-3 full-height" v-if="isPatient">
                    <button @click="navigateTo('patient')" data-test="my-data">
                        <div class="svg-container">
                            <svg-icon icon-name="patient-data" />
                        </div>
                        <span>{{$t('footerMenu.myDataLabel')}}</span>
                    </button>
                </div>
                <div class="col-xs-3 full-height" v-if="isPatient">
                    <button @click="navigateTo('appointments')" data-test="appointments">
                        <div class="svg-container">
                            <svg-icon icon-name="calender" />
                        </div>
                        <span>{{$t('footerMenu.appointmentsLabel')}}</span>
                    </button>
                </div>
                <div class="col-xs-3 full-height">
                    <button @click="logOut()" data-test="log-out">
                        <div class="svg-container">
                            <svg-icon icon-name="logout" />
                        </div>
                        <span>{{$t('footerMenu.logOutLabel')}}</span>
                    </button>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { computed, inject } from 'vue';
    import { useUserService } from '@/Components/Users/User/UserService.js';


    const router = useRouter();
    const store = useStore();
    const userService = useUserService();
    const notHandler = inject('notificationHandler');

    const navigateTo = (routeName) => router.push({ name: routeName });

    const logOut = async () => {
        notHandler.terminate();
        await userService.logoffUser();

        navigateTo('welcome');
    }

    const isClinician = computed(() => store.getters.isClinician);
    const isPatient = computed(() => store.getters.isPatient);
</script>
