import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

const fnCreateClinic = async (clinic) => {
    let result = await dispatcher.postToApi('clinics', clinic);
    handleApiError(result, 'Error while creating clinic.');

    return result.data.clinicId;
}

const fnGetClinic = async (id) => {
    let result = await dispatcher.getFromApi(`clinics/${id}`);
    handleApiError(result, 'Error while fetching clinic.');

    return result.data;
}

const fnFindClinics = async (searchTerm) => {
    let result = await dispatcher.getFromApi(`clinics/search?searchTerm=${searchTerm}`);
    handleApiError(result, 'Error while searching for clinic.');

    return result.data.clinics;
}

const fnGetClinicsInRange = async (latMin, lngMin, latMax, lngMax) => {
    let result = await dispatcher.getFromApi(`clinics/range?latMin=${latMin}&lngMin=${lngMin}&latMax=${latMax}&lngMax=${lngMax}`);
    handleApiError(result, 'Error while getting clinics in range.');

    return result.data.clinics;
}



export const useClinicService = () => {
    const createClinic = async (name, address, zipCode, city, state, country, latitude, longitude, email, phone, organizationId) => {
        let clinic = {
            name: name,
            address: address,
            zipCode: zipCode,
            city: city,
            state: state,
            country: country,
            latitude: latitude,
            longitude: longitude,
            email: email,
            phone: phone,
            organizationId: organizationId,
        };

        return await serviceCall(
            async () => {
                let clinicId = await fnCreateClinic(clinic);
                return clinicId;
            }
        );
    }

    const getClinic = async (id) => {
        return await serviceCall(
            async () => {
                let data = await fnGetClinic(id);
                return data;
            }
        );
    }

    const findClinics = async (searchTerm) => {
        return await serviceCall(
            async () => {
                let clinics = await fnFindClinics(searchTerm);
                return clinics;
            }
        );
    }

    const getClinicsInRange = async (latMin, lngMin, latMax, lngMax) => {
        return await serviceCall(
            async () => {
                let clinics = await fnGetClinicsInRange(latMin, lngMin, latMax, lngMax);
                return clinics;
            }
        );
    }

    return {
        createClinic,
        getClinic,
        findClinics,
        getClinicsInRange,
    };
}