<template>
	<div id="userNavigation" class="container">
		<div class="row full-height">
			<div class="col-xs-12 full-height">
				<div class="img" @click="navigateTo('welcome')">
					<div class="img-container">
						<img :src="clinicianImage" alt="clinician" v-if="clinicianImage" />
						<img src="@/Assets/Images/PersonPlaceholder.jpg" alt="clinician" v-if="!clinicianImage" />
					</div>
				</div>
				<div class="name" @click="navigateTo('welcome')">
					<h3>{{$t('clinicianBarView.title')}}</h3>
					<h3><b>{{clinicianName}}</b></h3>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';

	export default {
		name: 'ClinicianNavigation',
		components: {

		},
		emits: [],
		setup() {
			const store = useStore();
			const router = useRouter();

            const clinicianImage = computed(() => store.state.clinician.image);
			const clinicianName = computed(() => store.getters.getClinicianName || "");

            const navigateTo = (name) => {
                router.push({ name: name });
            }

			return {
				navigateTo,
				clinicianImage,
				clinicianName,
			}
		}
	}
</script>
