<template>
    <template v-if="hasMeasurement">
        <router-link :to="disableLink ? '' : navigateToCreate" 
                     class="btn btn__card btn__card--medium">
            <div class="svg-container">
                <svg-icon :icon-name="props.measuredIconName" />
            </div>
            <div class="text-container center-text">
                <div class="mb-small">{{props.measuredLabel}}:</div>
                <slot :comps="components">
                    <div><b>{{measuredValue}}</b></div>
                </slot>
            </div>
        </router-link>
    </template>
    <template v-if="!hasMeasurement">
        <router-link :to="disableLink ? '' : navigateToCreate"
                     class="btn btn__card btn__card--medium">
            <div class="svg-container">
                <svg-icon :icon-name="props.nonMeasuredIconName" />
            </div>
            <div class="text-container center-text">
                <div class="mb-small">{{props.nonMeasuredLabel}}</div>
            </div>
        </router-link>
    </template>
</template>

<script setup>
    import { computed, defineProps, reactive } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';

    const props = defineProps({
        measurementDefinitionId: String,
        measuredIconName: String,
        measuredLabel: String,
        nonMeasuredIconName: String,
        nonMeasuredLabel: String,
        createMeasurementRouteName: String,
        returnToName: String,
        disableLinkWhenHasMeasurement: { type: Boolean, default: false }
    });

    const store = useStore();
    const { t } = useI18n();

    const isClinician = computed(() => store.getters.isClinician);

    const latestMeasurement = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: props.measurementDefinitionId }]));
    const hasMeasurement = computed(() => !!latestMeasurement.value);

    const disableLink = computed(() => (hasMeasurement.value && props.disableLinkWhenHasMeasurement) || !isClinician.value);

    const components = computed(() => {
        console.log('components')
        let c = [];

        let v = latestMeasurement.value;
        if (!v) return c;

        console.log('components - measurements present')

        for (var i = 0, l = v.measurementValue.components.length; i < l; i++) {
            let comp = v.measurementValue.components[i];

            console.log(`components - ${i}: ${comp.value}`, comp)

            let val = comp.value;
            let dtk = t(comp.definition.titleKey + '');
            let tlk = '';
            if (comp.definition.valueSetValues.length > 0) {
                let vsv = comp.definition.valueSetValues.filter(vs => vs.value == comp.value);
                if (vsv.length > 0) {
                    val = vsv[0].value + '';
                    tlk = t(vsv[0].titleKey + '');
                }
            }
            let cde = comp.definition.codingCode;
            let unt = comp.definition.unitText + '';

            console.log(`components - ${i}: ${val} ${cde} ${unt} ${tlk}`)

            c = [...c, { code: cde, value: val, deftitle: dtk, title: tlk, unit: unt }];
        }

        console.log(`components - list`, c)

        return c;
    });

    const measuredValue = computed(() => {
        let v = latestMeasurement.value;
        if (!v)
            return '';

        if (v.measurementValue.components.length == 0
            && v.measurementValue.value.definition.valueSetValues.length == 0) {
            // simple value
            return `${v.measurementValue.value.value} ${v.measurementValue.value.definition.unitText}`;
        }

        if (v.measurementValue.components.length == 0
            && v.measurementValue.value.definition.valueSetValues.length > 0) {
            // selectlist value
            let vsv = v.measurementValue.value.definition.valueSetValues.filter(vs => vs.value == v.measurementValue.value.value);
            return `${t(vsv[0].titleKey + '')} ${v.measurementValue.value.definition.unitText}`;
        }

        return '';
    });

    const navigateToCreate = reactive({
        name: props.createMeasurementRouteName,
        params: {
            measurementDefinitionId: props.measurementDefinitionId
        },
        query: {
            returnToName: props.returnToName
        }
    });
</script>
