<template>
    <div>
        <div class="container" data-test="message-container">
            <div class="row">
                <div class="col-xs-12">
                    <text-area v-model="message.text"
                               name="text"
                               :required="true"
                               :label="$t('messageNew.messageTextLabel')"
                               :helper="$t('messageNew.messageTextHelperText')"
                               :errorText="$t('messageNew.messageTextErrorText')"
                               @updated="stateChanged"
                               ref="messageText"></text-area>
                </div>

                <div class="col-xs-12">
                    <message-attachments :attachments="message.attachments"
                                         :patient-id="patientId"
                                         :can-add="true"
                                         :can-delete="true"
                                         @show="showPicture"
                                         @delete="deletePicture"
                                         @recorded="pictureRecorded"></message-attachments>
                </div>
            </div>
        </div>

        <div class="button-container-bottom animate-in-delay">
            <button type="button"
                    class="btn btn__text btn__text btn__text--green-border online-only"
                    :class="{disabled:!isValid}"
                    :disabled="!isValid"
                    @click="sendMessage"
                    data-test="send-button">
                <h3>{{$t('messageNew.sendMessageButton')}}</h3>
            </button>
        </div>

        <message-view-picture :show="messageViewPictureVisible"
                              :url="messageViewPictureUrl"
                              @close="hideMessageViewPicture"
                              :key="messageViewPictureUrl"></message-view-picture>
    </div>
</template>


<script setup>
    import TextArea from '@/Components/Shared/Form/TextArea.vue';
    import MessageViewPicture from '@/Components/Consultations/Messaging/MessageViewPicture.vue';
    import MessageAttachments from '@/Components/Consultations/Messaging/MessageAttachments.vue';

    import { computed, defineEmits, defineProps, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        clinicId: { type: String },
        clinicianId: { type: String },
        patientId: { type: String },
    });
    const emit = defineEmits(['messagesent']);

    const store = useStore();
    const { stateChanged, isValid } = useFormValidator();
    const messagingService = useMessagingService();
    const caller = useExceptionwrappedCaller();

    const senderId = computed(() => {
        if (store.getters.getUserType == 'Clinician')
            return props.clinicianId;
        else
            return props.patientId;
    });

    let messageViewPictureVisible = ref(false);
    let messageViewPictureUrl = ref('');

    let message = reactive({
        text: '',
        attachments: [],
    });


    // handling newly selected (and uploaded) picture
    const pictureRecorded = (recording) => {
        message.attachments.push(recording);
    }

    // handling deletion of a picture
    const deletePicture = (attachment) => {
        message.attachments = message.attachments.filter((a) => a.url != attachment.url);
    }


    // handling popup for viewing a picture
    const showPicture = (attachment) => {
        messageViewPictureUrl.value = attachment.url;
        messageViewPictureVisible.value = true;
    }

    const hideMessageViewPicture = () => {
        messageViewPictureVisible.value = false;
    }



    // handling sending the message incl. pictures
    const sendMessage = async () => {
        await caller.call(async () => {
            await messagingService.sendMessage(
                senderId.value,
                props.clinicId,
                props.clinicianId,
                props.patientId,
                message.text,
                message.attachments);

            emit('messagesent');
        });
    }
</script>
