<template>
    <div class="overlay overlay--dashboard" id="addRelatedPerson">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('addRelatedPerson.title')}}</b></h2>
                    <h5 class="full-width center-text pb-small">{{$t('addRelatedPerson.subTitle')}}</h5>
                </div>
                <div class=" col-xs-12">
                    <form>
                        <text-box v-model="relatedPerson.givenNames"
                                  name="givenNames"
                                  type="text"
                                  :required="true"
                                  :label="$t('addRelatedPerson.givenNamesLabel')"
                                  :errorText="$t('addRelatedPerson.givenNamesErrorText')"
                                  :helper="$t('addRelatedPerson.givenNamesHelperText')"
                                  :placeholder="$t('addRelatedPerson.givenNamesPlaceholder')"
                                  @updated="stateChanged"
                                  data-test="given-names" />
                        <text-box v-model="relatedPerson.familyName"
                                  name="familyName"
                                  type="text"
                                  :required="true"
                                  :label="$t('addRelatedPerson.familyNameLabel')"
                                  :errorText="$t('addRelatedPerson.familyNameErrorText')"
                                  :helper="$t('addRelatedPerson.familyNameHelperText')"
                                  :placeholder="$t('addRelatedPerson.familyNamePlaceholder')"
                                  @updated="stateChanged"
                                  data-test="family-name" />
                        <text-box v-model="relatedPerson.email"
                                  name="email"
                                  type="email"
                                  :label="$t('addRelatedPerson.emailLabel')"
                                  :helper="$t('addRelatedPerson.emailHelperText')"
                                  :placeholder="$t('addRelatedPerson.emailPlaceholder')"
                                  @updated="stateChanged"
                                  data-test="email" />
                        <phone-number v-model="relatedPerson.phone"
                                      name="phone"
                                      :label="$t('addRelatedPerson.phoneLabel')"
                                      :helper="$t('addRelatedPerson.phoneHelperText')"
                                      :placeholder="$t('addRelatedPerson.phonePlaceholder')"
                                      @updated="stateChanged"
                                      data-test="phone" />
                        <dropdownbox v-model="relatedPerson.relationType"
                                     name="relationType"
                                     :required="true"
                                     :elements="relationTypeList"
                                     :label="$t('addRelatedPerson.relationTypeLabel')"
                                     :helperText="$t('addRelatedPerson.relationTypeHelperText')"
                                     :errorText="$t('addRelatedPerson.relationTypeErrorText')"
                                     @updated="stateChanged"
                                     data-test="type" />
                    </form>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--red-border btn__text--short left" type="button" @click="closeDialog" data-test="cancel-button">
                        <h4>{{$t('general.cancel')}}</h4>
                    </button>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--green right" :class="{ disabled : !isValid }" :disabled="!isValid" type="button" @click="addRelatedPerson" data-test="add-button">
                        <h4>{{$t('general.add')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import { usePatientRelatedPersonService } from '@/Components/Patients/Patient/PatientRelatedPersonService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import Dropdownbox from '@/Components/Shared/Form/DropDownBox.vue';
    import PhoneNumber from '@/Components/Shared/Form/PhoneNumber.vue';


    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, isValid } = useFormValidator();
    const patientRelSvc = usePatientRelatedPersonService();
    const caller = useExceptionwrappedCaller();

    let relatedPerson = reactive({
        givenNames: '',
        familyName: '',
        email: '',
        phone: '',
        relationType: null
    });

    const relationTypeList = computed(() => [
        { "key": "0", "value": t('relationTypeList.parentToPatientLabel') },
        { "key": "1", "value": t('relationTypeList.childOfPatientLabel') },
        { "key": "2", "value": t('relationTypeList.spouseOfPatientLabel') },
        { "key": "3", "value": t('relationTypeList.siblingToPatientLabel') },
        { "key": "4", "value": t('relationTypeList.otherFamilyOfPatientLabel') },
        { "key": "5", "value": t('relationTypeList.friendOfPatientLabel') },
        { "key": "6", "value": t('relationTypeList.otherRelationToPatientLabel') }
    ]);

    const closeDialog = () => router.back();


    const addRelatedPerson = async () => {
        await caller.call(async () => {
            await patientRelSvc.addPatientRelatedPerson(
                relatedPerson.givenNames,
                relatedPerson.familyName,
                relatedPerson.phone,
                relatedPerson.email,
                relatedPerson.relationType);
            closeDialog();
        });
    }
</script>