<template>
    <header class="green">
        <top-navigation :green="true" />
    </header>
    <PatientDashboardNavigation v-if="isPatient"></PatientDashboardNavigation>
    <ClinicianDashbaordNavigation v-if="isClinician"></ClinicianDashbaordNavigation>
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transitionName">
            <component :is="Component" />
        </transition>
    </router-view>
    <Footer />
</template>

<script setup>
    import TopNavigation from '@/Components/Shared/TopNavigation.vue';
    import Footer from '@/Components/Shared/Footer.vue';
    import PatientDashboardNavigation from '@/Components/Patients/Patient/PatientDashboardNavigation';
	import ClinicianDashbaordNavigation from '@/Components/Organizations/Clinician/ClinicianDashbaordNavigation';
    import { useStore } from 'vuex';
    import { computed } from 'vue';

    const store = useStore();
    const isClinician = computed(() => store.getters.isClinician);
	const isPatient = computed(() => store.getters.isPatient);
</script>
