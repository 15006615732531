<template>
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="bar bar--space">
                    <div class="bar bar__content bar__content--split" :class="{ 'bar__content--full' : props.full }">
                        <div class="bar bar__content bar__content--title" data-test="bar-title-section">
                            <h2 data-test="bar-title" v-if="props.title"><b>{{props.title}}</b></h2>
                            <slot></slot>
                        </div>
                        <div class="bar bar__content bar__content--sub" v-if="props.by">
                            <h5 data-test="bar-by">{{formattedByLabel}} {{props.by}}</h5>
                        </div>
                        <div class="bar bar__content bar__content--date" v-if="displayDate" data-test="bar-date">
                            {{formattedDateLabel}} <date-display :iso-date="displayDate" display-as="date" />
                        </div>
                    </div>
                    <div class="bar bar__buttons" v-if="!props.full">
                        <button @click="closeDialog" data-test="bar-close">
                            <svg-icon icon-name="cross" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, defineEmits, defineProps } from 'vue';

    const props = defineProps({
        title: String,
        by: { type: String, default: null },
        date: String,
        full: Boolean,
        byLabel: { type: String, default: null },
        dateLabel: { type: String, default: null },
    });
    const emit = defineEmits(['close']);

    const displayDate = computed(() => {
        try {
            return props.date
                ? new Date(props.date)
                : null;
        }
        catch (e) {
            return null;
        }
    });

    const formattedByLabel = computed(() => props.byLabel ? props.byLabel + ':' : '');
    const formattedDateLabel = computed(() => props.dateLabel ? props.dateLabel + ':' : '');

    const closeDialog = () => emit('close');
</script>
