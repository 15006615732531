import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';

export const CONSENTSTATES_DENY = 0;
export const CONSENTSTATES_PERMIT = 1;

export const CONSENTTYPES_BLOODDONORREQUESTS = 1;

export const usePatientConsentService = () => {
    const store = useStore();
    
    const loadPatientConsents = async () => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                const consents = await fnGetPatientConsents(patientId);
                console.log('loadPatientConsents', consents)
                store.commit('initPatientConsents', consents);
            }
        );
    }

    const permitConsent = async (consentType) => {
        const patientId = store.getters.getPatientId;

        const data = createConsentDataStructure(store, consentType, CONSENTSTATES_PERMIT);

        await serviceCall(
            async () => {
                await fnPermitPatientConsents(patientId, consentType);
                store.commit('permitPatientConsents', data);
            }
        );
    }

    const denyConsent = async (consentType) => {
        const patientId = store.getters.getPatientId;

        const data = createConsentDataStructure(store, consentType, CONSENTSTATES_DENY);

        await serviceCall(
            async () => {
                await fnDenyPatientConsents(patientId, consentType);
                store.commit('denyPatientConsents', data);
            }
        );
    }

    return {
        loadPatientConsents,
        permitConsent,
        denyConsent,
    }
}



const createConsentDataStructure = (store, consentType, consentState) => {
    const patient = store.getters.getPatient;
    const isPatient = store.getters.isPatient;
    const recorderId = isPatient ? patient.id : store.getters.getClinicianId;
    const recorderGivenNames = isPatient ? patient.givenNames : store.getters.getClinician.givenNames;
    const recorderFamilyName = isPatient ? patient.familyName : store.getters.getClinician.familyName;

    return {
        consentState,
        consentType,
        patientId: patient.id,
        patientGivenNames: patient.givenNames,
        patientFamilyName: patient.familyName,
        recorderId,
        recorderGivenNames,
        recorderFamilyName,
        date: new Date().toISOString(),
    };
}

const fnGetPatientConsents = async (patientId) => {
    const result = await dispatcher.getFromApi(`patients/${patientId}/consents`);
    handleApiError(result, 'Error while reading patient consents.');
    return result.data.patientConsents;
}

const fnPermitPatientConsents = async (patientId, consentType) => {
    const result = await dispatcher.postToApi(`patients/${patientId}/consents/${consentType}/permit`);
    handleApiError(result, `Error while permitting patient consent to ${consentType}.`);
}

const fnDenyPatientConsents = async (patientId, consentType) => {
    const result = await dispatcher.postToApi(`patients/${patientId}/consents/${consentType}/deny`);
    handleApiError(result, `Error while denying patient consent to ${consentType}.`);
}
