<template>
    <div id="pushGeneralMessage" class="overlay overlay--dashboard">
        <bar :title="$t('pushGeneralMessage.title')" @close="closeDialog" />

        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h5 class="text-gray">{{$t('pushGeneralMessage.subTitle')}}</h5>
                </div>
            </div>

            <div class="row pt-large">
                <div class="col-xs-12">
                    <Label class="large"
                           :label="$t('pushGeneralMessage.messageLabelText')"></Label>
                </div>
                <div class="col-xs-12">
                    <TextArea v-model="model.message"
                              name="message"
                              :required="true"
                              :show-character-counter="true"
                              @updated="stateChanged"></TextArea>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <Label class="large" 
                           :label="$t('pushGeneralMessage.areaSelectorLabelText')"></Label>
                </div>
                <div class="col-xs-12">
                    <AreaSelector v-model="model.location"
                                  name="location"
                                  :required="true"
                                  @updated="stateChanged"
                                  @areaChanged="estimateRecipientCount"></AreaSelector>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 pt-large">
                    <p>{{$t('pushGeneralMessage.estimatedRecipientsText')}} <strong>{{recipientCount}}</strong></p>
                </div>
            </div>

            <div class="row pt-large">
                <div class="col-xs-6">
                    <button type="button"
                            class="btn btn__text btn__text--large btn__text--black-border left"
                            @click="closeDialog">
                        <h3>{{$t('general.close')}}</h3>
                    </button>
                </div>
                <div class="col-xs-6">
                    <button type="button"
                            class="btn btn__text btn__text--large btn__text--green right online-only"
                            :class="{disabled: !isValid}"
                            :disabled="!isValid"
                            @click="sendGeneralMessage">
                        <h3>{{$t('general.send')}}</h3>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import Label from '@/Components/Shared/Form/Label'
    import TextArea from '@/Components/Shared/Form/TextArea'
    import AreaSelector from '@/Components/Organizations/Push/AreaSelector'

    import { reactive, ref, toRaw } from 'vue'
    import { useRouter } from 'vue-router'
    import { usePushService } from '@/Components/Organizations/Push/PushService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';

    const router = useRouter();
    const service = usePushService();
    const caller = useExceptionwrappedCaller();
    const { stateChanged, isValid } = useFormValidator();

    const closeDialog = () => router.push({ name: 'push dashboard' });

    let model = reactive({
        message: '',
        location: {
            lat: null,
            lng: null,
            textLocation: null,
            radiusKm: 10,
        },
    });

    let recipientCount = ref(null);

    const estimateRecipientCount = async () => {
        if (!model.location.lat || !model.location.lng) return;
        await caller.call(async () => {
            recipientCount.value = await service.estimateRecipientCount(toRaw(model));
        })
    }

    const sendGeneralMessage = async () => {
        await caller.call(async () => {
            await service.sendGeneralMessage(toRaw(model));
        })
        router.push({ name: 'push dashboard' });
    }
</script>