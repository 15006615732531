<template>
    <div :class="{switch:type=='switch',left:type=='switch',checkbox:type=='checkbox',checkbutton:type=='checkbutton'}">
        <label>
            <span class="control-box">
                <input type="checkbox" v-model="valueCheck" />
                <template v-if="type == 'switch'">
                    <span class="slider round"></span>
                </template>
                <template v-else-if="type == 'checkbox'">
                    <svg-icon icon-name="check" />
                </template>
            </span>
            <span class="control-label">
                <slot>{{label}}</slot>
            </span>
        </label>
    </div>
</template>

<script setup>
    import { computed, defineEmits, defineProps, watch } from 'vue';
    import { FalseBoolean, EmptyString } from './PropTypes.js';


    const props = defineProps({
        modelValue: {
            type: [String, Boolean, Number]
        },
        type: {
            type: String,
            default: 'checkbox',
            validator(value) {
                return ['checkbox', 'switch', 'checkbutton'].includes(value);
            }
        },
        placeholder: EmptyString,
        label: EmptyString,
        errorText: EmptyString,
        helper: EmptyString,
        mustBeState: {
            type: [String, Boolean, Number],
            default: null
        },
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
        labelAdjustment: {
            type: String,
            default: 'right',
            validator(value) {
                return ['left', 'right'].includes(value);
            }
        },
        required: FalseBoolean,
        trueValue: {
            type: [String, Boolean, Number],
            default: true
        },
        falseValue: {
            type: [String, Boolean, Number],
            default: false
        },
    });
    const emit = defineEmits(['update:modelValue', 'updated']);

    const valueCheck = computed({
        get() {
            return props.modelValue == props.trueValue;
        },
        set(value) {
            emit('update:modelValue', value ? props.trueValue : props.falseValue);
        }
    });

    const isValid = (value) => {
        return props.mustBeState == null ||
            props.mustBeState == (value ? props.trueValue : props.falseValue);
    }

    watch(() => valueCheck.value, (value) => {
        emit('updated', {
            name: props.name,
            valid: isValid(value)
        });
    });

    emit('updated', {
        name: props.name,
        valid: isValid(valueCheck.value)
    });
</script>
