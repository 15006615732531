<template>
    <div id="cave" v-if="caveVisible" :class="{ open : caveExpanded }">
        <div class="container">
            <div class="row">
                <div class="col-xs-10 ">
                    <div class="content">
                        <div class="title">
                            <h4>{{$t('cave.title')}}</h4>
                        </div>
                        <div class="text" v-html="caveHtmlText"></div>
                    </div>
                </div>
                <div class="col-xs-2 button-container">
                    <button class="btn btn__round btn__round--large btn__round--red top" :class="{ open : caveExpanded }" @click="toggleCaveExpansion" data-test="cave-togglebutton">
                        <svg-icon icon-name="chevron" />
                    </button>
                    <button v-if="isClinician"
                            class="btn btn__round btn__round--medium btn__round--white-outline bottom"
                            :class="{ open : caveExpanded }"
                            @click="navigateTo('cave')">
                        <svg-icon icon-name="pen" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useCaveService } from '@/Components/Medications/Cave/CaveService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const caveService = useCaveService();
    const caller = useExceptionwrappedCaller();

    const caveText = computed(() => store.getters.getPatientCaveText);

    let caveExpanded = ref(false);

    const navigateTo = (routeName) => router.push({ name: routeName });

    const caveHtmlText = computed(() => caveText.value.replace(/\n/g, '<br/>'));

    const caveVisible = computed(() => caveText.value != '');

    const isClinician = computed(() => store.getters.isClinician);

    const toggleCaveExpansion = () => caveExpanded.value = !caveExpanded.value;

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await caveService.loadCave();
        })
    });
</script>