import Crypto from 'crypto-js';
import dispatcher from '@/Connections/Dispatcher.js';
import { USERPIN } from '@/Shared/Strings.js';

export const useEncryptedPin = () => {
    const getKey = (userId) => `${userId}_${USERPIN}`;

    const hasEncryptedPin = (userId) => {
        return dispatcher.hasLocal(getKey(userId));
    }

    const getEncryptedPin = (userId) => {
        return dispatcher.getFromLocal(getKey(userId));
    }

    const setEncryptedPin = (userId, hashedPin) => {
        return dispatcher.postToLocal(getKey(userId), hashedPin);
    }

    const hashPin = (userId, pin) => {
        const pinKey = pin + '-' + userId;
        return btoa(Crypto.SHA256(pinKey).words);
    }

    return {
        hasEncryptedPin,
        getEncryptedPin,
        setEncryptedPin,
        hashPin,
    };
}