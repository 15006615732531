import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';


const fnCreateMedication = async (patientId, medication) => {
    const result = await dispatcher.postToApi(`patients/${patientId}/medications`, medication);
    handleApiError(result, 'Error while creating medication');

    return result.data.medicationId;
}

const fnChangeMedicationInstruction = async (patientId, medicationId, medicationChange) => {
    const result = await dispatcher.putToApi(`patients/${patientId}/medications/${medicationId}/instruction`, medicationChange);
    handleApiError(result, 'Error while updating medication instruction');
}

const fnLoadMedications = async (patientId) => {
    const result = await dispatcher.getFromApi(`patients/${patientId}/medications`);
    handleApiError(result, 'Error while getting medications for patient');

    return result.data.medications;
}

const fnChangeMedicationAudioInstruction = async (patientId, medicationId, medicationChange) => {
    const result = await dispatcher.putToApi(`patients/${patientId}/medications/${medicationId}/audioinstruction`, medicationChange);
    handleApiError(result, 'Error while changing medication audio instruction.');
}

const fnDeleteMedicationAudioInstruction = async (patientId, medicationId, medicationChange) => {
    const result = await dispatcher.deleteFromApi(`patients/${patientId}/medications/${medicationId}/audioinstruction`, medicationChange)
    handleApiError(result, 'Error while deleting medication audio instruction.');
}


import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useMedicationService = () => {
    const store = useStore();

    const createMedication = async (codingCode, codingText, codingSystem, drug, dosage, duration, frequency, quantity, instruction, simpleOrdination, audioInstruction) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        const medication = {
            clinicianId,
            codingCode,
            codingText,
            codingSystem,
            drug,
            dosage,
            duration,
            frequency,
            quantity,
            instruction,
            simpleOrdination,
            audioInstruction,
        };

        await serviceCall(
            async () => {
                medication.medicationId = await fnCreateMedication(patientId, medication);

                medication.date = new Date().toISOString();
                medication.clinicianGivenNames = store.state.clinician.givenNames;
                medication.clinicianFamilyName = store.state.clinician.familyName;
                medication.patientId = patientId;
                medication.patientGivenNames = store.state.patient.givenNames;
                medication.patientFamilyName = store.state.patient.familyName;

                store.commit('addMedication', medication);
            }
        );
    }

    const changeMedicationInstruction = async (medicationId, instruction) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        const change = { clinicianId, instruction };

        await serviceCall(
            async () => {
                await fnChangeMedicationInstruction(patientId, medicationId, change);

                change.medicationId = medicationId;
                change.date = new Date().toISOString();
                change.clinicianGivenNames = store.state.clinician.givenNames;
                change.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('changeMedicationInstruction', change);
            }
        );
    }

    const changeMedicationAudioInstruction = async (medicationId, audioInstruction) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        const change = { audioInstruction, clinicianId };

        await serviceCall(
            async () => {
                await fnChangeMedicationAudioInstruction(patientId, medicationId, change);

                change.medicationId = medicationId;
                change.date = new Date().toISOString();
                change.clinicianGivenNames = store.state.clinician.givenNames;
                change.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('changeMedicationAudioInstruction', change);
            }
        );
    }

    const deleteMedicationAudioInstruction = async (medicationId) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        const change = { clinicianId };

        await serviceCall(
            async () => {
                await fnDeleteMedicationAudioInstruction(patientId, medicationId, change);

                change.medicationId = medicationId;
                change.date = new Date().toISOString();
                change.clinicianGivenNames = store.state.clinician.givenNames;
                change.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('deleteMedicationAudioInstruction', change);
            }
        );
    }

    const loadMedications = async () => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                const medications = await fnLoadMedications(patientId);

                store.commit('setMedications', medications); 
            }
        );
    }

    return {
        createMedication,
        changeMedicationInstruction,
        changeMedicationAudioInstruction,
        deleteMedicationAudioInstruction,
        loadMedications,
    }
}