import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

const fnCreateBooking = async (clinicianId, clinicId, patientId, bookingRequestId, bookingDate, requestAccess) => {
    let booking = {
        patientId, 
        clinicianId,
        bookingRequestId,
        bookingDate,
        requestAccess,
    };

    const result = await dispatcher.postToApi(`clinics/${clinicId}/bookings`, booking);
    handleApiError(result, 'Error while creating booking.');

    return result.data.bookingId;
}

const fnGetClinicianBookings = async (clinicianId) => {
    let result = await dispatcher.getFromApi(`clinicians/${clinicianId}/bookings`);
    handleApiError(result, 'Error while reading clinician bookings.');

    return result.data.bookings;
}

const fnGetPatientBookings = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/bookings`);
    handleApiError(result, 'Error while reading patient bookings.');

    return result.data.bookings;
}

const fnRejectBooking = async (patientId, bookingId, reason) => {
    let rejection = {
        rejectionReason: reason,
    }

    let result = await dispatcher.putToApi(`patients/${patientId}/bookings/${bookingId}/reject`, rejection);
    handleApiError(result, 'Error while rejecting booking.');
}


import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';
import { useBookingRequestService } from '@/Components/Consultations/BookingRequest/BookingRequestService.js';

export const useBookingService = () => {
    const store = useStore();
    const bookingRequestService = useBookingRequestService();


    const createBooking = async (clinicId, patientId, bookingRequestId, bookingDate, requestAccess) => {
        const clinicianId = store.getters.getClinicianId;
        let booking = {
            patientId,
            clinicianId,
            bookingRequestId,
            bookingDate,
            requestAccess,
        };
        await serviceCall(
            async () => {
                const bookingId = await fnCreateBooking(clinicianId, clinicId, patientId, bookingRequestId, bookingDate, requestAccess);
                booking.bookingId = bookingId;
                store.commit('addClinicianBooking', booking);
            }
        );
    }

    const refreshClinicianBookings = async () => {
        const clinicianId = store.getters.getClinicianId;
        await serviceCall(
            async () => {
                const bookings = await fnGetClinicianBookings(clinicianId);
                store.commit('setClinicianBookings', bookings);
            }
        );
    }

    const refreshPatientAppointments = async () => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                const bookings = await fnGetPatientBookings(patientId);
                const requests = await bookingRequestService.getPatientBookingRequests();
                store.commit('setPatientBookings', bookings);
                store.commit('setPatientRequests', requests);
            }
        );
    }

    const rejectBooking = async (bookingId, reason) => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnRejectBooking(patientId, bookingId, reason);
                store.commit('updatePatientBookings', { bookingId, reason });
            }
        );
    }


    return {
        createBooking,
        refreshClinicianBookings,
        refreshPatientAppointments,
        rejectBooking,
    }
}
