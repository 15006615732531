<template>
    <div id="pin_create" class="pin-create" >
        <div class="pin container">
            <div class="row">
                <div class="col-xs-12 pin pin__title">
                    <h2><b>{{$t('loginCreatePin.title')}}</b></h2>
                    <h4 v-if="!pin.confirm">{{$t('loginCreatePin.pinSubtitle')}}</h4>
                    <h4 v-if="pin.confirm">{{$t('loginCreatePin.confirmSubtitle')}}</h4>
                </div>

                <logon-pin-keyboard v-model="pin.code" :key="pin.confirm"></logon-pin-keyboard>

                <div class="col-xs-12 pin pin__error" v-if="pin.error">
                    <span>{{pin.error}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import LogonPinKeyboard from './LogonPinKeyboard';

    import { computed, defineEmits, reactive, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { setPin } from '@/Shared/PinCache.js';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useEncryptedPin } from '@/Shared/EncryptedPin.js';

    const emit = defineEmits(['pincreated', 'canceled']);

    const store = useStore();
    const { t } = useI18n();
    const userService = useUserService();
    const encryptedPin = useEncryptedPin();

    const userId = computed(() => store.getters.getUserId);

    let pin = reactive({
        code: '',
        confirm: '',
        error: '',
    });

    const reset = () => {
        pin.code = '';
        pin.confirm = '';
        pin.error = '';
    }


    watch(
        () => pin.code,
        async (pinCode) => {
            if (pinCode.length > 3) {
                if (pin.confirm == '') {
                    reset();
                    pin.confirm = pinCode;
                }
                else {
                    if (pin.confirm == pinCode) {
                        reset();
                        await createNewPin(pinCode)
                    }
                    else {
                        reset();
                        pin.error = t('loginCreatePin.notMatchingError');
                    }
                }
            }
        });

    // Create New Pin
    const createNewPin = async (pinCode) => {
        try {
            const hashedPin = encryptedPin.hashPin(userId.value, pinCode);
            setPin(hashedPin);
            encryptedPin.setEncryptedPin(userId.value, hashedPin);
            await userService.storePinHash(hashedPin);
            emit('pincreated');
        }
        catch (e) {
            console.log("PIN: createNewUserPin error : ", e)
            reset();
            pin.error = e.errorMessage;
        }
    }
</script>

