import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';

export const usePushService = () => {
    const store = useStore();

    const estimateRecipientCount = async (requestSetup) => {
        const organizationId = store.getters.getClinicianOrganizationId;
        const bloodTypes = fnConvertToBloodTypesList(requestSetup.bloodtype);

        let request = {
            organizationId,
            bloodTypes,
            latitude: requestSetup.location.lat,
            longitude: requestSetup.location.lng,
            radiusKm: requestSetup.location.radiusKm
        };

        return await serviceCall(
            async () => {
                console.log('PushService.estimateRecipientCount called', request);
                return await fnGetEstimateRecipientCount(request);
            }
        )
    }

    const sendDonorRequest = async (requestSetup) => {
        const clinicianId = store.getters.getClinicianId;
        const bloodTypes = fnConvertToBloodTypesList(requestSetup.bloodtype);

        const request = {
            clinicianId,
            bloodTypes,
            latitude: requestSetup.location.lat,
            longitude: requestSetup.location.lng,
            radiusKm: requestSetup.location.radiusKm
        };

        await serviceCall(
            async () => {
                console.log('PushService.sendDonorRequest called', request);
                await fnSendDonorRequest(request);
            }
        )
    }

    const sendGeneralMessage = async (requestSetup) => {
        const clinicianId = store.getters.getClinicianId;

        const request = {
            clinicianId,
            message: requestSetup.message,
            latitude: requestSetup.location.lat,
            longitude: requestSetup.location.lng,
            radiusKm: requestSetup.location.radiusKm
        };

        await serviceCall(
            async () => {
                console.log('PushService.sendGeneralMessage called', request);
                await fnSendGeneralMessage(request);
            }
        )
    }

    return {
        estimateRecipientCount,
        sendDonorRequest,
        sendGeneralMessage,
    };
}


const fnConvertToBloodTypesList = (request) => {
    if (!request) return [];

    let bloodTypes = [];
    if (request.aneg) bloodTypes.push({ group: 'A', rhesus: '-' });
    if (request.apos) bloodTypes.push({ group: 'A', rhesus: '+' });
    if (request.bneg) bloodTypes.push({ group: 'B', rhesus: '-' });
    if (request.bpos) bloodTypes.push({ group: 'B', rhesus: '+' });
    if (request.abneg) bloodTypes.push({ group: 'AB', rhesus: '-' });
    if (request.abpos) bloodTypes.push({ group: 'AB', rhesus: '+' });
    if (request.oneg) bloodTypes.push({ group: 'O', rhesus: '-' });
    if (request.opos) bloodTypes.push({ group: 'O', rhesus: '+' });
    return bloodTypes;
}

const fnGetEstimateRecipientCount = async (request) => {
    const result = await dispatcher.postToApi(`patients/consents/estimate`, request);
    handleApiError(result, 'Error while reading estimated patients with consent in area.');
    return result.data.estimatedRecipientCount;
}

const fnSendDonorRequest = async (request) => {
    const result = await dispatcher.postToApi(`organizations/push/senddonorrequest`, request);
    handleApiError(result, `Error while sending donor request to consenting patients.`);
}

const fnSendGeneralMessage = async (request) => {
    const result = await dispatcher.postToApi(`organizations/push/sendgeneralmessage`, request);
    handleApiError(result, `Error while sending general message to consenting patients.`);
}
