import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';


const fnCreatePatientInvoice = async (patientId, clinicianId, invoiceItems) => {
    let invoice = {
        clinicianId,
        invoiceItems,
    };

    let result = await dispatcher.postToApi(`patients/${patientId}/invoices`, invoice);
    handleApiError(result, 'Error while creating patient invoice');

    return result.data.invoiceId;
}

const fnGetPatientInvoice = async (patientId, invoiceId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/invoices/${invoiceId}`);
    handleApiError(result, 'Error while getting patient invoice');

    return result.data;
}

const fnGetPatientInvoices = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/invoices`);
    handleApiError(result, 'Error while getting patient invoices');

    return result.data.invoices;
}


import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useInvoiceService = () => {
    const store = useStore();


    const refreshPatientInvoices = async() => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                let invoices = await fnGetPatientInvoices(patientId);
                store.commit('setPatientInvoices', invoices);
            }
        );
    }

    const createPatientInvoice = async (invoiceItems) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        await serviceCall(
            async () => {
                const invoiceId = await fnCreatePatientInvoice(patientId, clinicianId, invoiceItems);
                // reload from server; there is too much information to build object on client
                const invoice = await fnGetPatientInvoice(patientId, invoiceId);
                store.commit('addPatientInvoice', invoice);
            }
        );
    }

    return {
        refreshPatientInvoices,
        createPatientInvoice,
    }
}

