<template>
    <div id="consultationnote" class="overlay overlay--patient">
        <div v-if="isClinician">
            <bar :title="$t('consultationNoteView.title')" @close="closeDialog" />
            <create-consultation-note :use-images="props.useImages"></create-consultation-note>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-consultation-notes />
    </div>
</template>

<script setup>
    import { computed, defineProps, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { useConsultationNoteService } from '@/Components/HealthData/ConsultationNote/ConsultationNoteService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import ListConsultationNotes from '@/Components/HealthData/ConsultationNote/ListConsultationNotes.vue';
    import CreateConsultationNote from '@/Components/HealthData/ConsultationNote/CreateConsultationNote.vue';

    const props = defineProps({
        useImages: { type: String, default: '' },
    });

    const router = useRouter();
    const store = useStore();
    const consultationNoteService = useConsultationNoteService();
    const caller = useExceptionwrappedCaller();

	const isClinician = computed(() => store.getters.isClinician);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await consultationNoteService.loadConsultationNotes();
        })
    });

    const closeDialog = () => {
        router.push({ name: 'patient' });
    }
</script>
