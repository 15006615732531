<template>
    <div class="min-100" :class="{gray: isClinician}" id="listInvoices">
        <bar :title="$t('listInvoices.title')" :full="isClinician" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head row">
                            <div class="col-xs-2"><span><b>{{$t('listInvoices.dateHeader')}}</b></span></div>
                            <div class="col-xs-6"><span><b>{{$t('listInvoices.clinicianHeader')}}</b></span></div>
                            <div class="col-xs-2 right-text"><span><b>{{$t('listInvoices.priceHeader')}}</b></span></div>
                            <div class="col-xs-2 right-text"><span><b>{{$t('listInvoices.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <div class="item row" v-for="invoice in orderedInvoices" :key="invoice.invoiceId" data-invoice-item>
                                <div class="col-xs-2"><date-display :iso-date="invoice.date" display-as="date" /></div>
                                <div class="col-xs-5"><span>{{invoice.clinicianGivenNames}} {{invoice.clinicianFamilyName}}</span></div>
                                <div class="col-xs-3 right-text">
                                    <span data-test="invoice-item-price">{{$n(calcTotal(invoice), 'currency', invoice.currencyLocale)}}</span>
                                </div>
                                <div class="col-xs-2 right-text" style="padding-right: 20px">
                                    <button type="button" @click="view(invoice)" data-test="invoice-item-button">
                                        <div class="svg">
                                            <svg-icon icon-name="eye" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const store = useStore();
    const router = useRouter();

    const invoices = computed(() => store.getters.getPatientInvoices);
    const isClinician = computed(() => store.getters.isClinician);

    const orderedInvoices = computed(() => invoices.value.map((i) => i).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1));

    const closeDialog = () => router.push({ name: 'patient' });

    const calcTotal = (invoice) => invoice.invoiceItems.reduce((p, c) => p + c.price, 0.0);

    const view = (invoice) => router.push({ name: 'viewinvoice', query: { invoiceId: invoice.invoiceId } });
</script>