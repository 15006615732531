<template>
    <div class="container pb-medium online-only" id="investigations-summary">
        <div v-if="isClinician">
            <div class="row">
                <div class="col-xs-12">
                    <div class="search online-only">
                        <input v-model="searchText"
                               :placeholder="$t('createMeasurementOm.searchPlaceholder')" />
                        <svg-icon icon-name="search" />
                    </div>
                </div>
            </div>

            <div class="row list">
                <template v-for="cat in categories" :key="cat.id">
                    <div class="col-xs-4 col-sm-3 col-md-2 center-text">
                        <ActionButton @click="showMeasurementDefinitionsInCategory(cat)"
                                      :text="cat.coding.text"
                                      svg="measurements"
                                      svg-style="fill:black"></ActionButton>
                    </div>
                </template>

                <div class="col-xs-4 col-sm-3 col-md-2 center-text">
                    <ActionButton @click="showMeasurementDefinitionsInCategory(catOther)"
                                  :text="catOther.coding.text"
                                  svg="measurements"
                                  svg-style="fill:black"></ActionButton>
                </div>
            </div>

            <div class="row" :class="{hidden:!filteringActive}">
                <div class="col-xs-12 mb-medium" style="background-color: #eee;">
                    <button class="btn btn__round btn__round--medium btn__round--green-outline right"
                            style="text-transform: unset; margin-bottom: unset; margin-top:4px; margin-right:5px"
                            type="button"
                            @click="clearFilters">
                        <span style="font-weight:bold">X</span>
                    </button>

                    <div style="line-height: 38px;vertical-align:baseline;padding-bottom:0px">
                        <span style="padding-left:5px;margin-right:15px;font-weight:600">{{$t('createMeasurementOm.filteringLabel')}}: </span>
                        <span style="padding-right:15px" :style="{display:(filteringActive?'inline':'none')}">{{filteringText}}</span>
                    </div>
                </div>
            </div>

            <div class="row list">
                <template v-for="def in filteredMeasurementDefinitions" :key="def.id">
                    <div class="col-xs-12 list__clickable list__clickable--right" @click="createMeasurement(def)">
                        <div class="title" :data-id="def.id">
                            <span>
                                {{$t(def.titleKey)}}
                            </span>
                            <div class="svg-container">
                                <svg-icon icon-name="chevron" />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="filteredMeasurementDefinitions.length == 0 && filteringActive">
                    <div class="col-xs-12 center-text">
                        <p>{{$t('createMeasurementOm.noMeasurementsForFilter')}}</p>
                    </div>
                </template>
            </div>
        </div>


        <!--<div class="row">
            <div class="col-xs-12">
                <div class="col-xs-3" v-if="isClinician">
                    <div class="measurement" @click="showOtherMeasurements()">
                        <div class="measurement__title">
                            <span>{{$t('investigationsSummary.otherMeasurementsButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" style="font-size:4rem;font-weight:bold">+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script setup>
    import ActionButton from '@/Components/Shared/Buttons/ActionButton.vue';

    import { computed, defineProps, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { vitalSignsMeasurements } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';

    const props = defineProps({
        returnToName: [String, Object]
    });
    const store = useStore();
    const isClinician = computed(() => store.getters.isClinician);
    const router = useRouter();
    const { t } = useI18n();

    // exclude these measurements, as they already appear in the summary overview
    const excludedMeasurements = vitalSignsMeasurements;

    const catOther = ref({
        id: 0,
        coding: {
            text: t('investigationsSummary.otherInvestigationsButton')
        }
    });

    const isNotDisplayedInSummary = (d) => !excludedMeasurements.includes(d.valueDefinition.codingCode);

    const searchText = ref(null);
    const categoryFilter = ref(null);

    const categories = computed(() => store.getters.getMeasurementCategories);

    const filteringActive = computed(() => searchText.value?.length > 0 || categoryFilter.value != null);

    const filteringText = computed(() => {
        if (categoryFilter.value != null) return categoryFilter.value?.coding.text;
        return searchText.value;
    });

    // get all measurements which are not excluded (because they appear in the summary)
    const measurementDefinitions = computed(() => {
        let fmd = store.getters.getMeasurementDefinitions.filter((d) => isNotDisplayedInSummary(d));
        return fmd.sort((a, b) => t(a.titleKey + '') < t(b.titleKey) ? -1 : 1);
    });


    const filteredMeasurementDefinitions = computed(() => {
        if (!searchText.value && !categoryFilter.value)
            return []; // measurementDefinitions.value;

        if (searchText.value && !categoryFilter.value) {
            return measurementDefinitions.value
                .filter((d) =>
                    (searchTextIsEmpty() || searchTextMatchTitle(d)));
        }
        else {
            const fnCatFilter = categoryFilter.value.id == 0
                ? hasNoCategory
                : hasFilterCategory;

            return measurementDefinitions.value
                .filter((d) =>
                    (searchTextIsEmpty() || searchTextMatchTitle(d))
                    && (noFilterChosen() || fnCatFilter(d)));
        }
    });

    const searchTextIsEmpty = () => !searchText.value || searchText.value?.length == 0;

    const searchTextMatchTitle = (d) => t(d.titleKey).toLowerCase().includes(searchText.value?.toLowerCase());

    const noFilterChosen = () => !categoryFilter.value;

    const hasNoCategory = (mDef) => !mDef.categories;

    const hasFilterCategory = (mDef) => {
        if (!mDef.categories)
            return false;

        return mDef.categories
            .filter(c =>
                c.code == categoryFilter.value?.coding.code
                && c.system == categoryFilter.value?.coding.system)
            .length > 0;
    }

    const anyFilters = () => categoryFilter.value != null;

    const clearFilters = () => {
        categoryFilter.value = null;
        searchText.value = null;
    }


    const showMeasurementDefinitionsInCategory = (category) => { categoryFilter.value = category }

    const closeDialog = async () => await router.push({ name: props.returnToName });

    const createMeasurement = async (measurementDefinition) => {
        await router.push({
            name: 'create measurement component',
            params: {
                measurementDefinitionId: measurementDefinition.valueDefinition.codingCode
            },
            query: {
                returnToName: 'investigations'
            }
        });
    };
</script>

<style scoped>
    .hidden {
        display: none;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
</style>
