<template>
    <div id="offline" :class="{active:!isOnline}">
        <div class="content">
            <div class="text">{{$t('offline.offlineNow')}}</div>
            <svg-icon icon-name="nointernet" />
        </div>
    </div>
</template>

<script setup>
    import { computed, watch, onMounted } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();
    const isOnline = computed(() => store.getters.getIsOnline);

    onMounted(() => {
        if (isOnline.value) {
            document.body.classList.add('online');
            document.body.classList.remove('offline');
        }
        else {
            document.body.classList.add('offline');
            document.body.classList.remove('online')
        }
    });

    watch(() => isOnline.value, (online) => {
        if (online) {
            document.body.classList.add('online');
            document.body.classList.remove('offline');
        }
        else {
            document.body.classList.add('offline');
            document.body.classList.remove('online')
        }
    });
</script>