<template>
    <div id="patientNavigation">
        <div class="container">
            <div class="row">
                <!-- Vitals -->
                <div class="col-xs-12 top">
                    <h2><b>{{$t('patientNavigation.vitalsTitle')}}</b></h2>
                    <span>{{$t('patientNavigation.bloodType')}}: </span>
                    <span><b>{{bloodType}}</b></span>
                </div>
                <!-- Patient Data -->
                <div class="col-xs-12 spacer">
                    <h2><b>{{$t('patientNavigation.dataTitle')}}</b></h2>
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="record" color="record" size="medium" svg="record" :text="$t('patientNavigation.viewRecordButton')" data-test="record-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="vitalsigns" color="vitalsigns" size="medium" svg="heartbeat" :text="$t('patientNavigation.vitalsignsButton')" data-test="vitalsigns-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="diagnosis" color="diagnosis" size="medium" svg="diagnosis" :text="$t('patientNavigation.diagnosisButton')" data-test="diagnosis-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="investigations" color="investigations" size="medium" svg="investigations" :text="$t('patientNavigation.investigationsButton')" data-test="investigations-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="medication" color="medication" size="medium" svg="medication" :text="$t('patientNavigation.medicationButton')" data-test="medication-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="immunization" color="immunization" size="medium" svg="immunization" :text="$t('patientNavigation.immunizationButton')" data-test="immunization-button" />
                </div>
                <div class="col-xs-3 col-sm-2" v-if="isClinician">
                    <CardButton link-to="cave" color="cave" size="medium" svg="heart" :text="$t('patientNavigation.caveButton')" data-test="cave-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="note" color="note" size="medium" svg="note" :text="$t('patientNavigation.noteButton')" data-test="note-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="episodeofcare" color="episodeofcare" size="medium" svg="care" :text="$t('patientNavigation.episodeOfCareButton')" data-test="eoc-button" />
                </div>
                <!-- Patient administration -->
                <div class="col-xs-12 spacer">
                    <h2><b>{{$t('patientNavigation.patientAdminTitle')}}</b></h2>
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="data" color="patient-data" size="medium" svg="patient-data" :text="$t('patientNavigation.patientDataButton')" data-test="patient-data-button" />
                </div>
                <div class="col-xs-3 col-sm-2" v-if="!isClinician && !isRelatedPatient">
                    <CardButton link-to="myrelations" color="relations" size="medium" svg="family" :text="$t('patientNavigation.RelationsButton')" data-test="myrelations-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="appointments" color="appointments" size="medium" svg="appointments" :text="$t('patientNavigation.appointmentButton')" data-test="appointments-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="patientbilling" color="billing" size="medium" svg="billing" :text="$t('patientNavigation.billingButton')" data-test="billing-button" />
                </div>
                <div class="col-xs-3 col-sm-2">
                    <template v-if="isClinician">
                        <CardButton :link-to="{name:'clinic conversation',params:{patientId}}" color="conversations" size="medium" svg="mail" :text="$t('patientNavigation.conversationsButton')" data-test="conversations-button" />
                    </template>
                    <template v-else>
                        <CardButton link-to="patient conversations" color="conversations" size="medium" svg="mail" :text="$t('patientNavigation.conversationsButton')" data-test="conversations-button" />
                    </template>
                </div>
                <div class="col-xs-3 col-sm-2">
                    <CardButton link-to="patient consents" color="consents" size="medium" svg="consents" :text="$t('patientNavigation.consentsButton')" data-test="consents-button" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import CardButton from '@/Components/Shared/Buttons/CardButton.vue';

    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { useMeasurementService } from '@/Components/HealthData/Measurements/MeasurementService.js';
    import { useMeasurementHelperService } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const { t } = useI18n();
    const measurementService = useMeasurementService();
    const {
        newestBloodType,
        newestBloodTypeGroupComponent,
        newestBloodTypeRhesusComponent
    } = useMeasurementHelperService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);
    const isRelatedPatient = computed(() => store.getters.isRelatedPatient);
    const patientId = computed(() => store.getters.getPatientId);

    watchEffect(async () => {
        if (patientId.value) {
            await caller.call(async () => {
                await measurementService.loadMeasurements();
            })
        }
    });

    const bloodType = computed(() => {
        let bt = newestBloodType.value;
        if (!bt)
            return t('patientNavigation.bloodTypeNotMeasuredLabel');

        const groupComponent = newestBloodTypeGroupComponent.value;
        const rhesusComponent = newestBloodTypeRhesusComponent.value;
        
        return `${groupComponent.value} ${t(rhesusComponent.definition.titleKey + '')} `
            + `${t(rhesusComponent.definition.valueSetValues.filter((vs) => vs.value == rhesusComponent.value)[0].titleKey + '')} `
            + `(${groupComponent.value}${rhesusComponent.value})`;
    });
</script>
