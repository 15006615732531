<template>
    <div class="textarea">
        <Label v-if="props.label" 
               :label="props.label" 
               :error="error" 
               :errorText="props.errorText" 
               :helper="props.helper" />
        <textarea v-model="value" 
                  :placeholder="props.placeholder" 
                  :data-state="dataState" 
                  formnovalidate />
        <div v-if="props.showCharacterCounter" style="padding-bottom:25px;margin-top:-10px">
            {{$t('textArea.charactersLabelText')}}: {{value.length}}
        </div>
    </div>
</template>

<script setup>
    import Label from './Label.vue';

    import { defineEmits, defineProps } from 'vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    const props = defineProps({
        modelValue: EmptyString,
        placeholder: EmptyString,
        label: EmptyString,
        errorText: EmptyString,
        helper: EmptyString,
        pattern: NullableString,
        patternCaseSensitive: FalseBoolean,
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
        required: FalseBoolean,
        showCharacterCounter: {
            type: Boolean,
            default: false
        }
    });
    const emit = defineEmits(['update:modelValue', 'updated']);

    const { value, error, dataState } = useInputValidator(props, { emit });
</script>