<template>
    <div id="createCancerEpisodeOfCare" class="overlay overlay--patient" v-if="isClinician">
        <bar :title="$t(`createCancerEpisodeOfCare.title`)" @close="closeDialog" />
        <div class="container">
            <div class="row">
                
                <div class="content">
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.serumUreaLevelsKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.serumUreaLevelsLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewSerumUreaLevelsLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.serumCreatinineLevelsKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.serumCreatinineLevelsLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewSerumCreatinineLevelsLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.eGFRKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.eGFRLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNeweGFRLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.astLevelsKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.astLevelsLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewASTLevelsLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.altLevelsKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.altLevelsLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewALTLevelsLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.alpLevelsKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.alpLevelsLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewALPLevelsLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.ptKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.ptLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewPTLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.pttKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.pttLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewPTTLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.inrKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.inrLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewINRLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.ggtKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.ggtLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewGGTLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.serumAlbuminLevelsKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.serumAlbuminLevelsLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewSerumAlbuminLevelsLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.rbgKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.rbgLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewRBGLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.lymphocytesKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.lymphocytesLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewLymphocytesLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.plateletCountKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.plateletCountLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewPlateletCountLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.cancerAntigenKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.cancerAntigenLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewCancerAntigenLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.chestXRayReportKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.chestXRayReportLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewChestXRayReportLink')"
                                                 create-measurement-route-name="eoccreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.ctScanChestAbdomenReportKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.ctScanChestAbdomenReportLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewCTScanChestAbdomenReportLink')"
                                                 create-measurement-route-name="eoccancercreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="cancer.abdominalUltrasoundReportKey"
                                                 measured-icon-name="measurements"
                                                 :measured-label="$t('createCancerEpisodeOfCare.abdominalUltrasoundReportLabel')"
                                                 non-measured-icon-name="measurements"
                                                 :non-measured-label="$t('createCancerEpisodeOfCare.registerNewAbdominalUltrasoundReportLink')"
                                                 create-measurement-route-name="eoccancercreate create measurement"
                                                 return-to-name="createcancerepisodeofcare">
                        </measurement-link-button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="content">
                    <div class="col-xs-6">
                        <button class="btn btn__text btn__text--black-border btn__text--short left"
                                type="button" @click="closeDialog" data-test="cancel-button">
                            <h4>{{$t('general.close')}}</h4>
                        </button>
                    </div>
                    <div class="col-xs-6">
                        <button class="btn btn__text btn__text--green right online-only" :class="{ disabled : !isValid }" :disabled="!isValid"
                                type="button" @click="createEpisodeOfCare" data-test="add-button">
                            <h4>{{$t('general.create')}}</h4>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup>
    import DateTime from '@/Components/Shared/Form/DateTime.vue';
    import MeasurementLinkButton from './MeasurementLinkButton.vue';
    import MeasurementImageButton from './MeasurementImageButton.vue';

    import { computed, reactive, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import { useCancerEpisodeOfCareService } from '@/Components/HealthData/EpisodeOfCare/CancerEpisodeOfCareService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import * as cancer from '@/Components/HealthData/EpisodeOfCare/CancerConstants.js';

    const store = useStore();
    const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const service = useCancerEpisodeOfCareService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);

    let eoc = reactive({
    });

    const closeDialog = () => router.push({ name: 'episodeofcare' });

    const createEpisodeOfCare = async () => {
        await caller.call(async () => {
            await service.createCancerEpisodeOfCare();
            closeDialog();
        });
    }
</script>
