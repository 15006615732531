<template>
    <div id="immunization" class="overlay overlay--patient">
        <bar v-if="isClinician" :title="$t('immunizationView.title')" @close="closeDialog" />
        <coded-concept-search v-if="isClinician" code-type="immunizationcodes" navigate-on-select="create immunization" class="online-only" />
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-immunizations />
    </div>
</template>

<script setup>
    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useImmunizationService } from '@/Components/HealthData/Immunization/ImmunizationService.js';
    import ListImmunizations from '@/Components/HealthData/Immunization/ListImmunizations.vue';
    import CodedConceptSearch from '@/Components/Terminology/Shared/CodedConceptSearch.vue';

    const store = useStore();
    const router = useRouter();
    const caller = useExceptionwrappedCaller();
    const immunizationService = useImmunizationService();

	const isClinician = computed(() => store.getters.isClinician);

    const closeDialog = () => router.push({ name: 'patient' });

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await immunizationService.loadImmunizations();
        })
    });
</script>
