<template>
    <div class="overlay" id="logon">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('loginView.title')}}</b></h2>
                    <h5 class="full-width center-text pb-medium">
                        <span>{{$t('loginView.dontHaveProfile')}} 
                        <button class="btn btn__link" 
                                @click="navigateTo('register')" 
                                data-test="register-here-button">
                            <span>{{$t('loginView.registerHere')}}</span>
                        </button></span>
                    </h5>
                    <form>
                        <div class="row">
                            <div class="col-xs-push-1 col-xs-10">
                                <text-box v-model="model.username"
                                          name="username"
                                          :label="$t('loginView.usernameLabel')"
                                          data-test="username-field"></text-box>
                            </div>
                        </div>

                        <div class="error">{{model.error}}</div>

                        <div class="row">
                            <div class="col-xs-push-2 col-xs-8">
                                <button type="submit"
                                        @click.prevent="logonNext"
                                        class="btn btn__text btn__text--large btn__text--green full-width"
                                        data-test="next-button">
                                    <h1><span>{{$t('loginView.nextButton')}}</span></h1>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row">
                        <div class="col-xs-push-1 col-xs-10 center-text pb-medium">
                            <h5>{{$t('loginView.forgotPassword')}} 
                            <button class="btn btn__link" 
                                    @click="navigateTo('reset')" 
                                    data-test="reset-password-here">
                                <span>{{$t('loginView.resetHere')}}</span></button></h5>
                        </div>
                        <div class="col-xs-push-2 col-xs-8 center-text">
                            <button class="btn btn__link" @click="closeDialog" data-test="go-back">
                                <h3>{{$t('general.goBackButton')}}</h3>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox';

    import { inject, reactive } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useEncryptedPin } from '@/Shared/EncryptedPin.js';

    const store = useStore();
    const router = useRouter();
    const userService = useUserService();
    const encryptedPin = useEncryptedPin();

    const enterPinDialog = inject('enterPinDialog');
    const notHandler = inject('notificationHandler');

    let model = reactive({
        username: '',
        error: '',
    });

    const navigateTo = (name, params) => router.push({ name, params });

    const canLogonWithPin = async (user) => {
        if (user == null)
            return false;

        if (!userService.hasValidUserToken(user.userId))
            return false;

        const matchesServerToken = await userService.hasMatchingServerToken(user.userId)
        if (!matchesServerToken)
            return false;

        if (!encryptedPin.hasEncryptedPin(user.userId))
            return false;

        return true;
    }

    const logonNext = async () => {
        const savedUser = userService.getSavedUserByUserIdentifier(model.username);
        let routeName = '';
        let params = null;

        const logonWithPinOK = await canLogonWithPin(savedUser);
        if (logonWithPinOK) {
            //store.commit('setUserId', savedUser.userId);
            const res = await enterPinDialog('auth', savedUser.userId);
            console.log('pin entered - result', res);
            if (res) {
                notHandler.start();
                routeName = 'dashboard';
            }
            else {
                routeName = 'welcome';
            }
        }
        else if (store.getters.getIsOnline) {
            routeName = 'logon password';
            params = { username: model.username };
            console.log('proceed to logon with password', params);
        }
        else {
            routeName = 'offline cant logon';
        }
        navigateTo(routeName, params);
    }

    const closeDialog = () => navigateTo('welcome');
</script>
