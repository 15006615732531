import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useConsultationNoteService = () => {
    const store = useStore();

    const createConsultationNote = async (note, audioNote, images) => {
        let patientId = store.getters.getPatientId;
        let clinicianId = store.getters.getClinicianId;

         // TODO: clean up images-to-attachment-conversion on client too
        const attachments = images?.split('|')
                .filter((i) => i != '')
                .map((i) => ({ url: i })) || [];

        let consultationNote = {
            clinicianId,
            note,
            audioNote,
            attachments,
        };

        await serviceCall(
            async () => {
                const result = await dispatcher.postToApi(`patients/${patientId}/consultationnotes`, consultationNote);
                handleApiError(result, 'Error while creating consultation note.');

                //TODO: remove when attachments have been implemented on the client
                consultationNote.images = images;

                consultationNote.consultationNoteId = result.data.consultationNoteId;
                consultationNote.date = new Date().toISOString();
                consultationNote.clinicianGivenNames = store.state.clinician.givenNames;
                consultationNote.clinicianFamilyName = store.state.clinician.familyName;
                consultationNote.patientId = patientId;
                consultationNote.patientGivenNames = store.state.patient.givenNames;
                consultationNote.patientFamilyName = store.state.patient.familyName;

                store.commit('addConsultationNote', consultationNote);
            }
        );
    }

    const loadConsultationNotes = async () => {
        let patientId = store.getters.getPatientId;

        await serviceCall(
            async () => { 
                const result = await dispatcher.getFromApi(`patients/${patientId}/consultationnotes`);
                handleApiError(result, 'Error while reading consultation note.');

                const notes = result.data.consultationNotes;

                //TODO: remove when attachments have been implemented on the client
                notes.forEach((note) => {
                    note.images = note.attachments.map((a) => a.url).join('|');
                    delete note.attachments;
                });

                store.commit('setConsultationNotes', notes);
            }
        );
    }

    return {
        createConsultationNote,
        loadConsultationNotes,
    }
}
