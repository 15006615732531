import dispatcher from '@/Connections/Dispatcher.js';
import * as strings from '@/Shared/Strings.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

const getCodes = async (group, criteria) => {
    let organizationId = dispatcher.getFromLocal(strings.CLINICIANORGANIZATIONID);
    let result = await dispatcher.postToApi(`${group}/search`, { criteria, organizationId });
    handleApiError(result, 'Error while fetching codes for ${group}');
    
    return result.data.codes;
}

export default {
    name: "CodedConceptSearchService",
    getCodes,
}