import api from './ApiProxy.js';
import lsProxy from './LocalStorageProxy.js';
import ssProxy from './SessionStorageProxy.js';
import accessTokens from './AccessTokens.js';
import { REFRESHTOKEN, USERID } from '@/Shared/Strings.js';

// API

// Headers
const getHeaders = (requireAccessToken) => {
    let headers = {};
    headers['Content-type'] = 'application/json';

    if (requireAccessToken !== false) { // default to demanding accessToken
        let accessToken = accessTokens.accessTokenCheck();
        if (accessToken && accessToken != "undefined") {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }
    }

    return headers;
}

// retry function for connecting to endpoint with accesstoken refresh logic built in
const retryAccessToken = async (action) => {
    let result = await action().catch((e) => e.response);
    if (result.status == 401) {
        console.log("endpoint not allowed");
        let hasRefreshToken = await accessTokens.hasVerifiedToken();
        if (hasRefreshToken) {
            console.log("accesstoken refreshed - retrying");
            result = await action().catch((e) => e.response);
        }
        if (result.status == 401) {
            console.log('INVALID REFRESH TOKEN - LOG OFF!');
            if (result.status == 400 && result.data.errors && result.data.errors.length) {
                if (result.data.errors[0].code == 120) {    // refresh token mismatch
                    const userId = ssProxy.get(USERID);
                    lsProxy.delete(`${userId}_${REFRESHTOKEN}`);
                }
            }
            throw new Error('invalidrefreshtoken');
        }
    }
    return result;
}

const getFromApi = async (address) => {
    return await retryAccessToken(async () => await api.get(address, getHeaders()));
}

const postToApi = async (address, data) => {
    return await retryAccessToken(async () => await api.post(address, data, getHeaders()));
}

const putToApi = async (address, data) => {
    return await retryAccessToken(async () => await api.put(address, data, getHeaders()));
}

const deleteFromApi = async (address, data) => {
    return await retryAccessToken(async () => await api.del(address, data, getHeaders()));
}


// send data to the endpoint without requiring accesstoken (e.g. login or register)
const postToApiAnonymous = async (address, data) => {
    let result = await api
        .post(address, data, getHeaders(false))
        .catch(function (error) {
            result = error.response;
        });

    return result;
}


// Local
const hasLocal = (key) => lsProxy.has(key);

const getLocalKeys = (key) => lsProxy.getKeysByPartialKey(key);

const getFromLocal = (key) => lsProxy.get(key);

const postToLocal = (key, data) => lsProxy.set(key, data);

const deleteFromLocal = (key) => lsProxy.remove(key);

const getFromLocalClear = (key) => lsProxy.get(key, false);

const postToLocalClear = (key, data) => lsProxy.set(key, data, false);


// Session
const getFromSession = (key) => ssProxy.get(key);

const postToSession = (key, data) => ssProxy.set(key, data);

const deleteFromSession = (key) => ssProxy.remove(key);



export default {
    name: "Dispatcher",

    // API
    getFromApi,
    postToApi,
    postToApiAnonymous,
    putToApi,
    deleteFromApi,

    // Local
    hasLocal,
    getLocalKeys,
    getFromLocal,
    postToLocal,
    deleteFromLocal,
    getFromLocalClear,
    postToLocalClear,

    // Session
    getFromSession,
    postToSession,
    deleteFromSession,
}