<template>
    <div>
        <template v-for="image in service.images" :key="image.id">
            <div class="col-xs-3 col-sm-2" data-test="image-list-imageframe">
                <div class="image-list-image square center-image"
                     :style="{'background-image':`url('${image.data}')`}"
                     @click="showImage(image)"
                     data-test="clickable-image">
                    <div v-if="canDelete">
                        <button @click.prevent.stop="deleteImage(image)" class="delete-round" data-test="delete-image-button">X</button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
    import { computed, defineProps, inject } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps({
        editable: { type: Boolean, default: false }
    });

    const { t } = useI18n();

    const service = inject('imageListPickerService');
    if (!service) throw new Error('imageListPickerService not provided');

    const alertDialog = inject('alertDialog');
    const showImagePopup = inject('showImagePopup');

    const canDelete = computed(() => props.editable);

    const showImage = (image) => {
        showImagePopup({ imageSource: image.data });
    }

    const deleteImage = async (image) => {
        let ok = await alertDialog({
            header: t('imageList.deleteImageAlertHeader'),
            body: t('imageList.deleteImageAlertBody'),
            cancel: t('general.no'),
            accept: t('general.yes')
        });

        if(ok)
            service.deleteImage(image.id);
    }
</script>