<template>
    <img :src="theImage" :onerror="loadError" />
</template>

<script setup>
    import { defineProps, ref, watchEffect } from 'vue';

    const props = defineProps({
        profileId: {
            type: String,
            required: false,
            default: null
        },
    });

    const noImage = require('@/Assets/Images/PersonPlaceholder.jpg');

    let theImage = ref('about:blank');

    const setImage = (profileId) => {
        let cb = new Date().getTime();
        theImage.value = `${process.env.VUE_APP_AZURE_CDN_URL}/profilepicture/${profileId}.jpeg?cb=${cb}`;
    };

    const loadError = () => {
        theImage.value = noImage;
    }

    watchEffect(() => {
        if (!props.profileId) return;
        setImage(props.profileId);
    });
</script>