import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useAuditLogService = () => {
    const store = useStore();

    const getPatientAuditLogEntries = async (patientId) => {
        patientId = patientId || store.getters.getPatientId;

        return await serviceCall(
            async () => {
                let result = await dispatcher.getFromApi(`patients/${patientId}/auditlogentries`);
                handleApiError(result, 'Error while getting audit logs');

                return result.data.entries;
            }
        );
    }

    return {
        getPatientAuditLogEntries
    };
}
