<template>
    <div id="pin" class="overlay overlay--full-screen transparent" :class="{active: props.showPin}">
        <bar @close="closeDialog">
            <logon-pin-username :user-id="props.userId"></logon-pin-username>
        </bar>

        <div class="pin container">
            <logon-create-pin v-if="showCreatePin" @pincreated="pinCreated" :key="props.dlgTs"></logon-create-pin>
            <logon-enter-pin v-if="showEnterPin" v-bind="props" @pinentered="pinEntered" :key="props.dlgTs"></logon-enter-pin>
        </div>
    </div>
</template>

<script setup>
    import LogonPinUsername from './LogonPinUsername';
    import LogonEnterPin from './LogonEnterPin';
    import LogonCreatePin from './LogonCreatePin';

    import { computed, defineEmits, defineProps } from 'vue';

    const emit = defineEmits(['done','canceled']);
    const props = defineProps({
        showPin: {
            type: Boolean,
            default: false
        },
        pinType: {
            type: String,
            validations: (value) => ['enter', 'create'].includes(value.toLowerCase())
        },
        dlgTs: {
            type: Number,
            default: new Date().getTime()
        },
        action: {
            type: String,
            validations: (value) => ['auth', 'refresh'].includes(value.toLowerCase())
        },
        userId: { type: String }
    });

    const showCreatePin = computed(() => props.showPin && props.pinType == 'create');
    const showEnterPin = computed(() => props.showPin && props.pinType == 'enter');

    const pinEntered = () => emit('done');
    const pinCreated = () => emit('done');
    const closeDialog = () => emit('canceled');
</script>

