<template>
    <div class="overlay overlay--dashboard">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('requestAppointment.title')}}</b></h2>
                    <h5 class="full-width center-text">
                        <span>{{$t('requestAppointment.subTitle')}}</span>
                    </h5>
                </div>
                <div class="col-xs-12">
                    <hr />
                    <clinic-short-info v-bind="clinic"></clinic-short-info>
                    <hr />
                    <clinic-request-form v-if="clinic" :clinic-id="clinic.clinicId"></clinic-request-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import ClinicRequestForm from './ClinicRequestForm';
    import ClinicShortInfo from './ClinicShortInfo';

    import { defineProps, ref, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';
    import { useClinicService } from '@/Components/Organizations/Clinic/ClinicService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        clinicId: String,
    });

    const router = useRouter();
    const service = useClinicService();
    const caller = useExceptionwrappedCaller();

    let clinic = ref(null);

    watchEffect(async () => {
        if (!props.clinicId) return;
        await caller.call(async () => {
            clinic.value = await service.getClinic(props.clinicId);
        });
    });

    const closeDialog = () => router.push({ name: 'findclinic' });
</script>
