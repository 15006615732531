<template>
    <div class="overlay overlay--patient">
        <bar :title="$t('patientAuditLog.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="pb-medium">{{$t('patientAuditLog.subtitle')}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-3"><strong>{{$t('patientAuditLog.dateHeader')}}</strong></div>
                <div class="col-xs-3"><strong>{{$t('patientAuditLog.userHeader')}}</strong></div>
                <div class="col-xs-6"><strong>{{$t('patientAuditLog.actionHeader')}}</strong></div>
            </div>
            <div class="row" v-for="entry in entries" :key="entry.id">
                <div class="col-xs-3" :title="formatDate(entry.timestamp)">
                    <date-display :iso-date="entry.timestamp" display-as="date" />
                </div>
                <div class="col-xs-3" style="overflow-x:hidden;text-overflow:ellipsis" :title="entry.username">
                    <span>{{entry.username}}</span>
                </div>
                <div class="col-xs-6" style="overflow-x:hidden;text-overflow:ellipsis" :title="entry.requestName">
                    <span>{{entry.requestName}}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { computed, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useAuditLogService } from '@/Components/Security/AuditLog/AuditLogService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const service = useAuditLogService();
    const caller = useExceptionwrappedCaller();

    const loadedEntries = ref([]);
    const entries = computed(() => loadedEntries.value.map((v) => v).sort((a, b) => a.timestamp > b.timestamp ? -1 : 1));

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => { 
            loadedEntries.value = await service.getPatientAuditLogEntries();
        })
    });

    const formatDate = (date) => {
        try {
            return (new Date(date)).toLocaleString();
        }
        catch (e) {
            return date;
        }
    }

    const closeDialog = () => router.push({ name: 'patient' });
</script>
