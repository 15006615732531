<template>
    <div class="overlay overlay--dashboard" id="myClinicians">
        <bar @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('myClinicians.title')}}</b></h2>
                    <h5 class="full-width center-text pb-small">{{$t('myClinicians.subTitle')}}</h5>
                    <div class="search">
                        <input :placeholder="$t('myClinicians.inputPlaceholder')"
                               v-model="filter"
                               v-on:keyup.enter="refreshList" />
                        <svg-icon icon-name="search" />
                        <button class="btn btn__round btn__round--large btn__round--green online-only" type="button" @click="refreshList">
                            <div class="svg-container">
                                <svg-icon icon-name="refresh" />
                            </div>
                        </button>
                    </div>
                </div>
                <div class="col-xs-push-3 col-xs-6  filter" style="display:none">
                    <!-- TEMP REMOVE ! -->
                    <button class="btn btn__text-icon btn__text-icon--green-dark full-width" @click="openFilter">
                        <div>
                            <svg-icon icon-name="lock" />
                            <h4><span>{{$t('myClinicians.filters')}}</span></h4>
                        </div>
                    </button>
                </div>
            </div>

            <div class="row">
                <list-clinician-requests :filter="filter"
                                         @granted="grantedAccess"
                                         @denied="deniedAccess"
                                         @paused="pausedAccess"
                                         @resumed="resumedAccess"
                                         @reactivated="reactivatedAccess" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import ListClinicianRequests from '@/Components/Patients/Patient/ListClinicianRequests.vue';

    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const router = useRouter();
    const patientAccessService = usePatientAccessService();
    const caller = useExceptionwrappedCaller();

    let filter = ref('');

    const closeDialog = () => router.push({ name: 'dashboard' });

    const grantedAccess = async (granted) => {
        console.log('MyClinicians.grantedAccess', granted);
        await caller.call(async () => {
            await patientAccessService.grantPatientAccessTo(
                granted.clinicianId,
                granted.patientAccessId,
                granted.endDate);
        });
    };

    const deniedAccess = async (denied) => {
        console.log('MyClinicians.deniedAccess', denied);
        await caller.call(async () => {
            await patientAccessService.revokePatientAccessFrom(
                denied.clinicianId,
                denied.patientAccessId);
        });
    };

    const pausedAccess = async (paused) => {
        console.log('MyClinicians.pausedAccess', paused);
        await caller.call(async () => {
            await patientAccessService.pausePatientAccessTo(
                paused.clinicianId,
                paused.patientAccessId);
        });
    };

    const resumedAccess = async (resumed) => {
        console.log('MyClinicians.resumedAccess', resumed);
        await caller.call(async () => {
            await patientAccessService.resumePatientAccessTo(
                resumed.clinicianId,
                resumed.patientAccessId,
                resumed.endDate);
        });
    };

    const reactivatedAccess = async (reactivated) => {
        console.log('MyClinicians.reactivatedAccess', reactivated);
        await caller.call(async () => {
            await patientAccessService.reactivatePatientAccessTo(
                reactivated.clinicianId,
                reactivated.patientAccessId,
                reactivated.endDate);
        });
    };

    const refreshList = async () => {
        console.log('MyClinicians.refreshList');
        await caller.call(async () => {
            await patientAccessService.refreshPatientAccessForPatient();
        });
    }
</script>
