import dispatcher from '@/Connections/Dispatcher.js'
import * as strings from '@/Shared/Strings.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

const createImmunization = async (vaccineCode, vaccineText, vaccineSystem, vaccineProductname, vaccineComplete, vaccineExpirationDate, administeringDate) => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let clinicianId = dispatcher.getFromLocal(strings.CLINICIANID);

    let immunization = {
        clinicianId,
        vaccineCode,
        vaccineText,
        vaccineSystem,
        vaccineProductname,
        vaccineComplete,
        vaccineExpirationDate,
        administeringDate,
    };

    let result = await dispatcher.postToApi(`patients/${patientId}/immunizations`, immunization);
    handleApiError(result, 'Error while creating immunization.');

    return result.data.immunizationId;
}

const getImmunization = async (immunizationId) => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let result = await dispatcher.getFromApi(`patients/${patientId}/immunizations/${immunizationId}`);
    handleApiError(result, 'Error while reading immunization.');

    return result.data;
}

const getImmunizations = async () => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let result = await dispatcher.getFromApi(`patients/${patientId}/immunizations`);
    handleApiError(result, 'Error while reading immunizations.');

    return result.data.immunizations || [];
}

const completeImmunization = async (immunizationId) => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let clinicianId = dispatcher.getFromLocal(strings.CLINICIANID);
    let result = await dispatcher.putToApi(`patients/${patientId}/immunizations/${immunizationId}/complete`, { clinicianId });
    handleApiError(result, 'Error while completing immunization.');
}

const deleteImmunization = async (immunizationId) => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let clinicianId = dispatcher.getFromLocal(strings.CLINICIANID);
    let result = await dispatcher.deleteFromApi(`patients/${patientId}/immunizations/${immunizationId}`, { clinicianId });
    handleApiError(result, 'Error while deleting immunization.');
}

export default {
    name: 'ImmunizationService',
    createImmunization,
    getImmunization,
    getImmunizations,
    completeImmunization,
    deleteImmunization,
}

import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useImmunizationService = () => {
    const store = useStore();

    const createImmunization = async (vaccineCode, vaccineText, vaccineSystem, vaccineProductname, vaccineBatchnumber, vaccineComplete, vaccineExpirationDate, administeringDate) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        let immunization = {
            clinicianId,
            vaccineCode,
            vaccineText,
            vaccineSystem,
            vaccineProductname,
            vaccineBatchnumber,
            vaccineComplete,
            vaccineExpirationDate,
            administeringDate,
        };

        await serviceCall(
            async () => {
                const result = await dispatcher.postToApi(`patients/${patientId}/immunizations`, immunization);
                handleApiError(result, 'Error while creating immunization.');

                immunization.immunizationId = result.data.immunizationId;
                immunization.date = new Date().toISOString();
                immunization.clinicianGivenNames = store.state.clinician.givenNames;
                immunization.clinicianFamilyName = store.state.clinician.familyName;
                immunization.patientId = patientId;
                immunization.patientGivenNames = store.state.patient.givenNames;
                immunization.patientFamilyName = store.state.patient.familyName;

                store.commit('addImmunization', immunization);
            }
        );
    }

    const completeImmunization = async (immunizationId) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        let immunization = { immunizationId, clinicianId };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`patients/${patientId}/immunizations/${immunizationId}/complete`, immunization);
                handleApiError(result, 'Error while completing immunization.');

                store.commit('setImmunizationComplete', immunization);
            }
        );
    }

    const loadImmunizations = async () => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                const result = await dispatcher.getFromApi(`patients/${patientId}/immunizations`);
                handleApiError(result, 'Error while reading immunizations.');

                let immunizations = result.data.immunizations || [];
                store.commit('setImmunizations', immunizations);
            }
        );
    }

    return {
        createImmunization,
        completeImmunization,
        loadImmunizations,
    }
}