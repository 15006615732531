<template>
    <div class="overlay overlay--dashboard">
        <bar :title="$t('clinicianBookingRequests.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="search">
                        <search-input v-model="filterInput" name="search" :placeholder="$t('clinicianBookingRequests.searchBoxPlaceholder')" />
                        <svg-icon icon-name="search" />
                    </div>
                </div>
            </div>
        </div>
        <list-clinic-booking-requests :clinic-id="clinicId" :filter="filterInput" />
    </div>
</template>


<script setup>
    import ListClinicBookingRequests from '@/Components/Consultations/BookingRequest/ListClinicBookingRequests';
    import SearchInput from '@/Components/Shared/Form/SearchInput';

    import { ref } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    let filterInput = ref('');

    const closeDialog = () => router.push({ name: 'dashboard' });
</script>