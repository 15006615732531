import { computed, provide, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
import { useBookingService } from '@/Components/Consultations/Booking/BookingService.js';
import { useBookingRequestService } from '@/Components/Consultations/BookingRequest/BookingRequestService.js';
import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
import ls from '@/Connections/LocalStorageProxy.js';
import { useUserNotificationsService } from '@/Components/Organizations/Notifications/NotificationsService.js';
import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

export const usePollingNotifications = (showInvalidRefreshTokenDialog, showMissingPatientAccessDialog) => {
    const store = useStore();
    const caller = useExceptionwrappedCaller();
    const patientAccessService = usePatientAccessService();
    const userNotificationsService = useUserNotificationsService();
    const bookingService = useBookingService();
    const bookingRequestService = useBookingRequestService();
    const messagingService = useMessagingService();

    const isOnline = computed(() => store.getters.getIsOnline);

    let notHandler = null;
    let isUpdatingNotifications = false;
    let notHandlerTrigger = ref(0);

    watch(
        () => notHandlerTrigger.value,
        async () => await updateNotifications());


    const notHandlerActions = {
        start: () => {
            if (isOnline.value) {
                notHandler = setTimeout(() => notHandlerTrigger.value = new Date().getTime(), 7500);
                //console.log('Starting notification handler......');
            }
        },
        terminate: () => {
            if (notHandler) {
                clearTimeout(notHandler);
                notHandler = null;
                //console.log('Terminating notification handler......');
            }
        }
    };

    const notActions = [];
    const registerNotificationAction = (type, action) => {
        notActions.push({ type, action });
    }

    registerNotificationAction(2,
        async () => {
            //console.log('...appointment/booking info received')
            if (store.getters.isPatient)
                await bookingService.refreshPatientAppointments();
            else {
                await bookingService.refreshClinicianBookings();
                await bookingRequestService.refreshClinicBookingRequests();
            }
        });
    registerNotificationAction(3,
        async () => {
            //console.log('...patient access changed')
            if (store.getters.isPatient)
                await patientAccessService.refreshPatientAccessForPatient();
            else {
                await patientAccessService.refreshPatientAccessForClinician();

                let userId = store.getters.getUserId;
                // cleanup patientdata from persistent store for all patients to whom this clinician hasn't got access anymore.
                store.getters.getCliniciansPatientAccess.forEach((a) => {
                    if (!store.getters.clinicianCanAccessPatientData(a.patientId)) {
                        const userPatientLSId = userId + '_p_' + a.patientId;
                        ls.remove(userPatientLSId);
                    }
                });

                // clean up patientdata from persistent store for all deleted associations
                const lsKeys = ls.getKeysByPartialKey(userId + '_p_');
                console.log('CLEANUP DELETED PATIENT ACCESS - ALL CLINICIANS DATAKEYS', lsKeys);
                lsKeys.forEach((k) => {
                    let patientId = k.replace(userId + '_p_', '');
                    console.log('CHECK FOR DELETION - FOUND PATIENTID', k, patientId);
                    if (patientId && !store.getters.clinicianHasAccessToPatient(patientId)) {
                        console.log('CLEANING UP PATIENTDATA OF DELETED ASSOCIATION', k);
                        ls.remove(k);
                    }
                });

                return () => {
                    //console.log('POST CHECK - PATIENT ACCESS - CURRENT ROUTE', route.name, route.path);
                    // check if current patient is still accessible
                    if (!store.getters.clinicianCanAccessPatientData(store.getters.getPatientId))
                        throw new Error('patientaccess');
                }
            }
        });
    registerNotificationAction(4,
        async () => {
            //console.log('...new message(s)')
            if (store.getters.isPatient)
                await messagingService.loadConversationsForPatient(store.getters.getPatientId);
            else
                await messagingService.loadConversationsForClinic(store.getters.getClinicianClinicId);
        });

    const updateNotifications = async () => {
        notHandlerActions.terminate();

        if (!store.getters.getIsLoggedOn) return;

        try {
            try {
                // function which can be used for running code after the main code is run e.g.,
                // for checking data state and throwing errors, after the main loop has finished.
                let postCheck = () => { };
                isUpdatingNotifications = true;

                let newNots = await userNotificationsService.updateUserNotifications();
                if (!newNots || newNots.length == 0) {
                    isUpdatingNotifications = false;
                    notHandlerActions.start();
                    return;
                }

                // dispatch
                let alreadyCalledTypes = [];
                for (var i = 0, l = newNots.length; i < l; i++) {
                    if (alreadyCalledTypes.includes(newNots[i].type)) continue;
                    let notAction = notActions.filter((a) => a.type == newNots[i].type);
                    if (notAction.length == 1) {
                        postCheck = await notAction[0].action();
                        if (!postCheck) {
                            postCheck = () => { };
                        }
                    }
                    alreadyCalledTypes.push(newNots[i].type);
                }

                store.commit('setUserNotifications', newNots);

                postCheck();
            }
            catch (e) {
                const msg = e.message || e;
                if (msg == 'invalidrefreshtoken' || msg == 'patientaccess')
                    throw e;
            }
            isUpdatingNotifications = false;
            notHandlerActions.start();
        }
        catch (e) {
            const msg = e.message || e;
            if (msg == 'invalidrefreshtoken') {
                await showInvalidRefreshTokenDialog();
            }
            else if (msg == 'patientaccess') {
                await showMissingPatientAccessDialog();
                notHandlerActions.start();
            }
            else {
                notHandlerActions.start();
            }
            isUpdatingNotifications = false;
        }
    };

    watchEffect(async () => {
        if (!store.getters.getIsLoggedOn) return;

        // when user is logged in then make sure access permissions are refreshed on app reload...
        await caller.call(async () => {
            if (store.getters.isPatient && store.getters.getPatientId)
                await patientAccessService.refreshPatientAccessForPatient();
            else if(store.getters.isClinician && store.getters.getClinicianId)
                await patientAccessService.refreshPatientAccessForClinician();

            if(isOnline.value)
                notHandlerActions.start();
        });
    });

    // notification polling must be stopped when offline and when logged off
    watch(
        () => isOnline.value,
        (online) => {
            if (!online) {
                notHandlerActions.terminate();
            }
            else if (!isUpdatingNotifications) {
                notHandlerActions.start();
            }
        });

    provide('notificationHandler', notHandlerActions);

    return {
        notHandlerActions,
    }
}