<template>
    <div class="overlay overlay--dashboard">
        <bar :title="$t('createBookingRequest.title')" @close="closeDialog" />
        <div class="container" v-if="request">
            <div class="row">
                <div class="col-xs-12">
                    <div class="user">
                        <div class="data">
                            <div class="info">
                                <patient-list-info v-bind="patientInfo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 pt-medium" data-test="bookingrequest-message">
                    <h4 class="pb-small"><b>{{$t('createBookingRequest.patientMessageLabel')}}:</b></h4>
                    <h5>{{request.message}}</h5>
                    <hr />
                </div>
                <div class="col-xs-12" v-if="mode=='none'">
                    <button type="button" class="btn btn__text btn__text--green-border left online-only" @click="rejectRequest" data-test="bookingrequest-reject"><h4>{{$t('general.reject')}}</h4></button>
                    <button type="button" class="btn btn__text btn__text--green right online-only" @click="scheduleRequest" data-test="bookingrequest-schedule"><h4>{{$t('createBookingRequest.scheduleButton')}}</h4></button>
                </div>
                <reject-booking-request-form v-if="mode=='reject'" :bookingRequestId="props.bookingRequestId" @cancel="mode='none'" />
                <schedule-booking-form v-if="mode=='schedule'" :request="request" @cancel="mode='none'" />
            </div>
        </div>
    </div>
</template>


<script setup>
    import PatientListInfo from '@/Components/Consultations/BookingRequest/PatientListInfo';
    import RejectBookingRequestForm from '@/Components/Consultations/BookingRequest/RejectBookingRequestForm';
    import ScheduleBookingForm from '@/Components/Consultations/BookingRequest/ScheduleBookingForm';

    import { computed, defineProps, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useBookingRequestService } from '@/Components/Consultations/BookingRequest/BookingRequestService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        bookingRequestId: String,
    });

    const store = useStore();
    const router = useRouter();
    const service = useBookingRequestService();
    const caller = useExceptionwrappedCaller();

    let request = ref(null);
    let patientInfo = ref(null);
    let mode = ref('none');

    const clinician = computed(() => store.getters.getClinician);

    watchEffect(async () => {
        if (!clinician.value || !clinician.value.clinicId || !props.bookingRequestId) return;
        await caller.call(async () => {
            request.value = await service.getBookingRequest(clinician.value.clinicId, props.bookingRequestId);
            let connected = store.getters.clinicianHasAccessToPatient(request.value.patientId);

            patientInfo.value = {
                patientId: request.value.patientId,
                name: [request.value.patientGivenNames, request.value.patientFamilyName].join(' '),
                date: request.value.date,
                connected: connected,
            };
        });
    });

    const rejectRequest = () => mode.value = 'reject';

    const scheduleRequest = () => mode.value = 'schedule';

    const closeDialog = () => router.push({ name: 'clinician booking requests' });
</script>