import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';
import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

export const usePregnancyEpisodeOfCareService = () => {
    const store = useStore();

    const loadPregnancyEpisodesOfCare = async () => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                let eocs = await fnLoadPregnancyEpisodesOfCare(patientId);

                store.commit('refreshPregnancyEpisodesOfCare', eocs);
            }
        );
    }   

    const createPregnancyEpisodeOfCare = async (termDate, firstEncounterDate) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        let eoc = {
            episodeOfCareType: 'PregnancyEpisodeOfCare',
            clinicianId,
            termDate,
            firstEncounterDate
        };

        await serviceCall(
            async () => {
                eoc.episodeOfCareId = await fnCreatePregnancyEpisodeOfCare(patientId, eoc);

                eoc.date = new Date();
                eoc.clinicianGivenNames = store.getters.getClinician.givenNames;
                eoc.clinicianFamilyName = store.getters.getClinician.familyName;
                eoc.patientId = patientId;
                eoc.patientGivenNames = store.getters.getPatient.givenNames;
                eoc.patientFamilyName = store.getters.getPatient.familyName;

                store.commit('addEpisodeOfCare', eoc);
            }
        );
    }

    const changePregnancyTermDate = async (episodeOfCareId, termDate) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        let eoc = {
            episodeOfCareId,
            clinicianId,
            termDate
        }

        await serviceCall(
            async () => {
                await fnChangePregnancyEpisodeOfCareTermDate(patientId, eoc);

                eoc.date = new Date();
                eoc.clinicianGivenNames = store.getters.getClinician.givenNames;
                eoc.clinicianFamilyName = store.getters.getClinician.familyName;
                eoc.patientId = patientId;
                eoc.patientGivenNames = store.getters.getPatient.givenNames;
                eoc.patientFamilyName = store.getters.getPatient.familyName;

                store.commit('changeEpisodeOfCareTermDate', eoc);
            }
        );
    }

    const changePregnancyEncounterDate = async (episodeOfCareId, firstEncounterDate) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        let eoc = {
            episodeOfCareId,
            clinicianId,
            firstEncounterDate
        }
        await serviceCall(
            async () => {
                await fnChangePregnancyEpisodeOfCareEncounterDate(patientId, eoc);

                eoc.date = new Date();
                eoc.clinicianGivenNames = store.getters.getClinician.givenNames;
                eoc.clinicianFamilyName = store.getters.getClinician.familyName;
                eoc.patientId = patientId;
                eoc.patientGivenNames = store.getters.getPatient.givenNames;
                eoc.patientFamilyName = store.getters.getPatient.familyName;

                store.commit('changeEpisodeOfCareEncounterDates', eoc);
            }
        );
    }

    return {
        loadPregnancyEpisodesOfCare,
        createPregnancyEpisodeOfCare,
        changePregnancyTermDate,
        changePregnancyEncounterDate,
    }
}


// Server API-calls
const fnLoadPregnancyEpisodesOfCare = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/episodesofcare/pregnancy`);
    handleApiError(result, 'Error while getting patient\'s pregnancy episodes of care.');
    return result.data.episodesOfCare;
}

const fnCreatePregnancyEpisodeOfCare = async (patientId, eoc) => {
    let result = await dispatcher.postToApi(`patients/${patientId}/episodesofcare/pregnancy`, eoc);
    handleApiError(result, 'Error while creating patient\'s pregnancy episode of care.');
    return result.data.episodeOfCareId;
}

const fnChangePregnancyEpisodeOfCareTermDate = async (patientId, eoc) => {
    const result = await dispatcher.putToApi(`patients/${patientId}/episodesofcare/${eoc.episodeOfCareId}/pregnancy/termdate`, eoc);
    handleApiError(result, 'Error while changing patient\'s pregnancy episode of care term date.');
}

const fnChangePregnancyEpisodeOfCareEncounterDate = async (patientId, eoc) => {
    const result = await dispatcher.putToApi(`patients/${patientId}/episodesofcare/${eoc.episodeOfCareId}/pregnancy/encounterdate`, eoc);
    handleApiError(result, 'Error while changing patient\'s pregnancy episode of care encounter date.');
}
