<template>
    <div id="topNavigation" class="container" :data-green="props.green">
        <!-- Navigation Top -->
        <div class="row full-height">
            <div class="col-xs-3 full-height">
                <button class="btn btn__icon btn__icon--medium left" @click="notificationsClick" data-test="notification-button">
                    <div class="counter" :class="{hasNotifications : notificationCount}"><span>{{notificationCount}}</span></div>
                    <svg-icon icon-name="notification" />
                </button>
            </div>
            <div class="col-xs-6 full-height">
                <div class="icon-small full-height" @click="navigateToRoot" data-test="logo-link">
                    <img v-if="green" :src="require('@/Assets/Images/logo-white.png')" />
                    <img v-if="!green" :src="require('@/Assets/Images/logo-red.png')" />
                </div>
            </div>
            <div class="col-xs-3 full-height">
                <button class="btn btn__icon btn__icon--medium right burger" @click="toggleNav" :data-page="navigationIsOpen" data-test="hamburger-button">
                    <svg-icon icon-name="cross" class="close" />
                    <svg-icon icon-name="burger" class="open" />
                </button>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <Navigation :dp="navigationIsOpen" />
    </Teleport>
</template>

<script setup>
    import Navigation from '@/Components/Shared/Navigation';

    import { computed, defineProps, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';

    const props = defineProps({
        green: Boolean,
    });

    const router = useRouter();
    const store = useStore();

    const notificationCount = computed(() => store.getters.getUserNotifications.length);

    const navigationIsOpen = computed(() => store.state.sideNavigationIsOpen);

    const notificationsClick = () => store.commit('toggleNotifications');

    const navigateToRoot = () => router.push('/');

    const closeMenu = () => navToggle.value = false;

    let navToggle = ref(false);

    const toggleNav = () => {
        navToggle.value = !navToggle.value;
        store.commit('toggleSideNavigation');
    }

    const navigateTo = (name) => {
        navToggle.value = false;
        router.push({ name });
    }
</script>
