import Exception from '@/Components/Shared/Exceptions.js';

export const handleApiError = (result, defaultMessage) => {
    if (result.status > 299) {
        if (result.status == 400 && result.data?.errors?.length) {
            throw new Exception(
                result.data.errors[0].message,
                result.data.errors[0].hint,
                result.data.errors[0].code);
        }
        else if (result.status == 401 && result.data?.errors?.length) {
            //console.log('401 ERRORHANDLING', result);
            throw new Exception(result.data.errors[0].message);
        }

        //console.log('ERRORHANDLING', result);
        throw new Exception(defaultMessage);
    }
}