import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';


const fnGetPatientAccessForPatient = async (patientId) => {
    const result = await dispatcher.getFromApi(`patients/${patientId}/access`);
    handleApiError(result, 'Error when fetching patients list.');

    return result.data.requests;
}

const fnGetPatientAccessForClinician = async (clinicianId) => {
    const result = await dispatcher.getFromApi(`clinicians/${clinicianId}/patients/access`);
    handleApiError(result, 'Error when fetching patients list.');

    return result.data.requests;
}

const fnGetPatientAccessRequestForClinician = async (clinicianId, patientAccessId) => {
    const result = await dispatcher.getFromApi(`clinicians/${clinicianId}/patients/access/${patientAccessId}`);
    handleApiError(result, 'Error when fetching patients list.');

    return result.data;
}

const fnRequestPatientAccessTo = async (clinicianId, patientId) => {
    const result = await dispatcher.postToApi(`patients/${patientId}/access`, { clinicianId });
    handleApiError(result, 'Error when requesting access to patient data.');

    return result.data.patientAccessId;
}

const fnResendRequestPatientAccessTo = async (clinicianId, patientId) => {
    const result = await dispatcher.postToApi(`patients/${patientId}/access/resend`, { clinicianId });
    handleApiError(result, 'Error when resending access request to patient.');

    //return result.data.patientAccessId;
}

const fnGrantPatientAccessTo = async (patientId, clinicianId, patientAccessId, endDate) => {
    const requestData = {
        clinicianId,
        patientAccessId,
        endDate,
    };
    const result = await dispatcher.putToApi(`patients/${patientId}/access`, requestData);
    handleApiError(result, 'Error when granting access to your data.');
}

const fnPausePatientAccessTo = async (patientId, clinicianId, patientAccessId) => {
    const requestData = {
        clinicianId,
        patientAccessId,
    };
    const result = await dispatcher.putToApi(`patients/${patientId}/access/pause`, requestData);
    handleApiError(result, 'Error when pausing access to your data.');
}

const fnResumePatientAccessTo = async (patientId, clinicianId, patientAccessId, endDate) => {
    const requestData = {
        clinicianId,
        patientAccessId,
        endDate,
    };
    const result = await dispatcher.putToApi(`patients/${patientId}/access/resume`, requestData);
    handleApiError(result, 'Error when pausing access to your data.');
}

const fnReactivatePatientAccessTo = async (patientId, clinicianId, patientAccessId, endDate) => {
    const requestData = {
        clinicianId,
        patientAccessId,
        endDate,
    };
    const result = await dispatcher.putToApi(`patients/${patientId}/access/reactivate`, requestData);
    handleApiError(result, 'Error when reactivating access to your data.');
}

const fnRevokePatientAccessFrom = async (patientId, clinicianId, patientAccessId) => {
    const requestData = {
        clinicianId,
        patientAccessId,
    };
    const result = await dispatcher.deleteFromApi(`patients/${patientId}/access`, requestData);
    handleApiError(result, 'Error when revoking access to your data.');
}



import { serviceCall } from '@/Shared/ServiceWrapper.js';
import { useStore } from 'vuex';

export const usePatientAccessService = () => {
    const store = useStore();

    const refreshPatientAccessForClinician = async () => {
        const clinicianId = store.getters.getClinicianId;
        await serviceCall(
            async () => {
                const patientAccess = await fnGetPatientAccessForClinician(clinicianId);
                store.commit('setPatientsAccess', patientAccess);
            }
        );
    }

    const refreshPatientAccessForPatient = async () => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                const patientAccess = await fnGetPatientAccessForPatient(patientId);
                store.commit('setPatientsAccess', patientAccess);
            }
        );
    }

    const getPatientAccessForClinician = async (clinicianId) => {
        const clinicianIdToGet = clinicianId || store.getters.getClinicianId;
        return await serviceCall(
            async () => {
                return await fnGetPatientAccessForClinician(clinicianIdToGet);
            }
        );
    }

    const getPatientAccessForPatient = async (patientId) => {
        const patientIdToGet = patientId || store.getters.getPatientId;
        return await serviceCall(
            async () => {
                return await fnGetPatientAccessForPatient(patientIdToGet);
            }
        );
    }

    const getPatientAccessRequestForClinician = async (patientAccessId) => {
        const clinicianId = store.getters.getClinicianId;
        return await serviceCall(
            async () => {
                return await fnGetPatientAccessRequestForClinician(clinicianId, patientAccessId);
            }
        );
    }

    const requestPatientAccessTo = async (patientId) => {
        const clinicianId = store.getters.getClinicianId;
        await serviceCall(
            async () => {
                const patientAccessId = await fnRequestPatientAccessTo(clinicianId, patientId);

                let pa = store.getters.getPatientAccessRequest(patientAccessId);
                if (!pa)
                    pa = await fnGetPatientAccessRequestForClinician(clinicianId, patientAccessId);
                store.commit('setPatientAccessRequest', pa);
            }
        );
    }

    const resendRequestPatientAccessTo = async (patientId) => {
        const clinicianId = store.getters.getClinicianId;
        await serviceCall(
            async () => {
                await fnResendRequestPatientAccessTo(clinicianId, patientId);
                store.commit('setPatientAccessResend', { patientId, clinicianId, resendDate: new Date().toUTCString() });
            }
        );
    }


    const grantPatientAccessTo = async (clinicianId, patientAccessId, endDate) => {
        // patient grants access to clinician
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnGrantPatientAccessTo(patientId, clinicianId, patientAccessId, endDate);
                store.commit('setPatientAccessGranted', { clinicianId, patientAccessId, endDate });
            }
        );
    }
    const revokePatientAccessFrom = async (clinicianId, patientAccessId) => {
        // patient revokes/denies access for clinician
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnRevokePatientAccessFrom(patientId, clinicianId, patientAccessId);
                store.commit('setPatientAccessRevoked', { clinicianId, patientAccessId });
            }
        );
    }
    const pausePatientAccessTo = async (clinicianId, patientAccessId) => {
        // patient pauses access for clinician
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnPausePatientAccessTo(patientId, clinicianId, patientAccessId);
                store.commit('setPatientAccessPaused', { clinicianId, patientAccessId });
            }
        );
    }
    const resumePatientAccessTo = async (clinicianId, patientAccessId, endDate) => {
        // patient resumes access for clinician
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnResumePatientAccessTo(patientId, clinicianId, patientAccessId, endDate);
                store.commit('setPatientAccessResumed', { clinicianId, patientAccessId, endDate });
            }
        );
    }
    const reactivatePatientAccessTo = async (clinicianId, patientAccessId, endDate) => {
        // patient reactivates access for clinician
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnReactivatePatientAccessTo(patientId, clinicianId, patientAccessId, endDate);
                store.commit('setPatientAccessReactivated', { clinicianId, patientAccessId, endDate });
            }
        );
    }



    return {
        refreshPatientAccessForClinician,
        refreshPatientAccessForPatient,
        getPatientAccessForClinician,
        getPatientAccessForPatient,
        getPatientAccessRequestForClinician,
        requestPatientAccessTo,
        resendRequestPatientAccessTo,
        grantPatientAccessTo,
        revokePatientAccessFrom,
        pausePatientAccessTo,
        resumePatientAccessTo,
        reactivatePatientAccessTo,
    }
}