import dispatcher from '@/Connections/Dispatcher.js'
import * as strings from '@/Shared/Strings.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';


//const createMeasurement = async (measurementValue) => {
//    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
//    let clinicianId = dispatcher.getFromLocal(strings.CLINICIANID);

//    let measurement = {
//        clinicianId,
//        value: measurementValue
//    };

//    let result = await dispatcher.postToApi(`patients/${patientId}/measurements`, measurement);
//    handleApiError(result, 'Error while creating measurement.');

//    return result.data.measurementId;
//}

const getMeasurement = async (measurementId) => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let result = await dispatcher.getFromApi(`patients/${patientId}/measurements/${measurementId}`);
    handleApiError(result, 'Error while reading measurement.');

    return result.data;
}

//const getMeasurements = async () => {
//    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
//    let result = await dispatcher.getFromApi(`patients/${patientId}/measurements/`);
//    handleApiError(result, 'Error while reading measurement.');

//    return result.data.measurements;
//}

const deleteMeasurement = async (measurementId) => {
    let patientId = dispatcher.getFromLocal(strings.PATIENTID);
    let clinicianId = dispatcher.getFromLocal(strings.CLINICIANID);
    let result = await dispatcher.deleteFromApi(`patients/${patientId}/measurements/${measurementId}`, { clinicianId });
    handleApiError(result, 'Error while deleting measurement.');
}

export default {
    name: 'MeasurementService',
    getMeasurement,
    deleteMeasurement,
}

import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useMeasurementService = () => {
    const store = useStore();

    const createMeasurement = async(measurementValue) => {
        let patientId = store.getters.getPatientId;
        let clinicianId = store.getters.getClinicianId;

        const requestData = {
            clinicianId,
            value: measurementValue
        };

        await serviceCall(
            async () => {
                const result = await dispatcher.postToApi(`patients/${patientId}/measurements`, requestData);
                handleApiError(result, 'Error while creating measurement.');

                const measurement = {
                    measurementId: result.data.measurementId,
                    date: new Date().toISOString(),
                    deleted: false,
                    clinicianId,
                    clinicianGivenNames: store.getters.getClinician.givenNames,
                    clinicianFamilyName: store.getters.getClinician.familyName,
                    patientId,
                    patientGivenNames: store.getters.getPatient.givenNames,
                    patientFamilyName: store.getters.getPatient.familyName,
                    measurementValue
                };

                store.commit('addMeasurement', measurement);
            }
        );
    }

    const loadMeasurements = async() => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                const result = await dispatcher.getFromApi(`patients/${patientId}/measurements`);
                handleApiError(result, 'Error while reading measurement.');

                const measurements = result.data.measurements;

                store.commit('setMeasurements', measurements);
            }
        );
    }

    return {
        createMeasurement,
        loadMeasurements,
    }
}
