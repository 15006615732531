import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useUserService } from '@/Components/Users/User/UserService.js';


export const useErrorDialogs = (errorDialog) => {
    const store = useStore();
    const userService = useUserService();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    // this is called by either the notification handler loop directly or error handler event (via a custom event handler)
    const showInvalidRefreshTokenDialog = async () => {
        const showDialog = route.name != 'welcome';
        console.log('[ERROR HANDLER] INVALID REFRESH TOKEN - show dialog: ', showDialog);
        await userService.logoffUser();
        if (showDialog) {
            router.push({ name: 'welcome' });
            await errorDialog({ header: t('errors.invalidrefreshtokenTitle'), body: t('errors.invalidrefreshtoken') })
        }
    }

    // this is called by either the notification handler loop directly or error handler event (via a custom event handler)
    const showMissingPatientAccessDialog = async () => {
        // cleanup current patient's data if access the current patient is lost to, redirect to patients list and show dialog.
        const currentPatientId = store.getters.getPatientId;
        const canAccessPatient = store.getters.clinicianCanAccessPatientData(currentPatientId);
        console.log('SHOW MISSING PATIENT ACCESS DIALOG', currentPatientId, canAccessPatient);
        if (currentPatientId && !canAccessPatient) {
            store.commit('resetPatientInfo');
            if ((route.path == '/patient' || route.path.startsWith('/patient/'))) {
                router.push({ name: 'mypatients' });
                await errorDialog({ header: t('errors.patientaccessTitle'), body: t('errors.patientaccess') });
            }
        }
    }

    return {
        showInvalidRefreshTokenDialog,
        showMissingPatientAccessDialog,
    }
}