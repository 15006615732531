<template>
    <form autocomplete="off">
        <input type="text" v-model="value" :placeholder="placeholder" :data-state="dataState"  formnovalidate />
    </form>
</template>

<script setup>
    import { defineEmits, defineProps } from 'vue';
    import { EmptyString } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    const props = defineProps({
        modelValue: EmptyString,
        placeholder: EmptyString,
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
    });

    const emit = defineEmits(['update:modelValue', 'updated']);

    const { value, dataState } = useInputValidator(props, { emit });
</script>