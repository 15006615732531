<template>
    <div class="search-and-results" :class="{ showResults: showResults, hasInput: search}">
        <div class="search-block">
            <button class="close" @click="toggleResults" :class="{ showResults: showResults, hasInput: search}">
                <svg-icon icon-name="chevron-down" />
            </button>
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="search online-only">
                            <search-input v-model="search" name="search" :placeholder="$t('findClinic.searchBoxPlaceholder')" />
                            <svg-icon icon-name="search" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <list-clinics :clinics="clinics"></list-clinics>
    </div>
</template>


<script>
    import SearchInput from '@/Components/Shared/Form/SearchInput';
    import ListClinics from '@/Components/Consultations/BookingRequest/ListClinics';

    import { ref, watch } from 'vue';
    import { useClinicService } from '@/Components/Organizations/Clinic/ClinicService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    export default {
        name: '',
        components: {
            SearchInput,
            ListClinics,
        },
        setup() {
            const service = useClinicService();
            const caller = useExceptionwrappedCaller();
            let search = ref('');
            let clinics = ref([]);
            let showResults = ref(false);

            const toggleResults = () => {
                showResults.value = !showResults.value;
            }

            watch(() => search.value, async (nv) => {
                showResults.value = nv;
                if (nv) {
                    await caller.call(async () => {
                        clinics.value = await service.findClinics(nv);
                    });
                }
                else
                    clinics.value = [];
            });

            return {
                search,
                clinics,
                showResults,
                toggleResults,
            };
        }
    }
</script>