<template>
    <div class="measurement-create-component">
        <div class="row">
            <template v-if="definition.displayType==0">
                <div class="col-xs-12">
                    <text-box v-model="value"
                              :name="'_'+definition.codingCode"
                              :label="labelText"
                              :helperText="$t(definition.titleKey + 'HelperText')"
                              :errorText="$t(definition.titleKey + 'ErrorText')"
                              :required="definition.required"
                              @updated="stateChangedEx"></text-box>
                </div>
            </template>

            <template v-if="definition.displayType==1">
                <div class="col-xs-12">
                    <text-area v-model="value"
                               :name="'_'+definition.codingCode"
                               :label="labelText"
                               :helperText="$t(definition.titleKey + 'HelperText')"
                               :errorText="$t(definition.titleKey + 'ErrorText')"
                               :required="definition.required"
                               @updated="stateChangedEx"></text-area>
                </div>
            </template>

            <template v-if="definition.displayType==2">
                <div class="col-xs-12">
                    <radio-list v-model="value"
                                :name="'_'+definition.codingCode"
                                :elements="definition.valueSetValues.map((vk) => { return { value: $t(vk.titleKey+''), key: vk.value }})"
                                :label="labelText"
                                :helperText="$t(definition.titleKey + 'HelperText')"
                                :errorText="$t(definition.titleKey + 'ErrorText')"
                                :required="definition.required"
                                @updated="stateChangedEx"></radio-list>
                </div>
            </template>

            <template v-if="definition.displayType==3">
                <div class="col-xs-12">
                    <drop-down-box v-model="value"
                                   :name="'_'+definition.codingCode"
                                   :elements="definition.valueSetValues.map((vk) => { return { value: $t(vk.titleKey+''), key: vk.value }})"
                                   :label="labelText"
                                   :helperText="$t(definition.titleKey + 'HelperText')"
                                   :errorText="$t(definition.titleKey + 'ErrorText')"
                                   :required="definition.required"
                                   @updated="stateChangedEx"></drop-down-box>
                </div>
            </template>

            <template v-if="definition.displayType==5">
                <div class="col-xs-12">
                    <number v-model="value"
                            :name="'_'+definition.codingCode"
                            :label="labelText"
                            :helperText="$t(definition.titleKey + 'HelperText')"
                            :errorText="$t(definition.titleKey + 'ErrorText')"
                            :required="definition.required"
                            @updated="stateChangedEx"></number>
                </div>
            </template>

            <template v-if="definition.displayType==6">
                <div style="height:65px">
                    <image-list-picker v-model="value"
                                       :editable="true"
                                       :max-images="definition.displayTypeMeta?.maxImageCount || 1"></image-list-picker>
                    <template v-if="definition.titleKey==bloodTypeTitleKey">
                        <div class="col-xs-9 col-sm-10 ec-promo-text">
                            <div class="ec-promo-headline">
                                {{$t(definition.titleKey + 'EldonCardMethodText')}}
                            </div>
                            <div class="ec-promo-subline">
                                {{$t(definition.titleKey + 'AlternativeMethodText')}}
                            </div>
                            <div class="eldon-logo">
                                <img :src="eldonLogo" alt="Eldon Biologicals A/S logo" />
                            </div>
                        </div>
                    </template>
                </div>            
            </template>

            <template v-if="definition.displayType==7">
                <div class="col-xs-12">
                    <check-box v-model="value"
                               type="switch"
                               :name="'_'+definition.codingCode"
                               :label="labelText"
                               :helperText="$t(definition.titleKey + 'HelperText')"
                               :required="definition.required"
                               @updated="stateChangedEx"></check-box>
                </div>
            </template>
        </div>
    </div>
</template>


<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue'
    import TextArea from '@/Components/Shared/Form/TextArea.vue'
    import DropDownBox from '@/Components/Shared/Form/DropDownBox.vue'
    import RadioList from '@/Components/Shared/Form/RadioList.vue'
    import Number from '@/Components/Shared/Form/Number.vue'
    import ImageListPicker from '@/Components/Shared/Form/ImageListPicker.vue'
    import CheckBox from '@/Components/Shared/Form/CheckBox.vue'

    import { computed, defineEmits, defineProps } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'

    const props = defineProps({
        modelValue: String,
        definition: Object,
    });
    const emit = defineEmits(['update:modelValue', 'updated']);

    const { t } = useI18n();
    const { stateChanged } = useFormValidator();

    const bloodTypeTitleKey = 'measurement.bloodTypeImages';
    const eldonLogo = require('@/Assets/Images/eldoncardlogo2.jpg');

    const value = computed({
        get() { return props.modelValue; },
        set(value) {
            emit('update:modelValue', value);
        }
    });

    const stateChangedEx = (newState) => {
        console.log('[CreateMeasurementComponent] State changed', newState);
        stateChanged(newState);
        emit('updated', newState);
    };

    const labelText = computed(() => {
        let txt = t(props.definition.titleKey + '');
        if (props.definition.unitText)
            txt += ` (${props.definition.unitText.replace(/\^(\d+)/g, '<span style="vertical-align:super;font-size:.8rem">$1</span>')})`;
        return txt;
    });
</script>