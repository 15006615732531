<template>
    <div class="overlay hide-down" id="terms">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="language language-en" :class="{show:(lang=='en')}">
                        <h1>MY PERSONAL HEALTH BANK APPLICATION DISCLAIMER</h1>
                        <h1>TERMS OF USE AGREEMENT</h1>
                        <p>This Terms of Use Agreement (this “Agreement”) is entered into by and between the <b>MPHB</b> and “you,” the user of this Application, also known as “My Personal Health Bank” (the “Site”). Access to, use of and/or browsing of the Application is provided subject to the terms and conditions set forth herein. By accessing, using and/or browsing the Application, you hereby agree to these terms and conditions.</p>
                        <p>This Agreement contains warranty disclaimers and other provisions that limit <b>MPHB</b>   liability to you. Please read these terms and conditions carefully and in their entirety, as using, accessing and/or browsing the site constitutes acceptance of these terms and conditions. if you do not agree to be bound to each and every term and condition set forth herein, please exit the site immediately and do not use, access and/or browse the application.</p>
                        <p>By entering the Application, you acknowledge and agree that you have read and understand these terms and conditions, that the provisions, disclosures and disclaimers set forth herein are fair and reasonable, and that your agreement to follow and be bound by these terms and conditions is voluntary and is not the result of fraud, duress or undue influence exercised upon you by any person or entity</p>

                        <p>This disclaimer is part of the “Privacy Policy” which describes MPHB project policies and procedures on the collection, use and disclosure of your information when you use this application.</p>
                        <p>MPHB will facilitate that you have the possibility to create your own account which will be hosted by MPHB. Here you may store and retrieve your health information each time you visit your Medical Doctor at all facilities in your area of residence and throughout Tanzania as well as in case you visit another doctor outside Tanzania. MPHB team believes in empowering patients. This means that you as a patient will own your health data (just like you own your bank account and all the information you use in your financial transactions through your bank). The My personal Health Bank application offers the same opportunity and guarantees protection of your rights to privacy by upholding utmost ethical standards at all times.</p>

                        <p>MPHB may use Your data in an Anonymized way to provide and improve the Services offered to You by the My Personal Health Bank application and upon the approval of the Ministry of Health issue reports on  the prevalence of certain diseases in any area. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the University of Dodoma (UDOM), the Muhimbili University of Health and Allied Sciences (MUHAS) and the Southern Denmark University (SDU) in line with the rules and regulations of the country.</p>
                        <h2>INTERPRETATION AND DEFINITIONS</h2>
                        <h3>Interpretation</h3>
                        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                        <h3>Definitions</h3>
                        <p>For the purposes of this Privacy Policy:</p>
                        <ul class="indented no-bullets">
                            <li><b>Account</b> means a unique account created for You to access our Service or parts of our Service.</li>
                            <li><b>Application</b> means the software program provided by the Company downloaded by You on any electronic device, named MPHB</li>
                            <li><b>Anonymised</b> means any information that cannot be related to an identified or identifiable individual.</li>
                            <li><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to MPHB.</li>
                            <li><b>Country</b> refers to: Tanzania</li>
                            <li><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                            <li><b>Personal Data</b> is any information that relates to an identified or identifiable individual.</li>
                            <li><b>Service</b> refers to the Application.</li>
                            <li><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                            <li><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                            <li><b>You</b> refer to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                        </ul>

                        <h2>COLLECTING AND USING YOUR PERSONAL DATA</h2>
                        <h3>Types of Data Collected</h3>
                        <h4>Personal Data</h4>
                        <p>While using Our Service, We ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                        <ul class="indented no-bullets">
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Address, State, Province, Postal code, Area of residence</li>
                            <li>Demographic information</li>
                            <li>Image</li>
                            <li>Likewise, this application among others will seek to collect personal information regarding your medical history and parameters related to Your current medical issues (laboratory investigations, findings, examinations and diagnosis and services received.</li>
                        </ul>

                        <h4>Usage Data</h4>
                        <p>You reserve all the rights regarding access and use of Your data stored in the MPHB application. Doctors and other healthcare providers can only access and update the data after You have given Your consent. As part of the operation of the system, Usage Data will be collected automatically when using the Service.</p>
                        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                        <p>We may also collect information that Your browser sends whenever You visit Our Service or when you access the Service by or through a mobile device.</p>

                        <h4>Information that may be collected while using the Application</h4>
                        <p>While using our Application in order to provide features, we may collect:</p>
                        <ul>
                            <li>Information regarding your location by region or city (not address unless attached to a clinic)</li>
                            <li>Pictures and other information from your Device's camera and photo library</li>
                            <li>Quality of your medical consultations, e.g., evaluations, surveys or feedback</li>
                            <li>The cost related to your expenditures for health </li>
                            <li>Treatment and medical safety issues related to the care you receive</li>
                            <li>Treatment outcomes</li>
                        </ul>
                        <p>We use this information to provide features of Our Service, to improve and customize Our Service. This information may as well upon Your consent, be used to evaluate the impact of using the application in improving health care services and health outcomes. </p>

                        <h4>Use of Your Personal Data</h4>
                        <p>The Company may use the information collected about you for the limited purpose and related <b>MPHB</b> functionality, or as otherwise specifically described in this disclaimer and as permitted by applicable laws. Such limited purposes include circumstances where it is necessary for <b>MPHB</b> to provide or complete Services requested by, or for, You, or where You have given <b>MPHB</b> Your express consent. Your Personal Data may be used to perform a variety of purposes, including:</p>
                        <ul class="indented no-bullets">
                            <li><b>To provide and maintain Our Service,</b> including to monitor the usage of Our Service.</li>
                            <li><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                            <li><b>For the performance of the application:</b> the development, compliance and undertaking of the agreed performance criteria and indicators of the application.</li>
                            <li><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                            <li><b>To provide You</b> with news and general information about other functions, services and tools which may be added as part of improving the system.</li>
                            <li><b>To manage Your requests:</b> To attend and manage user’s requests to Us.</li>
                            <li><b>For other purposes:</b> Confidentiality is maintained at high level. Upon your consent we may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
                        </ul>

                        <b>MPHB</b> may share the Information collected about you with third parties for the limited purposes of providing the Services or as otherwise described herein as follows;
                        <ul>
                            <li>For the purpose of complying with any applicable law, legal process, governmental request, to enforce any of our legal rights, defend against legal claims, to investigate any illegal use, abuse, violations of our terms and any other similar uses.If We receive a judicial order that requires it to share or disclose Your information or information about You to a third party.</li>
                            <li><b>With Service Providers:</b> in relation to Your medical needs and consultations.</li>
                            <li>If MPHB re-organizes the operation of the Service within a different corporation, or if MPHB merges into or with other entities or merges the operation of the Service with a third party, MPHB shall be entitled to transfer to the new entity a copy of the Information about You collected from the Service, provided however, that those entities agree to be bound by the provisions of this policy.</li>
                            <li><b>With other users:</b> Upon Your request and consent, when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside Anonymously.</li>
                        </ul>

                        <h4>Retention of Your Personal Data</h4>
                        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

                        <h4>Transfer of Your Personal Data</h4>
                        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be stored, transferred to — and maintained in cloud solutions outside—of Your district, region, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. The support team maintaining the solution will also be located in different parts of the world</p>
                        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to have your data stored at, transferred to and the system maintained from various locations and in cloud solutions.</p>
                        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

                        <h4>Disclosure of Your Personal Data</h4>
                        <h4>Business Transactions</h4>
                        <p>If the Company is involved in a merger, acquisition or asset sale, Your Anonymized Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                        <h4>Law enforcement</h4>
                        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                        <h4>Other legal requirements</h4>
                        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                        <ul>
                            <li>Comply with a legal obligation</li>
                            <li>Protect and defend the rights or property of the Company</li>
                            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>Protect the personal safety of Users of the Service or the public</li>
                            <li>Protect against legal liability</li>
                        </ul>

                        <h4>Security of Your Personal Data</h4>
                        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

                        <h2>DETAILED INFORMATION ON THE PROCESSING OF YOUR PERSONAL DATA</h2>
                        <p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</p>

                        <h4>Usage, Performance and Miscellaneous</h4>
                        <p>We may use third-party Service Providers to provide better improvement of our Service. The services are amongst others, but are not limited to, the following: </p>
                        <div class="indented">
                            <h5>Postmark</h5>
                            <p>Postmark is an e-mail delivery service used for sending e-mail notifications. The service is operated by AC PM LLC.</p>
                            <p>Postmark may collect information from Your device.</p>
                            <p>The information gathered by Postmark is held in accordance with its Privacy Policy: <a href="https://postmarkapp.com/privacy-policy" target="_blank">https://postmarkapp.com/privacy-policy</a></p>

                            <h5>Twilio</h5>
                            <p>Twilio is a single platform with flexible APIs for any channel, built-in intelligence, and global infrastructure to support you at scale. We use it for sending SMS-notifications. The service is operated by Twilio Inc.</p>
                            <p>Twilio may collect information from Your Device.</p>
                            <p>The information gathered by Twilio is held in accordance with its Privacy Policy: <a href="https://www.twilio.com/legal/privacy" target="_blank">https://www.twilio.com/legal/privacy</a></p>

                            <h5>Google Maps</h5>
                            <p>Google Maps is a service where you can find local businesses, view maps and get driving directions. It is operated by Google.</p>
                            <p>Google Maps may collect information from Your Device.</p>
                            <p>The information gathered by Google Maps is held in accordance with the Privacy Policy of Google: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>

                            <h5>Google Firebase</h5>
                            <p>Google Firebase is a service that helps you build and run successful apps. We use it for communication between server and client. It is operated by Google.</p>
                            <p>Google Firebase may collect information from Your device.</p>
                            <p>The information gathered by Google Firebase is held in accordance with the Privacy Policy of Google: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>

                            <h5>Azure</h5>
                            <p>Azure is an open, flexible, enterprise-grade cloud computing platform. It is operated by Microsoft.</p>
                            <p>Azure may collect information from Your Device.</p>
                            <p>The information gathered by Azure is held in accordance with the Privacy Policy of Microsoft: <a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">https://privacy.microsoft.com/en-us/privacystatement</a></p>
                        </div>

                        <h2>CHILDREN'S PRIVACY</h2>
                        <p>Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from children under 13. Only through a parent´s or guardian´s account, and with their consent, we may collect personally identifiable information about children under 13.  If you are a parent or guardian and you are aware that your Children has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers. </p>

                        <h2>LEGAL COMPLIANCE.</h2>
                        <p>MPHB will comply with the applicable domestic and international laws, regulations and guidelines regarding publishing data for scientific research. MPHB will ensure that the researchers abide to the conditions set by national regulatory bodies including but not limited to payment of fees, securing clearance certificates and where necessary professional licenses as requirements for conducting studies in the country.</p>

                        <h2>LINKS TO OTHER WEBSITES</h2>
                        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
                        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                        <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
                        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                        <h2>CONTACT US</h2>
                        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                        <ul>
                            <li>
                                By email:<br /><br />
                                <a href="mailto:info@mypersonalhealthbank.com">info@mypersonalhealthbank.com</a>
                            </li>
                        </ul>

                        <p>BY CLICKING THE BUTTON BELOW, I CERTIFY THAT I HAVE READ, UNDERSTOOD AND VOLUNTARILY ACCEPT ALL THE TERMS AND CONDITIONS AS OUTLINED IN THIS STATEMENT</p>
                    </div>
                    <div class="language language-sw" :class="{show:(lang=='sw')}">
                        <h1>SERA YA FARAGHA</h1>
                        <h1>KANUSHO LA MATUMIZI YA BENKI YANGU BINAFSI YA AFYA</h1>
                        <h1>MAKUBALIANO YA MASHARTI YA MATUMIZI.</h1>

                        <p>Makubaliano haya ya masharti ya matumizi ("Makubaliano”) yameingiwa  kati ya <b>MPHB</b> na "wewe," mtumiaji wa Programu hii, inayojulikana pia kama "Benki Yangu Binafsi ya Afya" ("Tovuti"). Ufikiaji, utumiaji na/au kuvinjari kwa Maombi umetolewa kwa kuzingatia vigezo na masharti yaliyowekwa humu. Kwa kufikia, kutumia na/au kuvinjari Programu, unakubali na vigezo na masharti hiva.</p>

                        <p>Makubaliano haya yana kanusho la udhamini na masharti mengine ambayo yanaweka ukomo wa uwajibikaji wa <b>MPHB</b> kwako. Tafadhali soma vigezo na masharti haya kwa uangalifu na kwa ukamilifu, kwani kutumia, kufikia na/au kuvinjari katika tovuti kunajumuisha ukubalifu wa vigezo na masharti haya, ikiwa hukubali kuwajibika kwa kila kigezo na masharti yaliyowekwa hapa, tafadhali ondoka kwenye tovuti mara moja na usitumie, kufikia na/au kuvinjari programu.</p>
                        <p>Kwa kuingia katika aplikesheni, unakubali na kukubali kwamba umesoma na kuelewa vigezo na masharti haya, kwamba masharti, ufichuzi na kanusho zilizofafanuliwa humu ni za haki na za kuridhisha, na kwamba makubaliano yako ya kufuata na kufungwa na vigezo na masharti haya ni kwa hiari na si matokeo ya ulaghai, shinikizo au ushawishi usiofaa unaofanywa na mtu au taasisi yoyote.</p>
                        <p>Kanusho hili ni sehemu ya "Sera ya Faragha" ambayo inafafanua sera na taratibu za mradi wa MPHB kuhusu ukusanyaji, matumizi na ufichuaji wa maelezo yako unapotumia aplikesheni hii.</p>
                        <p><b>MPHB</b> itakuwezesha kuwa na uwezekano wa kuunda akaunti yako mwenyewe ambayo itatunzwa na <b>MPHB</b>. Hapa unaweza kuhifadhi na kurejesha taarifa zako za afya kila unapomtembelea Daktari wako katika vituo vyote vya eneo lako la makazi na Tanzania nzima na pia endapo utatembelea daktari mwingine nje ya Tanzania. Timu ya MPHB inaamini katika kuwawezesha wagonjwa. Hii inamaanisha kuwa wewe kama mgonjwa utamiliki data yako ya afya (kama vile unavyomiliki akaunti yako ya benki na maelezo yote unayotumia katika miamala yako ya kifedha kupitia benki yako). Aplikesheni ya Benki Yangu Binafsi ya Afya inatoa fursa sawa na inakuhakikishia ulinzi wa haki zako za faragha kwa kuzingatia viwango vya juu zaidi vya maadili kila wakati.</p>
                        <p>MPHB inaweza kutumia taarifa zako kwa njia inayoficha utambuzi wako ili kutoa na kuboresha Huduma zinazotolewa Kwako na aplikesheni ya Benki Yangu binafsi ya afya na baada ya idhini ya Wizara ya Afya kutoa ripoti kuhusu kuenea kwa magonjwa fulani katika eneo lolote. Kwa kutumia huduma, unakubali ukusanyaji na matumizi ya maelezo kwa mujibu wa Sera hii ya Faragha. Sera hii ya faragha imeundwa kwa msaada wa Chuo Kikuu cha Dodoma (UDOM), Chuo Kikuu cha Afya na Sayansi Shirikishi Muhimbili (MUHAS) na Chuo Kikuu cha Kusini mwa Denmark (SDU) kwa kuzingatia sheria na taratibu za nchi.</p>
                        <h2>TAFSIRI NA MAANA</h2>
                        <h3>TAFSIRI</h3>
                        <p>Maneno ambayo herufi ya mwanzo imeandikwa kwa herufi kubwa yana maana zilizofafanuliwa chini kwa masharti yafuatayo. Tafsiri zifuatazo zitakuwa na maana sawa bila kujali zinaonekana katika umoja au wingi.</p>
                        <h3>MAANA</h3>
                        <p>Kwa madhumuni ya Sera hii ya Faragha:</p>
                        <ul class="indented no-bullets">
                            <li><b>Akaunti</b> maana yake ni akaunti ya kipekee iliyoundwa kwa ajili Yako ili kutumia huduma yetu au sehemu za huduma yetu;</li>
                            <li><b>Aplikesheni</b> maana yake ni programu inayotolewa na Kampuni iliyopakuliwa na wewe kwenye kifaa chochote cha kielektroniki, inayoitwa MPHB;</li>
                            <li><b>Kutojulikana</b> maana yake ni taarifa yoyote ambayo haiwezi kuhusishwa na mtu anayetambulika au anayetambulika;</li>
                            <li><b>Kampuni</b> maana yake ni itajulikana kama "Kampuni", "Sisi", "Sisi" au "Yetu" katika makubaliano haya ya sera ya faragha) inarejelea MPHB;</li>
                            <li><b>Nchi maana</b> yake ni Tanzania;</li>
                            <li><b>Kifaa</b> maana <b>yake</b> ni kifaa chochote kinachoweza kufikia huduma kama vile kompyuta, simu ya mkononi, kompyuta au kibao ya kidijitali;</li>
                            <li><b>Taarifa  binafsi</b> maana yake ni taarifa yoyote inayohusiana na mtu anayetambulika au anayetambulika;</li>
                            <li><b>Huduma</b> maana yake ni aplikesheni;</li>
                            <li><b>Mtoa Huduma</b> maana yake ni mtu yeyote wa asili au wa kisheria ambaye anachakata taarifa kwa niaba ya Kampuni. Pia inahusisha kampuni au watu wengine walioajiriwa na Kampuni kuwezesha huduma, kutoa huduma kwa niaba ya Kampuni, kutekeleza huduma zinazohusiana na Huduma au kusaidia Kampuni kuchanganua jinsi huduma hiyo inavyotumiwa;</li>
                            <li><b>Matumizi ya taarifa</b> maana yake ni taarifa iliyokusanywa kiotomatiki, ama inayotolewa na matumizi ya huduma au kutoka kwa miundombinu ya huduma yenyewe (kwa mfano, muda wa kutembelewa kwa ukurasa); na</li>
                            <li><b>Wewe</b> maana yake ni mtu anayefikia au kutumia huduma, au kampuni, au taasisi nyingine ya kisheria kwa niaba  ya mtu kama huyo anafikia au kutumia huduma, kama inavyotumika.</li>
                        </ul>

                        <h2>KUSANYA NA KUTUMIA TAARIFA ZAKO BINAFSI</h2>
                        <h3>Aina za Data Zilizokusanywa</h3>
                        <h4>Taarifa binafsi</h4>
                        <p>Unapotumia Huduma Yetu, tunakuomba utupe taarifa fulani zinazoweza kukutambulisha binafsi ambazo zinaweza kutumiwa kuwasiliana au kukutambulisha. Taarifa zinazoweza kumtambulisha mtu binafsi zinaweza kujumuisha, lakini hazijajifunga kwa:</p>
                        <ul class="indented no-bullets">
                            <li>Barua pepe</li>
                            <li>Jina la kwanza na jina la mwisho</li>
                            <li>Namba ya Simu</li>
                            <li>Anuani,kata,anuani ya kazi</li>
                            <li>Taarifa za idadi ya watu</li>
                            <li>Picha</li>
                        </ul>
                        <p>Vile vile, aplikesheni hii miongoni mwa zingine itatafuta kukusanya taarifa za kibinafsi kuhusu historia yako ya matibabu na vigezo vinavyohusiana na masuala yako ya sasa ya matibabu (uchunguzi wa kimaabara, matokeo, tathmini na uchunguzi na huduma zinazopokelewa).</p>

                        <h4>Matumizi ya Taarifa</h4>
                        <p>Unahifadhi haki zote kuhusu ufikiaji na matumizi ya taarifa zako zilizohifadhiwa katika aplikesheni ya MPHB. Madaktari na watoa huduma wengine wa afya wanaweza tu kufikia na kuhuisha taarifa zako baada ya Wewe kutoa idhini Yako. Kama sehemu ya uendeshaji wa mfumo, Matumizi ya taarifa zitakusanywa kiotomatiki pale unapotumia Huduma.</p>
                        <p>Matumizi ya taarifa yanaweza kujumuisha maelezo kama vile anwani ya Itifaki ya Mtandao ya Kifaa Chako (m.f anwani ya IP), aina ya kivinjari, toleo la kivinjari, kurasa za Huduma yetu unayotembelea, saa na tarehe uliyotembelea, muda uliotumika kwenye kurasa hizo, kifaa cha kipekee, vitambulisho na taarifa nyingine za uchunguzi.</p>
                        <p>Unapofikia Huduma kwa au kupitia kifaa cha rununu, tunaweza kukusanya taarifa fulani kiotomatiki, ikijumuisha, lakini sio tu, aina ya kifaa cha rununu unachotumia, kitambulisho cha kipekee cha kifaa chako cha mkononi, anwani ya IP ya kifaa chako cha mkononi, simu yako ya mkononi, mfumo wa uendeshaji, aina ya kivinjari cha simu cha mkononi unachotumia, vitambulishi vya kipekee vya kifaa na taarifa nyingine ya uchunguzi.</p>
                        <p>Tunaweza pia kukusanya maelezo ambayo kivinjari chako hutuma wakati wowote unapotembelea huduma yetu au unapoifikia huduma kupitia au kupitia kifaa cha mkononi.</p>

                        <h4>Taarifa zinazoweza kukusanywa wakati wa kutumia Programu</h4>
                        <p>Unapotumia aplikesheni yetu ili kutoa vipengele, tunaweza kukusanya:</p>
                        <ul>
                            <li>Taarifa kuhusu eneo lako ki mkoa au mji  (sio anwani isipokuwa ikiwa imeambatanishwa na kliniki)</li>
                            <li>Picha na taarifa nyingine kutoka kwa kamera ya Kifaa chako na maktaba ya picha</li>
                            <li>Ubora wa mashauriano yako ya matibabu, m.f, tathmini, uchunguzi au maoni</li>
                            <li>Gharama inayohusiana na matumizi yako kwa afya</li>
                            <li>Masuala ya matibabu na usalama wa kimatibabu yanayohusiana na huduma unayopokea</li>
                            <li>Matokeo ya matibabu</li>
                        </ul>
                        <p>Tunatumia maelezo haya kutoa vipengele vya huduma yetu, kuboresha na kubainisha huduma yetu. Maelezo haya yanaweza kwa idhini Yako, kutumika kutathmini athari za kutumia aplikesheni katika kuboresha huduma za afya na matokeo ya afya.</p>


                        <h4>Matumizi ya taarifa  zako binafsi</h4>
                        <p>Kampuni inaweza kutumia taarifa iliyokusanywa kukuhusu kwa madhumuni machache na utendakazi unaohusiana na majukumu ya <b>MPHB</b>, au jinsi ilivyoelezwa vinginevyo katika kanusho hili na inavyoruhusiwa na Sheria zinazotumika. Madhumuni kama haya yenye ukomo ni pamoja na hali ambapo ni muhimu kwa MPHB kutoa au kukamilisha huduma zilizoombwa na, au kwa ajili ya, Wewe, au pale ambapo umetoa  kibali chako cha moja kwa moja kwa MPHB. Taarifa zako binafsi zinaweza kutumika kutekeleza madhumuni mbalimbali, ikiwa ni pamoja na:</p>
                        <ul class="indented no-bullets">
                            <li><b>Kutoa na kudumisha Huduma Yetu;</b> ikijumuisha kufuatilia matumizi ya Huduma Yetu.</li>
                            <li><b>Kusimamia Akaunti Yako:</b> kudhibiti usajili wako kama mtumiaji wa huduma. Taarifa zako binafsi unazotoa zinaweza kukupa ufikiaji wa utendaji tofauti wa huduma ambazo zinapatikana kwako kama mtumiaji uliyesajiliwa.</li>
                            <li><b>Kwa utendakazi wa aplikesheni:</b> ukuzaji, kufuata na kutekeleza vigezo vya utendaji vilivyokubaliwa na viashiria vya aplikesheni.</li>
                            <li><b>Ili kuwasiliana na wewe:</b> Kuwasiliana na wewe kwa barua pepe, simu, meseji, au njia zingine za mawasiliano ya kielektroniki, kama vile taarifa zinazotumwa na programu ya simu ya mkononi kuhusu mauhisho au mawasiliano ya taarifa yanayohusiana na utendaji kazi, bidhaa au huduma zilizoainishwa, ikijumuisha mauhisho ya usalama, inapobidi au inafaa kwa utekelezaji wao.</li>
                            <li><b>Ili kukupa wewe</b> habari na maelezo ya jumla kuhusu vipengele vingine, huduma na zana ambazo zinaweza kuongezwa kama sehemu ya kuboresha mfumo.</li>
                            <li><b>Ili kudhibiti maombi yako:</b> Kuhudhuria na kudhibiti maombi ya mtumiaji kwetu.</li>
                            <li><b>Kwa madhumuni mengine:</b> Usiri hudumishwa kwa kiwango cha juu. Kwa idhini yako tunaweza kutumia maelezo yako kwa madhumuni mengine, kama vile uchanganuzi wa taarifa, kubainisha mitindo ya utumiaji, kubainisha ufanisi wa kampeni zetu za utangazaji na kutathmini na kuboresha huduma, bidhaa, uuzaji na uzoefu wako.</li>
                        </ul>

                        <p>MPHB inaweza kutoa taarifa ilizokusanya kukuhusu wewe na washirika wengine kwa madhumuni machache ya kutoa Huduma au kama ilivyoelezwa hapa chini kama ifuatavyo;</p>
                        <ul>
                            <li>Kwa madhumuni ya kutii sheria yoyote inayotumika, michakato ya kisheria, maombi ya serikali, kutekeleza haki zetu zozote za kisheria, kutetea dhidi ya madai ya kisheria, kuchunguza matumizi yoyote haramu, matumizi mabaya, ukiukaji wa masharti yetu na matumizi mengine yoyote sawa na hayo. Tukipokea amri ya mahakama ambayo inahitaji kushiriki au kufichua maelezo yako au maelezo kukuhusu kwa mhusika mwingine.</li>
                            <li>Na Watoa Huduma: kuhusiana na mahitaji Yako ya matibabu na mashauriano.</li>
                            <li>Iwapo MPHB itapanga upya utendaji kazi wa huduma ndani ya shirika tofauti, au ikiwa MPHB itaunganishwa ndani au na vyombo vingine au kuunganisha utendaji kazi wa huduma na wahusika wengine, MPHB itakuwa na haki ya kuhamisha kwa taasisi mpya nakala ya maelezo kukuhusu yaliyokusanywa kutoka kwa Huduma, mradi hata hivyo, taasisi hizo zinakubali kufungwa na masharti ya sera hii.</li>
                            <li>Na watumiaji wengine: Kwa maombi na idhini yako, unaposhiriki kutoa taarifa zako binafsi au vinginevyo na kuingiliana katika maeneo ya umma na watumiaji wengine, taarifa kama hizo zinaweza kutazamwa na watumiaji wote na inaweza kusambazwa hadharani nje bila kujulikana.</li>
                        </ul>

                        <h4>Uhifadhi wa taarifa zako ya binafsi</h4>
                        <p>Kampuni itahifadhi taarifa zako binafsi kwa kadri ya muda inavyohitajika kwa madhumuni yaliyowekwa katika sera hii ya faragha. Tutahifadhi na kutumia taarifa zako binafsi kwa kiwango kinachohitajika ili kutii matakwa yetu ya kisheria (kwa mfano, ikiwa tutahitajika kuhifadhi taarifa zako ili kutii sheria zinazotumika), kutatua mizozo, na kutekeleza makubaliano ya  kisheria na sera.</p>
                        <p>Kampuni pia itahifadhi Matumizi ya taarifa kwa madhumuni ya uchanganuzi wa ndani. Taarifa kwa ujumla huhifadhiwa kwa muda mfupi zaidi, isipokuwa wakati taarifa hizi zinatumiwa kuimarisha usalama au kuboresha utendakazi wa huduma yetu, au tuna wajibu wa kisheria kuhifadhi taarifa kwa muda mrefu zaidi.</p>

                        <h4>Uhamisho wa taarifa zako binafsi</h4>
                        <p>Taarifa zako, ikijumuisha taarifa zako binafsi, huchakatwa katika afisi za uendeshaji za Kampuni na katika maeneo mengine yoyote ambapo wahusika wanaohusika katika uchakataji wanapatikana. Inamaanisha kuwa maelezo haya yanaweza kuhifadhiwa, kuhamishwa hadi au kudumishwa nje ya Wilaya yako, Mkoa, nchi au mamlaka nyingine ya serikali ambapo Sheria za ulinzi wa taarifa zinaweza kutofautiana na zile za mamlaka yako. Timu ya usaidizi inayodumisha suluhisho pia itapatikana katika sehemu tofauti za ulimwengu.</p>
                        <p>Idhini yako kwa Sera hii ya Faragha ikifuatiwa na uwasilishaji wako wa maelezo kama haya inawakilisha makubaliano yako ya kuhifadhi taarifa zako, kuhamishwa kwa na mifumo inayotunzwa kutoka sehemu tofauti na udhibiti.</p>
                        <p>Kampuni itachukua hatua zote zinazohitajika ili kuhakikisha kuwa taarifa zako zinashughulikiwa kwa usalama na kwa mujibu wa Sera hii ya faragha na hakuna uhamisho wa taarifa zako  binafsi utafanyika kwa shirika au nchi isipokuwa  kama kuna udhibiti wa kutosha unaojumuisha usalama wa taarifa =[[;ll na taarifa nyingine za kibinafsi.</p>

                        <h4>Ufichuaji wa taarifa zako binafsi</h4>
                        <h4>Miamala ya Biashara</h4>
                        <p>Ikiwa Kampuni itahusika katika muunganisho, upataji au uuzaji wa mali, taarifa zako binafsi zisizo na utambuzi zinaweza kuhamishwa. Tutatoa taarifa kabla ya taarifa zako binafsi kuhamishwa na kuwa chini ya sera ya faragha tofauti na hii.</p>
                        <h4>Utekelezaji wa Sheria</h4>
                        <p>Katika mazingira fulani, Kampuni inaweza kuhitajika kufichua taarifa zako binafsi ikihitajika kufanya hivyo kisheria au kujibu maombi halali ya mamlaka ya umma (m.f  mahakama au wakala wa serikali).</p>

                        <h4>Mahitaji mengine ya kisheria</h4>
                        <p>Kampuni inaweza kufichua taarifa zako binafsi kwa imani nzuri kwamba hatua kama hiyo ni muhimu kwa:</p>
                        <ul>
                            <li>Kuzingatia wajibu wa kisheria</li>
                            <li>Kulinda na kutetea haki au mali ya Kampuni</li>
                            <li>Kuzuia au kuchunguza makosa yanayowezekana kuhusiana na huduma</li>
                            <li>Kulinda usalama binafsi wa watumiaji wa huduma au umma</li>
                            <li>Kulinda dhidi ya dhima ya kisheria</li>
                        </ul>

                        <h4>Usalama wa taarifa zako binafsi</h4>
                        <p>Usalama wa taarifa zako binafsi ni muhimu Kwetu, lakini kumbuka kwamba hakuna njia ya uwasilishaji kwenye Mtandao, au njia ya uhifadhi wa kielektroniki iliyo salama asilimia mia (100%). Ingawa tunajitahidi kutumia njia zinazokubalika kibiashara kulinda taarifa zako binafsi, hatuwezi kukuhakikishia usalama wake kwa ukamilifu.</p>

                        <h2>MAELEZO YA KINA JUU YA UCHAKATAJI WA TAARIFA ZAKO BINAFSI</h2>
                        <p>Watoa huduma tunaotumia wanaweza kufikia taarifa zako binafsi. Wauzaji hawa wengine hukusanya, kuhifadhi, kutumia, kuchakata na kuhamisha taarifa kuhusu Shughuli zako kwenye huduma yetu kwa mujibu wa sera zao za faragha.</p>

                        <h4>Matumizi, Utendaji na Mengineyo</h4>
                        <p>Tunaweza kutumia watoa huduma wengine ili kutoa uboreshaji bora wa huduma yetu. Huduma hizo ni pamoja na  lakini hazijafungwa kwa hizi  zifuatazo:</p>

                        <div class="indented">
                            <h5>Postmark</h5>
                            <p>Postmark ni huduma ya ufikishaji wa taarifa ya baruapepe. Huduma hii inaendeshwa na AC PM LLC.</p>
                            <p>Postmark inaweza kukusanya taarifa kutoka kwenye kifaa chako.</p>
                            <p>Taarifa zinazokusanywa na Postmark zinadhibitiwa kwa mujibu wa sera yao ya faragha.</p>
                            <p><a href="https://postmarkapp.com/privacy-policy" target="_blank">https://postmarkapp.com/privacy-policy</a></p>

                            <h5>Twilio</h5>
                            <p>Twilio ni jukwaa moja lenye API zinazonyumbulika za chaneli yoyote, akili iliyojengewa ndani, na miundombinu ya kimataifa ili kukusaidia kwa kiwango kikubwa. Tunaitumia kutuma taarifa za SMS. Huduma hii inaendeshwa na Twilio Inc.</p>
                            <p>Twilio inaweza kukusanya taarifa kutoka kwenye kifaa chako.</p>
                            <p>Taarifa zinazokusanywa na Postmark zinadhibitiwa kwa mujibu wa sera yao ya faragha.</p>
                            <p><a href="https://www.twilio.com/legal/privacy" target="_blank">https://www.twilio.com/legal/privacy</a></p>

                            <h5>Ramani ya Google</h5>
                            <p>Ramani ya Google ni huduma ambapo unaweza kupata biashara za karibu nawe, kutazama ramani na kupata maelekezo ya kuendesha. Inaendeshwa na Google.</p>
                            <p>Ramani ya Google inaweza kukusanya taarifa kutoka kwenye kifaa chako.</p>
                            <p>Taarifa zinazokusanywa na ramani ya google   zinadhibitiwa kwa mujibu wa sera yao ya faragha. Sera ya google: https://policies.google.com/privacy?hl=en</p>

                            <h5>Google Firebase.</h5>
                            <p>Google Firebase ni huduma inayokusaidia kuunda na kuendesha programu zilizofanikiwa. Tunaitumia kwa mawasiliano kati ya seva na mteja. Inaendeshwa na Google.</p>
                            <p>Google Firebase inaweza kukusanya maelezo kutoka kwenye kifaa chako.</p>
                            <p>Taarifa zinazokusanywa na Google Firebase zinadhibitiwa kwa mujibu wa sera yao ya faragha.</p>
                            <p>Sera ya google: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>

                            <h5>Azure</h5>
                            <p>Azure ni jukwaa la kompyuta la wingu lililo wazi, linalonyumbulika, la kiwango cha biashara. Inaendeshwa na Microsoft.</p>
                            <p>Azure inaweza kukusanya maelezo kutoka kwa Kifaa Chako.</p>
                            <p>Taarifa iliyokusanywa na Azure inadhibitiwa kwa mujibu wa Sera ya Faragha ya Microsoft: <a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">https://privacy.microsoft.com/en-us/privacystatement</a></p>
                        </div>

                        <h2>FARAGHA YA WATOTO</h2>
                        <p>Huduma yetu haishughulikii mtu yeyote aliye chini ya umri wa miaka 13 ("Watoto"). Hatukusanyi taarifa za binafsi kutoka kwa watoto walio na umri wa chini ya miaka 13 kwa kufahamu. Kupitia akaunti ya mzazi au mlezi pekee, na kwa kibali chake, tunaweza kukusanya taarifa zinazomtambulisha binafsi kuhusu watoto walio na umri wa chini ya miaka 13. Ikiwa wewe ni mzazi au mlezi na wewe unajua kwamba Watoto wako wametupa taarifa binafsi, tafadhali wasiliana nasi. Tukifahamu kwamba tumekusanya taarifa za Kibinafsi kutoka kwa mtoto aliye chini ya umri wa miaka 13 bila uthibitisho wa kibali cha mzazi, tunachukua hatua za kuondoa maelezo hayo kwenye seva zetu.</p>

                        <h2>UTII WA SHERIA.</h2>
                        <p>MPHB itatii sheria, kanuni na miongozo inayotumika  ndani ya nchi na kimataifa kuhusu uchapishaji wa taarifa binafsi na taarifa za  kisayansi. MPHB itahakikisha kuwa watafiti wanafuata masharti yaliyowekwa na vyombo vya udhibiti vya kitaifa ikiwa ni pamoja na malipo ya ada, kupata hati za kibali na inapobidi leseni za kitaalamu kama mahitaji ya kufanya tafiti nchini.</p>

                        <h2>VIUNGO KWA TOVUTI NYINGINE</h2>
                        <p>Huduma yetu inaweza kuwa na viungo vya tovuti zingine ambazo hazitumiki nasi. Ukibofya kiungo cha wahusika wengine, utaelekezwa kwenye tovuti ya mtu huyo wa tatu. Tunakushauri sana ukague Sera ya Faragha ya kila tovuti unayotembelea.</p>
                        <p>Hatuna udhibiti na hatutawajibika kwa maudhui, sera za faragha au desturi za tovuti au huduma za wahusika wengine.</p>

                        <h2>MABADILIKO YA SERA HII YA FARAGHA</h2>
                        <p>Tunaweza kuhuisha sera yetu ya faragha mara kwa mara. Tutakuarifu kuhusu mabadiliko yoyote kwa kuchapisha Sera mpya ya Faragha kwenye ukurasa huu.</p>
                        <p>Tutakujulisha kupitia barua pepe na/au taarifa kuu kwenye huduma yetu, kabla ya mabadiliko kuanza kutumika na kuhuisha tarehe ya "Uhisho la Mwisho" juu ya Sera hii ya Faragha.</p>
                        <p>Unashauriwa kukagua Sera hii ya Faragha mara kwa mara kwa mabadiliko yoyote. Mabadiliko kwenye Sera hii ya Faragha hutumika yanapochapishwa kwenye ukurasa huu.</p>

                        <h2>WASILIANA NASI</h2>
                        <p>Ikiwa una maswali yoyote kuhusu Sera hii ya Faragha, unaweza kuwasiliana nasi:</p>
                        <ul>
                            <li>
                                Kwa barua pepe:<br /><br />
                                <a href="mailto:info@mypersonalhealthbank.com">info@mypersonalhealthbank.com</a>
                            </li>
                        </ul>

                        <p>KWA KUBONYEZA KITUFE HAPA CHINI NINATHIBITISHA KWAMBA NIMESOMA, NIMEELEWA NA KUKUBALI KWA HIARI VIGEZO NA MASHARTI YOTE KAMA YALIVYOBAINISHWA KATIKA TAARIFA HII</p>
                    </div>
                    <div class="language language-uk" :class="{show:(lang=='uk')}">
                        <h1>MY PERSONAL HEALTH BANK APPLICATION DISCLAIMER</h1>
                        <h1>TERMS OF USE AGREEMENT</h1>
                        <p>This Terms of Use Agreement (this “Agreement”) is entered into by and between the <b>MPHB</b> and “you,” the user of this Application, also known as “My Personal Health Bank” (the “Site”). Access to, use of and/or browsing of the Application is provided subject to the terms and conditions set forth herein. By accessing, using and/or browsing the Application, you hereby agree to these terms and conditions.</p>
                        <p>This Agreement contains warranty disclaimers and other provisions that limit <b>MPHB</b>   liability to you. Please read these terms and conditions carefully and in their entirety, as using, accessing and/or browsing the site constitutes acceptance of these terms and conditions. if you do not agree to be bound to each and every term and condition set forth herein, please exit the site immediately and do not use, access and/or browse the application.</p>
                        <p>By entering the Application, you acknowledge and agree that you have read and understand these terms and conditions, that the provisions, disclosures and disclaimers set forth herein are fair and reasonable, and that your agreement to follow and be bound by these terms and conditions is voluntary and is not the result of fraud, duress or undue influence exercised upon you by any person or entity</p>

                        <p>This disclaimer is part of the “Privacy Policy” which describes MPHB project policies and procedures on the collection, use and disclosure of your information when you use this application.</p>
                        <p>MPHB will facilitate that you have the possibility to create your own account which will be hosted by MPHB. Here you may store and retrieve your health information each time you visit your Medical Doctor at all facilities in your area of residence and throughout Tanzania as well as in case you visit another doctor outside Tanzania. MPHB team believes in empowering patients. This means that you as a patient will own your health data (just like you own your bank account and all the information you use in your financial transactions through your bank). The My personal Health Bank application offers the same opportunity and guarantees protection of your rights to privacy by upholding utmost ethical standards at all times.</p>

                        <p>MPHB may use Your data in an Anonymized way to provide and improve the Services offered to You by the My Personal Health Bank application and upon the approval of the Ministry of Health issue reports on  the prevalence of certain diseases in any area. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the University of Dodoma (UDOM), the Muhimbili University of Health and Allied Sciences (MUHAS) and the Southern Denmark University (SDU) in line with the rules and regulations of the country.</p>
                        <h2>INTERPRETATION AND DEFINITIONS</h2>
                        <h3>Interpretation</h3>
                        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                        <h3>Definitions</h3>
                        <p>For the purposes of this Privacy Policy:</p>
                        <ul class="indented no-bullets">
                            <li><b>Account</b> means a unique account created for You to access our Service or parts of our Service.</li>
                            <li><b>Application</b> means the software program provided by the Company downloaded by You on any electronic device, named MPHB</li>
                            <li><b>Anonymised</b> means any information that cannot be related to an identified or identifiable individual.</li>
                            <li><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to MPHB.</li>
                            <li><b>Country</b> refers to: Tanzania</li>
                            <li><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                            <li><b>Personal Data</b> is any information that relates to an identified or identifiable individual.</li>
                            <li><b>Service</b> refers to the Application.</li>
                            <li><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                            <li><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                            <li><b>You</b> refer to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                        </ul>

                        <h2>COLLECTING AND USING YOUR PERSONAL DATA</h2>
                        <h3>Types of Data Collected</h3>
                        <h4>Personal Data</h4>
                        <p>While using Our Service, We ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                        <ul class="indented no-bullets">
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Address, State, Province, Postal code, Area of residence</li>
                            <li>Demographic information</li>
                            <li>Image</li>
                            <li>Likewise, this application among others will seek to collect personal information regarding your medical history and parameters related to Your current medical issues (laboratory investigations, findings, examinations and diagnosis and services received.</li>
                        </ul>

                        <h4>Usage Data</h4>
                        <p>You reserve all the rights regarding access and use of Your data stored in the MPHB application. Doctors and other healthcare providers can only access and update the data after You have given Your consent. As part of the operation of the system, Usage Data will be collected automatically when using the Service.</p>
                        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                        <p>We may also collect information that Your browser sends whenever You visit Our Service or when you access the Service by or through a mobile device.</p>

                        <h4>Information that may be collected while using the Application</h4>
                        <p>While using our Application in order to provide features, we may collect:</p>
                        <ul>
                            <li>Information regarding your location by region or city (not address unless attached to a clinic)</li>
                            <li>Pictures and other information from your Device's camera and photo library</li>
                            <li>Quality of your medical consultations, e.g., evaluations, surveys or feedback</li>
                            <li>The cost related to your expenditures for health </li>
                            <li>Treatment and medical safety issues related to the care you receive</li>
                            <li>Treatment outcomes</li>
                        </ul>
                        <p>We use this information to provide features of Our Service, to improve and customize Our Service. This information may as well upon Your consent, be used to evaluate the impact of using the application in improving health care services and health outcomes. </p>

                        <h4>Use of Your Personal Data</h4>
                        <p>The Company may use the information collected about you for the limited purpose and related <b>MPHB</b> functionality, or as otherwise specifically described in this disclaimer and as permitted by applicable laws. Such limited purposes include circumstances where it is necessary for <b>MPHB</b> to provide or complete Services requested by, or for, You, or where You have given <b>MPHB</b> Your express consent. Your Personal Data may be used to perform a variety of purposes, including:</p>
                        <ul class="indented no-bullets">
                            <li><b>To provide and maintain Our Service,</b> including to monitor the usage of Our Service.</li>
                            <li><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                            <li><b>For the performance of the application:</b> the development, compliance and undertaking of the agreed performance criteria and indicators of the application.</li>
                            <li><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                            <li><b>To provide You</b> with news and general information about other functions, services and tools which may be added as part of improving the system.</li>
                            <li><b>To manage Your requests:</b> To attend and manage user’s requests to Us.</li>
                            <li><b>For other purposes:</b> Confidentiality is maintained at high level. Upon your consent we may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
                        </ul>

                        <b>MPHB</b> may share the Information collected about you with third parties for the limited purposes of providing the Services or as otherwise described herein as follows;
                        <ul>
                            <li>For the purpose of complying with any applicable law, legal process, governmental request, to enforce any of our legal rights, defend against legal claims, to investigate any illegal use, abuse, violations of our terms and any other similar uses.If We receive a judicial order that requires it to share or disclose Your information or information about You to a third party.</li>
                            <li><b>With Service Providers:</b> in relation to Your medical needs and consultations.</li>
                            <li>If MPHB re-organizes the operation of the Service within a different corporation, or if MPHB merges into or with other entities or merges the operation of the Service with a third party, MPHB shall be entitled to transfer to the new entity a copy of the Information about You collected from the Service, provided however, that those entities agree to be bound by the provisions of this policy.</li>
                            <li><b>With other users:</b> Upon Your request and consent, when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside Anonymously.</li>
                        </ul>

                        <h4>Retention of Your Personal Data</h4>
                        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

                        <h4>Transfer of Your Personal Data</h4>
                        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be stored, transferred to — and maintained in cloud solutions outside—of Your district, region, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. The support team maintaining the solution will also be located in different parts of the world</p>
                        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to have your data stored at, transferred to and the system maintained from various locations and in cloud solutions.</p>
                        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

                        <h4>Disclosure of Your Personal Data</h4>
                        <h4>Business Transactions</h4>
                        <p>If the Company is involved in a merger, acquisition or asset sale, Your Anonymized Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                        <h4>Law enforcement</h4>
                        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                        <h4>Other legal requirements</h4>
                        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                        <ul>
                            <li>Comply with a legal obligation</li>
                            <li>Protect and defend the rights or property of the Company</li>
                            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>Protect the personal safety of Users of the Service or the public</li>
                            <li>Protect against legal liability</li>
                        </ul>

                        <h4>Security of Your Personal Data</h4>
                        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

                        <h2>DETAILED INFORMATION ON THE PROCESSING OF YOUR PERSONAL DATA</h2>
                        <p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</p>

                        <h4>Usage, Performance and Miscellaneous</h4>
                        <p>We may use third-party Service Providers to provide better improvement of our Service. The services are amongst others, but are not limited to, the following: </p>
                        <div class="indented">
                            <h5>Postmark</h5>
                            <p>Postmark is an e-mail delivery service used for sending e-mail notifications. The service is operated by AC PM LLC.</p>
                            <p>Postmark may collect information from Your device.</p>
                            <p>The information gathered by Postmark is held in accordance with its Privacy Policy: <a href="https://postmarkapp.com/privacy-policy" target="_blank">https://postmarkapp.com/privacy-policy</a></p>

                            <h5>Twilio</h5>
                            <p>Twilio is a single platform with flexible APIs for any channel, built-in intelligence, and global infrastructure to support you at scale. We use it for sending SMS-notifications. The service is operated by Twilio Inc.</p>
                            <p>Twilio may collect information from Your Device.</p>
                            <p>The information gathered by Twilio is held in accordance with its Privacy Policy: <a href="https://www.twilio.com/legal/privacy" target="_blank">https://www.twilio.com/legal/privacy</a></p>

                            <h5>Google Maps</h5>
                            <p>Google Maps is a service where you can find local businesses, view maps and get driving directions. It is operated by Google.</p>
                            <p>Google Maps may collect information from Your Device.</p>
                            <p>The information gathered by Google Maps is held in accordance with the Privacy Policy of Google: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>

                            <h5>Google Firebase</h5>
                            <p>Google Firebase is a service that helps you build and run successful apps. We use it for communication between server and client. It is operated by Google.</p>
                            <p>Google Firebase may collect information from Your device.</p>
                            <p>The information gathered by Google Firebase is held in accordance with the Privacy Policy of Google: <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>

                            <h5>Azure</h5>
                            <p>Azure is an open, flexible, enterprise-grade cloud computing platform. It is operated by Microsoft.</p>
                            <p>Azure may collect information from Your Device.</p>
                            <p>The information gathered by Azure is held in accordance with the Privacy Policy of Microsoft: <a href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">https://privacy.microsoft.com/en-us/privacystatement</a></p>
                        </div>

                        <h2>CHILDREN'S PRIVACY</h2>
                        <p>Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from children under 13. Only through a parent´s or guardian´s account, and with their consent, we may collect personally identifiable information about children under 13.  If you are a parent or guardian and you are aware that your Children has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers. </p>

                        <h2>LEGAL COMPLIANCE.</h2>
                        <p>MPHB will comply with the applicable domestic and international laws, regulations and guidelines regarding publishing data for scientific research. MPHB will ensure that the researchers abide to the conditions set by national regulatory bodies including but not limited to payment of fees, securing clearance certificates and where necessary professional licenses as requirements for conducting studies in the country.</p>

                        <h2>LINKS TO OTHER WEBSITES</h2>
                        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                        <h2>CHANGES TO THIS PRIVACY POLICY</h2>
                        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                        <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
                        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                        <h2>CONTACT US</h2>
                        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                        <ul>
                            <li>
                                By email:<br /><br />
                                <a href="mailto:info@mypersonalhealthbank.com">info@mypersonalhealthbank.com</a>
                            </li>
                        </ul>

                        <p>BY CLICKING THE BUTTON BELOW, I CERTIFY THAT I HAVE READ, UNDERSTOOD AND VOLUNTARILY ACCEPT ALL THE TERMS AND CONDITIONS AS OUTLINED IN THIS STATEMENT</p>
                    </div>
                </div>

                <div class="col-xs-12 center-text pt-medium">
                    <button type="button" 
                            class="btn btn__text btn__text btn__text--green-border online-only" 
                            @click="closeDialog"
                            data-test="accept-button">
                        <h3>{{$t('general.accept')}}</h3>
                    </button>
                </div>
            </div>        
        </div>
    </div>
</template>

<script>
    import { useLanguageController } from '@/Components/Shared/LanguageController.js';

    export default {
        name: 'TermsAndConditions',
        emits: ['accepted'],
        setup(_, { emit }) {
            const { getLang } = useLanguageController();
            const lang = getLang();

            const closeDialog = () => emit('accepted');

            return {
                lang,
                closeDialog,
            };
        }
    }
</script>