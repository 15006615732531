<template>
    <div>
        <div id="register" class="overlay">
            <bar title="" @close="closeDialog" />
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2 class="full-width center-text pb-small"><b>{{$t('registerPatient.title')}}</b></h2>
                        <h5 class="full-width center-text pb-small">{{$t('registerPatient.subTitle')}}</h5>
                        <form :class="{ locked: lockForm }">

                            <div>
                                <text-box v-model="register.givenNames"
                                          name="givenNames"
                                          :required="true"
                                          :label="$t('registerPatient.givenNamesLabel')"
                                          :errorText="$t('registerPatient.givenNamesError')"
                                          :helper="$t('registerPatient.givenNamesHelper')"
                                          :placeholder="$t('registerPatient.givenNamesPlaceholder')"
                                          @updated="stateChanged"
                                          data-test="given-names"/>

                                <text-box v-model="register.familyName"
                                          name="familyName"
                                          :required="true"
                                          :label="$t('registerPatient.familyNameLabel')"
                                          :errorText="$t('registerPatient.familyNameError')"
                                          :helper="$t('registerPatient.familyNameHelper')"
                                          :placeholder="$t('registerPatient.familyNamePlaceholder')"
                                          @updated="stateChanged"
                                          data-test="family-name"></text-box>

                                <text-box v-model="register.email"
                                          name="email"
                                          type="email"
                                          :label="$t('registerPatient.emailLabel')"
                                          :errorText="$t('registerPatient.emailError')"
                                          :helper="$t('registerPatient.emailHelper')"
                                          :placeholder="$t('registerPatient.emailPlaceholder')"
                                          @updated="emailStateChanged"
                                          data-test="email"></text-box>

                                <text-box v-model="register.phone"
                                          name="phone"
                                          type="tel"
                                          :label="$t('registerPatient.phoneLabel')"
                                          :helper="$t('registerPatient.phoneHelper')"
                                          :errorText="$t('registerPatient.phoneError')"
                                          :placeholder="$t('registerPatient.phonePlaceholder')"
                                          @updated="phoneStateChanged"
                                          data-test="phone"></text-box>

                                <div>{{$t('registerPatient.emailPhoneRequiredNote')}}</div>
                            </div>

                            <div style="padding-top:30px">
                                <text-box v-model="register.username"
                                          name="username"
                                          :required="true"
                                          :label="$t('registerPatient.usernameLabel')"
                                          :helper="$t('registerPatient.usernameHelper')"
                                          :errorText="$t('registerPatient.usernameError')"
                                          :placeholder="$t('registerPatient.usernamePlaceholder')"
                                          :pattern="usernamePattern"
                                          :pattern-case-sensitive="false"
                                          @updated="stateChanged"
                                          data-test="username"></text-box>

                                <text-box v-model="register.password"
                                          name="password"
                                          type="password"
                                          :required="true"
                                          :label="$t('registerPatient.passwordLabel')"
                                          :errorText="$t('registerPatient.passwordError')"
                                          :helper="$t('registerPatient.passwordHelper')"
                                          @updated="passwordStateChanged"
                                          data-test="password"></text-box>

                                <text-box v-model="register.passwordConfirm"
                                          name="confirmPassword"
                                          type="password"
                                          :required="true"
                                          :label="$t('registerPatient.confirmPasswordLabel')"
                                          :errorText="$t('registerPatient.confirmPasswordError')"
                                          :helper="$t('registerPatient.confirmPasswordHelper')"
                                          :pattern="passwordConfirmPattern"
                                          :pattern-case-sensitive="true"
                                          @updated="stateChanged"
                                          data-test="confirm-password"></text-box>
                            </div>


                            <check-box v-model="register.acceptTerms"
                                       name="acceptTerms"
                                       type="switch"
                                       :required="true"
                                       :label="$t('registerPatient.acceptTerms')"
                                       :must-be-state="true"
                                       @updated="checkTerms"
                                       data-test="accept-terms" />

                            <div class="col-xs-push-2 col-xs-8 pt--large">
                                <button class="btn btn__text btn__text--large btn__text--green-border full-width online-only"
                                        :class="{ disabled : !isValid }" 
                                        :disabled="!isValid" 
                                        type="button" 
                                        @click="createPatient()"
                                        data-test="create-button">
                                    <h1>{{$t('general.create')}}</h1>
                                </button>
                            </div>
                        </form>

                    </div>
                    <div class="col-xs-push-2 col-xs-8 center-text">
                        <button class="btn btn__link" @click="goBack" data-test="go-back-button">
                            <h3>{{$t('general.goBackButton')}}</h3>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <terms-and-conditions 
                :class="{ show : termsVisible }" 
                @accepted="closeTerms" 
                ref="termsElement"></terms-and-conditions>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import CheckBox from '@/Components/Shared/Form/CheckBox.vue';
    import TermsAndConditions from '@/Components/Shared/TermsAndConditions.vue';

    import { inject, ref, reactive, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { usePatientService } from './PatientService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { makeRegexSafe } from '@/Components/Shared/RegexUtils.js';
    import { useLanguageController } from '@/Components/Shared/LanguageController.js';

    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, isValid, invalidFields } = useFormValidator();
    const { getLang } = useLanguageController();
    const service = usePatientService();
    const caller = useExceptionwrappedCaller();
    const alertDialog = inject('alertDialog');

    let lockForm = ref(false);
    let termsVisible = ref(false);

    let register = reactive({
        givenNames: '',
        familyName: '',
        email: '',
        phone: '',
        username: '',
        password: '',
        passwordConfirm: '',
        acceptTerms: false
    });

    const usernamePattern = ref('^[a-z0-9_.@+-]{5,255}$');

    // keeps track of current password pattern to match (for confirmPassword)
    let passwordConfirmPattern = ref('.*');

    // special handler for password change which updates the confirm password fields validation pattern
    const passwordStateChanged = (info) => {
        //console.log('passwordStateChanged', info.valid/*, invalidFields*/);
        stateChanged(info);
        let regexSafe = makeRegexSafe(register.password);
        if (regexSafe == '') regexSafe = '.*';
        passwordConfirmPattern.value = '^' + regexSafe + '$';
        //console.log('Confirm pattern', passwordConfirmPattern.value);
    }

    const emailStateChanged = (info) => {
        //console.log('emailStateChanged', info.valid/*, invalidFields*/);
        if (invalidFields['phone']) console.log('EMAIL NOT REQUIRED');
        stateChanged(info);
    }

    const phoneStateChanged = (info) => {
        //console.log('phoneStateChanged', info.valid/*, invalidFields*/);
        if (invalidFields['email']) console.log('MOBILE PHONENO NOT REQUIRED');
        stateChanged(info);
    }

    const createPatient = async () => {
        if (!register.acceptTerms) {
            alert(t('registerPatient.acceptTermsError'));
            return;
        }

        try {
            await caller.call(async () => {
                const createResult = await service.createPatient(
                    register.givenNames,
                    register.familyName,
                    register.email,
                    register.phone,
                    register.username,
                    register.password,
                    getLang(),
                    true);

                router.push({
                    name: 'verify',
                    query: {
                        id: createResult.userId,
                        phone: register.phone && register.phone.length > 0 ? 1 : 0,
                        email: register.email && register.email.length > 0 ? 1 : 0
                    }
                });
            });
        }
        catch (e) {
            if (e.code) {
                if (e.code == 100 || e.code == 102 || e.code == 103 || e.code == 106 || e.code == 107) {
                    await alertDialog({
                        header: t('registerPatient.errorCreatingPatientAlertTitle'),
                        body: t(`registerPatient.${e.message}`),
                        buttons: 'accept',
                        accept: t('general.ok')
                    });
                }
                else if (e.code == 101) {
                    await alertDialog({
                        header: t('registerPatient.errorCreatingPatientAlertTitle'),
                        body: t(`registerPatient.${e.message}`, { username: e.hint }),
                        buttons: 'accept',
                        accept: t('general.ok')
                    });
                }
                else {
                    throw e;
                }
            }
            else {
                throw e;
            }
        }
    }

    const closeDialog = () => router.push({ name: 'welcome' });

    const goBack = () => router.back();

    const termsElement = ref(null);

    const openTerms = () => {
        if (termsElement.value.$el)
            termsElement.value.$el.scrollTo(0, 0);
        termsVisible.value = true;
    }
    const closeTerms = () => termsVisible.value = false;
    const checkTerms = (state) => {
        stateChanged(state);
        if (state.valid) {
            openTerms();
        }
    };
</script>
