<template>
    <div class="clinic-data">
        <ul>
            <li>
                <h3>{{name}}</h3>
            </li>
            <li>
                <span>{{address}}</span>
            </li>
            <li>
                <span>{{zipCode}} {{city}}, {{country}}</span>
            </li>
            <li>
                <a :href="'tel:'+phone">{{phone}}</a>
            </li>
        </ul>
    </div>
</template>


<script>
    export default {
        name: 'ClinicListInfo',
        props: {
            name: String,
            address: String,
            zipCode: String,
            city: String,
            state: String,
            country: String,
            phone: String,
            email: String,
        },
    }
</script>