<template>
    <div id="messageviewpicture" class="overlay overlay--patient hidden" :class="{active:props.show}" v-if="props.show">
        <bar :title="$t('messageViewPicture.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <img :src="props.url" alt="" style="max-width:100%;max-height:100%" />
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { defineEmits, defineProps } from 'vue';

    const props = defineProps({
        url: { type: String },
        show: { type: Boolean, default: false },
    });
    const emit = defineEmits(['close']);

    const closeDialog = () => emit('close');
</script>