<template>
    <div class="container">
        <div class="row" >
            <div class="clinic" v-for="request in bookingRequests" :key="request.bookingRequestId">
                <div class="col-xs-8">
                    <clinic-list-info v-bind="getClinicFromRequest(request)"></clinic-list-info>
                </div>
                <div class="col-xs-4">
                    <div class="date-container">
                        <div class="date">{{formatDate(request.date)}}</div>
                    </div>
                </div>
                <div class="rejected">
                    <button v-if="request.rejectedState == 1" type="button" class="btn btn__text-icon btn__text-icon--short btn__text-icon--red-transparent" @click="showRejectedRequest(request)">
                        <div>
                            <svg-icon icon-name="alert" />
                            <h5><span>{{$t('patientAppointments.rejectedByClinicLabel')}}</span></h5>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { inject } from 'vue';
    import { useI18n } from 'vue-i18n';
    import ClinicListInfo from './ClinicListInfo';
    
    export default {
        name: 'ListSentRequests',
        components: {
            ClinicListInfo,
        },
        props: {
            bookingRequests: Array, 
        },
        setup() {
            const { t } = useI18n();
            const infoDialog = inject('infoDialog');

            const getClinicFromRequest = (request) => {
                return {
                    name: request.clinicName,
                    address: request.clinicAddress,
                    zipCode: request.clinicZipCode,
                    city: request.clinicCity,
                    state: request.clinicState,
                    country: request.clinicCountry,
                    phone: request.clinicPhone,
                    email: request.clinicEmail,
                };
            }
    
            const showRejectedRequest = async (request) => {
                await infoDialog({
                    header: t('patientAppointments.rejectedRequestPopupHeader'),
                    body: request.rejectionReason,
                });
            }

            const formatDate = (date) => {
                return new Date(date).toLocaleDateString();
            }

            return {
                getClinicFromRequest,
                showRejectedRequest,
                formatDate,
            }
        }
    }
</script>