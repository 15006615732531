<template>
    <div class="overlay" id="helplinks">
        <bar title="" @click="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('helplinks.title')}}</b></h2>
                    <h4 class="full-width center-text pb-small">{{$t('helplinks.subTitle')}}</h4>
                    <h5 class="full-width center-text pb-medium">{{$t('helplinks.info')}}</h5>
                </div>
                <div class="col-xs-12 center-text">
                    <a class="btn btn__text btn__text--green-border btn__text--short full-width" target="_blank" :href="patientHelpEnglishLink" 
                       style="display: inline-block; padding: 5px 17px 4px 17px; line-height: 28px; text-decoration: none ">
                        <span>{{$t('helplinks.patientHelpEnglishButton')}}</span>
                    </a>
                </div>
                <div class="col-xs-12 center-text">
                    <a class="btn btn__text btn__text--green-border btn__text--short full-width" target="_blank" :href="patientHelpSwahiliLink" 
                       style="display: inline-block; padding: 5px 17px 4px 17px; line-height: 28px; text-decoration: none ">
                        <span>{{$t('helplinks.patientHelpSwahiliButton')}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const closeDialog = () => router.back();

    const patientHelpEnglishLink = computed(() => 'https://drive.google.com/file/d/1HKR2aXHaassqwsTYk3YHiQg2LBj5c_nJ/view?usp=sharing');
    const patientHelpSwahiliLink = computed(() => 'https://drive.google.com/file/d/1AHMET3wa-mbuwoIIwIphK085JVe3t1oE/view?usp=sharing');
</script>
