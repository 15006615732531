<template>
    <div class="overlay overlay--dashboard" id="myQr">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('myQr.title')}}</b></h2>
                    <h5 class="full-width center-text pb-medium">
                        <span>{{$t('myQr.subTitle')}}</span>
                    </h5>
                </div>
                <div class="col-md-12" v-if="patientId">
                    <div class="qr-code" :class="{active: patientId}">
                        <qrcode-vue class="qr" :value="patientId" :size="size" level="H" />
                    </div>
                </div>
                <div class="center-text full-width pt-small"><h5>{{patientId}}</h5></div>
                
            </div>
        </div>
    </div>
</template>


<script setup>
    import QrcodeVue from 'qrcode.vue'

    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';

    const router = useRouter();
    const store = useStore();
    const size = 200;

    const patientId = computed(() => { return store.getters.patientId; });

    const closeDialog = () => router.push('/dashboard');
</script>