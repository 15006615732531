<template>
    <div class="row">
        <div class="col-xs-6 col-xs-push-3 col-sm-2 col-sm-push-5 pin pin__active">
            <div class="row">
                <div class="pin__active--wrapper">
                    <div class="col-xs-3">
                        <div class="pin__active--number" :class="{hasDigit: code.length > 0}"></div>
                    </div>
                    <div class="col-xs-3">
                        <div class="pin__active--number" :class="{hasDigit: code.length > 1}"></div>
                    </div>
                    <div class="col-xs-3">
                        <div class="pin__active--number" :class="{hasDigit: code.length > 2}"></div>
                    </div>
                    <div class="col-xs-3">
                        <div class="pin__active--number" :class="{hasDigit: code.length > 3}"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- Numeric keypad -->
        <div class="col-xs-12">
            <div class="pin pin__digit">
                <div class="row">
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(1)" data-test="digit1"><span>1</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(2)"><span>2</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(3)"><span>3</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(4)"><span>4</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(5)"><span>5</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(6)"><span>6</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(7)"><span>7</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(8)"><span>8</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(9)"><span>9</span></button>
                    </div><div class="col-xs-4">
                        <button type="button"><span></span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="enterDigit(0)"><span>0</span></button>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" @click="deleteLastDigit"><span>&larr;</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, defineEmits, defineProps } from 'vue';

    const props = defineProps({
        modelValue: String
    });

    const emit = defineEmits(['update:modelValue']);

    let code = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit('update:modelValue', value);
        }
    });

    const MAX_LENGTH = props.maxLength || 4;

    const enterDigit = (digit) => {
        if (code.value.length == MAX_LENGTH) return;
        code.value += digit;
    }

    const deleteLastDigit = () => {
        if (code.value.length == 0) return;
        code.value = code.value.substring(0, code.value.length - 1);
    }
</script>
