<template>
    <check-box v-model="consentState"
               :name="`consent_${CONSENTTYPES_BLOODDONORREQUESTS}`"
               :trueValue="CONSENTSTATES_PERMIT"
               :falseValue="CONSENTSTATES_DENY"
               :label="$t('consentTypes.bloodDonorRequestTitle')"
               type="switch"
               class="online-only"></check-box>
</template>

<script setup>
    import CheckBox from '@/Components/Shared/Form/CheckBox.vue';

    import { ref, watch, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import {
        usePatientConsentService,
        CONSENTSTATES_PERMIT,
        CONSENTSTATES_DENY,
        CONSENTTYPES_BLOODDONORREQUESTS
    } from '@/Components/Patients/PatientConsent/PatientConsentService.js'
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const service = usePatientConsentService();
    const caller = useExceptionwrappedCaller();

    let consentState = ref(null);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        if (!store.getters.getPatientConsents) {
            await caller.call(async () => {
                await service.loadPatientConsents();
            });
        }
        const donorConsent = store.getters.getPatientConsents.filter((c) => c.consentType == CONSENTTYPES_BLOODDONORREQUESTS);
        if (donorConsent.length > 0) {
            consentState.value = donorConsent[0].consentState;
        }
    });

    watch(() => consentState.value, async (nv) => {
        await caller.call(async () => {
            if (consentState.value == CONSENTSTATES_DENY) {
                await service.denyConsent(CONSENTTYPES_BLOODDONORREQUESTS);
            }
            else if (consentState.value == CONSENTSTATES_PERMIT) {
                await service.permitConsent(CONSENTTYPES_BLOODDONORREQUESTS);
            }
        });
    });
</script>