<template>
    <div>
        <Label :label="props.label" :error="error" :errorText="props.errorText" :helper="props.helper" :helperIndent="true" />
        <input :type="props.type" v-model="value" :placeholder="props.placeholder" :data-state="dataState" formnovalidate />
    </div>
</template>

<script setup>
    import Label from './Label.vue';

    import { defineEmits, defineProps, watchEffect } from 'vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    const emit = defineEmits(['update:modelValue', 'updated']);

    const props = defineProps({
        modelValue: EmptyString,
        type: {
            type: String,
            default: 'date',
            validator(value) {
                return ['time', 'date'].includes(value);
            }
        },
        placeholder: EmptyString,
        label: EmptyString,
        errorText: EmptyString,
        helper: EmptyString,
        pattern: NullableString,
        patternCaseSensitive: FalseBoolean,
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
        required: FalseBoolean,
    });

    const { value, error, dataState } = useInputValidator(props, {emit});

    watchEffect(() => {
        if (!value.value) return;
        if (props.type === "date") {
            if (value.value) {
                if (value.value.length > 10) {
                    value.value = value.value.substring(0, 10);
                }
            }
        }
        else if (props.type === "time") {
            if (value.value) {
                if (value.value.indexOf('T') > -1) {
                    value.value = value.value.substr(value.value.indexOf('T') + 1, 5);
                }
                else if (value.value.length > 5) {
                    value.value = value.value.substr(0, 5);
                }
            }
        }
    });
</script>