<template>
    <div class="overlay overlay--patient">
        <bar :title="$t(`recordView.title${userType}`)" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="filter">
                        <div class="title">
                            <h4>{{$t('recordView.filterTitle')}}</h4>
                        </div>

                        <div class="checkbox inline">
                            <label>
                                <span class="control-box">
                                    <input type="checkbox" v-model="filter.diagnoses" />
                                    <svg-icon icon-name="check" />
                                </span>
                                <span class="control-label">{{$t('recordView.diagnosesCheckLabel')}}</span>
                            </label>
                        </div>
                        <div class="checkbox inline">
                            <label>
                                <span class="control-box">
                                    <input type="checkbox" v-model="filter.medications" />
                                    <svg-icon icon-name="check" />
                                </span>
                                <span class="control-label">{{$t('recordView.medicationsCheckLabel')}}</span>
                            </label>
                        </div>
                        <div class="checkbox inline">
                            <label>
                                <span class="control-box">
                                    <input type="checkbox" v-model="filter.notes" />
                                    <svg-icon icon-name="check" />
                                </span>
                                <span class="control-label">{{$t('recordView.notesCheckLabel')}}</span>
                            </label>
                        </div>
                        <div class="checkbox inline">
                            <label>
                                <span class="control-box">
                                    <input type="checkbox" v-model="filter.immunizations" />
                                    <svg-icon icon-name="check" />
                                </span>
                                <span class="control-label">{{$t('recordView.immunizationsCheckLabel')}}</span>
                            </label>
                        </div>
                        <div class="checkbox inline">
                            <label>
                                <span class="control-box">
                                    <input type="checkbox" v-model="filter.episodesOfCare" />
                                    <svg-icon icon-name="check" />
                                </span>
                                <span class="control-label">{{$t('recordView.episodesOfCareCheckLabel')}}</span>
                            </label>
                        </div>

                            <button class="erase" @click="resetFilter">
                                <div>
                                    <svg-icon icon-name="erase" />
                                    <span>{{$t('recordView.clearFilterLabel')}}</span>
                                </div>
                            </button>

                        <div class="year-picker">
                            <button class="left" @click="selectPreviousYear">
                                <svg-icon icon-name="chevron" />
                            </button>
                            <h3>{{selectedYear}}</h3>
                            <button class="right" @click="selectNextYear">
                                <svg-icon icon-name="chevron" />
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Entries -->
                <ListEntries :entries="filteredEntries" :year="selectedYear" @clicked="navigateToEntry" data-test="recordlist" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import ListEntries from './ListEntries.vue';

    import { computed, reactive, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { usePatientRecordService } from '@/Components/Records/Record/RecordService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const router = useRouter();
    const store = useStore();
    const patientRecordService = usePatientRecordService();
    const caller = useExceptionwrappedCaller();

    const userType = computed(() => store.getters.getUserType);
    const entries = computed(() => store.getters.getPatientRecordEntries);

    let filter = reactive({
        diagnoses: true,
        medications: true,
        immunizations: true,
        notes: true,
        episodesOfCare: true
    });
    let selectedYear = ref(Number(new Date().getFullYear()));

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await patientRecordService.loadRecord();
        })
    });

    const closeDialog = () => router.push({ name: 'patient' });

    const selectPreviousYear = () => selectedYear.value--;

    const selectNextYear = () => {
        if (selectedYear.value < new Date().getFullYear())
            selectedYear.value++;
    }

    const navigateToEntry = (type, id) => {
        //TODO: navigate to the recordentry type with the specified id...
        console.log("navigate to record entry", type, id);
    };

    const resetFilter = () => {
        filter.diagnoses = true;
        filter.medications = true;
        filter.immunizations = true;
        filter.notes = true;
        filter.episodesOfCare = true;
    }

    /*
    0: Note,
    1: Diagnosis,
    2: Medication,
    3: Immunization,
    4: Observation,
    5: ConsultationNote
    6: Episode of Care
    */
    const filteredEntries = computed(() => {
        console.log('filteredEntries', entries.value);
        if (entries.value == null)
            return [];

        return entries.value.filter((v) => {
            let entryType = v.entryType;
            return (filter.diagnoses && entryType == 1) ||
                (filter.medications && entryType == 2) ||
                (filter.immunizations && entryType == 3) ||
                (filter.notes && entryType == 5) ||
                (filter.episodesOfCare && entryType == 6);
        });
    });
</script>

