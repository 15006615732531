<template>
    <!-- Recorder + player -->
    <div class="col-xs-8 online-only" v-if="audio != '' && audio !== null && !smallPlayer">
        <div id="audioPlayer">
            <button class="player player__play left" @click="play" v-if="!playing && !paused">
                <svg-icon icon-name="play" />
            </button>
            <button class="player player__play left" @click="playContinue" v-if="!playing && paused">
                <svg-icon icon-name="play" />
            </button>
            <button class="player player__stop left" @click="stop" v-if="playing">
                <svg-icon icon-name="stop" />
            </button>
            <button class="player player__pause player--gray left" v-if="playing" @click="pause">
                <svg-icon icon-name="pause" />
            </button>
            <button class="player player__remove player--red left" @click="remove" v-if="isClinician && removeMedia">
                <svg-icon icon-name="cross" />
            </button>
        </div>
    </div>
    <div class="col-xs-4 pull-right online-only" v-if="!smallPlayer && record">
        <button class="btn btn__text-icon btn__text-icon--green right" @click="toggleRecording">
            <div>
                <svg-icon icon-name="recording" />
                <h3>{{$t('general.record')}}</h3>
            </div>
        </button>
    </div>
    <!-- Small player only -->
    <div id="audioPlayer" class="online-only" v-if="audio != '' && audio !== null && smallPlayer">
        <button class="player player__play player__play--small left" @click="play" v-if="!playing && !paused">
            <svg-icon icon-name="play" />
        </button>
        <button class="player player__stop player__play--small left" @click="stop" v-if="playing">
            <svg-icon icon-name="stop" />
        </button>
    </div>
</template>
<script>
    import { ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import uploadService from '@/Components/Shared/Form/Upload.js';

    //import { useStore } from 'vuex';
    //import uploadService from './Upload.js';

    export default {
        name: 'MediaRecorder',
        props: {
            audio: {
                type: String,
                default: ''
            },
            smallPlayer: {
                type: Boolean,
                default: false
            },
            record: {
                type: Boolean,
                default: true
            },
            removeMedia: {
                type: Boolean,
                default: true,
            }
        },
        emits: ['remove', 'toggle'],
        setup(props, { emit }) {
            const store = useStore();
            const isClinician = computed(() => store.getters.isClinician);

            let playing = ref(false);
            let paused = ref(false);
            let blob = ref(null);
            let audioElement = ref(null);

            //let playable = ref(false);

            //onMounted(() => {
            //    if (!props.audio.endsWith('.mp4') && iPhone) {
            //        // kan ikke afspilles på iPhone
            //        // periodisk tjek på om der findes en mp4 i stedet...
            //            // udskift props.audio ved mp4
            //            // playable = true
            //    }
            //    else {
            //        playable.value = true;
            //    }
            //})

            const play = async () => {
                console.log('getting audio blob')
                var blobUrl = await uploadService.getAudioFileUrl(props.audio);
                console.log('converting blob to audio', blobUrl);
                audioElement.value = new Audio(blobUrl);
                console.log('starting player');
                audioElement.value.play();
                playing.value = true;
                audioElement.value.onended = () => {
                    playing.value = false;
                    console.log('stopped playing');
                }
            }

            const playContinue = () => {
                audioElement.value.play();
                playing.value = true;
                paused.value = false;
                console.log('continuing playing');
            }

            const pause = () => {
                audioElement.value.pause();
                playing.value = false;
                paused.value = true;
                console.log('pausing playing');

            }

            const stop = () => {
                audioElement.value.pause();
                audioElement.value.currentTime = 0;
                playing.value = false;
                console.log('stopped playing');
            }

            const toggleRecording = () => {
                console.log('emitting : open recorder')
                emit('toggle');
            };

            const remove = () => {
                emit('remove')
            }

            return {
                toggleRecording,
                isClinician,
                blob,
                play,
                paused,
                playContinue,
                pause,
                stop,
                remove,
                playing
            }
        }
    }
</script>
