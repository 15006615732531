import api from './ApiProxy.js';
import lsProxy from './LocalStorageProxy.js';
import ssProxy from './SessionStorageProxy.js';
import * as strings from '@/Shared/Strings.js';


const getOnlineStatusFromLS = () => lsProxy.get(strings.ONLINESTATUS, false);

const refreshTokenValid = (token) => {
    const endDate = new Date(token.expireAt);
    return endDate > new Date();
}


/* check that refresh token of the current user exists and it is not expired */
const refreshTokenCheck = () => {
    const userId = ssProxy.get(strings.USERID);
    let refreshToken = lsProxy.get(userId + '_' + strings.REFRESHTOKEN, false);
    if (!refreshToken)
        return null;

    if (!refreshTokenValid(refreshToken))
        return null;

    return refreshToken;
}


/* check that access token of the current user exists and it not expired */
const accessTokenCheck = () => {
    if (!getOnlineStatusFromLS())
        return true;

    let currentDate = new Date();
    let accessToken = ssProxy.get(strings.ACCESSTOKEN);
    if (!accessToken)
        return null;

    let parsedToken = parseJwt(accessToken);
    let endDate = new Date(parsedToken.exp * 1000);

    if (endDate <= currentDate)
        return null;

    return accessToken;
}


function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


const refreshAccessToken = async (data) => {
    //console.log('Get new access token...');
    if (!getOnlineStatusFromLS()) {
        console.log('Access token granted due to offline mode...');
        return true;
    }

    let refreshToken =
    {
        "Token": data.tokenString,
        "Username": data.userName
    };

    let result = await api
        .post('refresh-token', refreshToken, { "Content-Type": "application/json" })
        .catch(function (error) {
            console.error("[refreshAccessToken] error:", error);
            result = error.response;
        });

    //console.log("[refreshAccessToken] result:", result);

    if (result && result.data && result.data.accessToken && result.data.accessToken !== undefined)
        ssProxy.set(strings.ACCESSTOKEN, result.data.accessToken);

    return result;
}


const hasVerifiedToken = async () => {
    let accessToken = accessTokenCheck();

    if (accessToken != null) {
        //console.log("accessTokenCheck OK");
        return true;
    }

    //console.log("accessTokenCheck Fail");

    let refreshToken = refreshTokenCheck();
    if (refreshToken == null) {
        console.log("refreshTokenCheck Fail");
        return false;
    }

    //console.log("refreshTokenCheck OK, getting new accessToken");
    let result = await refreshAccessToken(refreshToken);
    if (result.status == 200) {
        console.log("new access token generated");
        return true;
    }
    else {
        console.warn("access token not generated", result);
        return false;
    }
}




export default {
    name: "AccessTokens",
    accessTokenCheck,
    refreshTokenValid,
    hasVerifiedToken
}