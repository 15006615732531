import { useStore } from 'vuex';
import { useUserService } from '@/Components/Users/User/UserService.js';
import lsProxy from '@/Connections/LocalStorageProxy.js';

export const useSettingsService = () => {
    const store = useStore();
    const userService = useUserService();

    const clearLocalData = (userId) => {
        const keys = lsProxy.getKeysByPartialKey(userId);
        keys.forEach((key) => lsProxy.remove(key));

        userService.removeSavedUser(userId);

        store.commit('reinstateOnlineStatus');
    }

    return {
        clearLocalData
    }
}