<template>
    <bar :title="viewTitle" @close="closeDialog" />
    <div class="container" v-if="definitions && definitions.valueDefinition">
        <div class="row">
            <div class="col-xs-12">
                <form>
                    <template v-if="definitions.valueDefinition.displayType!=4">
                        <create-measurement-component v-model="values[definitions.valueDefinition.codingCode]"
                                                      :definition="definitions.valueDefinition"
                                                      @updated="stateChanged"></create-measurement-component>
                    </template>
                    <template v-for="def in definitions.componentDefinitions" :key="def.codingCode">
                        <create-measurement-component v-model="values[def.codingCode]"
                                                      :definition="def"
                                                      @updated="stateChanged"></create-measurement-component>
                    </template>

                    <template v-if="definitions.valueDefinition.codingCode == BLOODTYPEKEY">
                        <blooddonor-consent></blooddonor-consent>
                    </template>

                    <div class="col-xs-6">
                        <button class="btn btn__text btn__text--green-border left" type="button" @click="closeDialog">
                            <h4>{{$t('general.cancel')}}</h4>
                        </button>
                    </div>
                    <div class="col-xs-6">
                        <button class="btn btn__text btn__text--green right online-only" :class="{disabled:!isValid}" :disabled="!isValid" type="button" @click="create">
                            <h4>{{$t('general.create')}}</h4>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script setup>
    import CreateMeasurementComponent from '@/Components/HealthData/Measurements/Types/CreateMeasurementComponent.vue'
    import BlooddonorConsent from '@/Components/Patients/PatientConsent/PatientConsent.vue';

    import { computed, defineEmits, defineProps, reactive, toRaw, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    //import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import uploadService from '@/Components/Shared/Form/Upload.js';
    import { BLOODTYPEKEY } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';

    const props = defineProps({
        measurementDefinitionId: String
    });
    const emit = defineEmits(['created', 'canceled']);

    const store = useStore();
    const { t } = useI18n();

    let controls = reactive({});

    const stateChanged = (newState) => {
        console.log('[GenericCreate] State changed', newState);
        controls[newState.name] = newState.valid;
        console.log('Controls with validation registered', controls);
    }

    let isValid = computed(() => {
        let v = true;
        for (var p in controls)
            v = v && controls[p];
        return v;
    });

    console.log('GENERIC CREATE - ID', props.measurementDefinitionId);

    // stores entered values and maps these to the definitions by definition id
    let values = reactive({});

    const definitions = computed(() => store.getters.getMeasurementDefinition(props.measurementDefinitionId));

    watchEffect(() => {
        if (definitions.value == null) return;
        console.log('Definition changed', definitions.value);
        if (definitions.value.valueDefinition.displayType != 4)
            values[definitions.value.valueDefinition.codingCode] = '';
        definitions.value.componentDefinitions.forEach((cd) => values[cd.codingCode] = '');
    });

    const viewTitle = computed(() => {
        let title = definitions.value
            ? t('createMeasurement.title') + ' ' + t(definitions.value.titleKey + '')
            : t('createMeasurement.title');

        if (definitions.value.valueDefinition.codingCode == BLOODTYPEKEY)
            title += ' - use EldonCard';

        return title;
    });

    const create = async () => {
        //if (definitions.value.valueDefinition.displayType == 6) {
        //    // upload data to blob and replace data with reference
        //    let fileRefs = [];
        //    let data = values[definitions.value.valueDefinition.codingCode];
        //    data.split('|').filter((d) => d != '').forEach(async (d) => {
        //        let fileRef = await uploadService.uploadBase64DataUrl(d, 'patient');
        //        fileRefs = [...fileRefs, fileRef];
        //    });
        //    values[definitions.value.valueDefinition.codingCode] = fileRefs.join('|');
        //    console.log(`fileRefs ${values[definitions.value.valueDefinition.codingCode]}`);
        //}

        const value = {
            value: values[definitions.value.valueDefinition.codingCode],
            definition: toRaw(definitions.value.valueDefinition)
        };

        const componentPromises = definitions.value.componentDefinitions.map(async (cd) => {
            if (cd.displayType == 6) {
                // upload data to blob and replace data with reference
                let fileRefs = [];
                let data = values[cd.codingCode];
                let dataChunks = data.split('|').filter((d) => d != '');
                for (var i = 0; i < dataChunks.length; i++) {
                    let fileRef = await uploadService.uploadBase64DataUrl(dataChunks[i], 'patient');
                    fileRefs = [...fileRefs, fileRef];
                }
                values[cd.codingCode] = fileRefs.join('|');
                console.log(`fileRefs ${values[cd.codingCode]}`);
            }

            return {
                value: values[cd.codingCode].toString(),
                definition: toRaw(cd)
            }
        });

        let components = await Promise.all(componentPromises);

        let measurement = {
            titleKey: definitions.value.titleKey,
            value,
            components,
        };
        emit('created', measurement);
    }

    const closeDialog = () => emit('canceled');
</script>
