<template>
    <div id="patientEditEpisodeOfCare" class="overlay overlay--patient">
        <bar :title="$t(`editCancerEpisodeOfCare.title`)"
             :by="clinicianName" :by-label="$t('editCancerEpisodeOfCare.changedByLabel')"
             :date="editDate" :date-label="$t('editCancerEpisodeOfCare.changedDateLabel')"
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info">
                        <div class="content">
                            <div class="row">
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.serumUreaLevelsKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.serumUreaLevelsLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredSerumUreaLevelsLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.serumCreatinineLevelsKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.serumCreatinineLevelsLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredSerumCreatinineLevelsLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.eGFRKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.eGFRLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredeGFRLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.astLevelsKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.astLevelsLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredASTLevelsLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.altLevelsKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.altLevelsLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredALTLevelsLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.alpLevelsKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.alpLevelsLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredALPLevelsLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.ptKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.ptLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredPTLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.pttKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.pttLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredPTTLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.inrKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.inrLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredINRLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.ggtKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.ggtLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredGGTLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.serumAlbuminLevelsKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.serumAlbuminLevelsLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredSerumAlbuminLevelsLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.rbgKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.rbgLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredRBGLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.lymphocytesKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.lymphocytesLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredLymphocytesLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.plateletCountKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.plateletCountLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredPlateletCountLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.cancerAntigenKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.cancerAntigenLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredCancerAntigenLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.chestXRayReportKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.chestXRayReportLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredChestXRayReportLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.ctScanChestAbdomenReportKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.ctScanChestAbdomenReportLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredCTScanChestAbdomenReportLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="cancer.abdominalUltrasoundReportKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editCancerEpisodeOfCare.abdominalUltrasoundReportLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredAbdominalUltrasoundReportLabel"
                                                             create-measurement-route-name="eoccanceredit create measurement"
                                                             return-to-name="editcancerepisodeofcare">
                                    </measurement-link-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup>
    import MeasurementLinkButton from './MeasurementLinkButton.vue';

    import { computed, defineProps, reactive, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useCancerEpisodeOfCareService } from '@/Components/HealthData/EpisodeOfCare/CancerEpisodeOfCareService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import * as cancer from '@/Components/HealthData/EpisodeOfCare/CancerConstants.js';

    const props = defineProps({
        id: { type: String, required: true },
    });

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const service = useCancerEpisodeOfCareService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);

    const nonMeasuredSerumUreaLevelsLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewSerumUreaLevelsLink')
            : t('editCancerEpisodeOfCare.notMeasuredSerumUreaLevelsLink'));
    const nonMeasuredSerumCreatinineLevelsLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewSerumCreatinineLevelsLink')
            : t('editCancerEpisodeOfCare.notMeasuredSerumCreatinineLevelsLink'));
    const nonMeasuredeGFRLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNeweGFRLink')
            : t('editCancerEpisodeOfCare.notMeasuredeGFRLink'));
    const nonMeasuredASTLevelsLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewASTLevelsLink')
            : t('editCancerEpisodeOfCare.notMeasuredASTLevelsLink'));
    const nonMeasuredALTLevelsLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewALTLevelsLink')
            : t('editCancerEpisodeOfCare.notMeasuredALTLevelsLink'));
    const nonMeasuredALPLevelsLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewALPLevelsLink')
            : t('editCancerEpisodeOfCare.notMeasuredALPLevelsLink'));
    const nonMeasuredPTLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewPTLink')
            : t('editCancerEpisodeOfCare.notMeasuredPTLink'));
    const nonMeasuredPTTLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewPTTLink')
            : t('editCancerEpisodeOfCare.notMeasuredPTTLink'));
    const nonMeasuredINRLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewINRLink')
            : t('editCancerEpisodeOfCare.notMeasuredINRLink'));
    const nonMeasuredGGTLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewGGTLink')
            : t('editCancerEpisodeOfCare.notMeasuredGGTLink'));
    const nonMeasuredSerumAlbuminLevelsLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewSerumAlbuminLevelsLink')
            : t('editCancerEpisodeOfCare.notMeasuredSerumAlbuminLevelsLink'));
    const nonMeasuredRBGLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewRBGLink')
            : t('editCancerEpisodeOfCare.notMeasuredRBGLink'));
    const nonMeasuredLymphocytesLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewLymphocytesLink')
            : t('editCancerEpisodeOfCare.notMeasuredLymphocytesLink'));
    const nonMeasuredPlateletCountLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewPlateletCountLink')
            : t('editCancerEpisodeOfCare.notMeasuredPlateletCountLink'));
    const nonMeasuredCancerAntigenLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewCancerAntigenLink')
            : t('editCancerEpisodeOfCare.notMeasuredCancerAntigenLink'));
    const nonMeasuredChestXRayReportLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewChestXRayReportLink')
            : t('editCancerEpisodeOfCare.notMeasuredChestXRayReportLink'));
    const nonMeasuredCTScanChestAbdomenReportLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewCTScanChestAbdomenReportLink')
            : t('editCancerEpisodeOfCare.notMeasuredCTScanChestAbdomenReportLink'));
    const nonMeasuredAbdominalUltrasoundReportLabel = computed(() =>
        isClinician.value
            ? t('editCancerEpisodeOfCare.registerNewAbdominalUltrasoundReportLink')
            : t('editCancerEpisodeOfCare.notMeasuredAbdominalUltrasoundReportLink'));


    let clinicianName = ref('');
    let editDate = ref('');

    let model = reactive({
        episodeOfCareId: props.id,
    });

    let editing = reactive({});
    for (var key in model)
        editing[key] = model[key];

    let actions = {
    };

    watchEffect(async () => {
        if (store.getters.getPatientId) {
            await caller.call(async () => {
                await service.loadCancerEpisodesOfCare();
            });
        }
    });

    watchEffect(() => {
        const eoc = store.getters.getPatientEpisodeOfCare(props.id);
        if (eoc) {
            model.episodeOfCareId = eoc.episodeOfCareId;

            // refresh editing structure with new data
            for (var key in model)
                editing[key] = model[key];

            clinicianName.value = [eoc.clinicianGivenNames, eoc.clinicianFamilyName].join(' ');
            editDate.value = eoc.date;
        }
    });

    const closeDialog = () => router.push({ name: 'episodeofcare' });
</script>
