<template>
	<button :class="'btn btn__card btn__card--' + size + ' ' + color" @click="navigate()">
		<div class="svg-container">
			<svg xmlns="http://www.w3.org/2000/svg" :style="svgStyle">
				<use :xlink:href="'#'+svg" />
			</svg>
		</div>
		<div class="text-container" v-html="nodashtext"></div>
	</button>
</template>

<script setup>
	import { useRouter } from 'vue-router';
    import { computed, defineProps } from 'vue';

	const props = defineProps({
		linkTo: [String, Object],
		color: String,
		size: String,
		text: String,
		svg: String,
		svgStyle: { type: String, default: null },
	});

	let router = useRouter();

	let nodashtext = computed(() => props.text?.replace('-', '&shy;'));

	const navigate = async () => {
		if (typeof props.linkTo === "string")
			await router.push({ name: props.linkTo });
		else
			await router.push(props.linkTo);
	}
</script>
