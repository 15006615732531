<template>
    <div id="conversation">
        <div class="container">
            <div class="row" data-test="message-list">
                <template v-for="message in messages" :key="message.messageId">
                    <div class="col-xs-12 message-list-item" @click="viewMessageClicked(message)" data-test="message-item">
                        <div class="row">
                            <div class="col-xs-9">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <div class="message-sender-name" :class="{'has-content':getSenderName(message) && message.date}">{{getSenderName(message)}}</div>
                                        <div class="message-sent-date"><date-display :isoDate="message.date" /></div>
                                    </div>
                                    <div class="col-xs-9 message-text">
                                        {{message.text}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-3" v-if="hasAttachments(message)" data-test="message-attachments">
                                <div class="message-attachments"><svg-icon icon-name="clip"></svg-icon></div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="button-container-bottom animate-in-delay">
            <button type="button" class="btn btn__text btn__text btn__text--green-border online-only" @click="newMessageClicked" data-test="new-message-button">
                <h3>{{$t('conversation.newMessageButton')}}</h3>
            </button>
        </div>
    </div>
</template>


<script setup>
    import { computed, defineEmits, defineProps, onMounted, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        patientId: { type: String },
        clinicId: { type: String },
        clinicianId: { type: String }
    });
    const emit = defineEmits(['viewmessage', 'newmessage']);

    const store = useStore();
    const service = useMessagingService();
    const caller = useExceptionwrappedCaller();

    //console.log('Conversation ---->', props.patientId, props.clinicId, props.clinicianId);
    //onMounted(() => {
    //    //console.log('Conversation (mounted) ---->', props.patientId, props.clinicId, props.clinicianId);
    //});

    const messages = computed(() => {
        let msgs = store.getters.getConversationMessages(props.patientId, props.clinicId);
        return msgs.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
    });

    const conversationTimestamp = computed(() => store.getters.getConversationTimestamp);

    // refresh messages in this conversation if they are not loaded already (e.g., when conversation is deeplinked)
    watchEffect(async () => {
        //console.log('Conversation (watcheffect) ---->', props.patientId, props.clinicId, props.clinicianId, conversationTimestamp.value);
        if (props.patientId && props.clinicId && conversationTimestamp.value) {
            await caller.call(async () => {
                //console.log('Conversation LOADING DATA', props.patientId, props.clinicId);
                await service.refreshConversationByPatientAndClinic(props.patientId, props.clinicId);
            });
        }
    });

    const getSenderName = (message) => {
        if (message.senderId == message.clinicianId)
            return [message.clinicianGivenNames, message.clinicianFamilyName].join(' ').trim();
        else
            return [message.patientGivenNames, message.patientFamilyName].join(' ').trim();
    }

    const hasAttachments = (message) => message.attachments.length > 0;

    const viewMessageClicked = (message) => emit('viewmessage', message.messageId);

    const newMessageClicked = () => emit('newmessage');
</script>
