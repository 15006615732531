<template>
    <div class="overlay overlay--patient" id="editConsultationNote">
        <bar :title="$t('editConsultationNote.title')" 
             :by="clinicianName" :by-label="$t('editConsultationNote.changedByLabel')" 
             :date="changedDate" :date-label="$t('editConsultationNote.changedDateLabel')" 
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content">
                            <div class="title">
                                {{$t('editConsultationNote.consultationNoteLabel')}}
                            </div>
                            <div class="value" v-html="consultationNote.replace(/\n/g, '<br/>')"></div>
                        </div>
                        <div class="content" v-if="audioNote">
                            <div class="title">
                                {{$t('general.audio')}}
                            </div>
                            <div class="value">
                                <media-player :audio="audioNote" :removeMedia="false" :record="false"></media-player>
                            </div>
                        </div>
                        <div class="content">
                            <image-list-picker :editable="false" v-model="images" class="row" :key="props.id"></image-list-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';
    import ImageListPicker from '@/Components/Shared/Form/ImageListPicker.vue'

    import { defineProps, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        id: { type: String, required: true },
    });

    const store = useStore();
    const router = useRouter();

    let consultationNote = ref('');
    let changedDate = ref('');
    let audioNote = ref('')
    let clinicianName = ref('');
    let images = ref('');

    watchEffect(() => {
        const consNote = store.getters.getConsultationNote(props.id);
        if (!consNote) return;

        audioNote.value = consNote.audioNote;
        consultationNote.value = consNote.note;
        changedDate.value = consNote.date;
        images.value = consNote.images;
        clinicianName.value = [consNote.clinicianGivenNames, consNote.clinicianFamilyName].join(' ');
    });

    const closeDialog = () => router.push({ name: 'note' });
</script>
