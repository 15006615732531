<template>
	<div class="col-xs-12" v-for="request in requests" :key="request.patientAccessId">
		<div class="user">
            <div class="data">
                <div class="info">
                    <person-status-info v-bind="getPersonData(request)" />
                </div>
                <div class="more">
                    <button class="btn btn__round btn__round--medium btn__round--green-outline online-only" 
							@click="toggleOptions(request)">
                        <div class="svg-container">
                            <svg-icon icon-name="chevron-down" />
                        </div>
                    </button>
                </div>
            </div>
            <div class="actions" :class="{active: request.optionsActive}">
				<!-- ALL STATES -->
                <button class="btn btn__text btn__text--red-border btn__text--short left online-only" 
						v-show="notWizard(request)" 
						@click="denyAccess(request)"><h4>{{$t('listPatientAccess.disconnectButton')}}</h4></button>

				<!-- PENDING -->
                <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
						v-show="isPending(request) && notWizard(request)"
						@click="showStep1(request)"><h4>{{$t('general.accept')}}</h4></button>

				<!-- EXPIRED -->
                <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
						v-show="isExpired(request) && notWizard(request)"
						@click="showStep1(request)"><h4>{{$t('listPatientAccess.activateButton')}}</h4></button>

				<!-- ACTIVE -->
				<button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
						v-show="isActive(request) && !isLimited(request) && notWizard(request)" 
						@click="showStep2(request)"><h4>{{$t('listPatientAccess.limitButton')}}</h4></button>
				<button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
						v-show="isActive(request) && isLimited(request) && notWizard(request)" 
						@click="grantAccess(request)"><h4>{{$t('listPatientAccess.notLimitedButton')}}</h4></button>
                <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
						v-show="isActive(request) && notWizard(request)" 
						@click="pauseAccess(request)"><h4>{{$t('listPatientAccess.pauseButton')}}</h4></button>

				<!-- PAUSED -->
                <!--<button class="btn btn__text btn__text--green-border btn__text--short right" 
						v-show="isPaused(request) && notWizard(request)" 
						@click="showStep2(request)"><h4>Limit</h4></button>-->
                <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
						v-show="isPaused(request) && notWizard(request)" 
						@click="resumeAccess(request)"><h4>{{$t('listPatientAccess.resumeButton')}}</h4></button>

				<!-- GRANT PERIOD FLOW -->
                <div v-if="request.step1Visible">
                    <button class="btn btn__text btn__text--red-border btn__text--short left" 
							@click="cancelOptions(request)"><h4>{{$t('general.cancel')}}</h4></button>
                    <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
							v-show="isPending(request) || isExpired(request)"
							@click="grantAccess(request)"><h4>{{$t('listPatientAccess.notLimitedButton')}}</h4></button>
                    <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
							v-show="isPending(request) || isExpired(request)" 
							@click="showStep2(request)"><h4>{{$t('listPatientAccess.limitButton')}}</h4></button>
				</div>

                <div v-if="request.step2Visible">
                    <date-time type="date" v-model="request.editToDate" />
                    <button class="btn btn__text btn__text--red-border btn__text--short left" 
							@click="cancelOptions(request)"><h4>{{$t('general.cancel')}}</h4></button>
                    <button class="btn btn__text btn__text--green-border btn__text--short right online-only" 
							@click="limitedGrantAccess(request)"><h4>{{$t('general.accept')}}</h4></button>
                </div>

            </div>
        </div>

	</div>
</template>

<script>
    import { computed, ref, watch } from 'vue';
    import { useStore } from 'vuex';
	import PersonStatusInfo from './PersonStatusInfo.vue';
	import { makeRegexSafe } from '@/Components/Shared/RegexUtils.js';
	import DateTime from '@/Components/Shared/Form/DateTime.vue';

	export default {
		name: 'ListClinicianRequests',
		components: {
			PersonStatusInfo,
			DateTime,
        },
		props: {
			filter: String,
		},
		emits: ['denied', 'granted', 'paused', 'resumed', 'reactivated'],
		setup(props, { emit }) {
            const store = useStore();

			let patientAccess = computed(() => store.getters.getPatientAccess
				.map((v) => {
					// prepare each item in the structure for statehandling specific to the list handling
					return {
						patientAccessId: v.patientAccessId,
						patientId: v.patientId,
						clinicianId: v.clinicianId,
						clinicianGivenNames: v.clinicianGivenNames,
						clinicianFamilyName: v.clinicianFamilyName,
						date: v.date,
						fromDate: v.fromDate,
						toDate: v.toDate,
						hasAccess: v.hasAccess,
						paused: v.paused,
						requestDate: v.requestDate,
						location: v.location,
						optionsActive: false,
						step1Visible: false,
						step2Visible: false,
						editToDate: null,
					};
				}));

			let requests = ref(patientAccess.value);

			console.log('List clinicians requests mounted', patientAccess.value);

			watch(() => [props.filter, patientAccess.value], () => {
                let termslist = (props.filter || '').replace(/\s+/gi, ' ');
                termslist = makeRegexSafe(termslist);
                let terms = termslist.replace(' ', '|');
                console.log('filter changed', terms);
                try {
                    let rx = new RegExp('(' + terms + ')', 'gi');
                    requests.value = patientAccess.value.filter((v) => {
                        rx.lastIndex = 0;
                        let givenTest = rx.test(v.clinicianGivenNames);
                        rx.lastIndex = 0;
                        let familyTest = rx.test(v.clinicianFamilyName);

                        console.log('filtering', v.clinicianGivenNames, givenTest, familyTest);

                        return givenTest || familyTest;
                    });
                }
                catch (e) {
                    console.error(e);
                    // do nothing
                }
            });

			const toggleOptions = (req) => {
				console.log('toggleOptions', req);
				req.optionsActive = !req.optionsActive;
				if (!req.optionsActive) {
					cancelOptions(req);
                }
			}

			const isExpired = (req) => {
				return req.toDate != null
					&& new Date(req.toDate) < new Date();
			}

			const isPending = (req) => {
				return !isExpired(req)
					&& !req.hasAccess;
			}

			const isLimited = (req) => {
				return req.toDate != null;
            }

			const isPaused = (req) => {
				return !isExpired(req)
					&& req.paused;
			}

			const isActive = (req) => {
				return !isPending(req)
					&& !isExpired(req)
					&& !isPaused(req);
            }

			const notWizard = (request) => {
				return !(request.step1Visible || request.step2Visible);
			}

			const getPersonData = (req) => {
				//console.log('get persondata', req);
				return {
					entityId: req.clinicianId,
					givenNames: req.clinicianGivenNames,
					familyName: req.clinicianFamilyName,
					image: '',	// need clinician's image
					requested: true,
					hasAccess: req.hasAccess,
					paused: req.paused,
					location: req.location,
					toDate: req.toDate,
				};
			}

			const showStep1 = (request) => {
				request.step2Visible = false;
				request.step1Visible = true;
			}

			const showStep2 = (request) => {
				request.editToDate = request.toDate;
				request.step1Visible = false;
				request.step2Visible = true;
			}

			const hideWizard = (request) => {
				request.step1Visible = false;
				request.step2Visible = false;
			}

			const cancelOptions = (request) => {
				hideWizard(request);
				request.optionsActive = false;
				request.editToDate = null;
			}

			const denyAccess = (request) => {
				emit('denied', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId });
			};
			const grantAccess = (request) => {
				hideWizard(request);
				request.toDate = null;
				emit('granted', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId, endDate: null });
				request.optionsActive = false;
				request.editToDate = null;
			};
			const limitedGrantAccess = (request) => {
				let endDate = request.editToDate;
				if (endDate < new Date(1900, 1, 1))
					endDate = null;
				emit('granted', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId, endDate: endDate });
				request.optionsActive = false;
				request.toDate = endDate;
				request.editToDate = null;
			};
			const pauseAccess = (request) => {
				emit('paused', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId, endDate: request.toDate });
				request.optionsActive = false;
			};
			const resumeAccess = (request) => {
				console.log('before resume', request);
				emit('resumed', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId, endDate: request.toDate });
				console.log('after resume', request);
				//request.optionsActive = false;
			};
			const accessReactivated = (request) => {
				emit('reactivated', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId, endDate: request.toDate });
				request.optionsActive = false;
			};
			const limitedAccessReactivated = (request) => {
				let endDate = request.editToDate;
				if (!endDate || endDate < new Date(1900, 1, 1))
					endDate = null;
				emit('reactivated', { clinicianId: request.clinicianId, patientAccessId: request.patientAccessId, endDate: endDate });

				request.optionsActive = false;
				hideWizard(request);
				request.toDate = endDate;
				request.editToDate = null;
			};

			return {
				requests,
				isPaused,
				isExpired,
				isLimited,
				isPending,
				isActive,
				notWizard,
				cancelOptions,
				getPersonData,
				denyAccess,
				grantAccess,
				limitedGrantAccess,
				pauseAccess,
				resumeAccess,
				accessReactivated,
				showStep1,
				showStep2,
				limitedAccessReactivated,
				toggleOptions,
			};
		}
	}
</script>

