<template>
    <div id="clinicianclinicconversationmessage" class="overlay overlay--patient">
        <bar :title="$t('clinicianConversationMessage.title')" @close="closeDialog" />

        <message v-bind="data"></message>
    </div>
</template>


<script setup>
    import { computed, defineProps, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import Message from '@/Components/Consultations/Messaging/Message.vue';

    const props = defineProps({
        messageId: { type: String },
    });

    const router = useRouter();

    const messageId = computed(() => props.messageId);

    const data = reactive({
        messageId: messageId.value
    });

    const closeDialog = () => router.back();
</script>