<template>
    <!-- alert (=error), help, consent, info -->
    <div id="popup" :data-type="type" :data-active="open">
        <div class="popup">
            <div class="popup__header">
                <h4>{{header}}</h4>
                <button @click="canceled">
                    <svg-icon icon-name="cross" />
                </button>
            </div>
            <div class="popup__content">
                <slot><span>{{body}}</span></slot>
            </div>
            <div class="popup__buttons">
                <button @click="canceled" 
                        class="btn btn__text btn__text--medium btn__text--white-border pull-left" 
                        v-if="buttons.includes('cancel')"
                        data-test="popup-cancel-button">
                    <h5>{{cancel}}</h5>
                </button>
                <button @click="accepted" 
                        class="btn btn__text btn__text--medium btn__text--white-border pull-right" 
                        v-if="buttons.includes('accept')"
                        data-test="popup-accept-button">
                    <h5>{{accept}}</h5>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Popup',
        emits: ['accepted', 'declined'],
        props: {
			type: {
				type: String,
                default: 'alert',
                validator(value) {
                    return ['alert', 'consent', 'help', 'info'].includes(value);
                }
			},
			open: {
				type: Boolean,
				default: false
			},
            header: {
                type: String,
                default: ''
			},
			body: {
				type: String,
				default: ''
			},
			accept: {
				type: String,
				default: 'Accept'
			},
			cancel: {
				type: String,
				default: 'Cancel'
            },
            buttons: {
                type: String,
                default: 'accept,cancel',
                validator(value) {
                    // check if all button-identifiers are valid
                    return value.split(',').reduce((p,c) => p && ['accept', 'cancel'].includes(c), true);
                }
            }
		},
        setup(_, { emit }) {
            let accepted = () => {
                emit('accepted', true);
            }

			let canceled = () => {
				emit('declined', true);
			}

            return {
                accepted,
                canceled,
            }
        }
    }
</script>