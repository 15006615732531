<template>
    <div class="overlay overlay--dashboard" id="myPatients">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('connectQR.title')}}</b></h2>
                    <h5 class="full-width center-text pb-medium">
                        <span>{{$t('connectQR.subTitle')}}</span>
                    </h5>
                </div>
                <div class="col-xs-12">
                    <div class="qr-scanner">
                        <div class="result full-width" :class="{active : hasPatient || newPatient}">
                            <div class="center-text full-width">
                                <h4 class="pb-small">Found {{patientName}} :</h4>
                            </div>

                            <div class="btn-container" :class="{active: hasPatient}">
                                <button v-if="hasAccess && !accessPaused" class="btn btn__text btn__text--green" @click="navigateToPatient()">
                                    <h3>{{$t('connectQR.viewPatient')}}</h3>
                                </button>
                                <button v-if="hasAccess && accessPaused" class="btn btn__text btn__text--green-border">
                                    <h3>{{$t('connectQR.accessPaused')}}</h3>
                                </button>
                                <button v-if="!hasAccess" class="btn btn__text btn__text--green-border">
                                    <h3>{{$t('connectQR.accessPending')}}</h3>
                                </button>
                            </div>

                            <div class="btn-container" :class="{active: newPatient}">
                                <button class="btn btn__text btn__text--green" @click="connectToPatient()">
                                    <h3>{{$t('connectQR.connect')}}</h3>
                                </button>
                            </div>

                            <div class="center-text full-width"><h5 class="pt-small pb-small">{{scan}}</h5></div>
                        </div>
                        <qr-stream @decode="onDecode" class="mb" v-if="cameraAccess">
                            <div style="color: red;" class="frame"></div>
                        </qr-stream>
                        <div class="no-cam" :class="{active: !cameraAccess && !isLoadingCam}">
                            <h3 class="center-text">{{$t('connectQR.noCam')}}</h3>
                        </div>
                        <div class="qr-loader" :class="{active: isLoadingCam}">
                            <div class="load-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import { QrStream } from 'vue3-qr-reader'; // QrCapture, QrDropzone
    import { ref, onMounted, computed, watch } from 'vue';
    import { useStore } from 'vuex';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const patientService = usePatientService();
    const patientAccessService = usePatientAccessService();
    const caller = useExceptionwrappedCaller();

    const myPatients = computed(() => store.getters.getPatientAccess);
    const isLoadingCam = ref(true);
    const cameraAccess = ref(false);
    const hasPatient = ref(false);
    const newPatient = ref(false);
    const hasAccess = ref(false);
    const accessPaused = ref(false);
    const scan = ref('');
    const patientName = ref('');
    //const constraints = (window.constraints = {
    //    audio: false,
    //    video: true
    //});

    watch(() => [scan.value, myPatients.value], async () => {
        const qrScanResult = scan.value;

        // I have patient, so I may have access (could be paused)
        try {
            let myPatientByIdArray = myPatients.value.filter((p) => p.patientId == qrScanResult);
            let myPatientById = myPatientByIdArray[0]

            if (myPatientById != null) {
                patientName.value = myPatientById.patientGivenNames + " " + myPatientById.patientFamilyName;
                hasPatient.value = true;
                newPatient.value = false;
                hasAccess.value = myPatientById.hasAccess;
                accessPaused.value = myPatientById.paused;
                return;
            }
        }
        catch (e) {
            console.error(e.message || e);
        }

        // I see patient, but i am not connected
        try {
            let patients = await patientService.findPatients(qrScanResult);
            let patientSearchById = patients[0];
            patientName.value = patientSearchById.givenNames + " " + patientSearchById.familyName;
            newPatient.value = true;
            hasPatient.value = false;
            hasAccess.value = patientSearchById.hasAccess;
            accessPaused.value = patientSearchById.paused;
            return;
        }
        catch (e) {
            console.error(e.message || e);
        }

        // I know nothing :(
        scan.value = '';
        newPatient.value = false;
        hasPatient.value = false;
        hasAccess.value = false;
        accessPaused.value = false;
    });

    const onDecode = (qrScanResult) => {
        if (qrScanResult.length > 5) {
            scan.value = qrScanResult;
        }
    }

    const connectToPatient = async () => {
        await caller.call(async () => {
            await patientAccessService.requestPatientAccessTo(scan.value);
            router.push({ name: 'mypatients' });
        });
    };


    const navigateToPatient = async () => {
        await caller.call(async () => {
            await patientService.switchPatient(scan.value);
            router.push({ name: 'patient' });
        })
    }

    onMounted(() => {
        isLoadingCam.value = true;

        const constraints = (window.constraints = {
            audio: false,
            video: true
        });

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                if (stream) {
                    isLoadingCam.value = false;
                    cameraAccess.value = true;
                }
                else {
                    isLoadingCam.value = false;
                    cameraAccess.value = false;
                }
            })
            .catch(error => {
                console.log(error);
                isLoadingCam.value = false;
                cameraAccess.value = false;
            });
    });

    const closeDialog = () => router.push({ name: 'dashboard' });
</script>