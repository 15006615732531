import { watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { setupI18n, loadLocaleMessages, setI18nLanguage } from '@/I18n/Index.js';
import { useUserService } from '@/Components/Users/User/UserService.js';
import VueCookies from 'vue-cookies'
import { DEVICE_LANG_COOKIENAME } from '@/Shared/Strings.js';

const getLocalLang = () => {
    return VueCookies.get(DEVICE_LANG_COOKIENAME) || 'en';
}

export const setupLang = () => {
    const lang = getLocalLang();
    const i18n = setupI18n();

    loadLocaleMessages(i18n.global, lang).then(() => {
        setI18nLanguage(i18n.global, lang);
    });

    return i18n;
}

export const useLanguageController = () => {
    const store = useStore();
    const i18n = useI18n({ useScope: 'global' });
    const userService = useUserService();

    const updateLang = async (lang) => {
        //console.log('updating language in i18n: ', lang);

        await loadLocaleMessages(i18n, lang);
        setI18nLanguage(i18n, lang);
    }


    const changeLang = async (lang) => {
        if (store.getters.getUserId) {
            // update server
            try {
                await userService.changeUserLanguage(lang);
            }
            catch (e) {
                console.log('ERROR', e.message || e);
            }
        }

        if (!store.getters.getUserId) {
            //console.log('Changing language locally');
            // update cookie and change language for logged off user
            VueCookies.set(DEVICE_LANG_COOKIENAME, lang, '1y');
            await updateLang(lang);
        }
    }


    const getLang = () => {
        if (!store.getters.getUserId)
            return getLocalLang();
        return store.getters.getUserLanguage;
    }


    watchEffect(async () => {
        let lang = 'en';

        if (!store.getters.getUserId) {
            lang = getLocalLang();
        }
        else {
            lang = store.getters.getUserLanguage;
        }

        //console.log('auto updating language triggered by store change', );
        // update language when store language changes
        await updateLang(lang);
    });


    return {
        setupLang,
        changeLang,
        getLang,
    };
}
