import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';


const fnCreatePatient = async (givenNames, familyName, email, phone, username, password, language, createUser) => {
    givenNames = givenNames || '';
    familyName = familyName || '';
    email = email || '';
    phone = phone || '';
    username = username || '';
    password = password || '';
    language = language || 'en';
    createUser = createUser == null ? true : createUser;

    let patient = {
        givenNames: givenNames.split(' '),
        familyName,
        email,
        phone,
        username,
        password,
        language,
        createUser,
    };

    let result = await dispatcher.postToApiAnonymous(`patients`, patient);
    handleApiError(result, 'Error while creating patient.');

    return result.data;
}


// gets patient by id or otherwise the currently saved patient id from localstorage
const fnGetPatient = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}`);
    handleApiError(result, 'Error while reading patient.');

    return result.data;
}

const fnFindPatients = async (searchTerm) => {
    let result = await dispatcher.getFromApi(`patients/search?searchTerm=${searchTerm}`);
    handleApiError(result, 'Error while searching for patients.');

    return result.data.patients;
}

const fnChangePatientName = async (patientId, givenNames, familyName) => {
    let patientName = {
        givenNames: givenNames.split(' '),
        familyName,
    }

    let result = await dispatcher.putToApi(`patients/${patientId}/name`, patientName);
    handleApiError(result, 'Error while changing patient name');
}

const fnChangePatientPhone = async (patientId, phone) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/phone`, { phone });
    handleApiError(result, 'Error while changing patient phone');
}

const fnChangePatientEmail = async (patientId, email) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/email`, { email });
    handleApiError(result, 'Error while changing patient email');
}

const fnChangePatientBirthdate = async (patientId, birthdate) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/birthdate`, { birthdate });
    handleApiError(result, 'Error while changing patient birthdate');
}

const fnChangePatientGender = async (patientId, gender) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/gender`, { gender });
    handleApiError(result, 'Error while changing patient gender');
}

const fnChangePatientImage = async (patientId, image) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/image`, { image });
    handleApiError(result, 'Error while changing patient image');
}

const fnAssociatePatientWithOrganization = async (patientId, organizationId) => {
    let result = await dispatcher.postToApi(`patients/${patientId}/organizations`, { organizationId });
    handleApiError(result, 'Error while associating patient with organization');
}

const fnDisassociatePatientFromOrganization = async (patientId, organizationId) => {
    let result = await dispatcher.deleteFromApi(`patients/${patientId}/organizations/${organizationId}`);
    handleApiError(result, 'Error while disassociating patient from organization');
}

// gets all patientconnections for the currently logged on clinician
const fnGetClinicianPatients = async (clinicianId) => {
    let result = await dispatcher.getFromApi(`clinicians/${clinicianId}/patients`);
    handleApiError(result, 'Error while fetching your patients.');

    return result.data.patients;
}



import { useStore } from 'vuex';
import { serviceCall, offlineServiceCall } from '@/Shared/ServiceWrapper.js';
import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';

export const usePatientService = () => {
    const store = useStore();
    const patientAccessService = usePatientAccessService();

    const createPatient = async (givenNames, familyName, email, phone, username, password, language, createUser) => {
        return await serviceCall(
            async () => {
                const patientData = await fnCreatePatient(
                    givenNames,
                    familyName,
                    email,
                    phone,
                    username,
                    password,
                    language,
                    createUser);
                return patientData;
            },
            { checkLoggedIn: false }
        );
    }

    const loadPatient = async (patientId) => {
        const patientIdToGet = patientId || store.getters.getPatientId;
        if (!store.getters.getIsOnline) {
            let offlinePatient = store.getters.getOfflinePatient(patientIdToGet);
            //TODO: handle null (uncached) patient in some way... user must know it is not available
            store.commit('restorePatientInfoStoreAndCache', offlinePatient);
        }
        else {
            await serviceCall(
                async () => {
                    const patient = await fnGetPatient(patientIdToGet);
                    store.commit('setPatientInfo', patient);
                }
            );
        }
    }

    const getPatient = async (patientId) => {
        return await serviceCall(
            async () => {
                return await fnGetPatient(patientId);
            }
        );
    }

    const findPatients = async (searchTerm) => {
        return await serviceCall(
            async () => {
                const patients = await fnFindPatients(searchTerm);
                return patients;
            }
        );
    }

    const switchPatient = async (patientId) => {
        const patientIdToGet = patientId || store.getters.getPatientId;

        if (!store.getters.getIsOnline) {
            await offlineServiceCall(
                () => {
                    const offlinePatient = store.getters.getOfflinePatient(patientIdToGet);
                    if (!(offlinePatient && offlinePatient.id))
                        throw new Error('patientMissingOffline');

                    store.commit('restorePatientInfoStoreAndCache', offlinePatient);
                }
            );
        }
        else {
            await serviceCall(
                async () => {
                    const offlinePatient = store.getters.getOfflinePatient(patientIdToGet);
                    const patient = await fnGetPatient(patientIdToGet);
                    if (!patient)
                        throw new Error('patientMissing');

                    store.commit('resetPatientInfo');
                    if (offlinePatient && offlinePatient.id)
                        store.commit('restorePatientInfoStoreAndCache', offlinePatient);
                    store.commit('setPatientInfo', patient);
                }
            );
        }
    }

    const switchToRelatedPatient = async (relatedPatientId) => {
        if (!store.getters.getIsOnline) {
            await offlineServiceCall(
                () => {
                    const offlinePatient = store.getters.getOfflinePatient(relatedPatientId);
                    if (!offlinePatient || !offlinePatient.id)
                        throw new Error('relatedPatientMissingOffline');

                    store.commit('setRelatedPatientInfo');
                    store.commit('restorePatientInfoStoreAndCache', offlinePatient);
                }
            );
        }
        else {
            await serviceCall(
                async () => {
                    const offlinePatient = store.getters.getOfflinePatient(relatedPatientId);
                    const patient = await fnGetPatient(relatedPatientId);
                    if (!patient)
                        throw new Error('relatedPatientMissing');

                    store.commit('setRelatedPatientInfo');
                    store.commit('resetPatientInfo');                     // resets all patient props

                    // swap in all patient data from cache
                    if (offlinePatient && offlinePatient.id)
                        store.commit('restorePatientInfoStoreAndCache', offlinePatient);  // copies cached patient data
                    // update swapped in patient with data from server
                    store.commit('setPatientInfo', patient);              // only fetches some patient props

                    if (store.getters.isPatient)
                        patientAccessService.refreshPatientAccessForPatient();
                }
            );
        }
    }

    const switchToRelatingPatient = async () => {
        if (!store.getters.getIsOnline) {
            await offlineServiceCall(
                () => {
                    const offlinePatient = store.getters.getOfflinePatient(store.getters.getRelatingPatientId);
                    if (!(offlinePatient && offlinePatient.id))
                        throw new Error('patientMissingOffline');

                    store.commit('restorePatientInfoStoreAndCache', offlinePatient);
                    store.commit('resetRelatedPatientInfo');
                }
            );
        }
        else {
            await serviceCall(
                async () => {
                    const offlinePatient = store.getters.getOfflinePatient(store.getters.getRelatingPatientId);
                    const patient = await fnGetPatient(store.getters.getRelatingPatientId);
                    if (!patient)
                        throw new Error('patientMissing');

                    store.commit('resetPatientInfo');
                    if (offlinePatient && offlinePatient.id)
                        store.commit('restorePatientInfoStoreAndCache', offlinePatient);  // copies cached patient data
                    store.commit('setPatientInfo', patient);
                    store.commit('resetRelatedPatientInfo');
                    if (store.getters.isPatient)
                        patientAccessService.refreshPatientAccessForPatient();
                }
            );
        }
    }

    const changePatientName = async (givenNames, familyName) => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                await fnChangePatientName(patientId, givenNames, familyName);
                store.commit('setPatientName', { givenNames, familyName });
            }
        );
    }

    const changePatientBirthdate = async (birthdate) => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                await fnChangePatientBirthdate(patientId, birthdate);
                store.commit('setPatientBirthdate', { birthdate });
            }
        );
    }

    const changePatientGender = async (gender) => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                await fnChangePatientGender(patientId, gender);
                store.commit('setPatientGender', { gender });
            }
        );
    }

    const requestPatientPhoneChange = async (newPhone) => {
        let patientPhone = { newPhone };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`patients/requestphonechange`, patientPhone)
                handleApiError(result, 'Error while requesting change of patient phone');
                store.commit('setPatientPendingPhone', patientPhone);
            }
        );
    }

    const verifyPatientPhoneChange = async (token) => {
        let data = { token };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`patients/verifyphonechange`, data)
                handleApiError(result, 'Error while verifying change of patient phone');
                store.commit('setPendingPatientPhoneAsActive', {});
            }
        );
    }

    const requestPatientEmailChange = async (newEmail) => {
        let patientEmail = { newEmail };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`patients/requestemailchange`, patientEmail)
                handleApiError(result, 'Error while requesting change of patient email');
                store.commit('setPatientPendingEmail', patientEmail);
            }
        );
    }

    const verifyPatientEmailChange = async (token) => {
        let data = { token };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`patients/verifyemailchange`, data)
                handleApiError(result, 'Error while verifying change of patient email');
                store.commit('setPendingPatientEmailAsActive', {});
            }
        );
    }

    const changePatientImage = async (image) => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                await fnChangePatientImage(patientId, image);
                store.commit('setPatientImage', { image });
            }
        );
    }

    const addPatientToOrganization = async (organizationId) => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                await fnAssociatePatientWithOrganization(patientId, organizationId);
                store.commit('addPatientToOrganization', { organizationId });
            }
        );
    }

    const removePatientFromOrganization = async (organizationId) => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                await fnDisassociatePatientFromOrganization(patientId, organizationId);
                store.commit('removePatientFromOrganization', { organizationId });
            }
        );
    }

    const getClinicianPatients = async () => {
        const clinicianId = store.getters.getClinicianId;

        await serviceCall(
            async () => {
                const patients = await fnGetClinicianPatients(clinicianId);
                return patients;
            }
        );
    }

    return {
        createPatient,
        loadPatient,
        getPatient,
        findPatients,
        switchPatient,
        switchToRelatedPatient,
        switchToRelatingPatient,
        changePatientName,
        changePatientBirthdate,

        requestPatientPhoneChange,
        verifyPatientPhoneChange,

        requestPatientEmailChange,
        verifyPatientEmailChange,

        changePatientGender,
        changePatientImage,
        addPatientToOrganization,
        removePatientFromOrganization,
        getClinicianPatients,
    }
}