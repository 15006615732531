 <template>
    <header>
        <top-navigation />
        <user-navigation />
    </header>
    <cave />
    <patient-navigation />
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transitionName">
            <component :is="Component" />
        </transition>
    </router-view>
    <Footer />
</template>

<script setup>
    import TopNavigation from '@/Components/Shared/TopNavigation.vue';
    import UserNavigation from '@/Components/Shared/UserNavigation.vue';
    import PatientNavigation from '@/Components/Patients/Patient/PatientNavigation.vue';
    import Footer from '@/Components/Shared/Footer.vue';
    import Cave from '@/Components/Medications/Cave/GetCave.vue';

    import { watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useMeasurementDefinitionService } from '@/Components/HealthData/Measurements/MeasurementDefinitionService.js';
    import { usePatientConsentService } from '@/Components/Patients/PatientConsent/PatientConsentService.js';

    const store = useStore();
    const patientService = usePatientService();
    const patientConsentService = usePatientConsentService();
    const caller = useExceptionwrappedCaller();
    const measurementDefinitionService = useMeasurementDefinitionService();

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await patientService.loadPatient();
            await patientConsentService.loadPatientConsents();
        });
    });

    watchEffect(async () => {
        const defs = store.getters.getMeasurementDefinitions;
        if (defs && Array.isArray(defs) && defs.length > 0) return;
        await caller.call(async () => {
            await measurementDefinitionService.loadMeasurementDefinitions();
        });
    });
</script>
