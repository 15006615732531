export const serumUreaLevelsKey = '365755003';
export const serumCreatinineLevelsKey = '398000003';
export const eGFRKey = '80274001';
export const astLevelsKey = '373679006';
export const altLevelsKey = '56935002';
export const alpLevelsKey = '365771003';
export const ptKey = '7348004';
export const pttKey = '413432001';
export const inrKey = '165581004';
export const ggtKey = '113076002';
export const serumAlbuminLevelsKey = '365801005';
export const rbgKey = '271061004';
export const lymphocytesKey = '74765001';
export const plateletCountKey = '365632008';
export const cancerAntigenKey = '103092003';
export const chestXRayReportKey = '399208008';
export const ctScanChestAbdomenReportKey = '418891003';
export const abdominalUltrasoundReportKey = '45036003';
