<template>
    <div ref="root" class="overlay overlay--full-screen" :class="{active:props.open}" style="overflow:hidden;">
        <div class="zoom-wrapper">
            <div ref="zoomTarget" class="bounding-box" style="width:100%; overflow: hidden; z-index: 45">
                <img ref="image" :src="props.imageSource" :style="{ height: outOfBoundsHeight}" :class="{outofboundshorizontal: outOfBoundsHorizontal}" @load="imageLoad" />
            </div>
        </div>
        <div class="close-button">
            <button type="button" @click="closeDialog">
                <div class="svg-container">
                    <svg-icon icon-name="cross" style="width:50px;height:50px" />
                </div>
            </button>
        </div>

        <ImageViewerZoomControls @zoomIn="zoomIn" @zoomOut="zoomOut" @zoomReset="zoomReset"></ImageViewerZoomControls>
        <ImageViewerPanControls @panLeft="panLeft" @panRight="panRight" @panUp="panUp" @panDown="panDown"></ImageViewerPanControls>
    </div>
</template>

<script setup>
    import ImageViewerZoomControls from './ImageViewerZoomControls.vue';
    import ImageViewerPanControls from './ImageViewerPanControls.vue';

    import { defineEmits, defineProps, onBeforeUnmount, onMounted, reactive, ref, computed } from 'vue';

    import * as panzoom from 'panzoom';

    const emit = defineEmits(['close']);
    const props = defineProps({
        open: { type: Boolean, default: false },
        imageSource: String,
    });

    const outOfBoundsHeight = ref("auto");
    const outOfBoundsHorizontal = ref(false);
    let panzoomInstance = null;

    const root = ref();
    const zoomTarget = ref();
    const image = ref();

    onMounted(() => {
        let zoomElement =  zoomTarget.value;

        panzoomInstance = panzoom(zoomElement, {
            maxZoom: 3,
            minZoom: 0.5,
        });

    });

    const zoomReset = () => {
        panzoomInstance.zoomAbs(0, 0, 1);
        centerImage(image.value, root.value);
    }

    const zoomIn = () => zoom(1.3);

    const zoomOut = () => zoom(0.7);

    const panUp = () => pan(0, 50);

    const panDown = () => pan(0, -50);

    const panLeft = () => pan(50, 0);

    const panRight = () => pan(-50, 0);


    function centerImage(image, boundingBox)
    {
        let outsideBounds = isImageOutsideBounds(image);

        let widerAndLargerThanBounds = isImageWiderAndLargerBounds(image, boundingBox);

        if (outsideBounds)
        {          
            if (widerAndLargerThanBounds) {
                panzoomInstance.moveTo(0, 0);

                return;
            }

            if(!isImageWiderFormatThanBounds(image, boundingBox))
            {
                panzoomInstance.moveTo(0, 0);

                return;
            }
        }

        let rootVerticalSize = root.value.offsetHeight;
        let imageVerticalSize = image.naturalHeight;
        let centeredY = (rootVerticalSize - imageVerticalSize) / 2;

        panzoomInstance.moveTo(0, centeredY);
    }

    function pan(x, y) {
        let transform = panzoomInstance.getTransform();
        panzoomInstance.smoothMoveTo(transform.x + x, transform.y+y);
    }

    function zoom(zoomFactor) {
        var boundingBox = zoomTarget.value;
        
        let rootVerticalSize = root.value.offsetHeight;
        let imageVerticalSize = image.value.naturalHeight;
        let zoomOffsetY = (rootVerticalSize - imageVerticalSize) / 2; // To take into account the centered position of the image

        let centerY = (boundingBox.offsetHeight / 2) + zoomOffsetY;
        let centerX = boundingBox.offsetWidth / 2;

        panzoomInstance.smoothZoom(centerX, centerY, zoomFactor);
    }

    function resetBounds() {
        outOfBoundsHeight.value = "auto";
        outOfBoundsHorizontal.value = false;
    }

    function imageLoad(e) {
        resetBounds();

        let image = e.target;
        let boundingBox = root;

        let outsideBounds = isImageOutsideBounds(image);

        if(outsideBounds)
        {
            let imgWidth = image.naturalWidth;
            let imgHeight = image.naturalHeight;

            let boundingBoxWidth = boundingBox.value.offsetWidth;
            let boundingBoxHeight = boundingBox.value.offsetHeight;

            let boundingBoxElmAspectRatio = boundingBoxWidth / boundingBoxHeight;
            let imgAspectRatio = imgWidth / imgHeight;

            if (imgAspectRatio > boundingBoxElmAspectRatio) {
                outOfBoundsHeight.value = "auto";
                outOfBoundsHorizontal.value = true;
            }
            else {
                outOfBoundsHeight.value = boundingBoxHeight + "px";
                outOfBoundsHorizontal.value = false;
            }
        }

        panzoomInstance.zoomAbs(0, 0, 1);
        centerImage(image, boundingBox.value);
    }

    
    
    function isImageWiderFormatThanBounds(image, boundingBox)
    {
        let imgWidth = image.naturalWidth;
        let imgHeight = image.naturalHeight;
        
        let boundingBoxWidth = boundingBox.offsetWidth;
        let boundingBoxHeight = boundingBox.offsetHeight;
        
        let boundingBoxElmAspectRatio = boundingBoxWidth / boundingBoxHeight;
        let imgAspectRatio = imgWidth / imgHeight;

        return imgAspectRatio > boundingBoxElmAspectRatio;
    }

    function isImageWiderAndLargerBounds(image, boundingBox) {
        let imgWidth = image.naturalWidth;
        let imgHeight = image.naturalHeight;

        let boundingBoxWidth = boundingBox.offsetWidth;
        let boundingBoxHeight = boundingBox.offsetHeight;

        return imgWidth > boundingBoxWidth && imgHeight > boundingBoxHeight;
    }

    function isImageOutsideBounds(image)
    {
        let boundingBox = root;
        let imgWidth = image.naturalWidth;
        let imgHeight = image.naturalHeight;

        let boundingBoxWidth = boundingBox.value.offsetWidth;
        let boundingBoxHeight = boundingBox.value.offsetHeight;

        return imgWidth > boundingBoxWidth || imgHeight > boundingBoxHeight;
    }

    const closeDialog = () => emit('close');
</script>

<style scoped>
    .overlay {
        text-align: center;
        padding-bottom: 0;
    }

    .outofboundshorizontal {
        width: 100%;
    }
    .close-button {
        position: fixed;
        top: 5px;
        right: 10px;
        width: 50px;
        height: 50px;
        z-index: 50;
    }
</style>