<template>
    <div v-if="isClinician" id="createConsultationNote">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content online-only">
                            <text-area v-model="consultationNote" :key="noteKey"
                                        name="consultationNote"
                                        :required="true"
                                        :label="$t('createConsultationNote.consultationNoteLabel')"
                                        :errorText="$t('createConsultationNote.consultationNoteErrorText')"
                                        :helper="$t('createConsultationNote.consultationNoteHelperText')"
                                        :placeholder="$t('createConsultationNote.consultationNotePlaceholder')"
                                        @updated="stateChanged" />
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <image-list-picker v-model="images" :editable="true" :key="noteKey" :maxImages="10"></image-list-picker>
                </div>
                <media-player :audio="audio" @toggle="toggleRecording" @remove="removeAudio" :key="noteKey"></media-player>
                <div class="col-xs-12">
                    <button type="button"
                            class="btn btn__text btn__text--green right online-only"
                            :class="{ disabled : !isValid }"
                            :disabled="!isValid"
                            @click="createConsultationNote">
                        <h4>{{$t('general.create')}}</h4>
                    </button>
                </div>
            </div>
        </div>
        <media-recorder :class="{active : audioOverlayActive}" userType="patient" @uploadGuid="newAudio" @close="toggleRecording"></media-recorder>
    </div>
</template>

<script setup>
    import TextArea from '@/Components/Shared/Form/TextArea.vue';
    import MediaRecorder from '@/Components/Shared/Form/MediaRecorder.vue';
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';
    import ImageListPicker from '@/Components/Shared/Form/ImageListPicker.vue'

    import { computed, defineProps, ref, } from 'vue';
    import { useStore } from 'vuex';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useConsultationNoteService } from '@/Components/HealthData/ConsultationNote/ConsultationNoteService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import uploadService from '@/Components/Shared/Form/Upload.js';

    const props = defineProps({
        useImages: { type: String, default: '' }
    });

    const store = useStore();
    const { stateChanged, isValid } = useFormValidator();
    const consultationNoteService = useConsultationNoteService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);

    let noteKey = ref(0);
    let consultationNote = ref('');
    let audio = ref('');
    let images = ref(props.useImages);

    const createConsultationNote = async () => {
        await caller.call(async () => {


            // upload data to blob and replace data with reference
            let fileRefs = [];
            let data = images.value;
            let dataChunks = data.split('|').filter((d) => d != '');
            for (var i = 0; i < dataChunks.length; i++) {
                let fileRef = dataChunks[i];
                if (fileRef.startsWith('data:'))
                    fileRef = await uploadService.uploadBase64DataUrl(fileRef, 'patient');
                else if (fileRef.indexOf('?') > -1)
                    fileRef = fileRef.substr(0, fileRef.indexOf('?'));
                fileRefs = [...fileRefs, fileRef];
            }
            images.value = fileRefs.join('|');
            console.log(`fileRefs ${images.value}`);


            await consultationNoteService.createConsultationNote(consultationNote.value, audio.value, images.value);

            consultationNote.value = '';
            audio.value = '';
            images.value = '';
            noteKey.value += 1; // causes the controls to reload without the page unmounting
        })
    };

    // Audio 
    let audioOverlayActive = ref(false);

    const newAudio = (uploadGuid) => audio.value = uploadGuid;

    const removeAudio = () => audio.value = '';

    const toggleRecording = () => audioOverlayActive.value = !audioOverlayActive.value;

    // Audio End 
</script>