import dispatcher from '@/Connections/Dispatcher.js';
import * as strings from '@/Shared/Strings.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

//const getUserNotifications = async () => {
//    let userId = dispatcher.getFromSession(strings.USERID);
//    let notificationTimestamp = dispatcher.getFromLocal(strings.USERNOTIFICATIONSTIMESTAMP);

//    // create possibly next timestamp here to make sure the next update will catch changes made during this call
//    let newTimestamp = new Date().toISOString();    
//    let timestampParameter = '';
//    if (notificationTimestamp) {
//        notificationTimestamp = encodeURIComponent(notificationTimestamp);
//        timestampParameter += `?timestamp=${notificationTimestamp}`;
//    }

//    let result = await dispatcher.getFromApi(`users/${userId}/notifications${timestampParameter}`);
//    handleApiError(result, 'Error while fetching notifications');

//    if (result.data.notifications.length) {
//        dispatcher.postToLocal(strings.USERNOTIFICATIONSTIMESTAMP, newTimestamp);
//    }
//    return result.data.notifications;
//}

//const removeUserNotification = async (notificationId) => {
//    let userId = dispatcher.getFromSession(strings.USERID);
//    let result = await dispatcher.deleteFromApi(`users/${userId}/notifications/${notificationId}`);
//    handleApiError(result, 'Error while fetching notifications');
//}

//const clearUserNotifications = async (lastNotificationDate) => {
//    let userId = dispatcher.getFromSession(strings.USERID);
//    let request = {
//        date: lastNotificationDate,
//    }
//    let result = await dispatcher.deleteFromApi(`users/${userId}/notifications`, request);
//    handleApiError(result, 'Error while fetching notifications');
//}

//export default {
//    name: 'NotificationsService',
//    getUserNotifications,
//    removeUserNotification,
//    clearUserNotifications,
//}


import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useUserNotificationsService = () => {
    const store = useStore();

    const updateUserNotifications = async () => {
        const userId = store.getters.getUserId; 
        return await serviceCall(
            async () => {
                let notificationTimestamp = dispatcher.getFromLocalClear(strings.USERNOTIFICATIONSTIMESTAMP);


                // create possibly next timestamp here to make sure the next update will catch changes made during this call
                const newTimestamp = new Date().toISOString();
                let timestampParameter = '';
                if (notificationTimestamp) {
                    notificationTimestamp = encodeURIComponent(notificationTimestamp);
                    timestampParameter += `?timestamp=${notificationTimestamp}`;
                }

                const result = await dispatcher.getFromApi(`users/${userId}/notifications${timestampParameter}`);
                handleApiError(result, 'Error while fetching notifications');

                const notifications = result.data.notifications;
                if (notifications.length) {
                    //console.log('----------->-----------> updatet notification timestamp', notificationTimestamp, newTimestamp, notifications);
                    dispatcher.postToLocalClear(strings.USERNOTIFICATIONSTIMESTAMP, newTimestamp);
                }
                //store.commit('setUserNotifications', notifications);
                return notifications;
            }
        );
    }

    const removeUserNotification = async (notificationId) => {
        const userId = store.getters.getUserId;
        await serviceCall(
            async () => {
                const result = await dispatcher.deleteFromApi(`users/${userId}/notifications/${notificationId}`);
                handleApiError(result, 'Error while removing notifications');
                store.commit('removeUserNotification', { notificationId });
            }
        );
    }

    const clearUserNotifications = async () => {
        if (store.getters.getUserNotifications.length == 0)
            return;

        const userId = store.getters.getUserId;
        const date = store.getters.getUserNotifications.reduce((p, c) => p.date > c.date ? p : c, store.getters.getUserNotifications[0]);

        await serviceCall(
            async () => {
                const result = await dispatcher.deleteFromApi(`users/${userId}/notifications`, date);
                handleApiError(result, 'Error while deleting notifications');
                store.commit('clearUserNotifications');
            }
        );
    }

    return {
        updateUserNotifications,
        removeUserNotification,
        clearUserNotifications,
    }
}