<template>
    <div class="col-xs-12" v-for="relation in normalizedRelations" :key="relation.relatedPersonId">
        <div class="user">
            <div class="data">
                <div class="info" @click="view(relation)" :class="{approve: relation.hasAccess}">
                    <related-person-info v-bind="getRelationData(relation)" />
                </div>
                <div class="more">
                    <button class="btn btn__round btn__round--medium btn__round--green-outline"
                            @click="toggleOptions(relation)">
                        <div class="svg-container">
                            <svg-icon icon-name="chevron-down" />
                        </div>
                    </button>
                </div>
            </div>
            <div class="actions" :class="{active: relation.optionsActive}">
                <div class="col-xs-12">
                    <button v-if="!relation.hasAccess || true == false"
                            class="btn btn__text btn__text--red-border btn__text--short left online-only"
                            @click="removeRelatedPerson(relation)" data-test="actions-remove">
                        <h4>{{$t('general.remove')}}</h4>
                    </button>

                    <button class="btn btn__text btn__text--green-border btn__text--short left online-only"
                            @click="updateRelatedPerson(relation)" data-test="actions-update">
                        <h4>{{$t('general.update')}}</h4>
                    </button>

                    <button v-if="!relation.hasAccess"
                            class="btn btn__text btn__text--green btn__text--short right online-only"
                            @click="addHealthInformation(relation)" data-test="actions-add-data">
                        <h4>{{$t('listRelations.addData')}}</h4>
                    </button>

                    <button v-if="relation.hasAccess && true == false"
                            class="btn btn__text btn__text--red-border btn__text--short left online-only"
                            @click="removeHealthInformation(relation)" data-test="actions-remove-data">
                        <h4>{{$t('listRelations.removeData')}}</h4>
                    </button>

                    <button v-if="relation.hasAccess"
                            class="btn btn__text btn__text--green btn__text--short right"
                            @click="view(relation)" data-test="actions-view-data">
                        <h4>{{$t('listRelations.viewData')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import RelatedPersonInfo from './RelatedPersonInfo.vue';

    import { computed, defineProps, inject, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { makeRegexSafe } from '@/Components/Shared/RegexUtils.js';
    import { usePatientRelatedPersonService } from '@/Components/Patients/Patient/PatientRelatedPersonService.js';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        filter: String
    });

    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const patientRelSvc = usePatientRelatedPersonService();
    const patientService = usePatientService();
    const caller = useExceptionwrappedCaller();

    const confirmDialog = inject('confirmDialog');
    const infoDialog = inject('infoDialog');

    let normalizedRelations = ref([]);
    const relations = computed(() => store.getters.getPatientRelatedPersons);

    const refreshNormalized = () => {
        let termslist = (props.filter || '').replace(/\s+/gi, ' ');
        termslist = makeRegexSafe(termslist);
        let terms = termslist.replace(' ', '|');
        let rx = new RegExp('(' + terms + ')', 'gi');

        normalizedRelations.value = relations.value
            .filter((v) => {
                if (!props.filter) return true;

                rx.lastIndex = 0;
                let givenTest = rx.test(v.givenNames);
                rx.lastIndex = 0;
                let familyTest = rx.test(v.familyName);

                return givenTest || familyTest;
            })
            .map((relation) => {
                return {
                    ...relation,	// use spread operator to merge relation data into this one
                    hasAccess: relation.relatedPersonPatientId != null,
                    optionsActive: false,
                };
            });
    }

    refreshNormalized();

    watch(
        () => [props.filter, relations.value],
        () => refreshNormalized(),
        { immediate: true, deep: true });



    const getRelationData = (relation) => {
        console.log('relation', relation)
        return {
            entityId: relation.relatedPersonPatientId || relation.relatedPersonId,
            givenNames: relation.givenNames,
            familyName: relation.familyName,
            phone: relation.phone,
            email: relation.email,
            relationType: relation.relationType,
            hasAccess: relation.hasAccess || false,
        };
    }

    const toggleOptions = (relation) => {
        relation.optionsActive = !relation.optionsActive;
    }

    const view = async (relation) => {
        if (relation.hasAccess) {
            try {
                await caller.call(async () => {
                    await patientService.switchToRelatedPatient(relation.relatedPersonPatientId);
                    router.push({ name: 'patient' });
                });
            }
            catch (e) {
                const msg = e.message || e || '';
                if (msg.toLowerCase().includes('patientmissing')) {
                    await infoDialog({
                        header: t('listRelations.patientNotAvailableAlertHeader'),
                        body: t('listRelations.patientNotAvailableAlertBody'),
                    });
                }
                else
                    throw e;
            }
        }
    };

    const updateRelatedPerson = (relation) => {
        router.push({
            name: 'editrelatedperson',
            params: { id: relation.relatedPersonId }
        });
    };

    const removeRelatedPerson = async (relation) => {
        let selection = await confirmDialog({
            header: t('listRelations.removeRelatedPersonConfirmTitle'),
            body: t('listRelations.removeRelatedPersonConfirmBody'),
        });

        if (selection)
            await caller.call(async () => {
                await patientRelSvc.removePatientRelatedPerson(relation.relatedPersonId);
            });
    };

    const addHealthInformation = (relation) => {
        router.push({
            name: 'addrelatedpersonpatientdata',
            query: {
                relatedPersonId: relation.relatedPersonId
            }
        });
    };

    const removeHealthInformation = async (relation) => {
        // TODO: Handle when current patient is last relating person - then removal is not allowed, as it leads to orphans!
        await caller.call(async () => {
            await patientRelSvc.removeHealthInformationFromRelatedPerson(relation.relatedPersonId);
        });
    };
</script>
