import { createApp } from 'vue';
import App from '@/App';
import router from '@/Router/Index.js';
import store from '@/Store/Index.js';
import SvgIcon from '@/Components/Shared/SvgIcon.vue';
import DateDisplay from '@/Components/Shared/DateDisplay.vue';
import BoolDisplay from '@/Components/Shared/BoolDisplay.vue';
import Bar from '@/Components/Shared/Bar.vue';
import Popup from '@/Components/Shared/Popup.vue';
import ProfileImage from '@/Components/Shared/ProfileImage.vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { setupLang } from '@/Components/Shared/LanguageController.js';
import './registerServiceWorker'

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
    console.log('ERROR HANDLER: ', JSON.stringify(err), instance, info, err.stack);
    if (err.code == "MODULE_NOT_FOUND") return false;
    if (err.message && err.message == 'pinCanceled') return false;
    if (err.message && err.message == 'encryptedPinMissing') return false;
    //TODO: Do some logging of error details for later error reports
    //window.dispatchEvent(new CustomEvent('showErrorDialog', { detail: { err, instance, info } }));
}

const i18n = setupLang();

app.component('SvgIcon', SvgIcon);
app.component('Bar', Bar);
app.component('DateDisplay', DateDisplay);
app.component('BoolDisplay', BoolDisplay);
app.component('Popup', Popup);
app.component('ProfileImage', ProfileImage);

app.use(i18n)
    .use(store)
    .use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        },
    });

// need to wait for the router to finish doing its stuff...
new Promise((resolve) => {
    app.use(router);
    router.isReady().then(() => {
        resolve();
    });
}).then(() => {
    app.mount('#app');
});
