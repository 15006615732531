<template>
    <div id="pin_enter" class="pin-enter">
        <div class="pin container">
            <div class="row">
                <div class="col-xs-12 pin pin__title">
                    <h2><b>{{$t('loginEnterPin.title')}}</b></h2>
                    <h4>{{$t('loginEnterPin.subtitle')}}</h4>
                </div>

                <logon-pin-keyboard v-model="pin.code"></logon-pin-keyboard>

                <div class="col-xs-12 pin pin__error" v-if="pin.error">
                    <span>{{pin.error}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import LogonPinKeyboard from '@/Components/Users/User/LogonPinKeyboard';

    import { computed, defineEmits, defineProps, reactive, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { useUserService } from '@/Components/Users/User/UserService.js';

    const emit = defineEmits(['pinentered']);

    const props = defineProps({
        action: {
            type: String,
            default: 'refresh',
            validations: (v) => ['refresh', 'auth'].includes(v.toLowerCase())
        },
        userId: {
            type: String,
        }
    });

    const store = useStore();
    const { t } = useI18n();
    const userService = useUserService();
    
    const userId = computed(() => props.userId || store.getters.getUserId);

    console.log('login enter pin', userId.value, props);

    let pin = reactive({
        code: '',
        error: '',
    });

    const reset = () => {
        pin.code = '';
        pin.error = '';
    }

    watch(
        () => pin.code,
        async () => {
            if (pin.code.length > 3)
                await loginWithPin(pin.code);
        });

    // Login
    const loginWithPin = async (pinCode) => {
        try {
            if (props.action == 'refresh') {
                await userService.refreshPin(userId.value, pinCode);
                emit('pinentered');
            }
            else if (props.action == 'auth') {
                await userService.logonWithPin(userId.value, pinCode);
                emit('pinentered');
            }
        }
        catch (e) {
            const msg = e.message || e || '';
            console.log("PIN-ERROR: logonUserWithPin error : ", msg)
            if (msg.includes('invalidPin')) {
                reset();
                pin.error = t('loginEnterPin.wrongPinErrorText');
            }
            else
                throw e;
        }
    };
</script>

