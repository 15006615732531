<template>
    <div v-if="isClinician" id="edit-cave" class="overlay overlay--patient">
        <bar :title="displayTitle"
             :by="clinicianName" :by-label="$t('changeCave.changedByLabel')"
             :date="date" :date-label="$t('changeCave.changedDateLabel')"
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <textarea v-model="caveText" :placeholder="$t('changeCave.textPlaceholder')" class="online-only" data-test="cave-cavetext"></textarea>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--red-border btn__text--short left" @click="closeDialog" data-test="cave-cancelcave">
                        <h4>{{$t('general.cancel')}}</h4>
                    </button>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--green btn__text--short right online-only" @click="saveCave" data-test="cave-savecave">
                        <h4>{{$t('general.save')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useCaveService } from '@/Components/Medications/Cave/CaveService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const caveService = useCaveService();
    const caller = useExceptionwrappedCaller();

    let caveId = ref('');
    let caveText = ref('');
    let date = ref(null);
    let clinicianName = ref('');

    const isClinician = computed(() => store.getters.isClinician);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await caveService.loadCave();

            let cave = store.getters.getCave();
            if (cave == null)
                cave = { caveId: '', text: '' };

            caveId.value = cave.caveId;
            caveText.value = cave.text;
            date.value = cave.date;
            clinicianName.value = [cave.clinicianGivenNames, cave.clinicianFamilyName].join(' ');
        });
    });

    const displayTitle = computed(() => {
        return caveId.value != ''
            ? t('changeCave.title')
            : t('createCave.title')
    });

    const closeDialog = () => router.push({ name: 'patient' });

    const saveCave = async () => {
        await caller.call(async () => {
            await caveService.saveCave(caveId.value, caveText.value);
            closeDialog();
        });
    }
</script>