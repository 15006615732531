import dispatcher from '@/Connections/Dispatcher.js';
import { ONLINESTATUS, USERID, USERPIN } from '@/Shared/Strings.js';
import { getPin } from '@/Shared/PinCache.js';

const loggedOnCheck = (failOnNotLoggedIn = true) => {
    const userId = dispatcher.getFromSession(USERID);
    if (!userId && failOnNotLoggedIn)
        throw new Error('notLoggedIn');
    return userId;
}

const pinCheck = (userId) => {
    const hasEncryptedPin = dispatcher.hasLocal(userId + '_' + USERPIN);
    if (!hasEncryptedPin)
        throw new Error('encryptedPinMissing');

    const localHashedPin = getPin();
    if (!localHashedPin)
        throw new Error('pinMissing');
}

const isOnline = () => dispatcher.getFromLocalClear(ONLINESTATUS) == true;

export const serviceCall = async (mainAction, options = {
        checkPin: true,
        ignoreWhenOffline: true,
        executeWhenOffline: false,
        checkLoggedIn: true,
    }) =>
{
    const userId = loggedOnCheck(options.checkLoggedIn);

    if (options.checkPin)
        pinCheck(userId);

    if (!isOnline() && !options.executeWhenOffline) {
        if (options.ignoreWhenOffline) {
            console.log('Ignoring action while offline');
            return Promise.resolve(null);
        }
        else
            throw new Error('appIsOffline');
    }

    return await mainAction();
}

export const offlineServiceCall = async (mainAction) => {
    return await serviceCall(mainAction, {
        checkPin: true,
        executeWhenOffline: true,
    });
}
