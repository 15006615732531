<template>
    <div class="overlay overlay--patient" id="patientBilling">
        <bar :title="$t(`patientBilling.title${userType}`)" @close="closeDialog" v-if="isClinician" />
        <div class="container" v-if="isClinician" data-test-create-invoice-container>
            <div class="row">
                <div class="col-xs-12 center-text">
                    <button type="button"
                            class="btn btn__text btn__text btn__text--green-border online-only"
                            @click="createInvoice"
                            data-test-create-invoice>
                        <h3>{{$t('patientBilling.addNewInvoiceButton')}}</h3>
                    </button>
                </div>
            </div>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-invoices :filter="filter" />
    </div>
</template>

<script setup>
    import ListInvoices from '@/Components/Consultations/Invoice/ListInvoices';

    import { computed, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useInvoiceService } from '@/Components/Consultations/Invoice/InvoiceService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const invoiceService = useInvoiceService();
    const caller = useExceptionwrappedCaller();

    const userType = computed(() => store.getters.getUserType);
    const isClinician = computed(() => store.getters.isClinician);

    let filter = ref('');

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await invoiceService.refreshPatientInvoices();
        })
    });

    const closeDialog = () => router.push({ name: 'patient' });

    const createInvoice = () => router.push({ name: 'createinvoice' });
</script>
