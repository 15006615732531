<template>
    <div class="overlay overlay--patient" id="investigations">
        <bar v-if="isClinician" :title="$t(`investigationsView.title${userType}`)" @close="closeDialog"></bar>
        <investigations-summary v-if="isClinician"></investigations-summary>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-investigations></list-investigations>
    </div>
</template>

<script setup>
    import InvestigationsSummary from '@/Components/HealthData/Measurements/InvestigationsSummary.vue';
    import ListInvestigations from '@/Components/HealthData/Measurements/ListInvestigations.vue';

    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useMeasurementService } from '@/Components/HealthData/Measurements/MeasurementService.js';
    import { useMeasurementDefinitionService } from '@/Components/HealthData/Measurements/MeasurementDefinitionService.js';
    import { useMeasurementCategoryService } from '@/Components/HealthData/Measurements/MeasurementCategoriesService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const measurementService = useMeasurementService();
    const measurementDefinitionService = useMeasurementDefinitionService();
    const measurementCategoryService = useMeasurementCategoryService();
    const caller = useExceptionwrappedCaller();

    const userType = computed(() => store.getters.getUserType);
    const isClinician = computed(() => store.getters.isClinician);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await measurementService.loadMeasurements();
        });
    });

    watchEffect(async () => {
        await caller.call(async () => {
            const defTask = measurementDefinitionService.loadMeasurementDefinitions();
            const catTask = measurementCategoryService.loadMeasurementCategories();
            await Promise.all([defTask, catTask]);
        })
    });

    const closeDialog = async () => await router.push({ name: 'patient' });
</script>
