import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';


//const createMeasurementDefinition = async (definition) => {
//    let result = await dispatcher.postToApi(`measurementdefinitions`, definition);
//    handleApiError(result, 'Error while creating measurement definition');
//    return result.data;
//}

//const getMeasurementDefinitions = async () => {
//    let result = await dispatcher.getFromApi(`measurementdefinitions`);
//    handleApiError(result, 'Error while getting measurement definitions');
//    return result.data.measurementDefinitions;
//}

//const importMeasurementDefinitions = async (definitions) => {
//    let data = {
//        measurementDefinitions: definitions
//    };
//    let result = await dispatcher.postToApi(`measurementdefinitions/import`, data);
//    handleApiError(result, 'Error while importing measurement definitions');
//    return true;
//}

//export default {
//    name: 'MeasurementDefinitionService',

////    createMeasurementDefinition,
////    getMeasurementDefinitions,
////    importMeasurementDefinitions,
//}

import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useMeasurementDefinitionService = () => {
    const store = useStore();

    const createMeasurementDefinition = async(definition) => {
        await serviceCall(
            async () => {
                const result = await dispatcher.postToApi(`measurementdefinitions`, definition);
                handleApiError(result, 'Error while creating measurement definition');
            }
        );
    }

    const loadMeasurementDefinitions = async () => {
        await serviceCall(
            async () => {
                const result = await dispatcher.getFromApi(`measurementdefinitions`);
                handleApiError(result, 'Error while getting measurement definitions');
                const definitions = result.data.measurementDefinitions;
                store.commit('setMeasurementDefinitions', definitions);
            }
        );
    }

    const importMeasurementDefinitions = async (measurementDefinitions) => {
        const data = {
            measurementDefinitions
        };
        await serviceCall(
            async () => {
                const result = await dispatcher.postToApi(`measurementdefinitions/import`, data);
                handleApiError(result, 'Error while importing measurement definitions');
            }
        );
    }

    return {
        createMeasurementDefinition,
        loadMeasurementDefinitions,
        importMeasurementDefinitions,
    }
}