<template>
    <div class="image-list-picker-container">
        <image-list :editable="props.editable" class="image-list-picker-container-image-list"></image-list>
        <image-picker v-if="service.canAddImage" class="image-list-picker-container-image-picker"></image-picker>
        <slot></slot>
    </div>
</template>

<script setup>
    import ImageList from './ImageList.vue'
    import ImagePicker from './ImagePicker.vue'

    import { computed, defineEmits, defineProps, provide, reactive, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import uploadService from '@/Components/Shared/Form/Upload.js';

    const emit = defineEmits(['update:modelValue']);
    const props = defineProps({
        modelValue: { type: String, default: '' },
        maxImages: { type: Number, default: 1 },
        editable: { type: Boolean, default: false }
    });

    const store = useStore();

    const wrapImages = () => {
        const urlList = props.modelValue?.split('|');
        if (!urlList) return [];
        return urlList
            .filter((v) => v != '')
            .map((v, i) => ({ id: i, data: v }));
    }

    const unwrapImages = () => images.value.map((v) => v.data).join('|');

    let token = ref('');

    let images = ref([...wrapImages()]);

    const canAddImage = computed(() => props.maxImages > images.value.length && props.editable);

    watchEffect(async () => {
        if (store.getters.getPatientId && images.value && !token.value) {
            try {
                token.value = await uploadService.getAzureToken(store.getters.getPatientId);
            }
            catch (e) {
                console.log('token can not be acquired');
            }
        }
    });

    const service = reactive({
        images: computed(() => images.value.map((v) => {
            return {
                id: v.id,
                data: v.data.startsWith('https://') ? v.data + '?' + token.value : v.data
            }
        })),
        canAddImage,
        addImage: (id, data) => {
            images.value = [...images.value, { id, data }];
            emit('update:modelValue', unwrapImages());
        },
        deleteImage: (id) => {
            images.value = images.value.filter(i => i.id != id);
            emit('update:modelValue', unwrapImages());
        }
    });

    provide('imageListPickerService', service);
</script>