<template>
    <div class="overlay overlay--patient hidden" :class="{active: props.addNewItem}" id="createInvoice">
        <bar :title="$t('createPatientInvoice.titleItem')" @close="cancel" />
        <div class="container" id="CreateInvoiceItem">
            <div class="row">
                <div class="col-xs-12">
                    <div class="row">
                        <div class="col-xs-9" :key="compKey">
                            <text-box v-model="model.text"
                                      :label="$t('createPatientInvoice.incoiceItemTextLabel')"
                                      :helper="$t('createPatientInvoice.incoiceItemTextHelper')"
                                      :placeholder="$t('createPatientInvoice.incoiceItemTextPlaceholder')"
                                      :required="true"
                                      @updated="stateChanged" />

                        </div>
                        <div class="col-xs-3" :key="compKey">
                            <number v-model="model.price"
                                    :label="$t('createPatientInvoice.invoiceItemPrice')"
                                    :helper="$t('createPatientInvoice.invoiceItemPriceHelper')"
                                    :required="true"
                                    @updated="stateChanged"
                                    type="float" />
                        </div>
                        <div class="col-xs-6">
                            <button type="button" @click="cancel"
                                    class="btn btn__text btn__text--red-border left"
                                    data-test="cancel-button">
                                <h5>{{$t('general.cancel')}}</h5>
                            </button>
                        </div>
                        <div class="col-xs-6">
                            <button type="button" @click="save"
                                    class="btn btn__text btn__text--green right"
                                    :class="{disabled:!isValid}" :disabled="!isValid"
                                    data-test="save-button">
                                <h5>{{$t('general.add')}}</h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox';
    import Number from '@/Components/Shared/Form/Number';

    import { defineEmits, defineProps, reactive, ref } from 'vue';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';

    const props = defineProps({
        addNewItem: {
            default: false,
            type: Boolean
        }
    });
    const emit = defineEmits(['save', 'cancel']);

    const { stateChanged, isValid } = useFormValidator();

    let compKey = ref(0);

    let model = reactive({
        text: '',
        price: 0.0
    });

    const resetData = () => {
        model.text = '';
        model.price = 0.0;
        compKey.value += 1; // Renew the component key to reload it...
    }

    const save = () => {
        let data = {
            text: model.text,
            price: model.price,
        };
        emit('save', data);
        resetData();
    }

    const cancel = () => {
        emit('cancel');
        resetData();
    }
</script>