<template>
    <header class="green">
        <div id="logoBanner" class="container">
            <!-- Logo Banner-->
            <div class="row full-height">
                <div class="col-xs-push-2 col-xs-8">
                    <div class="logo-container">
                        <img :src="require('@/Assets/Images/logo-white.png')" />
                    </div>
                </div>
                <div class="col-xs-push-2 col-xs-2">
                    <button class="btn btn__text-circle" @click="navigateTo('helplinks')">
                        <span>?</span>
                    </button>
                </div>
            </div>
        </div>
    </header>
    <NoUser></NoUser>
    <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transitionName">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<script setup>
    import NoUser from '@/Components/Welcome/NoUser.vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const navigateTo = (name) => router.push({ name });
</script>

