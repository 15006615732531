<template>
    <svg xmlns="http://www.w3.org/2000/svg">
        <use :xlink:href="'#' + iconName" />
    </svg>
</template>

<script>
    export default {
        name: 'SvgIcon',
        props: {
            iconName: String
        }
    }
</script>