<template>
    <div class="overlay overlay--dashboard" id="editRelatedPerson">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('editRelatedPerson.title')}}</b></h2>
                    <h5 class="full-width center-text pb-small">{{$t('editRelatedPerson.subTitle')}}</h5>
                </div>
                <div class=" col-xs-12">
                    <div class="table-info">
                        <editor-content :valid="checkValid(['givenNames','familyName'])"
                                        @save="saveValues(['givenNames','familyName'], 'changeName')"
                                        @cancel="cancelValues(['givenNames','familyName'])"
                                        data-test="name-content">
                            <template v-slot:title>
                                {{$t('editRelatedPerson.nameLabel')}}
                            </template>
                            <template v-slot:value>
                                {{relatedPerson.givenNames}} {{relatedPerson.familyName}}
                            </template>
                            <template v-slot:default>
                                <text-box v-model="editing.givenNames"
                                          name="givenNames"
                                          type="text"
                                          :required="true"
                                          :label="$t('editRelatedPerson.givenNamesLabel')"
                                          :errorText="$t('editRelatedPerson.givenNamesErrorText')"
                                          :helper="$t('editRelatedPerson.givenNamesHelperText')"
                                          :placeholder="$t('editRelatedPerson.givenNamesPlaceholder')"
                                          @updated="stateChanged"
                                          data-test="given-names" />
                                <text-box v-model="editing.familyName"
                                          name="familyName"
                                          type="text"
                                          :required="true"
                                          :label="$t('editRelatedPerson.familyNameLabel')"
                                          :errorText="$t('editRelatedPerson.familyNameErrorText')"
                                          :helper="$t('editRelatedPerson.familyNameHelperText')"
                                          :placeholder="$t('editRelatedPerson.familyNamePlaceholder')"
                                          @updated="stateChanged"
                                          data-test="family-name" />
                            </template>
                        </editor-content>


                        <editor-content :valid="checkValid(['email'])"
                                        @save="saveValues(['email'], 'changeEmail')"
                                        @cancel="cancelValues(['email'])"
                                        data-test="email-content">
                            <template v-slot:title>
                                {{$t('editRelatedPerson.emailLabel')}}
                            </template>
                            <template v-slot:value>
                                {{relatedPerson.email}}
                            </template>
                            <template v-slot:default>
                                <text-box v-model="editing.email"
                                          class="label-up"
                                          name="email"
                                          type="email"
                                          :helper="$t('editRelatedPerson.emailHelper')"
                                          :errorText="$t('editRelatedPerson.emailError')"
                                          :placeholder="$t('editRelatedPerson.emailPlaceholder')"
                                          @updated="stateChanged"
                                          data-test="email" />
                            </template>
                        </editor-content>

                        <editor-content :valid="checkValid(['phone'])"
                                        @save="saveValues(['phone'], 'changePhone')"
                                        @cancel="cancelValues(['phone'])"
                                        data-test="phone-content">
                            <template v-slot:title>
                                {{$t('editRelatedPerson.phoneLabel')}}
                            </template>
                            <template v-slot:value>
                                {{relatedPerson.phone}}
                            </template>
                            <template v-slot:default>
                                <phone-number v-model="editing.phone"
                                              name="phone"
                                              :helper="$t('editRelatedPerson.phoneHelper')"
                                              :placeholder="$t('editRelatedPerson.phonePlaceholder')"
                                              @updated="stateChanged"
                                              data-test="phone" />
                            </template>
                        </editor-content>


                        <editor-content :valid="true" :lock-valid="true">
                            <template v-slot:title>
                                {{$t('editRelatedPerson.relationTypeLabel')}}
                            </template>
                            <template v-slot:value>
                                {{relationTypeText(relatedPerson.relationType)}}
                            </template>
                        </editor-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import PhoneNumber from '@/Components/Shared/Form/PhoneNumber.vue';
    import EditorContent from '@/Components/Shared/EditorContent.vue';

    import { computed, defineProps, reactive, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import { usePatientRelatedPersonService } from '@/Components/Patients/Patient/PatientRelatedPersonService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        id: String
    });

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, invalidFields } = useFormValidator();
    const patientRelSvc = usePatientRelatedPersonService();
    const caller = useExceptionwrappedCaller();

    const storeRelatedPerson = computed(() => store.getters.getPatientRelatedPerson(props.id));

    let relatedPerson = reactive({
        givenNames: '',
        familyName: '',
        email: '',
        phone: '',
        relationType: '',
    });

    watchEffect(() => {
        if (!storeRelatedPerson.value) return;
        relatedPerson.givenNames = storeRelatedPerson.value.givenNames;
        relatedPerson.familyName = storeRelatedPerson.value.familyName;
        relatedPerson.email = storeRelatedPerson.value.email;
        relatedPerson.phone = storeRelatedPerson.value.phone;
        relatedPerson.relationType = storeRelatedPerson.value.relationType;
    })

    // copy current values into a new structure used for editing mode to enable undoing edited values
    // NB: only handles flat structures - alternatively use a similar standard/lib clone-function
    let editing = reactive({});
    for (var key in relatedPerson)
        editing[key] = relatedPerson[key];


    // defines the actions used for updating values in backend
    let actions = {
        async changeName(data) {
            await caller.call(async () => {
                await patientRelSvc.changePatientRelatedPersonName(props.id, data.givenNames, data.familyName);
            })
        },
        async changePhone(data) {
            await caller.call(async () => {
                await patientRelSvc.changePatientRelatedPersonPhone(props.id, data.phone);
            });
        },
        async changeEmail(data) {
            await caller.call(async () => {
                await patientRelSvc.changePatientRelatedPersonEmail(props.id, data.email);
            });
        },
    };

    const relationTypeList = computed(() => [
        { "key": "0", "value": t('relationTypeList.parentToPatientLabel') },
        { "key": "1", "value": t('relationTypeList.childOfPatientLabel') },
        { "key": "2", "value": t('relationTypeList.spouseOfPatientLabel') },
        { "key": "3", "value": t('relationTypeList.siblingToPatientLabel') },
        { "key": "4", "value": t('relationTypeList.otherFamilyOfPatientLabel') },
        { "key": "5", "value": t('relationTypeList.friendOfPatientLabel') },
        { "key": "6", "value": t('relationTypeList.otherRelationToPatientLabel') }
    ]);

    const relationTypeText = (relationTypeKey) => {
        let text = "";
        relationTypeList.value.forEach((rt) => {
            if (Number(rt.key) == relationTypeKey) text = rt.value;
        });
        return text;
    }



    // used to check if a group of fields are valid or not (to deactivate or activate save-button)
    const checkValid = (fields) => {
        return invalidFields.value.filter((invalidField) => fields.includes(invalidField)).length == 0;
    };

    // save values in an editor
    // in task based scenarios it might be nescessary to define separate save-methods
    // for each editor-section to handle API-calls to server for the specific task
    const saveValues = async (fields, actionName) => {
        fields.map((fld) => relatedPerson[fld] = editing[fld]);
        if (actionName && actionName !== '' && actionName in actions) {
            let data = {
                relatedPersonId: props.id
            };
            fields.forEach((fld) => data[fld] = relatedPerson[fld]);
            await actions[actionName](data);
        }
        else {
            console.log(`${actionName} NOT FOUND!`);
        }
    }


    // cancel changes in an editor
    const cancelValues = (fields) => {
        fields.map((fld) => editing[fld] = relatedPerson[fld]);
    }


    const closeDialog = () => router.back();
</script>