import axios from 'axios';
import Config from '@/Router/Config.js';
import { createGuid } from '@/Shared/GuidTools.js';
import { CLIENTID } from '@/Shared/Strings.js';


const setBusy = () => window.dispatchEvent(new CustomEvent('setBusy'));
const setIdle = () => window.dispatchEvent(new CustomEvent('setIdle'));

const getClientId = () => {
    let clientId = localStorage.getItem(CLIENTID);
    if (!clientId) {
        clientId = createGuid();
        localStorage.setItem(CLIENTID, clientId);
    }
    return clientId;
}

const ignoreShowBusyRegEx = /\/(notifications(\?)?|refresh-token|diagnosiscodes\?|medicinecodes\?|immunizationcodes\?)/i;


// add client id to all post requests' headers
//axios.defaults.headers.post['x-client-id'] = getClientId();


// set busy-loader on request start
axios.interceptors.request.use(
    (config) => {
        // add client id to all post requests' headers
        config.headers['x-client-id'] = getClientId();

        //if (process.env.VUE_APP_ENVIRONMENT == "Local")
        //    console.log(new Error().stack.replace(/Error/gi, 'Callstack'));

        if (!(ignoreShowBusyRegEx).test(config.url))
            setBusy();

        return config;
    },
    (error) => {
        console.log('Axios request interceptor - ERROR', error);
        setIdle();
        return Promise.reject(error);
    });

// unset busy-loader on response
axios.interceptors.response.use(
    (response) => {
        //console.log('Axios response interceptor', response);
        setIdle();
        return response;
    },
    (error) => {
        console.log('Axios response interceptor - ERROR', error);
        setIdle();
        return Promise.reject(error);
    });


// API
const get = async (address, headers) => {
    //console.log('get ' + address + ' from API via dispatcher. Headers: ', headers);

    return await axios
        .get(Config.apiBaseUrl + address, { headers })
        .catch(function (error) {
            return error.response;
        });
}

const post = async (address, data, headers) => {
    console.log('post ' + address + ' to API via dispatcher. Headers: ', headers);

    return await axios
        .post(Config.apiBaseUrl + address, data, { headers })
        .catch(function (error) {
            return error.response;
        });
}

const put = async (address, data, headers) => {
    console.log('put ' + address + ' to API via dispatcher. Headers: ', headers);

    return await axios
        .put(Config.apiBaseUrl + address, data, { headers })
        .catch(function (error) {
            return error.response;
        });
}

const del = async (address, data, headers) => {
    //console.log('del ' + address + ' from API via dispatcher. Headers: ', headers);

    return await axios
        .delete(Config.apiBaseUrl + address, { data, headers })
        .catch(function (error) {
            return error.response;
        });
}

export default {
    name: "ApiProxy",
    get,
    post,
    put,
    del
}
