<template>
    <div v-if="isClinician" id="createImmunization" class="overlay overlay--patient">
        <bar :title="$t('createImmunization.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content">
                            <div class="col-xs-4 no-padding">
                                <div class="title">
                                    {{$t('createImmunization.codeGroupLabel')}}
                                </div>
                                <div class="value">
                                    {{props.code}}
                                </div>
                            </div>
                            <div class="col-xs-8 no-padding">
                                <div class="title">
                                    {{$t('createImmunization.textGroupLabel')}}
                                </div>
                                <div class="value">
                                    {{props.text}}
                                </div>
                            </div>
                        </div>
                        <div class="content">
                                <text-box v-model="vaccineProductname"
                                         name="vaccineProductname"
                                         type="text"
                                         :required="true"
                                         :label="$t('createImmunization.productNameLabel')"
                                         :errorText="$t('createImmunization.productNameErrorText')"
                                         :helper="$t('createImmunization.productNameHelperText')"
                                         :placeholder="$t('createImmunization.productNamePlaceholder')"
                                         @updated="stateChanged"
                                          data-test="immunization-productname"/>           
                            
                            <text-box v-model="vaccineBatchnumber"
                                         name="vaccineBatchnumber"
                                         type="text"
                                         :label="$t('createImmunization.batchnumberLabel')"
                                         :errorText="$t('createImmunization.batchnumberErrorText')"
                                         :helper="$t('createImmunization.batchnumberHelperText')"
                                         :placeholder="$t('createImmunization.batchnumberPlaceholder')"
                                         @updated="stateChanged"
                                          data-test="immunization-batchnumber"/>

                                <date-time v-model="vaccineExpirationDate"
                                          name="vaccineExpirationDate"
                                          type="date"
                                          :label="$t('createImmunization.expirationDateLabel')"
                                          :helper="$t('createImmunization.expirationDateHelperText')" 
                                           data-test="immunization-expirationdate"/>

                                <date-time v-model="administeringDate"
                                          name="administeringDate"
                                          type="date"
                                          :required="true"
                                          :label="$t('createImmunization.administeringDateLabel')"
                                          :errorText="$t('createImmunization.administeringDateErrorText')"
                                          :helper="$t('createImmunization.administeringDateHelperText')"
                                          @updated="stateChanged" 
                                           data-test="immunization-administeringdate"/>

                                <check-box v-model="vaccineComplete"
                                          name="vaccineComplete"
                                          type="switch"
                                          class="online-only"
                                          :label="$t('createImmunization.completeLabel')" />
                        </div>
                    </div>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--green-border left" type="button" @click="closeDialog">
                        <h4>{{$t('general.cancel')}}</h4>
                    </button>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--green right online-only" :class="{ disabled : !isValid }" :disabled="!isValid" type="button" @click="createImmunization" data-test="immunization-createbutton">
                        <h4>{{$t('general.create')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, defineProps, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useImmunizationService } from '@/Components/HealthData/Immunization/ImmunizationService.js';
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import CheckBox from '@/Components/Shared/Form/CheckBox.vue';
    import DateTime from '@/Components/Shared/Form/DateTime.vue';


    const props = defineProps({
        code: String,
        text: String,
        system: String,
    });

    const store = useStore();
    const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const caller = useExceptionwrappedCaller();
    const immunizationService = useImmunizationService();

	const isClinician = computed(() => store.getters.isClinician);

    let vaccineProductname = ref('');
    let vaccineBatchnumber = ref('');
    let vaccineComplete = ref(false);
    let vaccineExpirationDate = ref(null);

    //TODO: find a more robust implementation of this datehandling
    let d = new Date();
    d.setUTCMinutes(d.getUTCMinutes() - d.getTimezoneOffset());
    let administeringDate = ref(d.toISOString().substring(0, 16));

    const closeDialog = () => router.push({ name: 'immunization' });

    const createImmunization = async () => {
        await caller.call(async () => {
            await immunizationService.createImmunization(
                props.code,
                props.text,
                props.system,
                vaccineProductname.value,
                vaccineBatchnumber.value,
                vaccineComplete.value,
                vaccineExpirationDate.value,
                administeringDate.value);

            closeDialog();
        })
    };
</script>