<template>
    <div id="medication" class="overlay overlay--patient">
        <bar v-if="isClinician" :title="$t('medicationView.title')" @close="closeDialog" />
        <coded-concept-search v-if="isClinician" code-type="medicinecodes" navigate-on-select="create medication" class="online-only" />
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-medications />
    </div>

</template>

<script setup>
    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import ListMedications from './ListMedications.vue';
    import CodedConceptSearch from '@/Components/Terminology/Shared/CodedConceptSearch.vue';

    import { useMedicationService } from '@/Components/Medications/Medication/MedicationService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const medicationService = useMedicationService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await medicationService.loadMedications();
        });
    });

    const closeDialog = () => router.push({ name: 'patient' });
</script>
