<template>
    <div id="bloodTypeSelector" class="pb-small">
        <div class="row">
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.aneg">
                    <div class="short-bloodtype">A-</div>
                    <div class="long-bloodtype">A RhD Negative</div>
                </CheckBox>
            </div>
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.bneg">
                    <div class="short-bloodtype">B-</div>
                    <div class="long-bloodtype">B RhD Negative</div>
                </CheckBox>
            </div>
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.abneg">
                    <div class="short-bloodtype">AB-</div>
                    <div class="long-bloodtype">AB RhD Negative</div>
                </CheckBox>
            </div>
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.oneg">
                    <div class="short-bloodtype">O-</div>
                    <div class="long-bloodtype">O RhD Negative</div>
                </CheckBox>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.apos">
                    <div class="short-bloodtype">A+</div>
                    <div class="long-bloodtype">A RhD Positive</div>
                </CheckBox>
            </div>
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.bpos">
                    <div class="short-bloodtype">B+</div>
                    <div class="long-bloodtype">B RhD Positive</div>
                </CheckBox>
            </div>
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.abpos">
                    <div class="short-bloodtype">AB+</div>
                    <div class="long-bloodtype">AB RhD Positive</div>
                </CheckBox>
            </div>
            <div class="col-xs-3 col-sm-2 col-lg-1">
                <CheckBox type="checkbutton" v-model="model.opos">
                    <div class="short-bloodtype">O+</div>
                    <div class="long-bloodtype">O RhD Positive</div>
                </CheckBox>
            </div>
        </div>
    </div>
</template>

<script setup>
    import CheckBox from '@/Components/Shared/Form/CheckBox'

    import { computed, defineProps, defineEmits, onMounted, reactive, watch } from 'vue'

    const props = defineProps({
        modelValue: {
            type: Object,
            default: () => ({
                aneg: false,
                apos: false,
                bneg: false,
                bpos: false,
                abneg: false,
                abpos: false,
                oneg: false,
                opos: false,
            })
        },
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
        required: {
            type: Boolean,
            default: false
        },
    })
    const emit = defineEmits(['update:modelValue', 'updated']);

    let model = reactive(props.modelValue);

    const isValid = () => {
        return !props.required ||
            model.aneg ||
            model.apos ||
            model.bneg ||
            model.bpos ||
            model.abneg ||
            model.abpos ||
            model.oneg ||
            model.opos;
    }

    watch(model, (value) => {
        emit('update:modelValue', value);
        emit('updated', { name: props.name, valid: isValid() });
    })

    
    onMounted(() => emit('updated', { name: props.name, valid: isValid() }));
</script>
