<template>
    <div class="userinfobar row full-height">
        <div class="col-xs-9 full-height">
            <div class="img" @click="navigateTo(props.routeName)">
                <div class="img-container">
                    <profile-image :profile-id="props.profileId" :alt="props.profileName" />
                </div>
            </div>
            <div class="name" @click="navigateTo(props.routeName)">
                <h3>{{$t(props.titleKey+'')}}</h3>
                <h3><b>{{props.profileName}}</b></h3>
            </div>
        </div>
    </div>
</template>

<script setup>
	import { defineProps } from 'vue';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        profileId: String,
        profileName: String,
        routeName: String,
        titleKey: String,
        theme: {
            type: String,
            default: 'light',
            validator: (value) => ['white', 'light'].includes(value)
        }
    });

	const router = useRouter();

    const navigateTo = (name) => router.push({ name });
</script>
