import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';


const fnCreateCave = async (patientId, clinicianId, text) => {
    let result = await dispatcher.postToApi(`patients/${patientId}/cave`, { clinicianId, text });
    handleApiError(result, 'Error when creating cave');

    return result.data.caveId;
}

const fnChangeCave = async (patientId, clinicianId, caveId, text) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/cave/${caveId}`, { clinicianId, text });
    handleApiError(result, 'Error when updating cave');
}

const fnGetCave = async (patientId) => {
    if (!patientId)
        throw new Error('Error when getting patientId');

    let result = await dispatcher.getFromApi(`patients/${patientId}/cave`);
    if (result.status == 404)
        return null;
    handleApiError(result, 'Error when reading cave');

    return result.data;
}



export const useCaveService = () => {
    const store = useStore();

    const loadCave = async () => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                const cave = await fnGetCave(patientId);
                store.commit('setCave', cave);
            }
        );
    }

    const saveCave = async (caveId, text) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;
        let cave = {
            caveId,
            text,
            clinicianId,
        };

        await serviceCall(
            async () => {
                if (!caveId) {
                    cave.caveId = await fnCreateCave(patientId, clinicianId, text);

                    cave.patientGivenNames = store.state.patient.givenNames;
                    cave.patientFamilyName = store.state.patient.familyName;
                    cave.patientId = patientId;
                }
                else
                    await fnChangeCave(patientId, clinicianId, caveId, text);

                cave.date = new Date().toISOString();
                cave.clinicianGivenNames = store.state.clinician.givenNames;
                cave.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('setCave', cave);
            }
        );
    }

    return {
        loadCave,
        saveCave,
    }
}