<template>
    <div id="conversationmessage">
        <div class="container" v-if="message" data-test="message-container">
            <div class="row">
                <div class="col-xs-12" data-test="message-info">
                    <div class="message-sender-name" :class="{'has-content':getSenderName(message) && message.date}">{{getSenderName(message)}}</div>
                    <div class="message-sent-date"><date-display :iso-date="message.date" /></div>
                </div>
                <div class="col-xs-12 message-text" data-test="message-text">
                    {{message.text}}
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <message-attachments :attachments="message.attachments"
                                         :patient-id="message.patientId"
                                         :can-select="isClinician"
                                         @show="showPicture"
                                         @select="selectionChanged"></message-attachments>
                </div>
            </div>
        </div>

        <div class="button-container-bottom animate-in-delay" v-if="isClinician">
            <button type="button" 
                    class="btn btn__text btn__text--green-border online-only" 
                    :class="{disabled:!imagesSelected}"
                    :disabled="!imagesSelected"
                    @click="createNote" 
                    data-test="new-message-button">
                <h5>{{$t('messageView.newConsultationNoteButton')}}</h5>
            </button>
        </div>

        <message-view-picture :show="messageViewPictureVisible"
                              :url="messageViewPictureUrl"
                              @close="hideMessageViewPicture"></message-view-picture>
    </div>
</template>


<script setup>
    import MessageViewPicture from '@/Components/Consultations/Messaging/MessageViewPicture.vue';
    import MessageAttachments from '@/Components/Consultations/Messaging/MessageAttachments.vue';

    import { computed, defineProps, inject, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';

    const props = defineProps({
        messageId: { type: String },
    }); 

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const service = useMessagingService();
    const caller = useExceptionwrappedCaller();
    const patientService = usePatientService();

    const infoDialog = inject('infoDialog');

    const isClinician = computed(() => store.getters.isClinician);

    const message = computed(() => store.getters.getConversationMessage(props.messageId));

    let messageViewPictureVisible = ref(false);
    let messageViewPictureUrl = ref('');
    let selectedImages = ref([]);

    watchEffect(async () => {
        if (props.messageId) {
            await caller.call(async () => {
                await service.refreshConversationByMessage(props.messageId);
            });
        }
    });

    const getSenderName = (message) => {
        if (message.senderId == message.clinicianId)
            return [message.clinicianGivenNames, message.clinicianFamilyName].join(' ').trim();
        else
            return [message.patientGivenNames, message.patientFamilyName].join(' ').trim();
    }

    const showPicture = (attachment) => {
        messageViewPictureUrl.value = attachment.url;
        messageViewPictureVisible.value = true;
    }

    const hideMessageViewPicture = () => messageViewPictureVisible.value = false;

    const selectionChanged = (attachment) => {
        if (attachment.selected && !selectedImages.value.includes(attachment.url))
            selectedImages.value = [...selectedImages.value, attachment.url];
        else if (!attachment.selected && selectedImages.value.includes(attachment.url))
            selectedImages.value = selectedImages.value.filter((i) => i == attachment.url);
    }

    const createNote = async () => {
        try {
            await caller.call(async () => {
                // switch to patient of this message
                await patientService.switchPatient(message.value.patientId);

                // change to create note view for the patient
                router.push({
                    name: 'note',
                    params: {
                        useImages: selectedImages.value.join('|'),
                    }
                });
            });
        }
        catch (e) {
            const msg = e.message || e || '';
            if (msg.toLowerCase().includes('patientmissingoffline')) {
                await infoDialog({
                    header: t('messageView.patientNotAvailableAlertHeader'),
                    body: t('messageView.patientNotAvailableAlertBody'),
                });
            }
            else if (msg.toLowerCase().includes('patientaccess')) {
                await infoDialog({
                    header: t('messageView.patientAccessMissingAlertHeader'),
                    body: t('messageView.patientAccessMissingAlertBody'),
                });
            }
            else {
                console.log('ERROR', e);
                throw e;
            }
        }
    }

    const imagesSelected = computed(() => selectedImages.value.length > 0);
</script>
