<template>
    <div class="overlay overlay--patient fixed-button">
        <bar :title="$t('patientConversationMessageNew.title')" @close="closeDialog" />

        <message-new v-bind="data" @messagesent="createNewMessage"></message-new>
    </div>
</template>


<script setup>
    import MessageNew from '@/Components/Consultations/Messaging/MessageNew';

    import { computed, defineProps, reactive } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        clinicId: { type: String },
        clinicianId: { type: String },
    });

    const store = useStore();
    const router = useRouter();

    const patientId = computed(() => store.getters.getPatientId);

    const data = reactive({
        clinicId: props.clinicId,
        patientId: patientId.value,
        clinicianId: props.clinicianId
    });

    const createNewMessage = () => router.back();

    const closeDialog = () => router.back();
</script>