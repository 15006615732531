<template>
    <div class="overlay overlay--patient" id="vitalSigns">
        <bar v-if="isClinician" :title="$t(`vitalSignsView.title${userType}`)" @close="closeDialog"></bar>
        <vital-signs-summary v-if="isClinician"></vital-signs-summary>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-vital-signs></list-vital-signs>
    </div>
</template>

<script setup>
    import VitalSignsSummary from '@/Components/HealthData/Measurements/VitalSignsSummary.vue';
    import ListVitalSigns from '@/Components/HealthData/Measurements/ListVitalSigns.vue';

    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useMeasurementService } from '@/Components/HealthData/Measurements/MeasurementService.js';
    import { useMeasurementDefinitionService } from '@/Components/HealthData/Measurements/MeasurementDefinitionService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const measurementService = useMeasurementService();
    const measurementDefinitionService = useMeasurementDefinitionService();
    const caller = useExceptionwrappedCaller();

    const userType = computed(() => store.getters.getUserType);
    const isClinician = computed(() => store.getters.isClinician);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await measurementService.loadMeasurements();
        });
    });

    watchEffect(async () => {
        await caller.call(async () => {
            await measurementDefinitionService.loadMeasurementDefinitions();
        })
    });

    const closeDialog = async () => await router.push({ name: 'patient' });
</script>
