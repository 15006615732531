<template>
    <div class="container">
        <div class="row" v-for="request in filteredRequests" :key="request.bookingRequestId">
            <div class="col-xs-12">
                <div class="user" data-test="bookingrequestlist-user">
                    <div class="data">
                        <div class="info">
                            <patient-list-info v-bind="getPatientFromRequest(request)"></patient-list-info>
                        </div>
                        <div class="more">
                            <button @click="bookingRequest(request)" class="btn btn__round btn__round--medium btn__round--green-outline online-only">
                                <div class="svg-container">
                                    <svg-icon icon-name="chevron" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import PatientListInfo from './PatientListInfo';

    import { computed, defineProps, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { useBookingRequestService } from '@/Components/Consultations/BookingRequest/BookingRequestService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        clinicId: String,
        filter: { type: String, default: '' },
    });

    const store = useStore();
    const router = useRouter();
    const bookingRequestService = useBookingRequestService();
    const caller = useExceptionwrappedCaller();

    const bookingRequests = computed(() => store.getters.getClinicBookingRequests.filter((br) => br.rejectedState == 0));

    watchEffect(async () => {
        if (!store.getters.getClinicianClinicId) return;
        await caller.call(async () => {
            await bookingRequestService.refreshClinicBookingRequests();
        });
    });

    const filteredRequests = computed(() => {
        if (!bookingRequests.value)
            return [];

        let propsFilter = props.filter.toLowerCase();
        return bookingRequests.value.filter((v) =>
            v.patientGivenNames.toLowerCase().includes(propsFilter) ||
            v.patientFamilyName.toLowerCase().includes(propsFilter));
    });

    const getPatientFromRequest = (request) => {
        let connected = store.getters.clinicianHasAccessToPatient(request.patientId)
        return {
            patientId: request.patientId,
            name: [request.patientGivenNames, request.patientFamilyName].join(' '),
            connected: connected,
            date: request.date,
        };
    }

    const bookingRequest = (request) => {
        router.push({
            name: 'clinician booking request',
            query: { bookingRequestId: request.bookingRequestId }
        });
    }
</script>