<template>
    <div class="container">
        <div class="row">
            <div class="clinic" v-for="booking in bookings" :key="booking.bookingId">
                <div class="col-xs-8">
                    <clinic-booking-list-info v-bind="getClinicFromBooking(booking)"></clinic-booking-list-info>
                </div>
                <div class="col-xs-4 ">
                    <div class="date-container">
                        <div class="date"><date-display :iso-date="booking.bookingDate" display-as="date"/></div>
                        <div class="time"><svg-icon icon-name="clock" /><date-display :iso-date="booking.bookingDate" display-as="shorttime"/></div>
                    </div>
                </div>
                <div class="rejected">
                    <button type="button" @click="rejectReason(booking)" 
                            class="btn btn__text-icon btn__text-icon--short btn__text-icon--red-transparent online-only"
                            data-test="reject-booking-button">
                        <div>
                            <svg-icon icon-name="alert" />
                            <h5><span>{{$t('patientAppointments.rejectAppointmentButton')}}</span></h5>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <popup v-bind="rejectReasonPopupConfig" @accepted="rejectAppointment" @declined="closeRejectAppointment" data-test="reject-popup">
            <text-area v-model="rejectReasonText" 
                       :required="true"
                       :label="$t('patientAppointments.rejectAppointmentPopupReasonLabel')"
                       @updated="stateChanged"
                       data-test="reject-reason-text" />
        </popup>
    </div>
</template>


<script>
    import { reactive, ref, onMounted } from 'vue';
    import { useI18n } from 'vue-i18n';
    import ClinicBookingListInfo from './ClinicBookingListInfo';
    import TextArea from '@/Components/Shared/Form/TextArea';
    import * as strings from '@/Shared/Strings.js';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';

    export default {
        name: 'ListBookings',
        components: {
            ClinicBookingListInfo,
            TextArea,
        },
        props: {
            bookings: Array,
        },
        emits: ['rejected'],
        setup(_, { emit }) {
            const { t } = useI18n();
            const { stateChanged, isValid } = useFormValidator();

            let rejectReasonPopupConfig = reactive({
                type: strings.POPUPTYPE_ALERT,
                open: false,
                header: '',
                accept: '',
                cancel: ''
            });
            let rejectReasonText = ref('');
            let rejectingBooking = null;

            onMounted(() => {
                rejectReasonPopupConfig.header = t('patientAppointments.rejectAppointmentPopupHeader');
                rejectReasonPopupConfig.accept = t('general.reject');
                rejectReasonPopupConfig.cancel = t('general.cancel');
            });

            const getClinicFromBooking = (booking) => {
                return {
                    name: booking.clinicName,
                    address: booking.clinicAddress,
                    zipCode: booking.clinicZipCode,
                    city: booking.clinicCity,
                    state: booking.clinicState,
                    country: booking.clinicCountry,
                    phone: booking.clinicPhone,
                    email: booking.clinicEmail,
                    clinicianGivenNames: booking.clinicianGivenNames,
                    clinicianFamilyName: booking.clinicianFamilyName,
                };
            }

            const rejectReason = (booking) => {
                console.log('rejectReason popup opened');
                rejectingBooking = booking;
                rejectReasonPopupConfig.open = true;
            }
            
            const closeRejectAppointment = () => {
                rejectReasonPopupConfig.open = false;
                rejectReasonText.value = '';
            }

            const rejectAppointment = () => {
                if (!isValid.value) {
                    alert(t('patientAppointments.rejectAppointmentErrorReasonMissingText'));
                    return;
                }

                emit('rejected', {
                    bookingId: rejectingBooking.bookingId,
                    reason: rejectReasonText.value
                });
                closeRejectAppointment();
            }


            return {
                stateChanged,
                isValid,
                rejectReasonPopupConfig,
                rejectReasonText,
                rejectAppointment,
                closeRejectAppointment,
                getClinicFromBooking,
                rejectReason,
            }
        }
    }
</script>