<template>
    <div class="overlay overlay--patient" id="patientAppointments">
        <bar :title="$t(`patientAppointments.title${userType}`)" @close="closeDialog" />
        <div class="tabs">
            <!-- TABS Selector -->
            <div class="tab-head animate-in-delay">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-4 no-padding-right">
                            <div class="tab" :class="{active:props.tabNumber==0}">
                                <button type="button" class="full-width left-text " @click="changeTab(0)"><span>{{$t('patientAppointments.bookedAppointmentsTabLabel')}} ({{bookings.length}})</span></button>
                            </div>
                        </div>
                        <div class="col-xs-4 no-padding">
                            <div class="tab" :class="{active:props.tabNumber==1}">
                                <button type="button" class="full-width center-text " @click="changeTab(1)"><span>{{$t('patientAppointments.pendingAppointmentsTabLabel')}} ({{pendingRequests.length}})</span></button>
                            </div>
                        </div>
                        <div class="col-xs-4 no-padding-left">
                            <div class="tab" :class="{active:props.tabNumber==2}">
                                <button type="button" class="full-width right-text" @click="changeTab(2)"><span>{{$t('patientAppointments.rejectedAppointmentsTabLabel')}} ({{rejectedRequests.length}})</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- BOOKED Tab -->
            <div class="tab-result" :class=" {active:props.tabNumber==0}">
                <list-bookings :bookings="bookings" @rejected="rejectBooking"></list-bookings>
            </div>
            <!-- PENDING Tab -->
            <div class="tab-result" :class=" {active:props.tabNumber==1}">
                <list-sent-requests :bookingRequests="pendingRequests"></list-sent-requests>
            </div>
            <!-- REJECTED Tab -->
            <div class="tab-result" :class=" {active:props.tabNumber==2}">
                <list-sent-requests :bookingRequests="rejectedRequests"></list-sent-requests>
            </div>
        </div>
        <div class="button-container-bottom animate-in-delay" v-if="isPatient">
            <button type="button" class="btn btn__text btn__text btn__text--green-border online-only" @click="newBooking">
                <h3>{{$t('patientAppointments.bookNewAppointmentButton')}}</h3>
            </button>
        </div>
    </div>
</template>


<script setup>
    import ListSentRequests from '@/Components/Consultations/BookingRequest/ListSentRequests';
    import ListBookings from '@/Components/Consultations/Booking/ListBookings';

    import { computed, defineProps, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useBookingService } from '@/Components/Consultations/Booking/BookingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        tabNumber: { type: Number, default: 0 },
    });

    const store = useStore();
    const router = useRouter();
    const bookingService = useBookingService();
    const caller = useExceptionwrappedCaller();

    const userType = computed(() => store.getters.getUserType);
    const isPatient = computed(() => store.getters.isPatient);

    let pendingRequests = computed(() => store.getters.getPatientBookingRequests.filter((r) => r.rejectedState == 0));
    let rejectedRequests = computed(() => store.getters.getPatientBookingRequests.filter((r) => r.rejectedState == 1));
    let bookings = computed(() => store.getters.getPatientBookings.filter((b) => b.bookingState != 0));

    const changeTab = (no) => router.push({ query: { tabNumber: no } });

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        console.log('patient id is present in store - refresh appointments')
        await caller.call(async () => {
            await bookingService.refreshPatientAppointments();
        })
    });

    const rejectBooking = async (bookingInfo) => {
        await caller.call(async () => {
            await bookingService.rejectBooking(bookingInfo.bookingId, bookingInfo.reason);
        });
    }

    const newBooking = () => router.push({ name: 'findclinic' });

    const closeDialog = () => router.push({ name: 'patient' });
</script>