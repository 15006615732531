<template>
    <div class="overlay overlay--patient" id="editImmunization">
        <bar :title="$t('editImmunization.title')"
             :by="clinicianName" :by-label="$t('editImmunization.changedByLabel')"
             :date="date" :date-label="$t('editImmunization.changedDateLabel')"
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content">
                            <div class="col-xs-4 no-padding">
                                <div class="title">
                                    {{$t('editImmunization.codeLabel')}}
                                </div>
                                <div class="value">
                                    {{vaccineCode}}
                                </div>
                            </div>
                            <div class="col-xs-8 no-padding">
                                <div class="title">
                                    {{$t('editImmunization.nameLabel')}}
                                </div>
                                <div class="value">
                                    <span data-test="vaccine-name">{{vaccineText}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editImmunization.productNameLabel')}}
                            </div>
                            <div class="value">
                                <span data-test="product-name">{{vaccineProductname}}</span>
                            </div>
                        </div>                        
                        <div class="content">
                            <div class="title">
                                {{$t('editImmunization.batchnumberLabel')}}
                            </div>
                            <div class="value">
                                <span data-test="product-name">{{vaccineBatchnumber}}</span>
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editImmunization.administeredDateLabel')}}
                            </div>
                            <div class="value">
                                <date-display :iso-date="administeringDate" display-as="date" data-test="administering-date" />
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editImmunization.expirationDateLabel')}}
                            </div>
                            <div class="value">
                                <date-display :iso-date="vaccineExpirationDate" display-as="date" data-test="expiration-date" />
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editImmunization.completedLabel')}}
                            </div>
                            <div class="value">
                                <bool-display v-if="!isClinician || vaccineCompleted"
                                              :value="vaccineCompleted"
                                              :true-label="$t('editImmunization.trueLabel')"
                                              :false-label="$t('editImmunization.falseLabel')"/>
                                <check-box v-if="isClinician && !vaccineCompleted"
                                           v-model="vaccineCompleted"
                                           name="vaccineCompleted"
                                           type="switch"
                                           :errorText="$t('editImmunization.completeErrorText')"
                                           :helper="$t('editImmunization.completeHelperText')"
                                           :placeholder="$t('editImmunization.completePlaceHolder')"
                                           @click.prevent.stop.capture="completeVaccine"
                                           class="online-only"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import CheckBox from '@/Components/Shared/Form/CheckBox.vue';

    import { computed, defineProps, inject, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useImmunizationService } from '@/Components/HealthData/Immunization/ImmunizationService.js';

    const props = defineProps({
        id: String,
    });

    const store = useStore();
    const router = useRouter();
    const isClinician = computed(() => store.getters.isClinician);
    const { t } = useI18n();
    const caller = useExceptionwrappedCaller();
    const immunizationService = useImmunizationService();

    const alertDialog = inject('alertDialog');

    let vaccineCode = ref('');
    let vaccineText = ref('');
    let vaccineProductname = ref('');
    let vaccineBatchnumber = ref('');
    let vaccineCompleted = ref(false);
    let vaccineExpirationDate = ref('');
    let administeringDate = ref('');
    let date = ref(null);
    let clinicianName = ref('');


    watchEffect(() => {
        const immunization = store.getters.getImmunization(props.id);
        if (!immunization) return;

        vaccineCode.value = immunization.vaccineCode;
        vaccineText.value = immunization.vaccineText;
        vaccineProductname.value = immunization.vaccineProductname;
        vaccineBatchnumber.value = immunization.vaccineBatchnumber;
        vaccineExpirationDate.value = immunization.vaccineExpirationDate;
        vaccineCompleted.value = immunization.vaccineComplete;
        administeringDate.value = immunization.administeringDate;
        date.value = immunization.date;
        clinicianName.value = [immunization.clinicianGivenNames, immunization.clinicianFamilyName].join(' ');
    });

    const completeVaccine = async () => {
        const accepted = await alertDialog({
            header: t('editImmunization.vaccineCompletedPopupHeader'),
            body: t('editImmunization.vaccineCompletedPopupMessage'),
            accept: t('general.accept'),
            cancel: t('general.cancel'),
        });

        if (accepted) {
            await caller.call(async () => {
                await immunizationService.completeImmunization(props.id);
                vaccineCompleted.value = true;
            });
        }
    }

    const closeDialog = () => router.push({ name: 'immunization' });
</script>
