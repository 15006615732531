<template>
    <label>
        <span v-html="label"></span>
        <span class="error" v-if="error">{{errorText}}</span>
        <span v-if="helper" class="helper-button" :data-helper-indent="helperIndent" @click="toggleHelp">?</span>
        <span class="helper-text" :class="{ show : helpOpen }" @click="toggleHelp">{{helper}}</span>
    </label>
</template>

<script>
    import { ref } from 'vue';
    
    export default {
        name: 'Label',
        props: {
            label: {
                type: String,
                default: ''
            },
            errorText: {
                type: String,
                default: ''
            },
            helper: {
                type: String,
                default: ''
            },
            helperIndent: {
                type: Boolean,
                default: false
            },
            error: {
                type: Boolean,
                default: false
            }
        },
        setup() {
            let helpOpen = ref(false);

            const toggleHelp = () => {
                helpOpen.value = !helpOpen.value;
            };

            return {
                toggleHelp,
                helpOpen,
            };
        }
    }
</script>