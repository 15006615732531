import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';


const fnAddPatientRelatedPerson = async (patientId, givenNames, familyName, phone, email, relationType) => {
    let relatedPerson = {
        givenNames: givenNames.split(' '),
        familyName,
        phone,
        email,
        relationType,
    }

    let result = await dispatcher.postToApi(`patients/${patientId}/relatedpersons`, relatedPerson);
    handleApiError(result, 'Error while adding related person');

    return result.data.relatedPersonId;
}

const fnRemovePatientRelatedPerson = async (patientId, relatedPersonId) => {
    let result = await dispatcher.deleteFromApi(`patients/${patientId}/relatedpersons/${relatedPersonId}`);
    handleApiError(result, 'Error while removing related person');
}

const fnChangePatientRelatedPersonPhone = async (patientId, relatedPersonId, phone) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/relatedpersons/${relatedPersonId}/phone`, { phone });
    handleApiError(result, 'Error while changing related person phone');
}

const fnChangePatientRelatedPersonEmail = async (patientId, relatedPersonId, email) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/relatedpersons/${relatedPersonId}/email`, { email });
    handleApiError(result, 'Error while changing related person email');
}

const fnChangePatientRelatedPersonName = async (patientId, relatedPersonId, givenNames, familyName) => {
    let data = {
        givenNames: givenNames.split(' '),
        familyName
    }

    let result = await dispatcher.putToApi(`patients/${patientId}/relatedpersons/${relatedPersonId}/name`, data);
    handleApiError(result, 'Error while changing related person name');
}

const fnAddHealthInformationToRelatedPerson = async (patientId, relatedPersonId, relatedPersonPatientId, patientDataAccessType) => {
    let data = {
        relatedPersonPatientId,
        patientDataAccessType,
    };

    let result = await dispatcher.postToApi(`patients/${patientId}/relatedpersons/${relatedPersonId}/patient`, data);
    handleApiError(result, 'Error while adding related person health information');
}

const fnRemoveHealthInformationFromRelatedPerson = async (patientId, relatedPersonId) => {
    let result = await dispatcher.deleteFromApi(`patients/${patientId}/relatedpersons/${relatedPersonId}/patient`);
    handleApiError(result, 'Error while removing related person health information');
}


export const usePatientRelatedPersonService = () => {
    const store = useStore();

    const addPatientRelatedPerson = async (
        givenNames,
        familyName,
        phone,
        email,
        relationType) =>
    {
        const patientId = store.getters.getPatientId;
        relationType = Number(relationType);
        await serviceCall(
            async () => {
                const relatedPersonId = await fnAddPatientRelatedPerson(
                    patientId,
                    givenNames,
                    familyName,
                    phone,
                    email,
                    relationType);
                store.commit('addPatientRelatedPerson', {
                    givenNames,
                    familyName,
                    phone,
                    email,
                    relationType,
                    relatedPersonId,
                    relatedPersonPatientId: null
                });
            }
        );
    }

    const removePatientRelatedPerson = async (relatedPersonId) => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnRemovePatientRelatedPerson(patientId, relatedPersonId);
                store.commit('removePatientRelatedPerson', { relatedPersonId });
            }
        );
    }

    const changePatientRelatedPersonEmail = async (relatedPersonId, email) => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnChangePatientRelatedPersonEmail(patientId, relatedPersonId, email);
                store.commit('changePatientRelatedPersonEmail', { relatedPersonId, email });
            }
        );
    }

    const changePatientRelatedPersonPhone = async (relatedPersonId, phone) => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnChangePatientRelatedPersonPhone(patientId, relatedPersonId, phone);
                store.commit('changePatientRelatedPersonPhone', { relatedPersonId, phone });
            }
        );
    }

    const changePatientRelatedPersonName = async (relatedPersonId, givenNames, familyName) => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnChangePatientRelatedPersonName(patientId, relatedPersonId, givenNames, familyName);
                store.commit('changePatientRelatedPersonName', { relatedPersonId, givenNames, familyName });
            }
        );
    }

    const addHealthInformationToRelatedPerson = async (relatedPersonId, relatedPersonPatientId, patientDataAccessType) => {
        const patientId = store.getters.getPatientId;
        patientDataAccessType = Number(patientDataAccessType);
        await serviceCall(
            async () => {
                await fnAddHealthInformationToRelatedPerson(
                    patientId,
                    relatedPersonId,
                    relatedPersonPatientId,
                    patientDataAccessType);
                store.commit('addHealthInformationToRelatedPerson', { relatedPersonId, relatedPersonPatientId, patientDataAccessType });
            }
        );
    }

    const removeHealthInformationFromRelatedPerson = async (relatedPersonId) => {
        const patientId = store.getters.getPatientId;
        await serviceCall(
            async () => {
                await fnRemoveHealthInformationFromRelatedPerson(patientId, relatedPersonId);
                store.commit('removeHealthInformationFromRelatedPerson', { relatedPersonId });
            }
        );
    }

    return {
        addPatientRelatedPerson,
        removePatientRelatedPerson,
        changePatientRelatedPersonEmail,
        changePatientRelatedPersonPhone,
        changePatientRelatedPersonName,
        addHealthInformationToRelatedPerson,
        removeHealthInformationFromRelatedPerson,
    }
}
