<template>
    <div class="overlay" id="ResetPassword">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('passwordResetRequest.title')}}</b></h2>
                    <h5 class="full-width center-text pb-medium" v-if="!resetRequestDone">
                        <span>{{$t('passwordResetRequest.subTitle')}}</span>
                    </h5>
                    <form>
                        <div v-if="!resetRequestDone" class="col-xs-push-1 col-xs-10 pt--large">
                            <text-box v-model="userIdentifier"
                                      name="userIdentifier"
                                      :required="true"
                                      :label="$t('passwordResetRequest.identLabel')"
                                      :errorText="$t('passwordResetRequest.identError')"
                                      :helper="$t('passwordResetRequest.identHelper')"
                                      :placeholder="$t('passwordResetRequest.identPlaceholder')"
                                      @updated="stateChanged" />
                            <button class="btn btn__text btn__text--large btn__text--green-border full-width online-only"
                                    :class="{ disabled : !isValid }"
                                    :disabled="!isValid"
                                    type="button"
                                    @click="requestResetUserPassword()"
                                    data-test="request-reset-button">
                                <h1>{{$t('general.reset')}}</h1>
                            </button>
                            <div class="error" v-if="resetRequestFailed" data-test="request-failed-message"><h5>{{$t('passwordResetRequest.error')}}</h5></div>
                        </div>

                        <div v-if="resetRequestDone" class="col-xs-push-1 col-xs-10 pt--large" data-test="receipt-message">
                            <div class="col-xs-12 center-text pb-medium">
                                <h5>{{$t('passwordResetRequest.sendingEmail')}} </h5>
                            </div>
                            <button class="btn btn__text btn__text--large btn__text--green full-width center"
                                    type="button"
                                    @click="closeDialog()"
                                    data-test="go-back">
                                <h1>{{$t('general.back')}}</h1>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';

    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';

    const { stateChanged, isValid } = useFormValidator();
    const router = useRouter();
    const service = useUserService();
    const caller = useExceptionwrappedCaller();

    let resetRequestFailed = ref(false);
    let resetRequestDone = ref(false);
    let userIdentifier = ref('');

    const requestResetUserPassword = async () => {
        try {
            await caller.call(async () => {
                await service.requestResetUserPassword(userIdentifier.value);
                resetRequestDone.value = true;
            });
        }
        catch (e) {
            resetRequestFailed.value = true;
            console.log(e.message || e);
        }
    }

    const closeDialog = () => router.push({ name: 'login' });
</script>