<template>
    <div class="clinic-data">
        <ul>
            <li>
                <h4><b>{{props.name}}</b></h4>
            </li>
            <li>
                <h5>{{clinicianName}}</h5><br />
            </li>
            <li>
                <span>{{props.address}}, {{props.zipCode}} {{props.city}}, {{props.country}}</span><br />
            </li>
            <li v-if="phone">
                {{$t('patientAppointments.phoneLabel')}}: <a :href="'tel:'+phone">{{props.phone}}</a>
            </li>
            <li v-if="email">
                {{$t('patientAppointments.emailLabel')}}: <a :href="'mailto:'+email">{{props.email}}</a>
            </li>
        </ul>
    </div>
</template>


<script setup>
    import { computed, defineProps } from 'vue';

    const props = defineProps({
        name: String,
        address: String,
        zipCode: String,
        city: String,
        state: String,
        country: String,
        phone: String,
        email: String,
        clinicianGivenNames: String,
        clinicianFamilyName: String,
    });

    const clinicianName = computed(() => [props.clinicianGivenNames, props.clinicianFamilyName].join(' '));
</script>