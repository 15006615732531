<template>
	<button :class="`btn btn__card btn__card--${size} ${color}`" @click="activateButton">
		<div class="svg-container">
			<svg xmlns="http://www.w3.org/2000/svg" :style="svgStyle">
				<use :xlink:href="`#${svg}`" />
			</svg>
		</div>
		<div class="text-container" v-html="nodashtext"></div>
	</button>
</template>

<script setup>
    import { computed, defineProps, defineEmits } from 'vue';

	const props = defineProps({
		color: { type: String, default: '' },
		size: {
			type: String,
			default: 'medium',
			validator(value) {
				return ['medium', 'large'].includes(value);
			}
		},
		text: String,
		svg: String,
		svgStyle: { type: String, default: null },
	});

	const emit = defineEmits(["click"]);

    const nodashtext = computed(() => props.text?.replace('-', '&shy;'));

	const activateButton = () => emit('click');
</script>