<template>
    <div id="clinicianclinicconversation" class="overlay overlay--patient fixed-button">
        <bar :title="$t('clinicianConversation.title')" @close="closeDialog" />

        <conversation v-bind="data" @viewmessage="viewMessage" @newmessage="createNewMessage"></conversation>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>


<script setup>
    import Conversation from '@/Components/Consultations/Messaging/Conversation.vue';

    import { computed, defineProps, reactive, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        patientId: { type: String }
    });

    const store = useStore();
    const router = useRouter();

    const clinicId = computed(() => store.getters.getClinicianClinicId);
    const clinicianId = computed(() => store.getters.getClinicianId);
    const patientId = computed(() => props.patientId);

    const data = reactive({
        clinicId: clinicId.value,
        clinicianId: clinicianId.value,
        patientId: patientId.value,
    });

    watchEffect(() => {
        data.patientId = patientId.value;
        data.clinicId = clinicId.value;
        data.clinicianId = clinicianId.value;
    });

    const viewMessage = (messageId) => {
        router.push({
            name: 'clinic conversation message',
            params: {
                messageId: messageId,
            }
        });
    }

    const createNewMessage = () => {
        router.push({
            name: 'new clinic conversation message',
            params: {
                patientId: patientId.value,
            }
        });
    };

    const closeDialog = () => router.back();
</script>