<template>
    <div class="overlay overlay--patient hidden" id="audioRecorder">
        <bar :title="$t('mediaRecorder.title')" @close="closeOverlay" />
        <div class="recorder__actions">
            <div class="col-xs-4 full-height">
                <button class="small-buttons" @click="play" v-if="blob && !playing">
                    <svg-icon icon-name="play" />
                    <div class="text"><h5>{{$t('general.play')}}</h5></div>
                </button>
                <button class="small-buttons small-buttons--red" @click="stop" v-if="blob && playing">
                    <svg-icon icon-name="stop" />
                    <div class="text"><h5>{{$t('general.stop')}}</h5></div>
                </button>
            </div>
            <div class="col-xs-4 full-height">
                <button class="big-buttons" v-if="!recordingState" @click="startRecording">
                    <svg-icon icon-name="recording" />
                    <div class="text" v-if="!blob"><h3>{{$t('general.start')}}</h3></div>
                    <div class="text" v-if="blob"><h3>{{$t('mediaRecorder.startNew')}}</h3></div>
                </button>
                <button class="big-buttons big-buttons--red" v-if="recordingState" @click="stopRecording">
                    <svg-icon icon-name="stop" />
                    <div class="text"><h3>{{$t('general.stop')}}</h3></div>
                </button>
            </div>
            <div class="col-xs-4 full-height">
                <button class="small-buttons small-buttons--gray" @click="remove" v-if="blob">
                    <svg-icon icon-name="cross" />
                    <div class="text"><h5>{{$t('general.remove')}}</h5></div>
                </button>
            </div>
            <div class="col-xs-12 full-height mt-large">
                <button class="btn btn__text btn__text--green" @click="saveBlob" v-if="blob"><h4>{{$t('mediaRecorder.saveRecording')}}</h4></button>
            </div>
        </div>
    </div>
</template>
<script>
    import { ref, onMounted } from 'vue';
    import uploadService from './Upload.js';

    export default {
        name: 'MediaRecorder',
        props: {
            userType :{
                default: 'patient',
                type: String,
            }
        },
        emits: ['uploadGuid', 'close'],
        setup(props, { emit }) {
            const recordingState = ref(false);
            const lastRecordingId = ref('');
            const blobSize = ref(null);
            let streamObj = null;
            let blob = ref(null);
            let playing = ref(false);
            let audioElement = ref(null);
            
            const ensureReleaseBlob = () => {
                if(blob.value != null)
                    window.URL.revokeObjectURL(blob.value);
            }

            const closeOverlay = () => {
                remove();
                emit('close');
            }

            const stop = () => {
                audioElement.value.pause();
                audioElement.value.currentTime = 0;
                playing.value = false;
                console.log('stopped playing');
            }

            const play = () => {
                try {
                    console.log("record audio play: ", blob);
                    ensureReleaseBlob();
                    var blobURL = window.URL.createObjectURL(blob.value);
                    audioElement.value = new Audio(blobURL);
                    audioElement.value.play();
                    playing.value = true;
                    audioElement.value.onended = () => {
                        playing.value = false;
                        console.log('stopped playing');
                    }

                } catch (e) {
                    console.log("record audio play err: ", e)
                }
            }

            const saveBlob = async () => {
                let uploadGuid = await uploadService.uploadFileName(blob.value, props.userType, true);
                console.log("uploadGuid :", uploadGuid);
                emit('uploadGuid', uploadGuid);
                emit('close');
            }

            const remove = () => {
                ensureReleaseBlob();
                streamObj = null;
                blobSize.value = null;
                blob.value = null;
            }

            const stopRecording = () => {
                try {
                    streamObj.stop();
                    recordingState.value = false;
                    console.log("record audio stop and convert to blob: ", streamObj)
                } catch (e) {
                    console.log("record audio stopRecording err: ", e)
                }
            }

            const startRecording = () => {
                try {
                    ensureReleaseBlob();
                    blob.value = null;
                    blobSize.value = null;
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then(stream => {
                            //connect the media stream to the first audio element
                            streamObj = new MediaRecorder(stream)
                            streamObj.ondataavailable = (e) => {
                                blob.value = e.data;
                                blobSize.value = blob.value.size;
                            }
                            streamObj.onstop = () => {
                                stream.getTracks().forEach(s => s.stop());
                            };
                            streamObj.start();
                            recordingState.value = true;
                            lastRecordingId.value = streamObj.stream.id;
                            console.log("record audio started: ", streamObj)
                        })
                        .catch(function (err) {
                            console.log("record audio err: ", err.name, err.message);

                        });

                } catch (e) {
                    console.log("record audio startRecording err: ", e)
                }
            }

            onMounted(() => {
                remove();
            });

            return {
                audioElement,
                playing,
                closeOverlay,
                remove,
                play,
                stop,
                blob,
                blobSize,
                saveBlob,
                lastRecordingId,
                startRecording,
                stopRecording,
                recordingState
            }
        }
    }
</script>
