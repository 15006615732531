<template>
    <div v-if="image" class="btn btn__card btn__card--medium">
        <div style="overflow:hidden;width:100%;height:100%">
            <img ref="thumbImage" 
                 :src="image" 
                 style="min-width: 100%; min-height: 100%; width: 100%; transform: translate(-50%,-50%); top: 50%; left: 50%; position: absolute;z-index:50;"
                 :style="imgStyle"
                 @click="showImage(image)" />
        </div>
    </div>
</template>

<script setup>
    import { computed, defineProps, inject, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import uploadService from '@/Components/Shared/Form/Upload.js';

    const props = defineProps({
        measurementDefinitionId: String,
    });

    const store = useStore();

    const showImagePopup = inject('showImagePopup');

    const thumbImage = ref();
    let imgStyle = ref(null);

    const img = new Image();
    const imageLoaded = function() {    // function is used here otherwise this is not bound to img...
        console.log('image loaded', this.width, this.height)
        imgStyle.value = this.width > this.height
            ? { width: 'auto', height: '100%' }
            : { height: 'auto', width: '100%' };
    };
    const imageLoadedErr = (e) => console.log('image load error', e);

    onMounted(() => {
        console.log('image button mounted')
        img.addEventListener('load', imageLoaded);
        img.addEventListener('error', imageLoadedErr);
        img.src = image.value;
    });

    onBeforeUnmount(() => {
        console.log('before image button unmounted')
        img.removeEventListener('load', imageLoaded);
        img.removeEventListener('error', imageLoadedErr);
    });

    const token = ref('');
    watchEffect(async () => {
        if (store.getters.getIsOnline && store.getters.getPatientId && !token.value)
            token.value = await uploadService.getAzureToken(store.getters.getPatientId);
    });

    const latestMeasurement = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: props.measurementDefinitionId }]));

    const showImage = (image) => {
        console.log('SHOW IMAGE', image)
        showImagePopup({ imageSource: image });
    }

    const image = computed(() => {
        console.log('MV image button', latestMeasurement.value)

        //TODO: How to handle multiple image components in the measurement?

        const imgs = latestMeasurement.value?.measurementValue?.components.filter((c) => c.definition.displayType == 6);
        return imgs?.length && imgs[0].value ? imgs[0].value + '?' + token.value : '';
    });
</script>
