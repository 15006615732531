<template>
    <profile-image :profile-id="patientId" />
    <ul>
        <li><h5 data-test="patient-list-info-name">{{name}}</h5></li>
        <li>{{$t('handleBookingRequests.requestSentLabel')}}: <date-display :iso-date="date" display-as="date" /></li>
        <li v-if="!connected">
            <span>{{$t('handleBookingRequests.needAccessLabel')}}</span>
        </li>
    </ul>
</template>


<script>
    export default {
        name: 'PatientListInfo',
        props: {
            patientId: String,
            name: String,
            date: String,
            connected: Boolean,
        },
    }
</script>