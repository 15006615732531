<template>
    <div id="clinicianChangeEmail" class="overlay overlay--patient">
        <bar @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('clinicianChangeEmail.title')}}</b></h2>
                </div>

                <div class="col-xs-12 col-md-6 col-md-offset-3" v-if="step==1">
                    <h5 class="full-width center-text pb-small">{{$t('clinicianChangeEmail.requestSubtitle')}}</h5>

                    <div class="pt-large">
                        <text-box v-model="model.email"
                                  name="email"
                                  type="email"
                                  :required="true"
                                  :label="$t('clinicianChangeEmail.emailLabel')"
                                  :helper="$t('clinicianChangeEmail.emailHelper')"
                                  :placeholder="$t('clinicianChangeEmail.emailPlaceholder')"
                                  @updated="stateChanged" />

                        <button class="btn btn__text btn__text--green right" type="button"
                                :class="{ disabled : !isValid }"
                                :disabled="!isValid"
                                @click="requestChangeEmail">
                            <h4>{{$t('clinicianChangeEmail.requestButtonLabel')}}</h4>
                        </button>
                    </div>
                </div>

                <div class="col-xs-12 col-md-6 col-md-offset-3" v-if="step==2">
                    <h5 class="full-width center-text pb-small">{{$t('clinicianChangeEmail.verificationSubtitle')}}</h5>

                    <div class="pt-large">
                        <text-box v-model="model.token"
                                  name="token"
                                  :required="true"
                                  :label="$t('clinicianChangeEmail.verificationTokenLabel')"
                                  :helper="$t('clinicianChangeEmail.verificationTokenHelper')"
                                  :placeholder="$t('clinicianChangeEmail.verificationTokenPlaceholder')"
                                  @updated="stateChanged" />
                    </div>

                    <button class="btn btn__text btn__text--green right" type="button"
                            :class="{ disabled : !isValid }"
                            :disabled="!isValid"
                            @click="verifyChangeEmail">
                        <h4>{{$t('clinicianChangeEmail.verificationButtonLabel')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';

    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import { useClinicianService } from './ClinicianService';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const service = useClinicianService();
    const caller = useExceptionwrappedCaller();

    let model = ref({
        email: '',
        token: ''
    });

    let step = ref(1);

    const closeDialog = () => {
        router.push({ name: 'clinician data' })
    }

    const requestChangeEmail = async () => {
        await caller.call(async () => {
            await service.requestClinicianEmailChange(model.value.email);
            step.value = 2;
        });
    }

    const verifyChangeEmail = async () => {
        await caller.call(async () => {
            await service.verifyClinicianEmailChange(model.value.token);
            closeDialog();
        });
    }
</script>
