<template>
    <div id="notifications" :data-active="isActive">
        <bar :title="$t('notifications.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <template v-for="notification in notifications" :key="notification.notificationId">
                    <div class="notification" @click="navigate(notification)" :data-notification-type="notification.type">
                        <div class="notification__header">
                            <svg-icon :icon-name="notificationTypeSvg(notification.type)" class="notification__header--type" />
                            <div class="notification__header--title"><h4>{{notificationsTypeTranslation(notification.type)}}</h4></div>
                            <button class="notification__header--delete online-only" 
                                    @click.stop="removeUserNotification(notification.notificationId)"
                                    data-test="notification-delete-button">
                                <svg-icon icon-name="cross" />
                            </button>
                        </div>
                        <div class="notification__body">
                            <div class="notification__body--text">{{getTranslatedText(notification.text)}}</div>
                        </div>
                    </div>
                </template>
                <div class="none col-md-12" v-if="!notifications.length"><h5>{{$t('notifications.noNotifications')}}</h5></div>
            </div>
        </div>
        <div class="bottom">
            <div class="container full-height">
                <div class="col-xs-12 full-height">
                    <button class="clear online-only right-text" @click="clearUserNotifications" data-test="clear-all-button">
                        <h4>
                            {{$t('notifications.clearAllLabel')}}
                        </h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useUserNotificationsService } from '@/Components/Organizations/Notifications/NotificationsService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const NOTIFY_SCHEDULE = 2;
    const NOTIFY_ACCESS = 3;
    const NOTIFY_MESSAGE = 4;

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const caller = useExceptionwrappedCaller();
    const notificationsService = useUserNotificationsService();

    const isClinician = computed(() => store.getters.isClinician);
    const isPatient = computed(() => store.getters.isPatient);

    const notifications = computed(() => {
        let notifications = store.getters.getUserNotifications.map((i) => i);
        // sort notifications descending by date
        return notifications.sort((a, b) => a.date > b.date ? -1 : 1);
    });

    const isActive = computed(() => store.state.notificationIsOpen);

    const closeDialog = () => {
        store.commit('toggleNotifications', false);
    }

    router.afterEach(() => {
        if (isActive.value) {
            console.log("notifications.isActive=" + isActive.value + " and route change initiated");
            console.log("closing the open notifications tab");
            closeDialog();
        }
    });

    const removeUserNotification = async (notificationId) => {
        await caller.call(async () => {
            await notificationsService.removeUserNotification(notificationId);
        });
    }

    const clearUserNotifications = async () => {
        await caller.call(async () => {
            await notificationsService.clearUserNotifications();
        });
    }

    const notificationsTypeTranslation = (type) => {
        if (type == NOTIFY_SCHEDULE) {
            return t('notifications.ScheduleNotification');
        }
        else if (type == NOTIFY_ACCESS) {
            return t('notifications.patientAccess');
        }
        else if (type == NOTIFY_MESSAGE) {
            return t('notifications.messages');
        }
        else {
            return t('notifications.unknownType');
        }
    }

    const notificationTypeSvg = (type) => {
        if (type == NOTIFY_SCHEDULE) {
            // ScheduleNotification
            return "date";
        }
        else if (type == NOTIFY_ACCESS) {
            // patientAccess
            return "lock"
        }
        else if (type == NOTIFY_MESSAGE) {
            // message
            return "mail"
        }
        else {
            return "generic";
        }
    }

    const getTranslatedText = (text) => {
        let txtKey = "notifications." + text.replace(/\s+/g, "_");
        return t(txtKey);
    }

    const navigate = async (notification) => {
        console.log('navigate', notification);
        let removeNotification = false;
        if (notification.type == NOTIFY_SCHEDULE) {
            if (isClinician.value) {
                removeNotification = true;
                router.push({ name: 'clinician booking requests' });
            }
            else if (isPatient.value) {
                removeNotification = true;
                let tabNo = 0;
                if (notification.text.includes('rejected')) tabNo = 2;  // TODO: This must be handled differently, as the text may be translated
                router.push({ name: 'appointments', query: { tabNumber: tabNo } });
            }
        }
        else if (notification.type == NOTIFY_ACCESS) {
            if (isClinician.value) {
                removeNotification = true;
                router.push({ name: 'mypatients' });
            }
            else if (isPatient.value) {
                removeNotification = true;
                router.push({ name: 'myclinicians' });
            }
        }
        else if (notification.type == NOTIFY_MESSAGE) {
            if (isClinician.value) {
                removeNotification = true;
                router.push({ name: 'clinic conversations' });
            }
            else if (isPatient.value) {
                removeNotification = true;
                router.push({ name: 'patient conversations' });
            }
        }
        if (removeNotification)
            await removeUserNotification(notification.notificationId);
    }
</script>