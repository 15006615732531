<template>
    <span class="bool-display">{{formattedText}}</span>
</template>

<script>
    import { computed } from 'vue';

    export default {
        name: 'BoolDisplay',
        props: {
            value: Boolean,
            trueLabel: {
                type: String,
                default: '',
            },
            falseLabel: {
                type: String,
                default: '',
            },
        },
        setup(props) {
            const formattedText = computed(() => {
                try {
                    return props.value ? props.trueLabel : props.falseLabel;
                }
                catch (e) {
                    return '';
                }
            });

            return {
                formattedText,
            }
        }
    }
</script>