
const get = (key) => {
    let local = sessionStorage.getItem(key);

    if (local && local != "undefined")
        return JSON.parse(local)
    else 
        return null;
}

const set = (key, data) => sessionStorage.setItem(key, JSON.stringify(data));

const remove = (key) => sessionStorage.removeItem(key);


export default {
    name: "SessionStorageProxy",
    get,
    set,
    remove
}
