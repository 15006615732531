export default class Exception extends Error {
    constructor(message, hint, code) {
        super(message);
        this.name = "Exception";
        this.hint = hint;
        this.code = code;
    }
}
//function Exception(message, hint, code) {
//    this.message = message;
//    this.hint = hint;
//    this.code = code;
//}

//export { Exception }