<template>
    <bar :title="viewTitle"
         :by="clinicianName" :by-label="$t('editMeasurement.changedByLabel')"
         :date="measurement?.date" :date-label="$t('editMeasurement.changedDateLabel')"
         @close="closeDialog"></bar>

    <div class="container" v-if="measurement">
        <div class="row">
            <div class="col-xs-12">
                <div class="table-info">
                    <template v-if="measurement.measurementValue.value.definition.displayType != 4">
                        <edit-measurement-component v-bind="measurement.measurementValue.value"></edit-measurement-component>
                    </template>
                    <template v-for="component in measurement.measurementValue.components" :key="component.definition.codingCode">
                        <edit-measurement-component v-bind="component"></edit-measurement-component>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import EditMeasurementComponent from '@/Components/HealthData/Measurements/Types/EditMeasurementComponent.vue'

    import { computed, defineEmits, defineProps } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';

    const props = defineProps({
        id: String
    });
    const emit = defineEmits(['canceled']);

    const store = useStore();
    const { t } = useI18n();

    console.log('GENERIC EDIT - ID', props.id);

    const measurement = computed(() => store.getters.getMeasurement(props.id));
    const clinicianName = computed(() => [measurement.value?.clinicianGivenNames, measurement.value?.clinicianFamilyName].join(' '));

    const viewTitle = computed(() => {
        return measurement.value?.measurementValue?.titleKey
            ? t('editMeasurement.title') + ' ' + t(measurement.value.measurementValue.titleKey + '')
            : t('editMeasurement.title');
    });

    const closeDialog = () => {
        emit('canceled');
    }
</script>
