<template>
    <div class="col-xs-12">
        <form>
            <div class="row">
                <div class="col-xs-6">
                    <date-time v-model="m.date"
                               type="date"
                               name="date"
                               :required="true"
                               @updated="stateChanged" 
                               data-test="bookingrequest-date"/>
                </div>
                <div class="col-xs-6">
                    <date-time v-model="m.time"
                               type="time"
                               name="time"
                               :required="true"
                               @updated="stateChanged" 
                               data-test="bookingrequest-time"/>
                </div>
                <div v-if="!hasAccess" class="col-xs-12">
                    <check-box v-model="m.requestAccess"
                               type="switch"
                               name="requestAccess"
                               :label="$t('createBookingRequest.requestPatientAccessLabel')" />
                </div>
            </div>
        </form>
        <hr />
    </div>

    <div class="col-xs-12">
        <button type="button"
                class="btn btn__text btn__text--green-border left"
                @click="cancel"
                data-test="bookingrequest-cancelinvitation">
            <h4>{{$t('general.cancel')}}</h4>
        </button>

        <button type="button"
                class="btn btn__text btn__text--green right online-only"
                :class="{disabled: !isValid||working}"
                :disabled="!isValid||working"
                @click="scheduleBooking"
                data-test="bookingrequest-sendinvitation">
            <h4>{{$t('handleBookingRequests.sendInvitationButton')}}</h4>
        </button>
    </div>
</template>


<script setup>
    import DateTime from '@/Components/Shared/Form/DateTime';
    import CheckBox from '@/Components/Shared/Form/CheckBox';

    import { computed, defineEmits, defineProps, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useBookingService } from '../Booking/BookingService.js';
    import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        request: Object,
    });
    const emit = defineEmits(['cancel']);

    const store = useStore();
    const router = useRouter();
    const bookingService = useBookingService();
    const patientAccessService = usePatientAccessService();
    const caller = useExceptionwrappedCaller();
    let { stateChanged, isValid } = useFormValidator();

    const hasAccess = computed(() => store.getters.clinicianHasAccessToPatient(props.request.patientId));

    console.log('Schedule form loading', props, hasAccess.value);

    let m = reactive({
        date: '',
        time: '',
        requestAccess: !hasAccess.value,
    });

    let working = ref(false);

    const scheduleBooking = async () => {
        if (!working.value) {
            await caller.call(async () => {
                working.value = true;
                const dt = new Date(m.date + 'T' + m.time);
                await bookingService.createBooking(
                    props.request.clinicId,
                    props.request.patientId,
                    props.request.bookingRequestId,
                    dt.toISOString(),
                    m.requestAccess);
                await patientAccessService.refreshPatientAccessForClinician();
                router.push({ name: 'clinician booking requests' });
            });
        }
    }

    const cancel = () => emit('cancel');
</script>