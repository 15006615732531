<template>
    <div class="col-xs-12">
        <div v-for="entry in yearEntries" :key="entry.id" class="record" @click="toggleActive(entry)">
            <!-- TODO : Need click active class !-->
            <div class="record__icon" data-type="diagnosis">
                <button class="btn btn__round btn__round--large btn__round--green-light"
                        :class="getTypesName(entry.entryType)"
                        @click="entryClicked(entry.entryType, entry.type)">
                    <svg-icon :icon-name="getTypesName(entry.entryType)" />
                </button>
            </div>
            <div class="record__info">
                <div class="record__info--date">
                    <date-display :iso-date="entry.entryDate" display-as="datetime" />
                </div>
                <div class="record__info--type">
                    <h2><b>{{ entry.title }}</b></h2>
                </div>
                <div class="record__info--data" :class="{active: entry.active}">
                    <p><span>{{$t('listRecordEntries.addedByLabel')}} : {{ getClinicianName(entry) }}</span></p>
                </div>
                <button v-if="isEntryType(0,entry)" class="more btn btn__icon btn__icon--small" :class="{active: entry.active}">
                    <svg-icon icon-name="chevron" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, defineEmits, defineProps } from 'vue';
    import { useStore } from 'vuex';

    const props = defineProps({
        entries: Array,
        year: Number,
    });
    const emit = defineEmits(['clicked']);

    const store = useStore();

    const isClinician = computed(() => store.getters.isClinician);

    const entryClicked = (type, id) => {
        emit('clicked', type, id);
    };

    let toggleActive = (entry) => {
        entry.active = !entry.active;
    }

    const getTypesName = (typeId) => {
        //let namez = ['Note', 'Diagnosis', 'Medication', 'Immunization', 'Observation', 'ConsultationNote'];
        let namez = ['Note', 'Diagnosis', 'Medication', 'Immunization', 'Note', 'Note', 'EpisodesOfCare'];
        return namez[typeId].toLowerCase();
    }

    const getClinicianName = (entry) => {
        return entry
            ? [entry.clinicianGivenNames, entry.clinicianFamilyName].join(' ')
            : '';
    }

    const isEntryType = (wantedType, entry) => {
        return entry.entryType == wantedType;
    }

    let yearEntries = computed(() => {
        let tmp = props.entries
            .filter((e) => new Date(e.entryDate).getFullYear() == props.year)
            .map((e) => {
                let ne = {
                    ...e,
                    active: isClinician.value || e.entryType != 0
                };
                return ne;
            });
        return tmp.sort((a, b) => new Date(a.entryDate) < new Date(b.entryDate) ? 1 : -1);
    });
</script>
