import dispatcher from '@/Connections/Dispatcher.js'
import Exception from '@/Components/Shared/Exceptions.js';
import * as strings from '@/Shared/Strings.js';
import api from './UploadApi.js';

const patientId = () => dispatcher.getFromLocal(strings.PATIENTID);
const clinicianId = () => dispatcher.getFromLocal(strings.CLINICIANID);

const environmentBlobUrl = `${process.env.VUE_APP_AZURE_IMAGE_BLOB_STORAGE}`;

const getAudioFileUrl = async (fileName) => {
    console.log('Getting audio file url');
    //let enviromentBlobUrl = dispatcher.getFromLocal(strings.ENVIROMENTBLOB);
    //let token = dispatcher.getFromLocal(strings.BLOBTOKEN);
    //let enviromentBlobUrl = `${process.env.VUE_APP_AZURE_IMAGE_BLOB_STORAGE}`;
    let token = await getAzureToken(patientId());
    let audioString = environmentBlobUrl + "/" + patientId() + "/" + fileName + "?" + token;
    console.log('audio string:', audioString);
    return audioString;
}

const getAzureToken = async (patientId, scope, clinicId) => {
    scope = scope || 'personal';
    return await api.getAzureToken(patientId, scope, clinicId);
}

const uploadFile = async (file, userType, fileName, isPrivate, pId) => {
    console.log('upload service initialized ...');
    pId = pId || patientId();
    let id = (userType == 'patient') ? pId : clinicianId();
    let fileType = file.type.split('/')[1];
    fileType = fileType.split(";")[0];

    if (fileName == '' || fileName == null) {
        fileName = createGuid();
	}

    const base64file = await getBase64(file);
    return await api.uploadBase64File(id, base64file, fileName, fileType, isPrivate);
//    let uploadObject = {
//        id: id,
//        Base64: await getBase64(file),
//        fileName: fileName,
//        fileExtension: fileType,
//        isPrivate: isPrivate
//    };

//    //let result = await dispatcher.postToApi(`azure/upload/`, uploadObject);
//    let result = await dispatcher.postToApi(`azure/upload/`, uploadObject);

//    if (result.status > 299) 
//        throw new Exception('Error while uploading');

//    console.log("server returned :" + result.data);
//    return result.data;
}

// Uploads a file to Azure BLOB while generating a new filename for the file (Guid)
const uploadFileName = async (file, userType, isPrivate) => {
    console.log('upload service initialized ...', file, userType, isPrivate);
    let fileName = createGuid();
    let id = (userType == 'patient') ? patientId() : clinicianId();
    let fileType = file.type.split(';')[0];
    fileType = fileType.split("/")[1];

    const base64file = await getBase64(file);
    const result = await api.uploadBase64File(id, base64file, fileName, fileType, isPrivate);

    //let uploadObject = {
    //    id: id,
    //    Base64: await getBase64(file),
    //    fileName: fileName,
    //    fileExtension: fileType,
    //    isPrivate: isPrivate
    //};

    ////let result = await dispatcher.postToApi(`azure/upload/`, uploadObject);
    //let result = await dispatcher.postToApi(`azure/upload/`, uploadObject);

    //if (result.status > 299)
    //    throw new Exception('Error while uploading');

    //console.log("server returned :" + result.data);

    // extract the resulting file extension from the returned filepath
    var fileParts = result.split('.');
    if(fileParts && fileParts.length > 1)
        fileType = fileParts[fileParts.length - 1];

    return fileName + "." + fileType;
}

// Ensures a blob of data is Base64 encoded and uploads it to Azure Blob Storage. 
// The filename is generated as a Guid.
const uploadBase64DataUrl = async (dataUrl, userType, userTypeId, isPrivate) => {
    console.log('upload blob service initialized ...');

    // some guards to ensure basic data validity...
    if (!dataUrl || !dataUrl.trim().startsWith('data:'))
        throw new Exception('dataUrl is required to have content and be of format "data:<header>,<data>"');

    if (!userType || '|patient|clinician|'.indexOf('|' + userType + '|') < 0)
        throw new Exception('userType is required to be patient or clinician');

    isPrivate = isPrivate || true;

    // then some splitting of the blob
    let fileName = createGuid();
    let id = userTypeId || ((userType == 'patient') ? patientId() : clinicianId());

    let header = dataUrl.split(',')[0];
    let data = dataUrl.split(',')[1];
    let mimeType = header.indexOf(';') >= 0 ? header.split(';')[0] : 'image/png';
    let encType = header.indexOf(';') >= 0 ? header.split(';')[1] : '';
    let fileExtension = mimeType.length > 0 ? mimeType.split('/')[1] : 'png';
    let base64Blob = encType == 'base64'
        ? dataUrl
        : `data:${mimeType};base64,${btoa(unescape(encodeURIComponent(data)))}`;

    return await api.uploadBase64File(id, base64Blob, fileName, fileExtension, isPrivate);

    // prepare the upload data structure
//    let uploadObject = {
//        id,
//        base64: base64Blob,
//        fileName,
//        fileExtension,
//        isPrivate
//    };

//    let result = await dispatcher.postToApi('azure/upload/', uploadObject);

//    if (result.status > 299)
//        throw new Exception('Error while uploading');

//    console.log("server returned :" + result.data);

//    return result.data;
}

function getBase64 (file) {
    // Returns a promise which gets resolved or rejected based on the reader events
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // Sets up even listeners BEFORE you call reader.readAsDataURL
        reader.onload = function () {
            const result = reader.result
            return resolve(result);
        };

        reader.onerror = function (error) {
            return reject(error);
        };
        // Calls reader function
        reader.readAsDataURL(file);
    })
}

// alternative for createGuid
function createGuid() {
    const url = URL.createObjectURL(new Blob());
    const [id] = url.toString().split('/').reverse();
    URL.revokeObjectURL(url);
    return id;
}

//function createGuid() {
//    var result, i, j;
//    result = '';
//    for (j = 0; j < 32; j++) {
//        if (j == 8 || j == 12 || j == 16 || j == 20)
//            result = result + '-';
//        i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
//        result = result + i;
//    }
//    return result;
//}

export default {
    name: 'UploadService',
    environmentBlobUrl,
    getAudioFileUrl,
    getAzureToken,
    uploadFile,
    uploadFileName,
    uploadBase64DataUrl,
    getBase64,
    createGuid,
}