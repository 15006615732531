<template>
    <div class="overlay overlay--patient" id="editMedication">
        <bar :title="$t('editMedication.title')"
             :by="clinicianName" :by-label="$t('editMedication.changedByLabel')"
             :date="date" :date-label="$t('editMedication.changedDateLabel')"
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info">
                        <div class="content">
                            <div class="col-xs-4 no-padding code">
                                <div class="title">
                                    {{$t('editMedication.codeLabel')}}
                                </div>
                                <div class="value">
                                    {{codingCode}}
                                </div>
                            </div>
                            <div class="col-xs-8 no-padding">
                                <div class="title">
                                    {{$t('editMedication.descriptionLabel')}}
                                </div>
                                <div class="value">
                                    {{codingText}}
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editMedication.drugLabel')}}
                            </div>
                            <div class="value">
                                {{drug}}
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editMedication.simpleOrdinationLabel')}}
                            </div>
                            <div class="value">
                                {{simpleOrdination}}
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editMedication.instructionLabel')}}
                            </div>
                            <div class="value" :class="{active: instructionEditor}">
                                {{instruction}}
                            </div>
                            <div v-if="isClinician" class="edit" :class="{active: !instructionEditor}">
                                <text-area v-model="instructionEdit"
                                           name="instruction"
                                           :errorText="$t('editMedication.instructionErrorText')"
                                           :helper="$t('editMedication.instructionHelperText')"
                                           :placeholder="$t('editMedication.instructionPlaceholder')"
                                           @updated="stateChanged" />
                                <div class="confirm-buttons">
                                    <button class="btn btn__text btn__text--green btn__text--short right" @click="changeInstruction"><h4>{{$t('general.save')}}</h4></button>
                                    <button class="btn btn__text btn__text--red-border btn__text--short left" @click="toggleInstructionEditor"><h4>{{$t('general.cancel')}}</h4></button>
                                </div>
                            </div>
                            <div class="edit-icons online-only" v-if="isClinician && !instructionEditor">
                                <button class="btn btn__round btn__round--medium btn__round--red-outline" @click="toggleInstructionEditor">
                                    <div class="svg-container">
                                        <svg-icon icon-name="pen" />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('general.audio')}}
                            </div>
                            <div class="row">
                                <media-player :audio="audioInstruction" @toggle="toggleRecording" :record="isClinician" @remove="deleteAudio"></media-player>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <media-recorder :class="{active : audioOverlayActive}" userType="patient" @uploadGuid="updateAudio" @close="toggleRecording"></media-recorder>
    </div>
</template>

<script setup>
    import TextArea from '@/Components/Shared/Form/TextArea.vue';
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';
    import MediaRecorder from '@/Components/Shared/Form/MediaRecorder.vue';

    import { computed, defineProps, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';

    import { useMedicationService } from '@/Components/Medications/Medication/MedicationService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        id: String,
    });

    const store = useStore();
    const router = useRouter();
    const { stateChanged } = useFormValidator();
    const medicationService = useMedicationService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);
    //const currentClinicianName = computed(() => store.getters.getClinicianName);

    let clinicianId = ref('');
    let codingCode = ref('');
    let codingText = ref('');
    let drug = ref('');
    let dosage = ref('');
    let duration = ref('');
    let frequency = ref('');
    let quantity = ref('');
    let instruction = ref('');
    let instructionEdit = ref('');
    let simpleOrdination = ref('');
    let date = ref(null);
    let patientName = ref('');
    let clinicianName = ref('');

    let instructionEditor = ref(false);

    let audioOverlayActive = ref(false);
    let audioInstruction = ref('');


    const toggleRecording = () => audioOverlayActive.value = !audioOverlayActive.value;

    const toggleInstructionEditor = () => {
        // revert changes to instruction when closing editor with cancel
        console.log('toggle instruction editor', instruction.value);
        instructionEdit.value = instruction.value;
        instructionEditor.value = !instructionEditor.value;
    }

    watchEffect(() => {
        let medication = store.getters.getMedication(props.id);
        if (!medication) return;

        clinicianId.value = medication.clinicianId;
        codingCode.value = medication.codingCode;
        codingText.value = medication.codingText;
        drug.value = medication.drug;
        dosage.value = medication.dosage;
        duration.value = medication.duration;
        frequency.value = medication.frequency;
        quantity.value = medication.quantity;
        instruction.value = medication.instruction;
        instructionEdit.value = medication.instruction;
        simpleOrdination.value = medication.simpleOrdination;
        date.value = medication.date;
        patientName.value = [medication.patientGivenNames, medication.patientFamilyName].join(' ');
        clinicianName.value = [medication.clinicianGivenNames, medication.clinicianFamilyName].join(' ');
        audioInstruction.value = medication.audioInstruction;
    });

    const closeDialog = () => router.push({ name: 'medication' });

    const updateAudio = async (newAudio) => {
        await caller.call(async () => {
            await medicationService.changeMedicationAudioInstruction(props.id, newAudio);
            audioInstruction.value = newAudio;
        });
    }

    const deleteAudio = async () => {
        await caller.call(async () => {
            await medicationService.deleteMedicationAudioInstruction(props.id);
            audioInstruction.value = '';
        });
    }

    const changeInstruction = async () => {
        await caller.call(async () => {
            console.log('change instruction', instructionEdit.value);
            await medicationService.changeMedicationInstruction(props.id, instructionEdit.value);
            instruction.value = instructionEdit.value
            toggleInstructionEditor();
        });
    };
</script>