<template>
    <div class="container pb-medium online-only" id="vitalsigns-summary">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(HEIGHTKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.heightButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(WEIGHTKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.weightButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(BLOODPRESSUREKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.bloodPressureButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(TEMPERATUREKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.temperatureButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(BLOODSUGARKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.bloodSugarButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(PULSERATEKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.pulseRateButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-3">
                    <div class="measurement" @click="showCreateMeasurement(RESPIRATORYRATEKEY)">
                        <div class="measurement__container">
                            <div class="center-y">
                                <span>{{$t('vitalSignsSummary.respiratoryRateButton')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import {
        BLOODPRESSUREKEY,
        BLOODSUGARKEY,
        TEMPERATUREKEY,
        WEIGHTKEY,
        HEIGHTKEY,
        PULSERATEKEY,
        RESPIRATORYRATEKEY
    } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';

    const store = useStore();
    const router = useRouter();

    const isClinician = computed(() => store.getters.isClinician);

    const showOtherMeasurements = async () => {
        if (isClinician.value)
            await router.push({ name: 'list other measurements' });
    }

    const showCreateMeasurement = async (id) => {
        if (isClinician.value) {
            await router.push({
                name: 'create vs-measurement component',
                params: {
                    measurementDefinitionId: id
                },
                query: {
                    returnToName: 'vitalsigns'
                }
            });
        }
    }
</script>
