<template>
    <div class="pin-user" v-if="username">
        <div class="pin-user__image">
            <profile-image :profile-id="userId" :alt="userImageAltText" />
        </div>
        <div class="pin-user__name">
            <span>{{username}}</span>
        </div>
    </div>
</template>

<script setup>
    import { computed, defineProps, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useUserService } from './UserService.js';

    const props = defineProps({
        userId: String
    });

    const store = useStore();
    const userService = useUserService();

    const userId = computed(() => props.userId || store.getters.getUserId);
    const username = computed(() => {
        const user = userService.getSavedUserByUserId(userId.value);
        return user?.name;
    });
    const userImageAltText = ref('users profile image');
</script>