<template>
    <span class="date-display">{{formattedDate}}</span>
</template>

<script>
    import { computed } from 'vue';

    export default {
        name: 'DateDisplay',
        props: {
            isoDate: {
                type: [String, Date],
            },
            displayAs: {
                type: String, 
                default: 'datetime',
                validator(value) {
                    return ['date', 'datetime', 'time', 'shorttime'].includes(value);
                }
            },
            textWhenEmpty: {
                type: String,
                default: ''
            }
        },
        setup(props) {
            const formattedDate = computed(() => {
                try {
                    if (!props.isoDate)
                        return props.textWhenEmpty;

                    //console.log('DateDisplay:', '['+props.isoDate+']', props.isoDate instanceof Date, props.isoDate instanceof String);
                    let realDate = new Date(props.isoDate);

                    switch (props.displayAs) {
                        case "date": return realDate.toLocaleDateString();
                        case "datetime": return realDate.toLocaleString();
                        case "time": return realDate.toLocaleTimeString();
                        case "shorttime": return realDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        default: return '';
                    }
                }
                catch (e) {
                    console.error(e.message || e);
                }
                return '';
            });

            return {
                formattedDate,
            }
        }
    }
</script>