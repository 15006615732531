<template>
    <div class="overlay overlay--dashboard">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="col-xs-12">
                <h2 class="full-width center-text pb-small"><b>{{$t('mySchedule.title')}}</b></h2>
                <h5 class="full-width center-text pb-small">{{$t('mySchedule.subTitle')}}</h5>
                <div class="search">
                    <input :placeholder="$t('mySchedule.findPatientPlaceholder')"
                           v-model="filter"
                           @keyup.enter="refreshList" />
                    <svg-icon icon-name="search" />
                    <button class="btn btn__round btn__round--large btn__round--green" type="button" @click="refreshList">
                        <div class="svg-container">
                            <svg-icon icon-name="refresh" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xs-12" v-for="booking in filteredBookings" :key="booking.id">
                    <div class="user" :class="{inactive : booking.bookingState == 0}">
                        <div class="data">
                            <div class="info">
                                <profile-image :profileId="booking.patientId" />
                                <ul>
                                    <li>
                                        <span class="status" v-if="booking.bookingState != 0">{{$t('mySchedule.appointment')}}</span>
                                        <span class="status" v-if="booking.bookingState == 0">{{$t('mySchedule.appointmentCancelled')}}</span>
                                    </li>
                                    <li><date-display :iso-date="booking.bookingDate" display-as="datetime" /></li>
                                    <li>{{booking.patientGivenNames}} {{booking.patientFamilyName}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { makeRegexSafe } from '@/Components/Shared/RegexUtils.js';
    import { useBookingService } from '@/Components/Consultations/Booking/BookingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const bookingService = useBookingService();
    const caller = useExceptionwrappedCaller();

    let filter = ref('');
    let filteredBookings = ref([]);

    let bookings = computed(() => store.getters.getClinicianBookings);

    watchEffect(async () => {
        if (!store.getters.getClinicianId) return;
        await caller.call(async () => {
            await bookingService.refreshClinicianBookings();

            console.log('setting up bookings in list', bookings.value);
            filteredBookings.value = bookings.value.map((p) => {
                return {
                    id: p.bookingId,
                    clinicId: p.clinicId,
                    clinicianId: p.clinicianId,
                    patientId: p.patientId,
                    patientGivenNames: p.patientGivenNames,
                    patientFamilyName: p.patientFamilyName,
                    image: p.image,
                    bookingState: p.bookingState,
                    bookingDate: p.bookingDate,
                }
            });
        });
    });

    watch(() => [filter.value, bookings.value], () => {
        let termslist = (filter.value || '').replace(/\s+/gi, ' ');
        termslist = makeRegexSafe(termslist);
        let terms = termslist.replace(' ', '|');
        console.log('filter changed', terms);
        try {
            let rx = new RegExp('(' + terms + ')', 'gi');
            filteredBookings.value = bookings.value
                .filter((v) => {
                    rx.lastIndex = 0;
                    let givenTest = rx.test(v.patientGivenNames);
                    rx.lastIndex = 0;
                    let familyTest = rx.test(v.patientFamilyName);

                    console.log('filtering', v.patientGivenNames, givenTest, familyTest);

                    return givenTest || familyTest;
                })
                .map((p) => {
                    return {
                        id: p.bookingId,
                        clinicId: p.clinicId,
                        clinicianId: p.clinicianId,
                        patientId: p.patientId,
                        patientGivenNames: p.patientGivenNames,
                        patientFamilyName: p.patientFamilyName,
                        image: p.image,
                        bookingState: p.bookingState,
                        bookingDate: p.bookingDate,
                    }
                });
        }
        catch (e) {
            console.error(e);
            // do nothing
        }
    });

    const refreshList = async () => {
        await caller.call(async () => {
            await bookingService.refreshClinicianBookings();
        });
    }

    const closeDialog = () => router.push({ name: 'dashboard' });
</script>