<template>
    <form autocomplete="off">
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" v-if="label" />
        <input type="text" v-model="search" :placeholder="placeholder" :data-state="dataState"  formnovalidate />
    </form>
    <div v-if="handleresults" class="table-layout folded" :class="{open: results.length}">
        <div class="body" :class="{expand : results.length}">
            <div class="item" v-for="result in results" :key="result.clinicId" @click="chooseClinic(result)">
                <div>{{result.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue';
    import Label from './Label.vue';
    import { EmptyString } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';
    import { useClinicService } from '@/Components/Organizations/Clinic/ClinicService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    export default {
        name: 'ClinicSearch',
        components: {
            Label,
        },
        props: {
            modelValue: EmptyString,
            placeholder: EmptyString,
            label: EmptyString,
            handleresults: {    // use internal result list to show results (true) or emit an event with results (false)
                type: Boolean,
                default: true
            },    
            errorText: EmptyString,
            helper: EmptyString,
            name: {
                type: String,
                default: '_' + Math.floor(Math.random() * 1000000000)
            },
            type: {
                type: String,
                default: 'guid',
                validator: (value) => value == 'guid'
            }
        },
        emits: ['update:modelValue','updated','results', 'searchTerm'],
        setup(props, context) {
            const service = useClinicService();
            const caller = useExceptionwrappedCaller();
            const { value, error, dataState } = useInputValidator(props, context);

            let results = ref([]);
            let search = ref('');
            let selected = ref('');
            let newSearch = ref(true);

            watch(() => search.value, async (nv) => {
                context.emit('searchTerm', nv);

                let foundClinics = [];
                if (selected.value != nv) {
                    value.value = '';
                    newSearch.value = true;
                    if (nv != '') {
                        await caller.call(async () => {
                            foundClinics = await service.findClinics(nv);
                        });
                    }
                }

                if (props.handleresults)
                    results.value = foundClinics;
                else
                    context.emit('results', foundClinics);
            });

            const chooseClinic = (clinic) => {
                newSearch.value = false;
                value.value = clinic.clinicId;
                selected.value = clinic.name;
                search.value = clinic.name;
            }

            return {
                search,
                error,
                dataState,
                results,
                chooseClinic,
            };
        }
    }
</script>