import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

const createOrganization = async (
    name,
    address,
    country,
    email,
    phone,
    diagnosisCodeSystem,
    diagnosisCodeSystemDisplay,
    diagnosisCodeSystemRoots,
    medicationCodeSystem,
    medicationCodeSystemDisplay,
    medicationCodeSystemRoots,
    localMedicationCodeSystem,
    localMedicationCodeSystemDisplay,
    localMedicationCodeSystemRoots,
    immunizationCodeSystem,
    immunizationCodeSystemDisplay,
    immunizationCodeSystemRoots) =>
{
    let organization = {
        name: name,
        address: address,
        country: country,
        email: email,
        phone: phone,
        diagnosisCodeSystem: diagnosisCodeSystem,
        diagnosisCodeSystemDisplay: diagnosisCodeSystemDisplay,
        diagnosisCodeSystemRoots: diagnosisCodeSystemRoots.split(','),
        medicationCodeSystem: medicationCodeSystem,
        medicationCodeSystemDisplay: medicationCodeSystemDisplay,
        medicationCodeSystemRoots: medicationCodeSystemRoots.split(','),
        localMedicationCodeSystem: localMedicationCodeSystem,
        localMedicationCodeSystemDisplay: localMedicationCodeSystemDisplay,
        localMedicationCodeSystemRoots: localMedicationCodeSystemRoots.split(','),
        immunizationCodeSystem: immunizationCodeSystem,
        immunizationCodeSystemDisplay: immunizationCodeSystemDisplay,
        immunizationCodeSystemRoots: immunizationCodeSystemRoots.split(','),
    };

    let result = await dispatcher.postToApi('organizations', organization);
    handleApiError(result, 'Error while creating organization.');

    return result.data.organizationId;
}

const findOrganizations = async (searchTerm) => {
    let result = await dispatcher.getFromApi(`organizations/search?searchTerm=${searchTerm}`);
    handleApiError(result, 'Error while searching for organization.');

    return result.data.organizations;
}

const getOrganizations = async (ids) => {
    let result = await dispatcher.postToApi(`organizations/get`, { organizationIds: ids });
    handleApiError(result, 'Error while fetching organization.');

    return result.data.organizations;
}

export default {
    name: "OrganizationService",
    createOrganization,
    findOrganizations,
    getOrganizations,
}