<template>
    <div class="overlay overlay--patient" id="createInvoice">
        <bar :title="$t('createPatientInvoice.title')" @close="cancelInvoice" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12 center-text">
                    <button type="button" @click="addInvoiceItem"
                            class="btn btn__text btn__text btn__text--green-border"
                            :class="{disabled:addingNewItem}" :disabled="addingNewItem"
                            data-test="add-new-button">
                        <h3>{{$t('createPatientInvoice.addNewItemButton')}}</h3>
                    </button>
                </div>
            </div>
        </div>
        <div class="container" v-if="isValid">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head row">
                            <div class="col-xs-6"><span><b>{{$t('createPatientInvoice.itemTextHeader')}}</b></span></div>
                            <div class="col-xs-5 right-text"><span><b>{{$t('createPatientInvoice.priceHeader')}}</b></span></div>
                            <div class="col-xs-1 center-text"><span><b><!--{{$t('createPatientInvoice.actionHeader')}}--></b></span></div>
                        </div>
                        <div class="body">
                            <div class="item row" v-for="(item,index) in model.invoiceItems" :key="index" data-test="invoice-item">
                                <div class="col-xs-6"><span>{{item.text}}</span></div>
                                <div class="col-xs-5 right-text"><span>{{$n(item.price, 'currency', locale)}}</span></div>
                                <div class="col-xs-1 center-text">
                                    <button type="button" 
                                            @click="deleteInvoiceItem(item)"
                                            data-test="delete-item-button">
                                        <div class="svg">
                                            <svg-icon icon-name="cross" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="item row total">
                                <div class="col-xs-6">
                                    <span>{{$t('createPatientInvoice.priceTotalLabel')}}</span>
                                </div>
                                <div class="col-xs-5 right-text">
                                    <span><b>{{$n(total, 'currency', locale)}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pt-large" v-if="isValid">
            <div class="row">
                <div class="col-xs-12">
                    <button type="button" @click="cancelInvoice" 
                            class="btn btn__text btn__text--green-border left"
                            data-test="cancel-invoice-button">
                        <h4>{{$t('general.cancel')}}</h4>
                    </button>
                    <button type="button" @click="finishInvoice" 
                            class="btn btn__text btn__text--green right" 
                            :class="{disabled:!isValid}" :disabled="!isValid"
                            data-test="finish-invoice-button">
                        <h4>{{$t('createPatientInvoice.finishInvoiceButton')}}</h4>
                    </button>
                </div>
            </div>
        </div>
        <create-invoice-item :addNewItem="addingNewItem" 
                             @save="invoiceItemSaved" 
                             @cancel="invoiceItemCanceled" />
    </div>
</template>


<script setup>
    import CreateInvoiceItem from './CreateInvoiceItem';

    import { computed, inject, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useInvoiceService } from '@/Components/Consultations/Invoice/InvoiceService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const invoiceService = useInvoiceService();
    const caller = useExceptionwrappedCaller();
    const confirmDialog = inject('confirmDialog');

    let model = reactive({
        invoiceItems: []
    });

    const locale = computed(() => store.getters.getClinicianLocale)

    const total = computed(() => model.invoiceItems.reduce((p, c) => p + c.price, 0.0));
    const isValid = computed(() => model.invoiceItems.length > 0);

    const closeDialog = () => router.push({ name: 'patientbilling' });

    let addingNewItem = ref(false);

    const addInvoiceItem = () => addingNewItem.value = true;
    const invoiceItemCanceled = () => addingNewItem.value = false;

    const invoiceItemSaved = (newItem) => {
        //TODO: show create item component...
        model.invoiceItems.push(newItem);
        addingNewItem.value = false;
    }


    const deleteInvoiceItem = async (item) => {
        const confirmed = await confirmDialog({
            header: t('createPatientInvoice.deleteInvoiceItemTitle'),
            body: t('createPatientInvoice.deleteInvoiceItemText'),
            accept: t('general.yes'),
            cancel: t('general.no'),
        });

        if (confirmed)
            model.invoiceItems = model.invoiceItems.filter((i) => i != item);
    }

    const finishInvoice = async () => {
        // confirm finish
        const confirmed = await confirmDialog({
            header: t('createPatientInvoice.approveInvoiceTitle'),
            body: t('createPatientInvoice.approveInvoiceText'),
            accept: t('createPatientInvoice.approveInvoiceFinishButton'),
        });

        if (confirmed) {
            // save to server and in store 
            await caller.call(async () => {
                await invoiceService.createPatientInvoice(model.invoiceItems);
                closeDialog();
            });
        }
    }

    const cancelInvoice = async () => {
        // confirm cancel
        const confirmed = await confirmDialog({
            header: t('createPatientInvoice.cancelInvoiceTitle'),
            body: t('createPatientInvoice.cancelInvoiceText'),
            accept: t('general.yes'),
            cancel: t('general.no'),
        });
        
        if (confirmed)
            closeDialog();
    }
</script>
