<template>
    <div class="overlay overlay--patient" id="editMeasurement">
        <router-view v-slot="{ Component }">
            <component :is="Component" @canceled="closeDialog" />
        </router-view>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue'
    import { useRouter } from 'vue-router';

    const props = defineProps({
        returnToName: String
    });

    const router = useRouter();

    const closeDialog = async () => await router.push({ name: props.returnToName || 'measurements' });
</script>