<template>
    <div class="loading" :data-active="isLoading">
        <div class="spinner">Loading...</div>
    </div>
</template>
<script>
    import { useStore } from 'vuex';
    import { computed } from 'vue';

    export default {
        name: 'Loading',
        setup() {
            const store = useStore();

            const isLoading = computed(() => {
                return store.state.isLoading;
            });

            return {
                isLoading
            }
        }
    }
</script>