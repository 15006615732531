<template>
    <div id="verifyEmailChange" class="overlay">
        <bar title="" @close="closeDialog"></bar>
        <div class="container">
            <div class="row">
                <div class="col-xs-12" v-if="state==VERIFYING">
                    <h3 class="full-width center-text pb-small">{{$t('verifyEmailChange.verifyingEmailChangeTitle')}}</h3>
                    <p class="center-text">{{$t('verifyEmailChange.verifyingEmailChangeSubtitle')}}</p>
                </div>

                <div class="col-xs-12" v-if="state==VERIFIED">
                    <h3 class="full-width center-text pb-small">{{$t('verifyEmailChange.emailVerifiedTitle')}}</h3>
                    <p class="center-text">{{$t('verifyEmailChange.emailVerifiedSubtitle')}}</p>
                </div>

                <div class="col-xs-12" v-if="state==FAILED">
                    <h3 class="full-width center-text pb-small">{{$t('verifyEmailChange.verificationFailedTitle')}}</h3>
                    <p class="center-text">{{$t('verifyEmailChange.verificationFailedSubtitle')}}</p>
                </div>

                <div class="col-xs-12 center-text pt-large">
                    <button type="button"
                            class="btn btn__text btn__text--green btn__text--medium center"
                            @click="$router.push({name:'dashboard'})">
                        <h4>{{$t('general.close')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        id: String,
        token: String
    });

    const router = useRouter();
    const service = useUserService();
    const caller = useExceptionwrappedCaller();

    const VERIFYING = 0;
    const VERIFIED = 1;
    const FAILED = 2;

    let state = ref(VERIFYING);

    const closeDialog = () => router.push({ name: 'welcome' });

    watchEffect(async () => {
        if (!props.id) return;
        //HACK! Because Vue Router unescapes + and turns it into space, we have to undo this
        //      since we need the raw token value to complete the confirmation...
        let urlToken = null;
        if (props.token)
            urlToken = props.token.replace(/\s+/g, '+');

        if (urlToken) {
            await caller
                .call(async () => {
                    await service.verifyEmailChange(props.id, urlToken);
                    state.value = VERIFIED;
                })
                .catch((e) => {
                    state.value = FAILED;
                });
        }
    });
</script>
