import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useMeasurementCategoryService = () => {
    const store = useStore();

    const loadMeasurementCategories = async () => {
        await serviceCall(
            async () => {
                const result = await dispatcher.getFromApi(`measurementcategories`);
                handleApiError(result, 'Error while getting measurement categories');
                const categories = result.data.measurementCategories;
                store.commit('setMeasurementCategories', categories);
            }
        );
    }

    return {
        loadMeasurementCategories
    }
}

