<template>
    <div id="dashboardLinkList" v-if="props.links && props.links.length > 0">
        <template v-for="link in props.links" :key="link.route">
            <div class="link-item">
                <a @click.prevent="navigateTo(link.route)" :data-test="`nav-clinician-${link.route.name.replaceAll(' ','-')}`">
                    <span v-if="props.showIcons" class="link-item-icon">
                        <svg-icon :icon-name="link.iconName" height="30px" width="30px" />
                    </span>
                    <h4>
                        {{$t(link.titleKey)}}
                        <span v-if="link.count && link.count > 0" class="counter hasNotifications">
                            <span>({{link.count}})</span>
                        </span>
                    </h4>
                    <span class="link-indicator">
                        <svg-icon icon-name="chevron" width="18px" height="18px" />
                    </span>
                </a>
            </div>
        </template>
    </div>
</template>

<script setup>
    import { defineEmits, defineProps } from 'vue';
    import { useRouter } from 'vue-router';

    /* 
    Receives an array with definitions of links and meta-data for these links.
    Example:
    [
        {
            route: { name: 'patient', params: { id: '12345' }},     // vue-router route-record
            count: null,                                            // if set, the number is shown in parenthesies after the title
            titleKey: 'clinicianDashboard.bookingRequestsButton',   // translationkey for a title specified in a vue-i18n language-file
            iconName: 'letter',                                     // name of SVG-icon (from SvgIcons.vue) - not shown when showIcons property is set to false
        },
    ]
    */
    const props = defineProps({
        links: Array,
        showIcons: { type: Boolean, default: true }
    });
    const emit = defineEmits(['navigated']);

    const router = useRouter();

    const navigateTo = (route) => {
        emit('navigated');
        router.push(route);
    }
</script>