<template>
    <div id="clinicianChangePhone" class="overlay overlay--patient">
        <bar @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('clinicianChangePhone.title')}}</b></h2>
                </div>

                <div class="col-xs-12 col-md-6 col-md-offset-3" v-if="step==1">
                    <h5 class="full-width center-text pb-small">{{$t('clinicianChangePhone.requestSubtitle')}}</h5>

                    <div class="pt-large">
                        <phone-number v-model="model.phone"
                                      name="phone"
                                      :required="true"
                                      :label="$t('clinicianChangePhone.phoneLabel')"
                                      :helper="$t('clinicianChangePhone.phoneHelper')"
                                      :placeholder="$t('clinicianChangePhone.phonePlaceholder')"
                                      @updated="stateChanged" />
                    </div>

                    <button class="btn btn__text btn__text--green right" type="button"
                            :class="{ disabled : !isValid }"
                            :disabled="!isValid"
                            @click="requestChangePhone">
                        <h4>{{$t('clinicianChangePhone.requestButtonLabel')}}</h4>
                    </button>
                </div>

                <div class="col-xs-12 col-md-6 col-md-offset-3" v-if="step==2">
                    <h5 class="full-width center-text pb-small">{{$t('clinicianChangePhone.verificationSubtitle')}}</h5>

                    <div class="pt-large">
                        <text-box v-model="model.token"
                                  name="token"
                                  :required="true"
                                  :label="$t('clinicianChangePhone.verificationTokenLabel')"
                                  :helper="$t('clinicianChangePhone.verificationTokenHelper')"
                                  :placeholder="$t('clinicianChangePhone.verificationTokenPlaceholder')"
                                  @updated="stateChanged" />
                    </div>

                    <button class="btn btn__text btn__text--green right" type="button"
                            :class="{ disabled : !isValid }"
                            :disabled="!isValid"
                            @click="verifyChangePhone">
                        <h4>{{$t('clinicianChangePhone.verificationButtonLabel')}}</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import PhoneNumber from '@/Components/Shared/Form/PhoneNumber.vue';

    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import { useClinicianService } from './ClinicianService';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const service = useClinicianService();
    const caller = useExceptionwrappedCaller();

    let model = ref({
        phone: '',
        token: ''
    });

    let step = ref(1);

    const closeDialog = () => {
        router.push({ name: 'clinician data' })
    }

    const requestChangePhone = async () => {
        await caller.call(async () => {
            await service.requestClinicianPhoneChange(model.value.phone);
            step.value = 2;
        });
    }

    const verifyChangePhone = async () => {
        await caller.call(async () => {
            await service.verifyClinicianPhoneChange(model.value.token);
            closeDialog();
        });
    }
</script>
