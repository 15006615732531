import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';


const createMessage = async (message) => {
    const result = await dispatcher.postToApi(`patients/${message.patientId}/messages`, message);
    handleApiError(result, 'Error while creating message.');
    return result.data.messageId;
}

const getConversationsForClinic = async (clinicId) => {
    const result = await dispatcher.getFromApi(`clinics/${clinicId}/messages`);
    handleApiError(result, 'Error while reading messages for clinic.');
    return result.data.messages;
}

const getConversationsForPatient = async (patientId) => {
    const result = await dispatcher.getFromApi(`patients/${patientId}/messages`);
    handleApiError(result, 'Error while reading messages for patient.');
    return result.data.messages;
}

const getConversationForPatientAndClinic = async (patientId, clinicId) => {
    const result = await dispatcher.getFromApi(`patients/${patientId}/conversations/byclinic/${clinicId}`);
    handleApiError(result, 'Error while reading message-conversation for patient.');
    return result.data.messages;
}

const getConversationForMessage = async (messageId) => {
    const result = await dispatcher.getFromApi(`patients/conversations/bymessage/${messageId}`);
    handleApiError(result, 'Error while reading message-conversation for patient.');
    return result.data.messages;
}



import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useMessagingService = () => {
    const store = useStore();


    const loadConversationsForClinic = async (clinicId) => {
        await serviceCall(
            async () => {
                let conversations = await getConversationsForClinic(clinicId);
                store.commit('initClinicianConversations', conversations);
                store.commit('setConversationTimestamp');
            }
        );
    }

    const loadConversationsForPatient = async (patientId) => {
        await serviceCall(
            async () => {
                let conversations = await getConversationsForPatient(patientId);
                store.commit('initPatientConversations', conversations);
                store.commit('setConversationTimestamp');
            }
        );
    }

        /// refreshes the conversation on a patient or clinician who are participating...
    const refreshConversationByPatientAndClinic = async (patientId, clinicId) => {
        clinicId = clinicId || store.getters.getClinicianClinicId;
        await serviceCall(
            async () => {
                let conversation = await getConversationForPatientAndClinic(patientId, clinicId);
                if (store.getters.isClinician)
                    store.commit('refreshCliniciansCurrentConversation', conversation);
                else
                    store.commit('refreshPatientsConversation', conversation);
            }
        );
    }

    const refreshConversationByMessage = async (messageId) => {
        await serviceCall(
            async () => {
                let conversation = await getConversationForMessage(messageId);
                if (store.getters.isClinician)
                    store.commit('refreshCliniciansCurrentConversation', conversation);
                else
                    store.commit('refreshPatientsConversation', conversation);
            }
        );
    }

    const sendMessage = async (senderId, clinicId, clinicianId, patientId, text, attachments) => {
        let message = {
            senderId,
            clinicId,
            clinicianId,
            patientId,
            text,
            attachments
        };
        await serviceCall(
            async () => {
                message.messageId = await createMessage(message);

                message.date = new Date();
                message.clinicianFamilyName = ''; //TODO: get from clinician - may not be available on client
                message.clinicianGivenNames = ''; //TODO: get from clinician - may not be available on client
                message.clinicName = ''; //TODO: get from somewhere - currently not available on client
                message.patientFamilyName = store.getters.getPatientFamilyName;   // get current patient's name
                message.patientGivenNames = store.getters.getPatientGivenNames;   // get current patient's name

                if (store.getters.isPatient)
                    store.commit('addPatientMessage', message);
                else
                    store.commit('addClinicianMessage', message);
            }
        );
    }

    return {
        loadConversationsForClinic,
        loadConversationsForPatient,
        refreshConversationByPatientAndClinic,
        refreshConversationByMessage,
        sendMessage,
    }
}