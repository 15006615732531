import { provide, reactive, ref, watch } from 'vue';

export const usePinDialogs = () => {
    let pinDialogData = reactive({
        showPin: false,
        pinType: 'enter',
        dlgTs: 0,
        action: '',
        userId: '',
    });

    let popupPromise = null;
    let popupAccept = ref(null);

    const acceptPinDialog = () => { // emitted done event from LogonPIN hits this
        console.log('PIN DLG - accept');
        pinDialogData.showPin = false;
        popupAccept.value = true;
    }

    const cancelPinDialog = () => { // emitted canceled event from LogonPIN hits this
        console.log('PIN DLG - cancel');
        pinDialogData.showPin = false;
        popupAccept.value = false;
    }

    const enterPinDialog = (action, userId) => {
        console.log('ENTER PIN DIALOG ACTIVATED', action, userId);
        popupAccept.value = null;
        pinDialogData.showPin = true;
        pinDialogData.pinType = 'enter';
        pinDialogData.dlgTs = new Date().getTime();
        pinDialogData.action = action || 'refresh';
        pinDialogData.userId = userId;
        popupPromise = new Promise((resolve) => {
            let popupWatch = watch(() => popupAccept.value, (nv) => {
                console.log('ENTER PIN DLG - user action!');
                resolve(nv);
                // clean up to avoid memory leaks
                popupPromise = null;
                popupWatch();
            });
        });
        return popupPromise;
    }

    const createPinDialog = () => {
        console.log('CREATE PIN DIALOG ACTIVATED');
        popupAccept.value = null;
        pinDialogData.showPin = true;
        pinDialogData.pinType = 'create';
        pinDialogData.dlgTs = new Date().getTime();
        popupPromise = new Promise((resolve) => {
            let popupWatch = watch(() => popupAccept.value, (nv) => {
                console.log('CREATE PIN DLG - user action!');
                resolve(nv);
                // clean up to avoid memory leaks
                popupPromise = null;
                popupWatch();
            });
        });
        return popupPromise;
    }

    provide('enterPinDialog', enterPinDialog);
    provide('createPinDialog', createPinDialog);

    return {
        pinDialogData,
        enterPinDialog,
        createPinDialog,
        acceptPinDialog,
        cancelPinDialog,
    };
}
