<template>
    <div class="gray min-100" id="list-investigations">
        <bar :title="$t('listInvestigations.title')" :full="isClinician" @close="closeDialog"></bar>
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head">
                            <div class="col-xs-4"><span><b>{{$t('listInvestigations.dateHeader')}}</b></span></div>
                            <div class="col-xs-6"><span><b>{{$t('listInvestigations.typeHeader')}}</b></span></div>
                            <div class="col-xs-2 center-text"><span><b>{{$t('listInvestigations.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <template v-for="measurement in measurements" :key="measurement.measurementId">
                                <div class="item" :class="{deleted: measurement.deleted}"
                                     @click="measurementClicked(measurement)">
                                    <div class="col-xs-4">
                                        <date-display :iso-date="measurement.date" display-as="date"></date-display>
                                    </div>
                                    <div class="col-xs-6">
                                        <span data-test="measurement-title">{{getTitleText(measurement)}}</span>
                                    </div>
                                    <div class="col-xs-2 full-height">
                                        <div class="svg">
                                            <svg-icon icon-name="eye"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { vitalSignsMeasurements } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const excludeMeasurements = vitalSignsMeasurements;

    const isClinician = computed(() => store.getters.isClinician);

    let measurements = computed(() => {
        let tmp = store.getters.getPatientMeasurements.map((v) => v);
        // filter out all vital signs measurements
        tmp = tmp.filter((m) => !excludeMeasurements.includes(m.measurementValue.value.definition.codingCode));
        return tmp.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1)
    });

    const getTitleText = (measurement) => t(measurement.measurementValue.value.definition.titleKey + '');

    const measurementClicked = async (measurement) => {
        await router.push({
            name: 'edit measurement component',
            params: {
                id: measurement.measurementId
            },
            query: {
                returnToName: 'investigations'
            }
        });
    };

    const closeDialog = async () => await router.push({ name: 'patient' });
</script>
