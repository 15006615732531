<template>
    <div class="overlay" id="ResetPassword">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('passwordReset.title')}}</b></h2>

                    <div v-if="validInitialValue" data-test="valid-layer">
                        <h5 class="full-width center-text pb-medium" v-if="!resetDone">
                            <span>{{$t('passwordReset.subTitle')}}</span>
                        </h5>

                        <form>
                            <div v-if="!resetDone" class="col-xs-push-1 col-xs-10 pt--large" data-test="reset-pending-layer">
                                <text-box v-model="reset.password"
                                          name="password"
                                          type="password"
                                          :required="true"
                                          :label="$t('passwordReset.passwordLabel')"
                                          :errorText="$t('passwordReset.passwordError')"
                                          :helper="$t('passwordReset.passwordHelper')"
                                          @updated="passwordStateChanged"
                                          data-password />

                                <text-box v-model="reset.passwordConfirm"
                                          name="confirmPassword"
                                          type="password"
                                          :required="true"
                                          :label="$t('passwordReset.confirmPasswordLabel')"
                                          :errorText="$t('passwordReset.confirmPasswordError')"
                                          :helper="$t('passwordReset.confirmPasswordHelper')"
                                          :pattern="passwordConfirmPattern"
                                          :pattern-case-sensitive="true"
                                          @updated="stateChanged"
                                          data-password-confirm />

                                <button class="btn btn__text btn__text--large btn__text--green-border full-width online-only"
                                        :class="{ disabled : !isValid }"
                                        :disabled="!isValid"
                                        type="button"
                                        data-reset-button
                                        @click="resetUserPassword()">
                                    <h2>{{$t('passwordReset.changePassword')}}</h2>
                                </button>
                                <div class="error" v-if="resetFailed" data-reset-failed><h5>{{$t('passwordReset.errorToken')}}</h5></div>
                            </div>

                            <div v-if="resetDone" class="col-xs-push-1 col-xs-10 pt--large" data-test="reset-done-layer">
                                <h5></h5>
                                <div class="col-xs-12 center-text pb-medium">
                                    <h5>{{$t('passwordReset.resetSuccess')}} </h5>
                                </div>
                                <button class="btn btn__text btn__text--large btn__text--green full-width center"
                                        type="button"
                                        @click="closeDialog()">
                                    <h1>{{$t('passwordReset.goToLogin')}}</h1>
                                </button>
                            </div>
                        </form>
                    </div>

                    <div v-if="!validInitialValue" data-test="invalid-layer">
                        <div class="col-xs-12 center-text pb-medium pt-medium">
                            <h5 class="error">{{$t('passwordReset.invalidInitialValuesLabel')}} </h5>
                        </div>
                        <button class="btn btn__text btn__text--large btn__text--green full-width center"
                                type="button"
                                @click="closeDialog()">
                            <h1>{{$t('general.goBackButton')}}</h1>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';

    import { computed, defineProps, reactive, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { makeRegexSafe, isGuid } from '@/Components/Shared/RegexUtils.js';

    const props = defineProps({
        id: {
            type: String,
            validator(value) {
                return isGuid(value);
            }
        },
        token: { type: String }
    });

    const { stateChanged, isValid } = useFormValidator();
    const router = useRouter();
    const service = useUserService();
    const caller = useExceptionwrappedCaller();

    let resetFailed = ref(false);
    let resetDone = ref(false);
    let passwordConfirmPattern = ref('');

    let reset = reactive({
        password: '',
        passwordConfirm: ''
    })

    const validInitialValue = computed(() => isGuid(props.id));

    const resetUserPassword = async () => {
        try {
            let urlToken = props.token.replace(/\s+/g, '+');
            await caller.call(async () => {
                await service.resetUserPassword(props.id, urlToken, reset.password);
                resetDone.value = true;
            });
        } catch (e) {
            resetFailed.value = true;
        }
    }

    const passwordStateChanged = (info) => {
        console.log('passwordStateChanged', info.valid);
        stateChanged(info);
        passwordConfirmPattern.value = '^' + makeRegexSafe(reset.password) + '$';
    }

    const closeDialog = () => router.push({ name: 'welcome' });
</script>