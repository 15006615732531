<template>
    <div class="overlay overlay--dashboard" id="settings" data-active-xxx="isOpen">
        <bar :title="$t('settings.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h4 class="full-width"><b>{{$t('settings.languageSettingLabel')}}</b></h4>
                    <drop-down-box v-model="language" :elements="languages" class="online-only" />
                </div>

                <!--<div class="col-xs-12" v-if="isClinician">
                    <h4 class="full-width pb-small"><b>{{$t('settings.personalInfoLabel')}}</b></h4>
                    <button class="btn btn__text btn__text--green-border btn__text--short" type="button" @click="clinicianDataOpen">
                        <span>{{$t('settings.editProfileButton')}}</span>
                    </button>
                </div>-->

                <div class="col-xs-12" v-if="isClinician && clinicianHelpEnglishLink">
                    <h4 class="full-width pb-small"><b>{{$t('settings.helpLabel')}}</b></h4>
                    <a class="btn btn__text btn__text--green-border btn__text--short" target="_blank" :href="clinicianHelpEnglishLink"
                       style="display:inline-block;padding: 3px inherit 2px inherit;line-height:28px;text-decoration:none">
                        <span>{{$t('settings.clinicianHelpEnglishLink')}}</span>
                    </a>
                </div>

                <div class="col-xs-12" v-if="!isClinician && (patientHelpEnglishLink || patientHelpSwahiliLink)">
                    <h4 class="full-width pb-small"><b>{{$t('settings.helpLabel')}}</b></h4>
                    <a class="btn btn__text btn__text--green-border btn__text--short" target="_blank" :href="patientHelpEnglishLink"
                       v-if="language=='en'"
                       style="display:inline-block;padding: 3px inherit 2px inherit;line-height:28px;text-decoration:none">
                        <span>{{$t('settings.patientHelpEnglishLink')}}</span>
                    </a>
                    <a class="btn btn__text btn__text--green-border btn__text--short" target="_blank" :href="patientHelpSwahiliLink"
                       v-if="language=='sw'"
                       style="display:inline-block;padding: 3px inherit 2px inherit;line-height:28px;text-decoration:none">
                        <span>{{$t('settings.patientHelpSwahiliLink')}}</span>
                    </a>
                </div>

                <div class="col-xs-12" v-if="isClinician && clinicianSurveyLink">
                    <h4 class="full-width pb-small"><b>{{$t('settings.surveyLabel')}}</b></h4>
                    <a class="btn btn__text btn__text--green-border btn__text--short" target="_blank" :href="clinicianSurveyLink"
                       style="display:inline-block;padding: 3px inherit 2px inherit;line-height:28px;text-decoration:none">
                        <span>{{$t('settings.clinicianSurveyLink')}}</span>
                    </a>
                </div>

                <div class="col-xs-12" v-if="false">
                    <h4 class="full-width pb-small"><b>{{$t('settings.deviceInfoLabel')}}</b></h4>
                    <div class="info" v-for="info in deviceInfo" :key="info.key">
                        <b>{{info.key}}</b>
                        <div class="full-width  pb-small">
                            {{info.value}}
                        </div>
                    </div>
                </div>

                <div class="col-xs-12">
                    <h4 class="full-width pb-small"><b>{{$t('settings.resetLocalDataLabel')}}</b></h4>
                    <button class="btn btn__text btn__text--red-border btn__text--short" type="button" @click="resetLocal">
                        <span>{{$t('settings.resetLocalDataButton')}}</span>
                    </button>
                </div>

                <div class="col-xs-12" v-if="userType == 'Patient'">
                    <h4 class="full-width pb-small"><b>{{$t('settings.auditLogLabel')}}</b></h4>
                    <button class="btn btn__text btn__text--red-border btn__text--short online-only" type="button" @click="navigateTo('auditLogEntries')">
                        <span>{{$t('settings.auditLogButton')}}</span>
                    </button>
                </div>
            </div>
        </div>
        <!--<clinician-data :clinicianDataActive="clinicianDataActive" @close="clinicianDataClose"></clinician-data>-->
    </div>
</template>

<script setup>
    import DropDownBox from '@/Components/Shared/Form/DropDownBox';
    //import ClinicianData from '@/Components/Organizations/Clinician/ClinicianData.vue';

    import { computed, inject, onMounted, ref, watch, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useLanguageController } from '@/Components/Shared/LanguageController.js';
    import { useSettingsService } from '@/Components/Shared/SettingsService.js';
    import { useUserService } from '@/Components/Users/User/UserService.js';

    const store = useStore();
    const router = useRouter();
    const { getLang, changeLang } = useLanguageController();
    const i18n = useI18n({ useScope: 'global' });
    const settingsService = useSettingsService();
    const userService = useUserService();

    //const clinicianDataActive = ref(false);
    const deviceInfo = ref('')
    const alertDialog = inject('alertDialog');

    const userType = computed(() => store.getters.getUserType);

    const isClinician = computed(() => store.getters.isClinician);

    const patientHelpEnglishLink = computed(() => 'https://drive.google.com/file/d/1HKR2aXHaassqwsTYk3YHiQg2LBj5c_nJ/view?usp=sharing');
    const patientHelpSwahiliLink = computed(() => 'https://drive.google.com/file/d/1AHMET3wa-mbuwoIIwIphK085JVe3t1oE/view?usp=sharing');
    const clinicianHelpEnglishLink = computed(() => 'https://drive.google.com/file/d/1Aqn8lxHAF1cS0TWm5jry8HDW7bPiEluy/view?usp=sharing');

    //const patientSurveyLink = computed(() => 'https://redcap.link/Patient_Baseline');
    //const patientSurveyFollowUpLink = computed(() => 'https://redcap.link/Patient_Follow_up');

    const clinicianSurveyLink = computed(() => 'https://redcap.link/Healthcare_provider_Baseline');
    //const clinicianSurveyFollowUpLink = computed(() => 'https://redcap.link/Healthcare_provider_Follow_up');

    const navigateTo = (routeName) => {
        router.push({ name: routeName });
    }

    let language = ref('en');

    onMounted(() => {
        language.value = getLang();
        var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        let device = [
            {
                key: 'appversion', value: navigator.appVersion
            },
            {
                key: 'phoneLaugnage', value: navigator.language
            },
            {
                key: 'viewport', value: width + " / " + height
            },
        ];
        deviceInfo.value = device;
    });

    watchEffect(() => {
        if (store.getters.getUserId)
            language.value = getLang();
    });

    const resetLocal = async () => {
        let resetOK = await alertDialog({
            header: i18n.t('settings.resetPopupTitle'),
            body: i18n.t('settings.resetPopupText'),
        });

        if (resetOK) {
            const userId = store.getters.getUserId;
            await userService.logoffUser();
            settingsService.clearLocalData(userId);
            navigateTo('welcome');
        }
    }

    let languages = computed(() => {
        // IMPORTANT: language names should NOT be translated to selected language!
        return [
            { key: 'en', value: 'English', selected: true },
            { key: 'sw', value: 'Kiswahili', selected: false },
            { key: 'uk', value: 'українська', selected: false }
        ]
    })

    const isOpen = computed(() => {
        //clinicianDataClose();
        return store.state.settingsIsOpen;
    });

    watch(() => language.value, async (lang) => {
        await changeLang(lang);
    });


    //const clinicianDataOpen = () => {
    //    console.log('opening');
    //    clinicianDataActive.value = true;
    //}

    //const clinicianDataClose = () => {
    //    console.log('closing');
    //    clinicianDataActive.value = false;
    //}

    const closeDialog = () => {
        router.push({ name: 'dashboard' });
        //store.commit('toggleSettings', false);
    }

    router.afterEach(() => {
        if (isOpen.value) {
            console.log("settings.isOpen=" + isOpen.value + " and route change initiated");
            console.log("closing the open settings tab");
            closeDialog();
        }
    });
</script>