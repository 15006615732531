<template>
    <form>
        <text-area v-model="m.message"
                   :required="true"
                   :label="$t('requestAppointment.requestMessageLabel')"
                   :errorText="$t('requestAppointment.requestMessageErrorText')"
                   :helper="$t('requestAppointment.requestMessageHelper')"
                   :placeholder="$t('requestAppointment.requestMessagePlaceholder')"
                   @updated="stateChanged" 
                   data-test="appointmentrequest-text"/>
        <div class="mb-medium">
            {{$t('requestAppointment.requestConsentMessage')}}
        </div>
        <button type="button" class="btn btn__text btn__text--green-border left" @click="cancel" data-test="appointmentrequest-cancel">
            <h4>{{$t('general.cancel')}}</h4>
        </button>
        <button type="button" 
                class="btn btn__text btn__text--green right online-only" 
                :class="{disabled:!isValid||working}" 
                :disabled="!isValid||working" 
                @click="sendRequest"
                data-test="appointmentrequest-confirm">
            <h4>{{$t('requestAppointment.sendRequestButton')}}</h4>
        </button>
    </form>
</template>


<script>
    import TextArea from '@/Components/Shared/Form/TextArea';

    import { reactive, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useBookingRequestService } from '@/Components/Consultations/BookingRequest/BookingRequestService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    export default {
        name: 'ClinicRequestForm',
        components: {
            TextArea,
        },
        props: {
            clinicId: String,
        },
        setup(props) {
            const router = useRouter();
            const { stateChanged, isValid } = useFormValidator();
            const service = useBookingRequestService();
            const caller = useExceptionwrappedCaller();

            let working = ref(false);
            let m = reactive({
                message: '',
            });

            const sendRequest = async () => {
                if (!working.value) {
                    working.value = true;
                    await caller.call(async () => {
                        await service.createBookingRequest(props.clinicId, m.message);
                        router.push({ name: 'appointments', query: { tabNumber: 1 } });
                    });
                }
            }

            const cancel = () => {
                router.back();
            }

            return {
                m,
                cancel,
                sendRequest,
                stateChanged,
                isValid,
                working,
            };
        }
    }
</script>