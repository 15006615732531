<template>
    <div>
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" />
        <select class="select" v-model="value" :data-state="dataState" formnovalidate>
            <option v-for="(element,key) in elements" :key="key"
                :text="element.value" :value="element.key" :selected="element.selected" />
        </select>
    </div>
</template>

<script>
    import Label from './Label.vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    export default {
        name: 'DropDownBox',
        components: {
            Label,
        },
        props: {
            modelValue: EmptyString,
            type: {
                type: String,
                default: 'dropdownbox',
                validator(value) {
                    return ['dropdownbox'].includes(value);
                }
            },
            label: EmptyString,
            errorText: EmptyString,
            helper: EmptyString,
            pattern: NullableString,
            patternCaseSensitive: FalseBoolean,
            name: {
                type: String,
                default: '_' + Math.floor(Math.random() * 1000000000)
            },
            elements: {
                type: Array,
                default: () => []
            },
            required: FalseBoolean,
        },
        emits: ['update:modelValue','updated'],
        setup(props, context) {
            const { value, error, dataState } = useInputValidator(props, context);

            return {
                value,
                error,
                dataState,
            };
        }
    }
</script>