<template>
    <div class="gray min-100" id="list-medication">
        <bar :title="$t('listMedications.title')" :full="isClinician" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout row">
                        <div class="head">
                            <div class="col-xs-2"><span><b>{{$t('listMedications.dateHeader')}}</b></span></div>
                            <div class="col-xs-5"><span><b>{{$t('listMedications.ordinationHeader')}}</b></span></div>
                            <!--<div class="col-xs-3"><span><b>{{$t('listMedications.clinicianHeader')}}</b></span></div>-->
                            <div class="col-xs-3 center-text"><span><b>{{$t('general.audio')}}</b></span></div>
                            <div class="col-xs-2 center-text"><span><b>{{$t('listMedications.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <div class="item"
                                 v-for="med in medications"
                                 :key="med.medicationId"
                                 >
                                <div class="col-xs-2" @click="medicationClicked(med.medicationId)">
                                    <date-display :iso-date="med.date" display-as="date" />
                                </div>
                                <div class="col-xs-5" @click="medicationClicked(med.medicationId)">
                                    <span>
                                        <span v-if="med.drug">{{med.drug}}, </span>
                                        {{med.simpleOrdination}}
                                    </span>
                                </div>
                                <div class="col-xs-3">
                                    <!--<span>{{med.clinicianGivenNames}} {{med.clinicianFamilyName}}</span>-->
                                    <media-player :audio="med.audioInstruction" :smallPlayer="true"></media-player>
                                </div>
                                <div class="col-xs-2" @click="medicationClicked(med.medicationId)">
                                    <div class="svg">
                                        <svg-icon icon-name="eye" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';

    const store = useStore();
    const router = useRouter();

    const isClinician = computed(() => store.getters.isClinician);
    const medications = computed(() => {
        let meds = store.getters.getPatientMedications.map((a) => a);
        return meds.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
    });

    const medicationClicked = (medicationId) => {
        router.push({ name: 'edit medication', params: { id: medicationId } });
    }

    const closeDialog = () => router.push({ name: 'patient' });
</script>