<template>
    <div class="col-xs-12" v-for="patient in normalizedPatients" :key="patient.id">
        <div class="user" :class="{inactive : !patient.hasAccess}">
            <div class="data">
                <div class="info" @click="view(patient)">
                    <person-status-info v-bind="getPersonData(patient)" />
                </div>
                <div class="more">
                    <!--<button v-if="canConnect(patient) || canView(patient)"
                            class="btn btn__round btn__round--medium btn__round--green-outline"
                            @click="toggleOptions(patient)">
                        <div class="svg-container">
                            <svg-icon icon-name="chevron-down" />
                        </div>
                    </button>-->

                    <button v-if="canResend(patient)" @click="resendAccessRequest(patient)" class="btn btn__text btn__text--green-border btn__text--short right online-only">
                        <h4>{{$t('general.resend')}}</h4>
                    </button>
                    <button v-if="canConnect(patient)" @click="connectTo(patient)" class="btn btn__text btn__text--green-border btn__text--short right online-only">
                        <h4>{{$t('general.connect')}}</h4>
                    </button>
                    <button v-if="canView(patient)" @click="view(patient)" class="btn btn__round btn__round--medium btn__round--green-outline">
                        <div class="svg-container">
                            <svg-icon icon-name="chevron" />
                        </div>
                    </button>
                </div>
                <!--<div class="actions" :class="{active : patient.optionsActive}">
                    <button v-if="canConnect(patient)" @click="connectTo(patient)" class="btn btn__text btn__text--green-border btn__text--short right">
                        <h4>{{$t('general.connect')}}</h4>
                    </button>
                    <button v-if="canView(patient)" @click="view(patient)" class="btn btn__text btn__text--green-border btn__text--short right">
                        <h4>{{$t('general.view')}}</h4>
                    </button>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, computed, watch, nextTick } from 'vue';
    import { useStore } from 'vuex';
    import PersonStatusInfo from './PersonStatusInfo.vue';

    export default {
        name: 'ListPatients',
        props: {
            patients: Array     // expects patientId, givenNames, familyName, image
        },
        components: {
            PersonStatusInfo,
        },
        emits: ['buttonclicked'],
        setup(props, { emit }) {
            const store = useStore();

            let normalizedPatients = ref([]);
            let patientAccess = computed(() => store.getters.getPatientAccess);

            watch(() => [props.patients, patientAccess.value], () => {
                nextTick(() => {
                    normalizedPatients.value = props.patients.map((patient) => {
                        let accesses = patientAccess.value.filter((p) => p.patientId == patient.patientId);
                        let access = accesses.length > 0 ? accesses[0] : null;

                        return {
                            patientId: patient.patientId,
                            givenNames: patient.givenNames,
                            familyName: patient.familyName,
                            image: patient.image,
                            requested: access != null,
                            hasAccess: access ? access.hasAccess : false,
                            paused: access ? access.paused : false,
                            toDate: access ? access.toDate : null,
                            location: '',
                            optionsActive: false,
                        };
                    });
                });
            });

            const getPersonData = (patient) => {
                return {
                    entityId: patient.patientId,
                    givenNames: patient.givenNames,
                    familyName: patient.familyName,
                    image: patient.image,
                    requested: patient.requested,
                    hasAccess: patient.hasAccess,
                    paused: patient.paused,
                    toDate: patient.toDate,
                    location: patient.location,
                };
            }

            const toggleOptions = (patient) => {
                console.log('toggleOptions', patient);
                patient.optionsActive = !patient.optionsActive;
            }

            const isExpired = (patient) => {
                return patient.requested
                    && patient.toDate != null
                    && new Date(patient.toDate) < new Date();
            }

            const isPending = (patient) => {
                return patient.requested
                    && !isExpired(patient)
                    && !patient.hasAccess;
            }

            const isPaused = (patient) => {
                return patient.requested
                    && !isExpired(patient)
                    && patient.paused;
            }

            const isActive = (patient) => {
                return patient.requested
                    && !isPending(patient)
                    && !isExpired(patient)
                    && !isPaused(patient);
            }

            const canConnect = (patient) => {
                return !patient.requested;
            }

            const canResend = (patient) => {
                let access = patientAccess.value.filter(p => p.patientId == patient.patientId);
                if (!access || access.length == 0) return false;

                let latestRequestDate = new Date(Math.max(new Date(access[0].requestDate), new Date(access[0].resendDate)));

                let requestCutoffDate = new Date().setTime(new Date().getTime() - (60 * 60 * 1000));

                return isPending(patient)
                    && (latestRequestDate < requestCutoffDate);
            }

            const canView = (patient) => {
                return isActive(patient);
            }

            const view = (patient) => {
                if (canView(patient)) {
                    emit('buttonclicked', { action: 'view', patient: patient });
                }
            };

            const connectTo = (patient) => {
                if (canConnect(patient)) {
                    emit('buttonclicked', { action: 'connect', patient: patient });
                }
            };

            const resendAccessRequest = (patient) => {
                emit('buttonclicked', { action: 'resend', patient: patient });
            };


            return {
                normalizedPatients,
                canConnect,
                canResend,
                isPending,
                canView,
                toggleOptions,
                getPersonData,
                view,
                connectTo,
                resendAccessRequest,
            };
        }
    }
</script>
