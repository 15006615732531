<template>
    <div id="patientconversations" class="overlay overlay--patient">
        <bar :title="$t('patientConversations.title')" @close="closeDialog" />
        <div class="container">
            <div class="row list" data-test="clinicConversations">
                <template v-for="conversation in conversations" :key="conversation.id">
                    <div class="col-xs-12 list__clickable list__clickable--right" @click="conversationClicked(conversation)" data-test="conversation-item">
                        <div class="title">
                            <span>
                                {{conversation.clinicName}}
                            </span>
                            <span v-if="conversation.clinicName && conversation.date">, </span>
                            <span>
                                <date-display :iso-date="conversation.date" />
                            </span>
                            <div class="svg-container">
                                <svg-icon icon-name="chevron" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="button-container-bottom animate-in-delay">
            <button type="button" class="btn btn__text btn__text btn__text--green-border online-only" @click="findClinicClicked" data-test="find-clinic-button">
                <h3>{{$t('patientConversations.findClinicButton')}}</h3>
            </button>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>


<script setup>
    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const messagingService = useMessagingService();
    const caller = useExceptionwrappedCaller();

    const patientId = computed(() => store.getters.getPatientId);
    const conversations = computed(() => store.getters.getPatientsConversations);

    watchEffect(async () => {
        if (patientId.value) {
            await caller.call(async () => {
                await messagingService.loadConversationsForPatient(patientId.value);
            });
        }
    });

    const conversationClicked = (conversation) => {
        router.push({
            name: 'patient conversation',
            params: {
                clinicId: conversation.clinicId,
            }
        });
    }

    const findClinicClicked = () => router.push({ name: 'findclinic' });

    const closeDialog = () => router.back();
</script>