<template>
    <div class="clinic-data">
        <ul>
            <li><h4>{{name}}</h4></li>
            <li>{{address}}</li>
            <li><a :href="'tel:' + phone">{{phone}}</a></li>
            <li><a :href="'mailto:' + email">{{email}}</a></li>
        </ul>
    </div>
</template>


<script>
    export default {
        name: 'ClinicShortInfo',
        props: {
            name: String,
            address: String,
            phone: String,
            email: String,
        },
    }
</script>