<template>
    <div id="verify" class="overlay">
        <bar title="" @close="closeDialog" />
        <div class="verified" :class="{show: (viewState == 0)}" data-test="verified-layer">
            <div class="container">
                <div class="row">
                    <div class="confirm">
                        <div class="col-xs-12">
                            <h3 class="full-width center-text pb-small">{{$t('verifyView.accountVerified')}}</h3>
                        </div>
                        <div class="col-xs-push-1 col-xs-10">
                            <button class="btn btn__text btn__text--large btn__text--green full-width online-only"
                                    @click="goToLogin"
                                    data-test="go-to-login">
                                <h1><span>{{$t('verifyView.gotoLogin')}}</span></h1>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="verify" :class="{show: (viewState == 1)}" data-test="verify-layer">
            <div class="container">
                <div class="row">
                    <div class="confirm">
                        <div class="col-xs-12">
                            <h2 class="full-width center-text pb-small"><b>{{$t('verifyView.title')}}</b></h2>

                            <div v-if="!confirmedEmail && props.email == 1" data-test="emailForm">
                                <div class="row" style="padding-bottom:30px">
                                    <div class="col-xs-12">
                                        <h4 class="full-width center-text pb-small">{{$t('verifyView.subTitleEmail')}}</h4>
                                        <h5 class="full-width center-text pb-medium">{{$t('verifyView.infoEmail')}}</h5>
                                    </div>

                                    <div class="col-xs-12">
                                        <text-box v-model="verify.emailToken"
                                                  name="emailToken"
                                                  type="text"
                                                  :errorText="$t('verifyView.emailTokenErrorText')"
                                                  :helper="$t('verifyView.emailTokenHelper')"
                                                  :placeholder="$t('verifyView.emailTokenPlaceholder')" />
                                    </div>
                                    <div class="col-xs-push-1 col-xs-10">
                                        <button class="btn btn__text-icon btn__text-icon--large btn__text-icon--green full-width online-only"
                                                @click.prevent="confirmEmail(null,null)"
                                                data-test="confirm-email-button">
                                            <div>
                                                <svg-icon icon-name="lock" />
                                                <h1><span>{{$t('verifyView.verifyNow')}}</span></h1>
                                            </div>
                                        </button>

                                        <div style="padding-bottom:15px">
                                            <a @click.prevent="resendEmailConfirmation"
                                               v-if="!resendEmailResultMessage"
                                               data-test="resend-email-confirmation">{{$t('verifyView.needEmailConfirmationCodeLinkText')}}</a>
                                            <span v-if="resendEmailResultMessage"
                                                  data-test="resend-email-confirmation-message">{{resendEmailResultMessage}}</span>
                                        </div>
                                        <div v-if="displayEmailError" class="error" data-test="emailErrorMessage">{{emailErrorMessage}}</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!confirmedPhone && props.phone == 1" data-test="phoneForm">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h4 class="full-width center-text pb-small">{{$t('verifyView.subTitlePhone')}}</h4>
                                        <h5 class="full-width center-text pb-medium">{{$t('verifyView.infoPhone')}}</h5>
                                    </div>

                                    <div class="col-xs-12">
                                        <text-box v-model="verify.phoneCode"
                                                  name="phoneCode"
                                                  type="text"
                                                  :errorText="$t('verifyView.phoneCodeErrorText')"
                                                  :helper="$t('verifyView.phoneCodeHelper')"
                                                  :placeholder="$t('verifyView.phoneCodePlaceholder')" />
                                    </div>

                                    <div class="col-xs-push-1 col-xs-10">
                                        <button class="btn btn__text-icon btn__text-icon--large btn__text-icon--green full-width online-only"
                                                @click.prevent="confirmPhone(null,null)"
                                                data-test="confirm-phone-button">
                                            <div>
                                                <svg-icon icon-name="lock" />
                                                <h1><span>{{$t('verifyView.verifyNow')}}</span></h1>
                                            </div>
                                        </button>

                                        <div style="padding-bottom:15px">
                                            <a @click.prevent="resendPhoneConfirmation"
                                               v-if="!resendPhoneResultMessage"
                                               data-test="resend-phone-confirmation">{{$t('verifyView.needPhoneConfirmationCodeLinkText')}}</a>
                                            <span v-if="resendPhoneResultMessage"
                                                  data-test="resend-phone-confirmation-message">{{resendPhoneResultMessage}}</span>
                                        </div>
                                        <div v-if="displayPhoneError" class="error" data-test="phoneErrorMessage">{{phoneErrorMessage}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="invalid" :class="{show: (viewState == 2)}" data-test="invalid-layer">
            <div class="container">
                <div class="row">
                    <div class="confirm">
                        <div class="col-xs-12">
                            <h3 class="full-width center-text pb-small">{{$t('verifyView.missingInformationError')}}</h3>
                        </div>
                        <!--<div class="col-xs-push-1 col-xs-10">
                            <button class="btn btn__text btn__text--large btn__text--green full-width online-only" @click="goToLogin">
                                <h1><span>{{$t('verifyView.gotoLogin')}}</span></h1>
                            </button>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';

    import { computed, defineProps, reactive, ref, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        id: { type: String, required: true },
        token: { type: String },
        phone: { type: String, default: '0' },
        email: { type: String, default: '0' },
    });

    const { t } = useI18n();
    const router = useRouter();
    const service = useUserService();
    const caller = useExceptionwrappedCaller();

    const confirmedEmail = ref(false);
    const confirmedPhone = ref(false);

    let emailErrorMessage = ref('');
    let phoneErrorMessage = ref('');
    let displayEmailError = ref(false);
    let displayPhoneError = ref(false);

    let resendEmailResultMessage = ref('');
    let resendPhoneResultMessage = ref('');

    let verify = reactive({
        emailToken: '',
        phoneCode: ''
    });

    //console.log('props', props.id, props.token, props.email, props.phone);

    const viewState = computed(() =>
        !props.id || (props.email == '0' && props.phone == '0')
            ? 2
            : (confirmedEmail.value || props.email == '0') && (confirmedPhone.value || props.phone == '0')
                ? 0
                : 1);

    const resendEmailConfirmation = async () => {
        await caller.call(async () => {
            await service.resendEmailConfirmation(props.id);
            resendEmailResultMessage.value = t('verifyView.resendEmailConfirmationResultMessageText');
        });
    }

    const resendPhoneConfirmation = async () => {
        await caller.call(async () => {
            await service.resendPhoneConfirmation(props.id);
            resendPhoneResultMessage.value = t('verifyView.resendPhoneConfirmationResultMessageText');
        });
    }

    const confirmEmail = async (id, token) => {
        try {
            await caller.call(async () => {
                console.log('verifying e-mail...', id, token, props.id, verify.emailToken);
                id = id || props.id;
                token = token || verify.emailToken;
                await service.confirmUserEmail(id, token);
                confirmedEmail.value = true;
            });
        }
        catch (e) {
            if (e.code && e.code == 104) {
                emailErrorMessage.value = t(`verifyView.${e.message}`);
            }
            else {
                emailErrorMessage.value = t('verifyView.verifyError');
            }
            displayEmailError.value = true;
            console.error(e.message || e);
        }
    }

    const confirmPhone = async (id, token) => {
        try {
            await caller.call(async () => {
                console.log('verifying mobile number...', id, token, props.id, verify.phoneCode);
                id = id || props.id;
                token = token || verify.phoneCode;
                await service.confirmUserPhoneNumber(id, token);
                confirmedPhone.value = true;
            });
        }
        catch (e) {
            if (e.code && e.code == 105) {
                phoneErrorMessage.value = t(`verifyView.${e.message}`);
            }
            else {
                phoneErrorMessage.value = t('verifyView.verifyError');
            }
            displayPhoneError.value = true;
            console.error(e.message || e);
        }
    }

    watchEffect(async () => {
        if (!props.id) return;
        //HACK! Because Vue Router unescapes + and turns it into space, we have to undo this
        //      since we need the raw token value to complete the confirmation...
        let urlToken = null;
        if (props.token)
            urlToken = props.token.replace(/\s+/g, '+');

        if (urlToken) {
            if (props.email == '1')
                await caller.call(async () => {
                    await confirmEmail(props.id, urlToken);
                });
            else if (props.phone == '1')
                await caller.call(async () => {
                    await confirmPhone(props.id, urlToken);
                });
        }
    });

    const goToLogin = () => router.push({ name: 'login' });

    const closeDialog = () => router.push({ name: "welcome" });
</script>
