import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
//import { messages } from './Lang/En.js';


const i18nOptions = {
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    //messages,
    globalInjection: true,
    modifiers: {
        quote: (str) => '"' + str + '"'
    },
    numberFormats: {
        'en': {
            currency: {
                style: 'currency',
                currency: 'USD',
            }
        },
        'sw': {
            currency: {
                style: 'currency',
                currency: 'TZS',
                currencyDisplay: 'symbol',
            }
        },
        'ke': {
            currency: {
                style: 'currency',
                currency: 'KES',
                currencyDisplay: 'symbol',
            }
        },
        'da': {
            currency: {
                style: 'currency',
                currency: 'DKK',
                currencyDisplay: 'symbol',
            }
        },
        'uk': {
            currency: {
                style: 'currency',
                currency: 'USD',
            }
        }
    }
};

export const SUPPORT_LOCALES = ['en', 'sw', 'uk']

export function setupI18n(options = i18nOptions) {
    const i18n = createI18n(options)
    setI18nLanguage(i18n.global, options.locale)
    return i18n
}

export function setI18nLanguage(i18nGlobal, locale) {
    //if (i18n.mode === 'legacy') {
    //    i18n.global.locale = locale
    //} else {
        i18nGlobal.locale.value = locale
    //}
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18nGlobal, locale) {
    // load locale messages with dynamic import
    console.log('loadLocaleMessages', i18nGlobal, locale);
    const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./Lang/${locale}.json`
    );

    // set locale and locale message
    console.log('setting locale and locale message')
    await i18nGlobal.setLocaleMessage(locale, messages.default[locale]);

    return nextTick();
}
