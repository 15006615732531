<template>
    <div class="gray min-100" id="list-immunizations">
        <bar :title="$t('listImmunization.title')" :full="isClinician" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head">
                            <div class="col-xs-2"><span><b>{{$t('listImmunization.dateLabel')}}</b></span></div>
                            <div class="col-xs-2"><span><b>{{$t('listImmunization.codeLabel')}}</b></span></div>
                            <div class="col-xs-4"><span><b>{{$t('listImmunization.nameLabel')}}</b></span></div>
                            <div class="col-xs-2"><span><b>{{$t('listImmunization.expirationDateLabel')}}</b></span></div>
                            <div class="col-xs-2 center-text"><span><b>{{$t('listImmunization.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <div class="item" v-for="immunization in immunizations" :key="immunization.immunizationId" @click="immunizationClicked(immunization.immunizationId)">
                                <div class="col-xs-2">
                                    <date-display :iso-date="immunization.date" display-as="date" />
                                </div>
                                <div class="col-xs-2">
                                    <span>{{immunization.vaccineCode}}</span>
                                </div>
                                <div class="col-xs-4">
                                    <span>{{immunization.vaccineText}}</span>
                                </div>
                                <div class="col-xs-2">
                                    <date-display :iso-date="immunization.vaccineExpirationDate" display-as="date" />
                                </div>
                                <div class="col-xs-2">
                                    <div class="svg">
                                        <svg-icon icon-name="eye" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const store = useStore();
    const router = useRouter();

    const isClinician = computed(() => store.getters.isClinician);
    const immunizations = computed(() => {
        let ims = store.getters.getPatientImmunizations.map((a) => a);
        return ims.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
    });

    const immunizationClicked = (immunizationId) => {
        router.push({ name: 'edit immunization', params: { id: immunizationId } });
    };

    const closeDialog = () => router.push({ name: 'patient' });
</script>
