<template>
    <div class="overlay overlay--patient" id="editDiagnosis">
        <bar :title="$t(`editDiagnosis.title${userType}`)"
             :by="clinicianName" :by-label="$t('editDiagnosis.changedByLabel')"
             :date="diagnosis.date" :date-label="$t('editDiagnosis.changedDateLabel')"
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content">
                            <div class="col-xs-4 no-padding">
                                <div class="title">
                                    {{$t('editDiagnosis.codeLabel')}}
                                </div>
                                <div class="value">
                                    {{diagnosis.diagnosisCode}}
                                </div>
                            </div>
                            <div class="col-xs-8 no-padding">
                                <div class="title">
                                    {{$t('editDiagnosis.descriptionLabel')}}
                                </div>
                                <div class="value">
                                    {{diagnosis.diagnosisText}}
                                </div>
                            </div>
                        </div>
                        <div class="content" v-if="diagnosis.onset">
                            <div class="title">
                                {{$t('editDiagnosis.onsetLabel')}}
                            </div>
                            <div class="value">
                                <date-display :iso-date="diagnosis.onset" display-as="date" />
                            </div>
                        </div>
                        <div class="content" v-if="diagnosis.note">
                            <div class="title">
                                {{$t('editDiagnosis.noteLabel')}}
                            </div>
                            <div class="value">
                                {{diagnosis.note}}
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editDiagnosis.abatedLabel')}}
                            </div>
                            <div class="value" v-if="!isClinician || diagnosis.abated">
                                <bool-display :value="diagnosis.abated"
                                              :true-label="$t('editDiagnosis.abatedTrueLabel')"
                                              :false-label="$t('editDiagnosis.abatedFalseLabel')" />
                            </div>
                            <div class="switch left online-only" v-if="isClinician && !diagnosis.abated">
                                <label>
                                    <span class="control-box">
                                        <input v-model="diagnosis.abated" type="checkbox" id="1" @click.prevent.stop.capture="abateDiagnosis">
                                        <span class="slider round"></span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{$t('editDiagnosis.chronicalLabel')}}
                            </div>
                            <div class="value" v-if="!isClinician || diagnosis.abated">
                                <bool-display :value="diagnosis.chronical"
                                              :true-label="$t('editDiagnosis.chronicalTrueLabel')"
                                              :false-label="$t('editDiagnosis.chronicalFalseLabel')" />
                            </div>
                            <div class="switch left online-only" v-if="isClinician && !diagnosis.abated">
                                <label>
                                    <span class="control-box">
                                        <input v-model="diagnosis.chronical" type="checkbox" id="2" @change="changeChronical">
                                        <span class="slider round"></span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="content" v-if="diagnosis.audioNote">
                            <div class="title">
                                {{$t('general.audio')}}
                            </div>
                            <div class="row">
                                <media-player :audio="diagnosis.audioNote" :removeMedia="false" :record="false"></media-player>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';

    import { computed, defineProps, inject, reactive, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useDiagnosisService } from '@/Components/HealthData/Diagnosis/DiagnosisService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        id: String,
    });

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const diagnosisService = useDiagnosisService();
    const caller = useExceptionwrappedCaller();
    const alertDialog = inject('alertDialog');

    const isClinician = computed(() => store.getters.isClinician);
    const userType = computed(() => store.getters.getUserType);


    let diagnosis = reactive({
        diagnosisCode: '',
        diagnosisText: '',
        diagnosisSystem: '',
        chronical: false,
        abated: false,
        note: '',
        audioNote: '',
        onset: null,
        date: null,
        clinicianGivenNames: '',
        clinicianFamilyName: '',
    });

    //const updateAudio = async (newAudio) => {
    //    await caller.call(async () => {
    //        await diagnosisService.changeDiagnosisAudioNote(props.id, newAudio);
    //        diagnosis.audioNote = newAudio;
    //    });
    //}

    const clinicianName = computed(() => [diagnosis.clinicianGivenNames, diagnosis.clinicianFamilyName].join(' '));

    watchEffect(() => {
        let storeDiagnosis = store.getters.getDiagnosis(props.id);

        for (var fld in storeDiagnosis)
            diagnosis[fld] = storeDiagnosis[fld];

        diagnosis.clinicianGivenNames = diagnosis.clinicianGivenNames || "";
        diagnosis.clinicianFamilyName = diagnosis.clinicianFamilyName || "";
    });

    const closeDialog = () => router.push({ name: 'diagnosis' });

    const changeChronical = async () => {
        await caller.call(async () => {
            await diagnosisService.changeDiagnosisChronical(props.id, diagnosis.chronical);
        });
    };

    const abateDiagnosis = async () => {
        const accepted = await alertDialog({
            header: t('editDiagnosis.abateWarningPopupHeader'),
            body: t('editDiagnosis.abateWarningPopupMessage'),
            accept: t('general.accept'),
            cancel: t('general.cancel'),
        });
        if (accepted) {
            await caller.call(async () => {
                await diagnosisService.abateDiagnosis(props.id);
                diagnosis.abated = true;
                diagnosis.chronical = false;
            });
        }
    }
</script>
