<template>
    <div class="measurement-edit-component">
        <div class="content">
            <div class="title">
                {{$t(props.definition.titleKey + '')}}
            </div>
            <template v-if="props.definition.displayType != 6">
                <div class="value">
                    {{displayValue}}
                    <span v-html="unitText"></span>
                </div>
            </template>
            <template v-if="props.definition.displayType == 6">
                <div class="value">
                    <image-list-picker :editable="false" v-model="displayValue" class="row"></image-list-picker>
                </div>
            </template>
        </div>
    </div>
</template>


<script setup>
    import ImageListPicker from '@/Components/Shared/Form/ImageListPicker.vue'

    import { computed, defineProps } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps({
        value: String,
        definition: Object,
    });

    const { t } = useI18n();

    const displayValue = computed(() => 
        props.definition.valueSetValues.length > 0
            ? props.definition.valueSetValues.reduce((p, c) => c.value == props.value ? t(c.titleKey+'') : p, props.value)
            : props.value
    );

    console.log('edit measurement value set up', displayValue.value);

    const unitText = computed(() => {
        if (!props.definition.unitText) return "";
        return `${props.definition.unitText.replace(/\^(\d+)/g,'<span style="vertical-align:super;font-size:.8rem">$1</span>')}`;
    });
</script>