<template>
    <profile-image :profile-id="entityId" />
    <ul>
        <li><span class="status">{{statusText}}</span></li>
        <li data-test="person-name">{{fullName}}</li>
        <li v-if="location">{{location}}</li>
        <li v-if="requested">
            <span v-if="toDate">{{$t('listPersonStatusInfo.expiresLabel')}}: <date-display :iso-date="toDate" display-as="date" /></span>
            <span v-if="false">{{$t('listPersonStatusInfo.expiresLabel')}}: {{$t('listPersonStatusInfo.expiresNeverLabel')}}</span>
        </li>
    </ul>
</template>


<script>
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    
    export default {
        name: 'PersonStatusInfo',
        props: {
            entityId: String,
            requested: Boolean,
            hasAccess: Boolean,
            paused: { type: Boolean, default: false },
            fromDate: String,
            toDate: String,
            givenNames: String,
            familyName: String,
            location: String,
            image: { type: String, default: null },
        },
        setup(props) {
            const { t } = useI18n();

            const fullName = computed(() => {
                return [props.givenNames, props.familyName].join(' ');
            });

            const statusText = computed(() => {
                console.log('Computing Status Text', props);

                if (!props.requested)
                    return t('patientAccessList.newLabel');

                let isExpired = props.toDate != null && new Date(props.toDate) < new Date();

                if (!props.hasAccess)
                    return t('patientAccessList.pendingLabel');

                if (isExpired)
                    return t('patientAccessList.expiredLabel');

                if (props.paused)
                    return t('patientAccessList.pausedLabel');

                return t('patientAccessList.connectedLabel');
            });

            return {
                fullName,
                statusText,
            };
        }
    }
</script>