<template>
    <div class="container" id="noUser">
        <div class="row">
            <div class="button col-xs-push-2 col-xs-8">
                <button class="btn btn__text-icon btn__text-icon--large btn__text-icon--green full-width" 
                        @click="naviageTo('/welcome/login')" data-test="login-button">
                    <div>
                        <svg-icon icon-name="lock" />
                        <h1><span>{{$t('welcomeView.login')}}</span></h1>
                    </div>
                </button>
                <button class="btn btn__text btn__text--large btn__text--green-border full-width" 
                        @click="naviageTo('/welcome/register')" data-test="register-button">
                    <h4><span>{{$t('welcomeView.registerNewUser')}}</span></h4>
                </button>
            </div>

            <div class="scan col-xs-push-1 col-xs-10">
                <button @click="openScanAction">
                    <svg-icon icon-name="scan" />
                    <h5><span>{{$t('welcomeView.scanId')}}</span></h5>
                </button>
            </div>

            <div class="col-xs-push-1 col-xs-10 center-text pt-large">
                <div>{{$t('welcomeView.changeLanguage')}}</div>
                <button type="button" class="btn btn__text btn__lang" @click="changeLang('en')" data-test="lang-button-en">English</button> |
                <button type="button" class="btn btn__text btn__lang" @click="changeLang('sw')" data-test="lang-button-sw">Kiswahili</button> |
                <button type="button" class="btn btn__text btn__lang" @click="changeLang('uk')" data-test="lang-button-uk">українська</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useLanguageController } from '@/Components/Shared/LanguageController.js';
    import { useRouter } from 'vue-router';

    const { changeLang } = useLanguageController();
    const router = useRouter();

    const naviageTo = (url) =>  router.push(url);
</script>
