<template>
    <div class="radio clearfix">
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" v-if="label"/>
        <div :data-state="dataState">
            <label v-for="(element,key) in elements" :key="key">
                <input type="radio" v-model="value" name="name" :value="element.value" formnovalidate>
                <span class="button"></span>
                <span class="text">{{element.label}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    import Label from './Label.vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    export default {
        name: 'RadioList',
        components: {
            Label,
        },
        props: {
            modelValue: EmptyString,
            label: EmptyString,
            errorText: EmptyString,
            helper: EmptyString,
            pattern: NullableString,
            patternCaseSensitive: FalseBoolean,
            name: {
                type: String,
                default: '_' + Math.floor(Math.random() * 1000000000)
            },
            elements: {
                type: Array,
                default: () => []
            },
            required: FalseBoolean,
        },
        emits: ['update:modelValue','updated'],
        setup(props, context) {
            const { value, error, dataState } = useInputValidator(props, context);

            return {
                value,
                error,
                dataState,
            };
        }
    }
</script>