<template>
    <div id="patientEditEpisodeOfCare" class="overlay overlay--patient">
        <bar :title="$t(`editPregnancyEpisodeOfCare.title`)"
             :by="clinicianName" :by-label="$t('editPregnancyEpisodeOfCare.changedByLabel')"
             :date="editDate" :date-label="$t('editPregnancyEpisodeOfCare.changedDateLabel')"
             @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info">
                        <editor-content @edit="editValues(['termDate'])"
                                        @save="saveValues(['termDate'], 'changeTermDate')"
                                        @cancel="cancelValues(['termDate'])"
                                        data-test="changeTermDate"
                                        :lockValid="!isClinician">
                            <template v-slot:title>
                                {{$t('editPregnancyEpisodeOfCare.termDateLabel')}}
                            </template>
                            <template v-slot:value>
                                <date-display :iso-date="model.termDate"
                                              display-as="date"
                                              data-test="view-term-date" />
                            </template>
                            <template v-slot:default>
                                <date-time v-model="editing.termDate"
                                           name="termDate"
                                           :required="true"
                                           :helper="$t('editPregnancyEpisodeOfCare.termDateHelper')"
                                           :errorText="$t('editPregnancyEpisodeOfCare.termDateErrorText')"
                                           @updated="stateChanged"
                                           data-test="edit-term-date"></date-time>
                            </template>
                        </editor-content>

                        <div class="content">
                            <div class="row">
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="bloodTypeKey"
                                                             measured-icon-name="bloodtype"
                                                             :measured-label="$t('patientNavigation.bloodType')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredBloodTypeLabel"
                                                             create-measurement-route-name="eocedit create measurement"
                                                             return-to-name="editpregnancyepisodeofcare"
                                                             :disable-link-when-has-measurement="true">
                                        <template v-slot="{comps}">
                                            <b>
                                                {{comps.filter((c)=> c.code == bloodTypeGroupKey)[0].value}}
                                                {{comps.filter((c)=> c.code == bloodTypeRhesusKey)[0].deftitle}} {{comps.filter((c)=> c.code == bloodTypeRhesusKey)[0].title}}
                                                ({{comps.filter((c)=> c.code == bloodTypeGroupKey)[0].value}}{{comps.filter((c)=> c.code == bloodTypeRhesusKey)[0].value}})
                                            </b>
                                        </template>
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-image-button :measurement-definition-id="bloodTypeKey"></measurement-image-button>
                                </div>
                            </div>
                        </div>

                        <editor-content @edit="editValues(['firstEncounterDate'])"
                                        @save="saveValues(['firstEncounterDate'], 'changeEncounterDate')"
                                        @cancel="cancelValues(['firstEncounterDate'])"
                                        data-test="change-encounter-dates"
                                        :lockValid="!isClinician"
                                        v-if="pregnancyRules.needsExtraCare()">
                            <template v-slot:title>
                                {{$t('editPregnancyEpisodeOfCare.encounterDatesLabel')}}
                            </template>
                            <template v-slot:value>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <date-display :iso-date="model.firstEncounterDate" display-as="date"
                                                      :text-when-empty="$t('editPregnancyEpisodeOfCare.firstEncounterDateNotSpecifiedViewLabel')" data-test="view-first-encounter-date" />
                                    </div>
                                </div>
                            </template>
                            <template v-slot:default>
                                <date-time v-model="editing.firstEncounterDate"
                                           name="firstEncounterDate"
                                           :label="$t('editPregnancyEpisodeOfCare.firstEncounterDateLabel')"
                                           :helper="$t('editPregnancyEpisodeOfCare.firstEncounterDateHelperText')"
                                           :errorText="$t('editPregnancyEpisodeOfCare.firstEncounterDateErrorText')"
                                           @updated="stateChanged"
                                           data-test="edit-first-encounter-date"></date-time>
                            </template>
                        </editor-content>

                        <div class="content">
                            <div class="row">
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="syfilisKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editPregnancyEpisodeOfCare.syfilisLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredSyfilisLabel"
                                                             create-measurement-route-name="eocedit create measurement"
                                                             return-to-name="editpregnancyepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="malariaKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editPregnancyEpisodeOfCare.malariaLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredMalariaLabel"
                                                             create-measurement-route-name="eocedit create measurement"
                                                             return-to-name="editpregnancyepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="hivKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editPregnancyEpisodeOfCare.hivLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredHivLabel"
                                                             create-measurement-route-name="eocedit create measurement"
                                                             return-to-name="editpregnancyepisodeofcare">
                                    </measurement-link-button>
                                </div>
                                <div class="col-xs-6 col-sm-3">
                                    <measurement-link-button :measurement-definition-id="hepatitisCKey"
                                                             measured-icon-name="measurements"
                                                             :measured-label="$t('editPregnancyEpisodeOfCare.hepatitisCLabel')"
                                                             non-measured-icon-name="measurements"
                                                             :non-measured-label="nonMeasuredHepatitisCLabel"
                                                             create-measurement-route-name="eocedit create measurement"
                                                             return-to-name="editpregnancyepisodeofcare">
                                    </measurement-link-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup>
    import DateTime from '@/Components/Shared/Form/DateTime.vue';
    import DateDisplay from '@/Components/Shared/DateDisplay.vue';
    import EditorContent from '@/Components/Shared/EditorContent.vue';
    import MeasurementLinkButton from './MeasurementLinkButton.vue';
    import MeasurementImageButton from './MeasurementImageButton.vue';

    import { computed, defineProps, reactive, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { usePregnancyEpisodeOfCareService } from '@/Components/HealthData/EpisodeOfCare/PregnancyEpisodeOfCareService.js';
    import { usePregnancyEpisodeOfCareRules } from '@/Components/HealthData/EpisodeOfCare/PregnancyEpisodeOfCareRules.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        id: { type: String, required: true },
    });

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, invalidFields } = useFormValidator();
    const pregnancyRules = usePregnancyEpisodeOfCareRules();
    const service = usePregnancyEpisodeOfCareService();
    const caller = useExceptionwrappedCaller();

    const bloodTypeKey = 'bloodType';
    const bloodTypeGroupKey = 'bloodTypeGroup';
    const bloodTypeRhesusKey = 'bloodTypeRhesus';
    const syfilisKey = 'venerealDiseaseResearchLabTest';
    const malariaKey = 'malariaRapidDiagnostic';
    const hivKey = 'providerInitiatedTreatmentAndCare';
    const hepatitisCKey = 'hepatitisC';

    const isClinician = computed(() => store.getters.isClinician);

    const nonMeasuredBloodTypeLabel = computed(() =>
        isClinician.value
            ? t('editPregnancyEpisodeOfCare.registerNewBloodTypeLink')
            : t('editPregnancyEpisodeOfCare.notMeasuredBloodTypeLink'));
    const nonMeasuredSyfilisLabel = computed(() =>
        isClinician.value
            ? t('editPregnancyEpisodeOfCare.registerNewSyfilisLink')
            : t('editPregnancyEpisodeOfCare.notMeasuredSyfilisLink'));
    const nonMeasuredMalariaLabel = computed(() =>
        isClinician.value
            ? t('editPregnancyEpisodeOfCare.registerNewMalariaLink')
            : t('editPregnancyEpisodeOfCare.notMeasuredMalariaLink'));
    const nonMeasuredHivLabel = computed(() =>
        isClinician.value
            ? t('editPregnancyEpisodeOfCare.registerNewHivLink')
            : t('editPregnancyEpisodeOfCare.notMeasuredHivLink'));
    const nonMeasuredHepatitisCLabel = computed(() =>
        isClinician.value
            ? t('editPregnancyEpisodeOfCare.registerNewHepatitisCLink')
            : t('editPregnancyEpisodeOfCare.notMeasuredHepatitisCLink'));

    let clinicianName = ref('');
    let editDate = ref('');

    let model = reactive({
        episodeOfCareId: props.id,
        termDate: '',
        firstEncounterDate: '',
    });

    let editing = reactive({});
    for (var key in model)
        editing[key] = model[key];

    let actions = {
        async changeTermDate(data) {
            await caller.call(async () => {
                await service.changePregnancyTermDate(data.episodeOfCareId, data.termDate);
            });
        },
        async changeEncounterDate(data) {
            console.log('changeEncounterDate', data)
            await caller.call(async () => {
                await service.changePregnancyEncounterDate(data.episodeOfCareId, data.firstEncounterDate);
            });
        },
    };

    // used to check if a group of fields are valid or not (to deactivate or activate save-button)
    const checkValid = (fields) => {
        return invalidFields.value.filter((v) => fields.includes(v)).length == 0;
    };

    // when changing to edit mode, transfer modelvalues to editvalues
    const editValues = (fields) => {
        fields.forEach((fld) => editing[fld] = model[fld]);
    }

    const saveValues = async (fields, actionName) => {
        fields.forEach((fld) => model[fld] = editing[fld]);
        if (actionName && actionName !== '' && actionName in actions) {
            let data = { episodeOfCareId: model.episodeOfCareId };
            fields.forEach((fld) => data[fld] = model[fld]);
            await actions[actionName](data);
        }
        else {
            console.log(`${actionName} NOT FOUND!`);
        }
    };

    // cancel changes in an editor
    const cancelValues = (fields) => {
        fields.forEach((fld) => editing[fld] = model[fld]);
    };

    watchEffect(async () => {
        if (store.getters.getPatientId) {
            await caller.call(async () => {
                await service.loadPregnancyEpisodesOfCare();
            });
        }
    });

    watchEffect(() => {
        const eoc = store.getters.getPatientEpisodeOfCare(props.id);
        if (eoc) {
            model.episodeOfCareId = eoc.episodeOfCareId;
            model.termDate = eoc.termDate;
            model.firstEncounterDate = eoc.firstEncounterDate;

            // refresh editing structure with new data
            for (var key in model)
                editing[key] = model[key];

            clinicianName.value = [eoc.clinicianGivenNames, eoc.clinicianFamilyName].join(' ');
            editDate.value = eoc.date;
        }
    });

    //const lastBT = computed(() => store.getters.getNewestPatientMeasurementByCode([{ code: bloodTypeKey }, { code: '080304C1-A9D0-400B-BFF3-5291CE5E3743' }]));

    //const bloodType = computed(() => {
    //    let bt = lastBT.value;
    //    if (!bt)
    //        return t('patientNavigation.bloodTypeNotMeasuredLabel');

    //    return `${bt.measurementValue.components[0].value} ${t(bt.measurementValue.components[1].definition.titleKey + '')} `
    //        + `${t(bt.measurementValue.components[1].definition.valueSetValues.filter(vs => vs.value == bt.measurementValue.components[1].value)[0].titleKey + '')} `
    //        + `(${bt.measurementValue.components[0].value}${bt.measurementValue.components[1].value})`;
    //});

    const closeDialog = () => router.push({ name: 'episodeofcare' });
</script>
