import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';
import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

export const useCancerEpisodeOfCareService = () => {
    const store = useStore();

    const loadCancerEpisodesOfCare = async () => {
        const patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                let eocs = await fnLoadCancerEpisodesOfCare(patientId);

                store.commit('refreshCancerEpisodesOfCare', eocs);
            }
        );
    }   

    const createCancerEpisodeOfCare = async () => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        let eoc = {
            episodeOfCareType: 'CancerEpisodeOfCare',
            clinicianId
        };

        await serviceCall(
            async () => {
                eoc.episodeOfCareId = await fnCreateCancerEpisodeOfCare(patientId, eoc);

                eoc.date = new Date();
                eoc.clinicianGivenNames = store.getters.getClinician.givenNames;
                eoc.clinicianFamilyName = store.getters.getClinician.familyName;
                eoc.patientId = patientId;
                eoc.patientGivenNames = store.getters.getPatient.givenNames;
                eoc.patientFamilyName = store.getters.getPatient.familyName;

                store.commit('addEpisodeOfCare', eoc);
            }
        );
    }

    return {
        loadCancerEpisodesOfCare,
        createCancerEpisodeOfCare,
    }
}


// Server API-calls
const fnLoadCancerEpisodesOfCare = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/episodesofcare/cancer`);
    handleApiError(result, 'Error while getting patient\'s cancer episodes of care.');
    return result.data.episodesOfCare;
}

const fnCreateCancerEpisodeOfCare = async (patientId, eoc) => {
    let result = await dispatcher.postToApi(`patients/${patientId}/episodesofcare/cancer`, eoc);
    handleApiError(result, 'Error while creating patient\'s cancer episode of care.');
    return result.data.episodeOfCareId;
}
