import Crypto from 'crypto-js';
import { getPin } from '@/Shared/PinCache.js';

const getKeysByPartialKey = (partialKey) => {
    let res = [];
    for (var i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith(partialKey)) {
            res.push(key);
        }
    }
    return res;
} 

const get = (key, encrypted = true) => {
    let local = localStorage.getItem(key);
    if (local === null || local === undefined)
        return null;

    if (!encrypted) 
        return JSON.parse(local);

    let secret = getPin();
    let bytes = Crypto.AES.decrypt(local, secret);
    let stringBytes = bytes.toString(Crypto.enc.Utf8);
    if (stringBytes === '') return '';
    let val = JSON.parse(stringBytes);
    return val;
}

const set = (key, data, encrypted = true) => {
    if (data === null || data === undefined) {
        remove(key);
        return;
    }

    if (!encrypted) {
        localStorage.setItem(key, JSON.stringify(data));
        return;
    }

    //localStorage.setItem(key+'__CLEAR', JSON.stringify(data));

    let secret = getPin();
    let cipherText = Crypto.AES.encrypt(JSON.stringify(data), secret).toString();
    localStorage.setItem(key, cipherText);
}

const remove = (key) => {
    localStorage.removeItem(key);
    //localStorage.removeItem(key+'__CLEAR');
}

const has = (key) => {
    const lk = localStorage.getItem(key);
    return lk !== null && lk !== undefined;
}


export default {
    name: "LocalStorageProxy",
    getKeysByPartialKey,
    get,
    set,
    remove,
    has,
}
