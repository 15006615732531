
//console.log('PINCACHE MODULE LOADED', new Date());

let pin = null;
//let handle = null;

export const getPin = () => {
    //console.log('READING LOCAL CACHED PIN: ', pin);
    return pin;
}

export const setPin = (value) => {
    //console.log('LOCAL CACHED PIN UPDATED!')
    pin = value;
    //if(handle !== null) clearTimeout(handle);
    //handle = setTimeout(clearPin, 30 * 1000);
}

export const clearPin = () => {
    console.log('LOCAL CACHED PIN CLEARED!')
    pin = null;
//    if (handle !== null) {
//        clearTimeout(handle);
//        handle = null;
//    }
}

