<template>
    <div>
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" style="float:none;width:100%" />
        <div style="display:flex">
            <select class="select" v-model="model.prefix" formnovalidate style="width:auto">
                <option v-for="prefix in prefixes" :key="prefix.country">{{prefix.code}}</option>
            </select>
            <input v-model="model.phoneNumber"
                   type="int"
                   :placeholder="placeholder"
                   :data-state="dataState"
                   style="flex-grow:initial;margin-left:10px"
                   formnovalidate />
        </div>
    </div>
</template>

<script setup>
    import Label from './Label.vue';

    import { computed, defineEmits, defineProps, reactive, watch } from 'vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    const props = defineProps({
        modelValue: EmptyString,
        placeholder: EmptyString,
        label: EmptyString,
        errorText: EmptyString,
        helper: EmptyString,
        pattern: NullableString,
        patternCaseSensitive: FalseBoolean,
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
        required: FalseBoolean,
        type: {
            type: String,
            default: 'tel'
        }
    });
    const emit = defineEmits(['update:modelValue', 'updated']);

    const { value, error, dataState } = useInputValidator(props, { emit });

    // TODO: get from somewhere...
    const prefixes = computed(() => [
        { code: '+44', country: 'United Kingdom', iso: 'GB' },
        { code: '+45', country: 'Denmark', iso: 'DK' },
        { code: '+49', country: 'Germany', iso: 'DE' },
        { code: '+250', country: 'Rwanda', iso: 'RW' },
        { code: '+254', country: 'Kenya', iso: 'KE' },
        { code: '+255', country: 'Tanzania', iso: 'TZ' },
    ]);

    const getPrefixFromValue = (val) => {
        if (val === null)
            return '';
        let p = prefixes.value.filter((p) => val.startsWith(p.code));
        if (p.length == 0)
            return '';
        if (p.length == 1)
            return p[0].code;
    }

    const getPhoneNumberFromValue = (prefix, val) => {
        if (val === null) return '';
        return val.substr(prefix.length, val.length - prefix.length);
    }

    let prefix = getPrefixFromValue(value.value);
    let phoneNumber = getPhoneNumberFromValue(prefix, value.value);

    let model = reactive({
        prefix: prefix,
        phoneNumber: phoneNumber,
    });

    watch(model, () => {
        if (model.phoneNumber == '')
            value.value = '';
        else
            value.value = model.prefix + model.phoneNumber;
    });
</script>