<template>
    <div id="areaSelector">
        <div class="row" v-if="props.showTextLocation">
            <div class="col-xs-12">
                <div class="search online-only">
                    <input v-model="model.textLocation"
                           :placeholder="$t('areaSelector.textLocationPlaceholderText')"
                           @keyup.enter.prevent="doLocateLocation"
                           data-test="search-field" />
                    <svg-icon icon-name="search" />
                    <button class="btn btn__round btn__round--large btn__round--green online-only"
                            type="button"
                            @click.prevent="doLocateLocation"
                            data-test="search-button">
                        <div class="svg-container">
                            <svg-icon icon-name="crosshair" />
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <div class="row" style="padding-bottom:15px">
            <div class="col-xs-12">
                <GMapMap ref="areaMap"
                         :center="center"
                         :zoom="8"
                         map-type-id="terrain"
                         @click="clicked">
                    <GMapMarker :key="index"
                                v-for="(m,index) in markers"
                                :position="m.position"
                                :title="m.title"
                                :clickable="true"
                                :draggable="true"
                                :move="true"
                                @dragend="changedPosition">
                    </GMapMarker>
                    <GMapCircle :options="circleOptions" v-if="model.lat && model.lng"
                                :center="model" 
                                :radius="model.radiusKm * 1000"></GMapCircle>
                </GMapMap>
            </div>
        </div>

        <div class="row" v-if="props.showRadiusSelector">
            <div class="col-xs-12 pb-small">
                <Label :label="$t('areaSelector.radiusKmSliderLabelText', { distance: model.radiusKm })" />
            </div>
            <div class="col-xs-12">
                <Slider v-model="model.radiusKm" 
                        :lazy="false"
                        :min="1"
                        tooltip-position="bottom"
                        @change="areaChanged" />
            </div>
        </div>
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<script setup>
    import Label from '@/Components/Shared/Form/Label'
    import Slider from '@vueform/slider';

    import { computed, defineEmits, defineProps, nextTick, onMounted, reactive, ref, watch } from 'vue'

    const props = defineProps({
        modelValue: {
            type: Object,
            default: () => ({
                lat: null,
                lng: null,
                textLocation: null,
                radiusKm: 10,
            })
        },
        name: {
            type: String,
            default: '_' + Math.floor(Math.random() * 1000000000)
        },
        required: {
            type: Boolean,
            default: false
        },
        showTextLocation: {
            type: Boolean,
            default: true
        },
        showRadiusSelector: {
            type: Boolean,
            default: true
        },
        circleRadiusKm: Number,
    });
    const emit = defineEmits(['update:modelValue', 'updated', 'areaChanged']);

    const areaMap = ref();

    let model = reactive(props.modelValue);

    const isValid = () => {
        return !props.required || (
                model.lat != null &&
                model.lng != null &&
                model.textLocation != null
            );
    };

    watch(model, (value) => {
        emit('update:modelValue', value);
        emit('updated', { name: props.name, valid: isValid() });
    })


    const markers = computed(() => [{ position: model }]);

    let center = reactive({
        lat: 0,
        lng: 0
    });

    const circleOptions = reactive({
        strokeColor: '#0000FF',
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: '#0000FE',
        fillOpacity: 0.25,
    });


    const clicked = async (e) => {
        model.lat = e.latLng.lat();
        model.lng = e.latLng.lng();
        await doLocateAddress();
        emit('areaChanged');
    }

    const changedPosition = async (e) => {
        model.lat = e.latLng.lat();
        model.lng = e.latLng.lng();
        await doLocateAddress()
        emit('areaChanged');
    }

    onMounted(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                center.lat = position.coords.latitude;
                center.lng = position.coords.longitude;
            },
            error => {
                console.log(error);
            }
        );
        emit('updated', { name: props.name, valid: isValid() });
        nextTick(() => {
            // make sure container is notified on load if location is specified already
            if (model.lat && model.lng)
                emit('areaChanged');
        });
    });

    const google = computed(() => window.google);

    const areaChanged = () => emit('areaChanged');

    const getAddressComponent = (addrComps, type) => {
        const c = addrComps.filter(c => c.types.includes(type));
        if (c.length == 0) return '';
        return c[0].long_name;
    }


    const doLocateAddress = async () => {
        areaMap.value.$mapPromise.then(async () => {
            const geocoder = new google.value.maps.Geocoder();
            const location = {
                lat: model.lat,
                lng: model.lng
            };

            const response = await geocoder.geocode({ location });
            if (!response.results[0]) return;

            const results = response.results[0];
            console.log('address components', results.address_components)

            model.textLocation = results.formatted_address;
        })
    }


    const doLocateLocation = () => {
        if (!model.textLocation && !model.textLocation?.replace(/\s+/g, '')) return;

        areaMap.value.$mapPromise.then(() => {
            const geocoder = new google.value.maps.Geocoder();
            const address = model.textLocation; // address + " " + model.value.city + " " + model.value.country;

            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == google.value.maps.GeocoderStatus.OK) {
                    model.lat = results[0].geometry.location.lat();
                    model.lng = results[0].geometry.location.lng();

                    areaMap.value.panTo(new google.value.maps.LatLng(model.lat, model.lng));

                    emit('areaChanged');
                }
            });
        })
    }
</script>