<template>
    <div class="container dashboardNavigation" id="clinicianDashboardNavigation">
        <div class="dashboardNavigation__image">
            <profile-image :profile-id="usersProfileId" :alt="usersName" :key="usersImage" />
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="dashboardNavigation__welcome">
                    <h1>{{$t('clinicianDashboard.title')}}</h1>
                    <h1 class="pb-small"><b>{{usersName}}</b></h1>
                    <h5 class="text-gray">{{$t('clinicianDashboard.userTitle')}}</h5>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-10 col-xs-push-1">
                <div class="row">
                    <div class="col-xs-4">
                        <button class="btn btn__card btn__card--large" @click="navigateTo('mypatients')">
                            <div class="svg-container">
                                <svg-icon icon-name="patient-data" />
                            </div>
                            <div class="text-container">
                                <span style="font-size:1.3rem">{{$t('clinicianDashboard.myPatientsButton')}}</span>
                            </div>
                        </button>
                    </div>

                    <div class="col-xs-4">
                        <button class="btn btn__card btn__card--large only-online" @click="navigateTo('findpatient')">
                            <div class="svg-container">
                                <svg-icon icon-name="search" />
                            </div>
                            <div class="text-container">
                                <span style="font-size: 1.3rem">{{$t('clinicianDashboard.findNewPatientButton')}}</span>
                            </div>
                        </button>
                    </div>

                    <div class="col-xs-4">
                        <button class="btn btn__card btn__card--large only-online" @click="navigateTo('qr')">
                            <div class="svg-container">
                                <svg-icon icon-name="qr" />
                            </div>
                            <div class="text-container">
                                <span style="font-size:1.3rem">{{$t('clinicianDashboard.connectQRButton')}}</span>
                            </div>
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12">
                        <DashboardLinkList :links="links"></DashboardLinkList>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import DashboardLinkList from '@/Components/Shared/DashboardLinkList';

    import { computed, reactive, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { useBookingRequestService } from '@/Components/Consultations/BookingRequest/BookingRequestService.js';
    import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const bookingRequestService = useBookingRequestService();
    const messagingService = useMessagingService();
    const caller = useExceptionwrappedCaller();

    const bookingRequests = computed(() => store.getters.getClinicBookingRequests.filter((br) => br.rejectedState == 0));
    const bookingsCount = computed(() => bookingRequests.value.length);

    const messages = computed(() => store.getters.getClinicsConversations);
    const messagesCount = computed(() => messages.value.length);

    const usersProfileId = computed(() => store.getters.getUserProfileId);
    const usersName = computed(() => store.getters.getClinicianName);
    const usersImage = computed(() => store.getters.getUserImage);

    const links = reactive([
        {
            route: { name: 'clinician booking requests' },
            count: computed(() => bookingsCount.value),
            titleKey: 'clinicianDashboard.bookingRequestsButton',
            iconName: 'booking'
        },
        {
            route: { name: 'clinic conversations' },
            count: computed(() => messagesCount.value),
            titleKey: 'clinicianDashboard.messagesButton',
            iconName: 'mail'
        },
        {
            route: { name: 'push dashboard' },
            count: null,
            titleKey: 'clinicianDashboard.pushButton',
            iconName: 'smartphone'
        },
    ]);

    watchEffect(async () => {
        await caller.call(async () => {
            if (store.getters.getClinicianId != '') {
                console.log('LOADING BOOKING REQUESTS FOR CLINICIAN', store.getters.getClinicianId)
                await bookingRequestService.refreshClinicBookingRequests();
            }
            if (store.getters.getClinicianClinicId != '') {
                console.log('LOADING MESSAGES FOR CLINIC', store.getters.getClinicianClinicId)
                await messagingService.loadConversationsForClinic(store.getters.getClinicianClinicId);
            }
        });
    });

    const navigateTo = (name) => router.push({ name: name });
</script>
