<template>
    <div class="upload">
        <input type="file" @change="upload" accept="image/png, image/jpeg" capture="user">
    </div>
</template>
<script>
    import { EmptyString } from './PropTypes.js';
    import uploadService from './Upload.js';

    export default {
        name: 'Upload',
        props: {
            modelValue: EmptyString,
            isPrivate: {
                type: Boolean,
                default: true
            },
            userType: {
                type: String,
                required: true,
                validator: (value) => ['clinician', 'patient'].includes(value),
            },
            imageName: {
                type: String,
                required: true,
                validator: (value) => value.length > 0,
            },
        },
        emits: ['update:modelValue','updated','cdn'],
        setup(props, { emit }) {
            const upload = async (value) => {
                let uploadCDN = await uploadService.uploadFile(value.target.files[0], props.userType, props.imageName, false);

                emit('cdn', uploadCDN);
                emit('update:modelValue', uploadCDN);
            }

            return {
                upload,
            }
        }
    }
</script>
