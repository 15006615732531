import { reactive, watchEffect } from 'vue';
import { useStore } from 'vuex';

export const useImageViewer = () => {
    const store = useStore();

    let imageViewerData = reactive({
        open: false,
        imageSource: '',
    });

    const showImagePopup = (options) => {
        imageViewerData.imageSource = options.imageSource;
        imageViewerData.open = true;
        console.log('opening image viewer', options.imageSource);
    }

    const closeImagePopup = () => {
        imageViewerData.open = false;
        imageViewerData.imageSource = '';
        console.log('closing image viewer');
    }

    watchEffect(() => {
        if (!store.getters.getUserId)
            closeImagePopup();
    });

    return {
        imageViewerData,
        showImagePopup,
        closeImagePopup
    }
}