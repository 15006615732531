<template>
    <div class="row" style="padding-top:15px">
        <template v-for="attachment in displayAttachments" :key="attachment.url">
            <div class="col-xs-3 col-sm-2 col-md-2" data-test="message-attachment">
                <div class="square center-image"
                     :style="{'background-image':`url('${attachment.url}')`}"
                     @click="showImage(attachment)"
                     data-test="clickable-image">
                    <div v-if="props.canDelete">
                        <button @click.prevent.stop="deleteImage(attachment)" class="delete-round" data-test="delete-image-button">X</button>
                    </div>
                    <div v-if="props.canSelect">
                        <input type="checkbox"
                               @click.stop="selectImage(attachment)"
                               style="
                                   position: absolute;
                                   right: 3px;
                                   height: 22px;
                                   top: 3px;
                                   padding-left: 25px;
                                   padding-bottom: 25px;
                                   border-radius: 5px;
                                   width: 22px;
                               "
                               data-test="select-image-checkbox" />
                    </div>
                </div>
            </div>
        </template>

        <div class="col-xs-3 col-sm-2 col-md-2" v-if="props.canAdd" data-test="add-button-container">
            <div class="square dashed" @click="attachNewImage" data-test="add-button">
                <input type="file" @change="setAttachment" ref="uploadFile" />
                <div class="new-picture">
                    <svg-icon icon-name="plus"></svg-icon>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { computed, defineEmits, defineProps, inject, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import uploadService from '@/Components/Shared/Form/Upload.js';

    const props = defineProps({
        patientId: { type: String, required: true },
        attachments: { type: Array, default: null },
        canAdd: { type: Boolean, default: false },
        canDelete: { type: Boolean, default: false },
        canSelect: { type: Boolean, default: false },
    });
    const emit = defineEmits(['show', 'recorded', 'delete', 'select']);

    const store = useStore();
    const { t } = useI18n();
    const confirmDialog = inject('confirmDialog');

    const uploadFile = ref();

    let token = ref('');
    
    watchEffect(async () => {
        if (props.patientId && props.attachments && !token.value) {
            // This try...catch needs to be in place for now,
            // otherwise unauthorized clinicians can't even read the message
            // which they must be able to.
            //TODO: (maybe) separate images on messages from images on a patient
            //      in general (which need protection by SAS - token)
            try {
                token.value = await uploadService.getAzureToken(props.patientId, 'group', store.getters.getClinicianClinicId);
            }
            catch (e) {
                console.log('token can not be acquired');
            }
        }
    });

    // generate a list of attachment objects with a token applied
    const displayAttachments = computed(() => {
        if (!props.attachments || !token.value) return [];
        return props.attachments.map((a) => { return { url: a.url + '?' + token.value, selected: a.selected || false } });
    });

    const attachNewImage = () => uploadFile.value.click();

    const setAttachment = async (e) => {
        console.log('setAttachment', e);
        const files = e.target.files;
        if (files.length == 0) return;

        for (var i = 0, l = files.length; i < l; i++) {
            let filename = uploadService.createGuid();
            let uploadCDN = await uploadService.uploadFile(files[i], 'patient', filename, true, props.patientId);

            emit('recorded', { url: uploadCDN });
        }
    }

    const showImage = (attachment) => emit('show', attachment);

    const deleteImage = async (attachment) => {
        let confirmed = await confirmDialog({
            header: t('messageNew.confirmDeleteHeader'),
            body: t('messageNew.confirmDeleteBody')
        });

        if (confirmed) {
            let attachmentUrlWoToken = attachment.url.replace(`?${token.value}`, '');
            emit('delete', { url: attachmentUrlWoToken });
        }
    }

    const selectImage = (attachment) => {
        attachment.selected = !(attachment.selected || false);
        console.log('attachment selection changed', attachment);
        emit('select', attachment);
    }
</script>
