<template>
    <div id="diagnosis" class="overlay overlay--patient">
        <bar v-if="isClinician" :title="$t('diagnosisView.title')" @close="closeDialog" />
        <coded-concept-search v-if="isClinician" code-type="diagnosiscodes" navigate-on-select="create diagnosis"  class="online-only" />
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
        <list-diagnoses />
    </div>
</template>

<script setup>
    import ListDiagnoses from './ListDiagnoses.vue';
    import CodedConceptSearch from '@/Components/Terminology/Shared/CodedConceptSearch.vue';

    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useDiagnosisService } from '@/Components/HealthData/Diagnosis/DiagnosisService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const caller = useExceptionwrappedCaller();
    const diagnosisService = useDiagnosisService();

    const isClinician = computed(() => store.getters.isClinician);

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            await diagnosisService.loadDiagnoses();
        });
    });

    const closeDialog = () => router.push({ name: 'patient' });
</script>
