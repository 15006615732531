<template>
    <div class="overlay overlay--dashboard" id="findClinic">
        <bar :title="$t('findClinic.title')" @close="closeDialog" />

        <GMapMap ref="clinicMap"
                 :center="center"
                 :zoom="8"
                 @bounds_changed="boundsChanged($event)"
                 @dragend="dragEnd"
                 @dragstart="dragStart"
                 @zoom_changed="zoomChanged"
                 map-type-id="terrain">
            <GMapCluster :zoomOnClick="true"
                         :minimumClusterSize="2">
                <GMapMarker :key="index"
                            v-for="(m,index) in markers"
                            :position="m.position"
                            :title="m.title"
                            :clickable="true"
                            :draggable="false"
                            :move="true"
                            :icon="svgMarker"
                            @position_changed="noop"
                            @click="openMarker(m.clinicId)">
                    <GMapInfoWindow :closeclick="true"
                                    @closeclick="openMarker(null)"
                                    :opened="openedMarkerID === m.clinicId">
                        <div class="info-box">
                            <div class="info-box__name">{{m.title}}</div>
                            <div class="info-box__info">{{m.address}}<br />{{m.zipCode}} {{m.city}}, {{m.country}}</div>
                            <div class="info-box__info">
                                <div class="btn btn__text btn__text--green btn__text--short book online-only" @click="requestBooking(m.clinicId)"><span>{{$t('findClinic.bookButton')}}</span></div>
                                <div class="btn btn__text btn__text--green-border btn__text--short message online-only" @click="newConversation(m.clinicId)"><span>{{$t('findClinic.sendMessageButton')}}</span></div>
                                <a v-if="m.phone" class="extra" :href="`tel:${m.phone}`"><svg-icon icon-name="phone" /><span>{{$t('findClinic.call')}}</span></a>
                                <a v-if="m.mail" class="extra" :href="`mailto:${m.mail}`"><svg-icon icon-name="mail" /><span>{{$t('findClinic.mail')}}</span></a>
                            </div>
                        </div>
                    </GMapInfoWindow>
                </GMapMarker>
            </GMapCluster>
        </GMapMap>
        <find-clinic-search></find-clinic-search>
    </div>
</template>


<script>
    import FindClinicSearch from '@/Components/Patients/Patient/FindClinicSearch';

    import { ref, reactive, onMounted, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useClinicService } from '@/Components/Organizations/Clinic/ClinicService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    export default {
        name: 'FindClinic',
        components: {
            FindClinicSearch,
        },
        setup() {
            const router = useRouter();
            const service = useClinicService();
            const caller = useExceptionwrappedCaller();
            const { stateChanged, isValid } = useFormValidator();
            const svgMarker = {
                path: "M11.5 2.2C8.9 3.7 7.3 5.9 6.5 9c-.5 1.8.6 4.9 4.1 11.7 2.7 5.1 5.1 9.3 5.4 9.3.3 0 2.7-4.2 5.4-9.3 4.8-9.4 5.2-11.9 2.3-15.8-2.8-3.7-8.3-4.9-12.2-2.7z",
                fillColor: "#be1934",
                fillOpacity: 0.9,
                strokeWeight: 0,
                rotation: 0,
                scale: 1,
            };

            let clinic = ref(null);
            let clinics = ref([]);
            let openedMarkerID = ref(null);

            let center = reactive({
                lat: 55.2,
                lng: 10.51
            });
            let markers = ref([]);
            let clinicMap = ref();
            let dragging = false;
            let hasBoundsChanged = false;
            let zoomed = false;
            let latestBounds = null;


            const updateMapMarkers = (res) => {
                console.log('updateMapMarkers', res);
                markers.value = res.map((c) => {
                    return {
                        position: {
                            lat: c.latitude,
                            lng: c.longitude,
                        },
                        title: c.name,
                        clinicId: c.clinicId,
                        address: c.address,
                        zipCode: c.zipCode,
                        city: c.city,
                        country: c.country,
                        phone: c.phone,
                        email: c.email,
                    }
                });
                console.log('updateMapMarkers - done', res);
            }

            const requestBooking = (clinicId) => {
                router.push({ name: 'clinic booking', params: { clinicId } });
            }

            const newConversation = (clinicId) => {
                router.push({
                    name: 'new patient conversation message',
                    params: { clinicId }
                });
            }

            const updateClinics = async () => {
                await caller.call(async () => {
                    let { la, lo } = latestBounds;
                    clinics.value = await service.getClinicsInRange(lo.lat, lo.lng, la.lat, la.lng);
                    updateMapMarkers(clinics.value);
                });
            }

            const zoomChanged = () => { zoomed = true; }
            const dragStart = () => { dragging = true; }
            const dragEnd = async () => {
                dragging = false;
                if (hasBoundsChanged) {
                    await updateClinics();
                }
            }
            const boundsChanged = async ($event) => {
                if (!$event) return;
                hasBoundsChanged = true;
                let ne = $event.getNorthEast();
                let sw = $event.getSouthWest();
                let lo = { lat: ne.lat(), lng: ne.lng() };
                let la = { lat: sw.lat(), lng: sw.lng() };
                latestBounds = { lo, la };
                if (!dragging && zoomed) {
                    zoomed = false;
                    await updateClinics();
                }
            }

            onMounted(() => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        center.lat = position.coords.latitude;
                        center.lng = position.coords.longitude;
                    },
                    error => {
                        console.log(error);
                    }
                );
            });

            let updateTrigger = ref(0);
            watch(() => clinicMap.value, (gmap) => {
                if (gmap) {
                    gmap.$mapPromise.then(map => {
                        console.log('clinicMap promise', map);
                        center.lat = map.center.lat();
                        center.lng = map.center.lng();
                        
                    });
                    setTimeout(() => {
                        updateTrigger.value += 1;
                        //updateClinics();
                    }, 2000);
                }
            });
            watch(() => updateTrigger.value, async () => await updateClinics());

            const showClinicInfo = (clinicId) => {
                console.log('showClinicInfo', clinicId);
                let clinicFound = clinics.value.filter((c) => c.clinicId == clinicId);
                if (clinicFound && clinicFound.length) {
                    clinic.value = clinicFound[0];
                }
            }

            const openMarker = (clinicId) => {
                openedMarkerID.value = clinicId;
                showClinicInfo(clinicId);
            }

            const closeDialog = () => {
                router.push({ name: 'dashboard' });
            }

            const noop = () => { }

            return {
                svgMarker,
                requestBooking,
                openedMarkerID,
                openMarker,
                zoomChanged,
                dragStart,
                dragEnd,
                boundsChanged,
                center,
                markers,
                showClinicInfo,
                clinicMap,
                clinic,
                clinics,
                stateChanged,
                isValid,
                closeDialog,
                noop,
                newConversation,
            };
        }
    }
</script>