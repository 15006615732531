<template>
    <div class="container dashboardNavigation" id="patientDashboardNavigation">
        <div class="dashboardNavigation__image">
            <profile-image :profile-id="usersProfileId" :alt="usersProfileName" />
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="dashboardNavigation__welcome">
                    <h1>{{$t('patientDashboard.title')}}</h1>
                    <h1><b>{{usersProfileName}}</b></h1>
                </div>
            </div>

            <div class="col-xs-10 col-xs-push-1">
                <div class="row">
                    <div class="col-xs-6" v-if="!isRelatedPatient">
                        <button class="btn btn__card btn__card--large" @click="navigateTo('myrelations')">
                            <div class="svg-container">
                                <svg-icon icon-name="family" />
                            </div>
                            <div class="text-container">
                                <span>{{$t('patientDashboard.myRelationsButton')}}</span>
                            </div>
                        </button>
                    </div>

                    <div class="col-xs-6" v-if="isRelatedPatient">
                        <button class="btn btn__card btn__card--large" @click="returnToSelf">
                            <div class="svg-container">
                                <svg-icon icon-name="return" />
                            </div>
                            <div class="text-container">
                                <span>{{$t('patientDashboard.returnToYourPatientButton')}}</span>
                            </div>
                        </button>
                    </div>

                    <div class="col-xs-6">
                        <button class="btn btn__card btn__card--large" @click="navigateTo('myqr')">
                            <div class="svg-container">
                                <svg-icon icon-name="qr" />
                            </div>
                            <div class="text-container">
                                <span>{{$t('patientDashboard.qrLabel')}}</span>
                            </div>
                        </button>
                    </div>

                    <div class="col-xs-12">
                        <DashboardLinkList :links="links"></DashboardLinkList>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import DashboardLinkList from '@/Components/Shared/DashboardLinkList';

    import { computed, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import navigationLinks from './PatientDashboardNavigation.json';

    const store = useStore();
    const router = useRouter();
    const patientService = usePatientService();
    const caller = useExceptionwrappedCaller();

    const links = reactive(navigationLinks);

    const messages = computed(() => store.getters.getPatientsConversations);
    const messagesCount = computed(() => messages.value.length);
    const usersProfileId = computed(() => store.getters.patientId);
    const usersProfileName = computed(() => store.getters.getUsersProfileName);
    const isRelatedPatient = computed(() => store.getters.isRelatedPatient);

    const navigateTo = (name) => router.push({ name: name });

    const returnToSelf = async () => {
        await caller.call(async () => {
            await patientService.switchToRelatingPatient();
        });
    }
</script>
