<template>
    <div class="col-xs-3 col-sm-2 col-md-2">
        <div class="image-picker-frame square dashed" @click="attachNewImage" data-test="add-button">
            <input type="file" @change="setAttachment" ref="uploadFile" />
            <div class="new-picture">
                <svg-icon icon-name="plus"></svg-icon>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, ref } from 'vue';
    import uploadService from '@/Components/Shared/Form/Upload.js';

    const service = inject('imageListPickerService');
    if (!service) throw new Error('imageListPickerService not provided');

    const uploadFile = ref();

    const attachNewImage = () => uploadFile.value.click();

    const setAttachment = async (e) => {
        const files = e?.target?.files;
        if (!files || files.length == 0) return;

        for (var i = 0, l = files.length; i < l; i++) {
            const imageId = uploadService.createGuid();

            const base64data = await uploadService.getBase64(files[i]);
            service.addImage(imageId, base64data);
        }
    }
</script>
