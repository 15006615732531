<template>
    <div class="overlay" id="logon">
        <bar title="" @close="goBack" />
        <div class="container">
            <div class="row">
                <h2 class="full-width center-text pb-small"><b>{{$t('offlineLogonView.title')}}</b></h2>
                <div class="col-xs-12 center-text">
                    <span v-html="$t('offlineLogonView.bodyText')"></span>
                </div>
                <div class="col-xs-push-2 col-xs-8 center-text pt-large">
                    <button class="btn btn__link" 
                            @click="goBack"
                            data-test="back-to-home-button">
                        <h3>{{$t('offlineLogonView.backToHome')}}</h3>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const goBack = () => router.push({ name: 'welcome' });
    const closeDialog = () => router.back();
</script>