<template>
    <div class="gray min-100" id="list-diagnoses">
        <bar :title="$t('listDiagnoses.title')" :full="isClinician" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head">
                            <div class="col-xs-2"><span><b>{{$t('listDiagnoses.dateHeader')}}</b></span></div>
                            <div class="col-xs-5"><span><b>{{$t('listDiagnoses.descriptionHeader')}}</b></span></div>
                            <div class="col-xs-3"><span><b>{{$t('listDiagnoses.statusHeader')}}</b></span></div>
                            <div class="col-xs-2 center-text"><span><b>{{$t('listDiagnoses.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <div class="item"
                                :class="{deleted: diagnosis.deleted, abated: diagnosis.abated}"
                                v-for="diagnosis in diagnoses" :key="diagnosis.diagnosisId"
                                @click="diagnosisClicked(diagnosis.diagnosisId)">
                                <div class="col-xs-2">
                                    <span><date-display :iso-date="diagnosis.date" display-as="date" /></span>
                                </div>
                                <div class="col-xs-5">
                                    <span>{{diagnosis.diagnosisText}}</span>
                                </div>
                                <div class="col-xs-3">
                                    <span v-if="diagnosis.abated">{{$t('listDiagnoses.statusAbate')}}</span>
                                    <span v-if="!diagnosis.abated && diagnosis.chronical">{{$t('listDiagnoses.statusChronical')}}</span>
                                </div>
                                <div class="col-xs-2">
                                    <div class="svg">
                                        <svg-icon icon-name="eye" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const store = useStore();
    const router = useRouter();

    const isClinician = computed(() => store.getters.isClinician);

    const diagnoses = computed(() => {
        let diags = store.getters.getPatientDiagnoses.map((a) => a);
        return diags.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
    });

    const diagnosisClicked = (diagnosisId) => router.push({ name: 'edit diagnosis', params: { id: diagnosisId } });

    const closeDialog = () => router.push({ name: 'patient' });
</script>