<template>
    <div class="col-xs-12">
        <form>
            <text-area v-model="reason"
                       name="reason"
                       :required="true"
                       :label="$t('handleBookingRequests.rejectionMessageLabel')"
                       :errorText="$t('handleBookingRequests.rejectionMessageErrorText')"
                       :helper="$t('handleBookingRequests.rejectionMessageHelperText')"
                       :placeholder="$t('handleBookingRequests.rejectionMessagePlaceholder')"
                       @updated="stateChanged" 
                       data-test="bookingrequest-rejectreason"/>
        </form>
        <hr />
    </div>
    <div class="col-xs-12">
        <button type="button" class="btn btn__text btn__text--green-border left" @click="cancel">
            <h4>{{$t('general.cancel')}}</h4>
        </button>
        <button type="button"
                class="btn btn__text btn__text--green right online-only"
                :class="{disabled:!isValid||working}"
                :disabled="!isValid||working"
                @click="sendRejection"
                data-test="bookingrequest-sendrejection">
            <h4>{{$t('handleBookingRequests.sendRejectionButton')}}</h4>
        </button>
    </div>
</template>
<script setup>
    import TextArea from '@/Components/Shared/Form/TextArea';

    import { defineEmits, defineProps, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useBookingRequestService } from './BookingRequestService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        bookingRequestId: String,
    });
    const emit = defineEmits(['cancel']);

    const store = useStore();
    const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const service = useBookingRequestService();
    const caller = useExceptionwrappedCaller();

    let reason = ref('');

    let working = ref(false);

    const sendRejection = async () => {
        if (!working.value) {
            await caller.call(async () => {
                working.value = true;
                const clinician = store.getters.getClinician;
                await service.rejectBookingRequest(clinician.clinicId, props.bookingRequestId, reason.value);
                router.push({ name: 'clinician booking requests' });
            });
        }
    }

    const cancel = () => emit('cancel');
</script>