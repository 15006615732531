<template>
    <div id="clinicianclinicconversationmessagenew" class="overlay overlay--patient fixed-button">
        <bar :title="$t('clinicianConversationMessageNew.title')" @close="closeDialog" />

        <message-new v-bind="data" @messagesent="createNewMessage"></message-new>
    </div>
</template>


<script setup>
    import MessageNew from '@/Components/Consultations/Messaging/MessageNew';

    import { computed, defineProps, reactive } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const props = defineProps({
        patientId: { type: String }
    });

    const store = useStore();
    const router = useRouter();

    const clinicId = computed(() => store.getters.getClinicianClinicId);
    const clinicianId = computed(() => store.getters.getClinicianId);

    const data = reactive({
        clinicId: clinicId.value,
        patientId: props.patientId,
        clinicianId: clinicianId.value
    });

    const createNewMessage = () => router.back();

    const closeDialog = () => router.back();
</script>