<template>
    <div class="search-results">
        <div class="container">
            <div class="row">
                <div class="clinic" v-for="clinic in clinics" :key="clinic.clinicId">
                    <div class="col-xs-8">
                        <clinic-list-info v-bind="clinic"></clinic-list-info>
                    </div>
                    <div class="col-xs-4">
                        <button type="button" style="margin-bottom:15px" class="btn btn__text btn__text--green online-only" @click="requestBooking(clinic.clinicId)">
                            <h4>{{$t('findClinic.bookButton')}}</h4>
                        </button>
                        <button type="button" style="margin-bottom:0;padding:0 5px 0 0;min-height:22px;clear:both;display:block;" class="btn btn__text btn__text--white online-only" @click="sendMessage(clinic.clinicId)">
                            {{$t('findClinic.sendMessageButton')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
    import { useRouter } from 'vue-router';
    import ClinicListInfo from './ClinicListInfo';

    export default {
        name: 'ListClinics',
        components: {
            ClinicListInfo,
        },
        props: {
            clinics: Array,
        },
        setup() {
            const router = useRouter();

            const requestBooking = (clinicId) => {
                router.push({
                    name: 'clinic booking',
                    params: { clinicId }
                });
            }

            const sendMessage = (clinicId) => {
                router.push({
                    name: 'new patient conversation message',
                    params: { clinicId }
                });
            }

            return {
                requestBooking,
                sendMessage,
            };
        }
    }
</script>