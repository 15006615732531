import { inject } from 'vue';

export const useExceptionwrappedCaller = (enterPin, notHandler) => {
    const enterPinDialog = enterPin || inject('enterPinDialog');
    const notificationHandler = notHandler || inject('notificationHandler');

    const call = async (action) => {
        let cnt = 3;
        while (cnt-- > 0) {
            try {
                await action();
                cnt = 0;
            }
            catch (e) {
                const msg = e.message || e || '';
                if (msg.includes('pinMissing')) {
                    notificationHandler?.terminate();
                    const enteredOK = await enterPinDialog();
                    if (!enteredOK) {
                        throw new Error('pinCanceled');
                    }
                    notificationHandler?.start();
                }
                else {
                    throw e;
                }
            }
        }
    }

    return {
        call,
    }
}
