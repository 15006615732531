<template>
    <div class="overlay overlay--dashboard" id="findPatients">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('findNewPatient.title')}}</b></h2>
                    <h5 class="full-width center-text pb-small">{{$t('findNewPatient.requestToConnect')}}</h5>
                    <div class="search online-only">
                        <input v-model="searchTerm"
                               :placeholder="$t('findNewPatient.searchInputPlaceholder')"
                               @keyup.enter="searchPatients"
                               data-test="search-field" />
                        <svg-icon icon-name="search" />
                        <button class="btn btn__round btn__round--large btn__round--green online-only" type="button"
                                @click="searchPatients" data-test="search-button">
                            <div class="svg-container">
                                <svg-icon icon-name="refresh" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <ListPatients :patients="patients" @buttonclicked="selectPatient"></ListPatients>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { inject, onMounted, ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import ListPatients from '@/Components/Patients/Patient/ListPatients.vue';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const infoDialog = inject('infoDialog');
    const patientService = usePatientService();
    const patientAccessService = usePatientAccessService();
    const caller = useExceptionwrappedCaller();

    let qr = '';
    let patients = ref([]);
    let searchTerm = ref('');

    onMounted(async () => {
        qr = route.params.qr;

        if (qr) {
            searchTerm.value = qr;
            await searchPatients();
        }
    });

    const searchPatients = async () => {
        await caller.call(async () => {
            const searchResult = await patientService.findPatients(searchTerm.value);
            patients.value = searchResult.map((r) => {
                return {
                    patientId: r.id,
                    givenNames: r.givenNames,
                    familyName: r.familyName,
                    image: r.image.url,
                }
            });
        });
    }

    const selectPatient = async (e) => {
        if (e.action == 'view') {
            await navigateToPatient(e.patient);
        }
        else if (e.action == 'connect') {
            await connectToPatient(e.patient);
        }
    }

    const connectToPatient = async (patient) => {
        console.log('connect to patient', patient);
        await caller.call(async () => {
            await patientAccessService.requestPatientAccessTo(patient.patientId);
        });
    };

    const navigateToPatient = async (patient) => {
        try {
            await caller.call(async () => {
                await patientService.switchPatient(patient.patientId);
                router.push({ name: 'patient' });
            });
        }
        catch (e) {
            console.log('navigateToPatient.catch', e);
            if (e.includes('Patient is not available'))
                await infoDialog({
                    header: t('findNewPatient.patientNotAvailableAlertHeader'),
                    body: t('findNewPatient.patientNotAvailableAlertBody'),
                });
            else
                throw e;
        }
    }

    const closeDialog = () => router.push({ name: 'dashboard' });
</script>
