import { ref, reactive, watch, computed } from 'vue';

export const useFormValidator = () => {
    // keeps track of all the fields that needs validation
    let fieldStates = reactive({ f: {} });
    let isValid = ref(true);

    // updates the state of all fields that needs validation
    watch(fieldStates, () => {
        //console.log('recalculating create button lock', fieldStates.f);

        let allGood = true;
        for (var i in fieldStates.f) {
            allGood = allGood && fieldStates.f[i];
        }

        isValid.value = allGood;
    });

    const stateChanged = ({ name, valid }) => {
        fieldStates.f[name] = valid;
        //console.log('stateChanged', name, valid, fieldStates.f);
    };

    const invalidFields = computed(() => {
        let tmp = [];
        for (var fld in fieldStates.f)
            if (!fieldStates.f[fld])
                tmp.push(fld);
        return tmp;
    });

    return {
        isValid: computed(() => isValid.value),
        stateChanged,
        invalidFields 
    }
}
