<template>
    <div class="overlay" id="logon">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-10 col-xs-push-1">
                    <h2 class="full-width center-text pb-small"><b>{{$t('loginView.title')}}</b></h2>
                    <h5 class="full-width center-text pb-medium">
                        <span>{{$t('loginView.dontHaveProfile')}} <button class="btn btn__link" @click="navigateTo('register')" data-test="register-here-button"><span>{{$t('loginView.registerHere')}}</span></button></span>
                    </h5>
                    <p>Enter password for {{props.username}}</p>
                    <form @submit.prevent="login">
                        <div>
                            <label>{{$t('loginView.passwordLabel')}}</label>
                            <input id="password" :type="showPassword ? 'text' : 'password'" v-model="state.password" data-test="password-field">

                            <button type="button" class="toggleShowPassword" @click="toggleShowPassword">
                                <svg-icon v-if="!showPassword && state.password != ''" icon-name="eye"></svg-icon>
                                <svg-icon v-if="showPassword && state.password != ''" icon-name="eye-crossed"></svg-icon>
                            </button>

                        </div>
                        <div class="error">{{state.error}}</div>
                        <div class="row">
                            <div class="col-xs-push-2 col-xs-8">
                                <button type="submit" class="btn btn__text btn__text--large btn__text--green full-width online-only" data-test="logon-button">
                                    <h1><span>{{$t('loginView.loginButton')}}</span></h1>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row">
                        <div class="col-xs-push-1 col-xs-10 center-text pb-medium">
                            <h5>{{$t('loginView.forgotPassword')}} <button class="btn btn__link" @click="navigateTo('reset')" data-test="reset-password-here"><span>{{$t('loginView.resetHere')}}</span></button></h5>
                        </div>
                        <div class="col-xs-push-2 col-xs-8 center-text">
                            <button class="btn btn__link" @click="closeDialog" data-test="go-back">
                                <h3>{{$t('general.goBackButton')}}</h3>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style>
    #password::-ms-reveal {
        display: none;
    }

    .toggleShowPassword {
        position: absolute;
        height: 40px;
        width: 40px;
        right: 10px;
    }

        .toggleShowPassword svg {
            position: relative;
            height: 50%;
            width: 50%;
        }
</style>

<script setup>
    import { defineProps, inject, reactive, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useUserService } from '@/Components/Users/User/UserService.js';

    const props = defineProps({
        username: { type: String, required: true }
    });

    let showPassword = ref(false);
    const toggleShowPassword = () => {
        showPassword.value = !showPassword.value;
    };


    const router = useRouter();
    const { t } = useI18n();
    const { logonUser } = useUserService();

    const notHandler = inject('notificationHandler');
    const alertDialog = inject('alertDialog');

    let state = reactive({
        password: '',
        error: ''
    });

    const navigateTo = (routeName) => router.push({ name: routeName });

    const login = async () => {
        try {
            console.log("Logging on ...", props.username);
            await logonUser(props.username, state.password);
            notHandler.start();
            navigateTo('dashboard');
        }
        catch (e) {
            if (e.code && e.code == 110) {
                await alertDialog({
                    header: t('loginView.errorLoggingInAlertTitle'),
                    body: t('loginView.confirmationMissingError'),
                    buttons: 'accept',
                    accept: t('general.confirm')
                });

                var paramsList = e.hint.split(',');
                var query = {};
                query['id'] = paramsList[0];
                if (paramsList.length > 1) query[paramsList[1]] = '1';
                if (paramsList.length > 2) query[paramsList[2]] = '1';

                router.push({ name: 'verify', query });
                console.log('after routing');
            }
            else {
                var msg = e.message || e || '[unreadable error]';
                console.log(msg);
                state.error = t('errors.loginFailed');
            }
        }

    };

    const closeDialog = () => navigateTo('welcome');
</script>

