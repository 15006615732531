import { ref, watch, computed } from 'vue';

const getRegExp = (props) => {
    if (!props) return /.*/gi;
    if (props.pattern && props.pattern != '') {
        //console.log("RegExp update", props.pattern, props.patternCaseSensitive);
        let rxOptions = props.patternCaseSensitive ? '' : 'i';
        return new RegExp(props.pattern, rxOptions);
    }
    if (props.type == 'email') return /^([a-z0-9_.+-]+)@([a-z0-9_.+-]+)\.([a-z]{2,})$/gi;   // no special and one @ etc.
    if (props.type == 'tel') return /^\+[1-9]\d{1,14}$/gi;   // number of 2-15 digits prefixed with + (plus)
    //if (props.type == 'password') return /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/gi;   // at least 12 chars/words
    if (props.type == 'password') return /.{5,}/gi; // at least 5 chars
    if (props.type == 'guid') return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (props.type == 'float') return /^(\d+\.\d+|\d*\.\d+|\d+|\.\d+)$/gi;
    if (props.type == 'int') return /^\d+$/gi;

    return /.*/gi;   // otherwise empty or whatever
};

export const useInputValidator = (props, { emit }) => {
    //console.log('Initializing useInputValidator', props);

    let inputState = ref(null);   // 
    let error = ref(false);     // indicate whether the input is in an error state or not

    const value = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            if (props.type == 'float') {
                let fv = parseFloat(value);
                emit('update:modelValue', !isNaN(fv) ? fv : 0);
            }
            else if (props.type == 'int') {
                let iv = parseInt(value);
                emit('update:modelValue', !isNaN(iv) ? iv : 0);
            }
            else {
                emit('update:modelValue', value);
            }
        }
    });

    let rx = getRegExp(props);

    const validate = (val) => {
        if ('date' == props.type) {
            //console.log('INPUT VALIDATOR - DATE TYPE INPUT', props.required, Date.parse(val));
            let parsedDate = Date.parse(val);
            // empty or valid date when not required or valid date when required
            return !props.required || !isNaN(parsedDate);
        }
        else if ('time' == props.type) {
            //console.log('INPUT VALIDATOR - DATE TYPE INPUT', props.required, val);
            let parsedDate = /([0-1][0-9]|2[0-3]):[0-5][0-9]/g.test(val);
            // empty or valid date when not required or valid date when required
            return !props.required || parsedDate;
        }
        else if (props.type == 'dropdownbox') {
            let v = true;
            if (!props.required)
                v = true;
            else if (val == null && props.elements != null)
                v = false;
            else if (props.elements == null)
                v = true;
            else 
                v = props.elements.filter((v) => v.key == val).length == 1;
            //console.log('VALIDATE INPUT DROPDOWN', props.name, props.required, '[' + val + ']', props.elements, v);
            return v;
        }
        else {  // use reg ex to validate contents
            rx.lastIndex = 0;
            // if it is empty then validity depends on if it is required or not
            if (val == null || val.length == 0)
                return !props.required;

            // otherwise test the value against the reg ex pattern
            return rx.test(val);
        }
    };

    const updatedValue = (nv) => {
        nv = nv || '';
        let isValid = validate(nv);
        error.value = !isValid; //(props.required || nv.length > 0) && !isValid;
        inputState.value = nv.length == 0 && !props.required ? null : error.value;

        emit('updated', { name: props.name, valid: isValid });
    };

    watch(() => props.pattern, () => {
        //console.log("props pattern changed.", props.name, props.pattern);
        rx = getRegExp(props);
        updatedValue(value.value);
    });

    watch(value, updatedValue);

    emit('updated', { name: props.name, valid: validate(value.value) });

    const dataState = computed(() => inputState.value != null ? !error.value : '');

    return {
        value,
        error: computed(() => error.value),
        dataState,
    }
}
