import { useMeasurementHelperService } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';


export const usePregnancyEpisodeOfCareRules = () => {
    const { newestBloodTypeRhesusComponent } = useMeasurementHelperService();

    const needsExtraCare = () => {
        // RhD neg needs extra care otherwise not
        if (!newestBloodTypeRhesusComponent?.value) return false;
        return newestBloodTypeRhesusComponent.value.value == "-";
    }

    const suggestAntiDInjectionDate = (termDate) => {
        if (!termDate || isNaN(new Date(termDate).getTime())) return null;
        let date = new Date(termDate);
        date.setDate(date.getDate() - 21);
        return date;
    }

    return {
        needsExtraCare,
        suggestAntiDInjectionDate,
    }
}