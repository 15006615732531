<template>
    <div>
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" />
        <input :type="theType" v-model="value" :placeholder="placeholder" :data-state="dataState" formnovalidate />
    </div>
</template>

<script>
    import { computed } from 'vue';
    import Label from './Label.vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    export default {
        name: 'Number',
        components: {
            Label,
        },
        props: {
            modelValue: Number,
            type: {
                type: String,
                default: 'number',
                validator(value) {
                    return ['number', 'phone', 'float', 'int'].includes(value);
                }
            },
            placeholder: EmptyString,
            label: EmptyString,
            errorText: EmptyString,
            helper: EmptyString,
            pattern: NullableString,
            patternCaseSensitive: FalseBoolean,
            name: {
                type: String,
                default: '_' + Math.floor(Math.random() * 1000000000)
            },
            required: FalseBoolean,
        },
        emits: ['update:modelValue','updated'],
        setup(props, context) {
            const { value, error, dataState } = useInputValidator(props, context);
            const theType = computed(() => ['number', 'phone'].includes(props.type) ? props.type : 'text');

            return {
                theType,
                value,
                error,
                dataState,
            };
        }
    }
</script>