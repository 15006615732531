<template>
    <profile-image :profile-id="props.entityId" />
    <ul>
        <li><span class="status" data-test="related-person-name">{{fullName}}</span></li>
        <li>{{relationShip}}</li>
        <li v-if="phone"><a :href="`tel:${props.phone}`">{{props.phone}}</a></li>
        <li v-if="email">{{props.email}}</li>
    </ul>
</template>


<script setup>
    import { computed, defineProps, onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const props = defineProps({
        entityId: String,
        givenNames: String,
        familyName: String,
        phone: { type: String, default: null },
        email: { type: String, default: null },
        relationType: Number,
        hasAccess: Boolean,
    });

    let translate = ref([]);

    onMounted(() => {
        translate.value = [
            t('relationTypeList.parentToPatientLabel'),
            t('relationTypeList.childOfPatientLabel'),
            t('relationTypeList.spouseOfPatientLabel'),
            t('relationTypeList.siblingToPatientLabel'),
            t('relationTypeList.otherFamilyOfPatientLabel'),
            t('relationTypeList.friendOfPatientLabel'),
            t('relationTypeList.otherRelationToPatientLabel')
        ]
    });

    const fullName = computed(() => [props.givenNames, props.familyName].join(' '));

    const relationShip = computed(() => translate[props.relationType]);
</script>