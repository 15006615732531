<template>
    <div class="gray min-100" id="list-consultationnotes">
        <bar :title="$t('listConsultationNotes.title')" :full="isClinician" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head">
                            <div class="col-xs-2"><span><b>{{$t('listConsultationNotes.dateHeader')}}</b></span></div>
                            <div class="col-xs-5"><span><b>{{$t('listConsultationNotes.noteHeader')}}</b></span></div>
                            <div class="col-xs-3 center-text"><span><b>{{$t('general.audio')}}</b></span></div>
                            <div class="col-xs-2 center-text"><span><b>{{$t('listConsultationNotes.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <div class="item"
                                 v-for="consultationNote in consultationNotes"
                                 :key="consultationNote.consultationNoteId">
                                <div class="col-xs-2" @click="consultationNoteClicked(consultationNote.consultationNoteId)">
                                    <date-display :iso-date="consultationNote.date" display-as="date" />
                                </div>
                                <div class="col-xs-5" @click="consultationNoteClicked(consultationNote.consultationNoteId)">
                                    <span>{{consultationNote.note}}</span>
                                </div>
                                <div class="col-xs-3">
                                    <media-player :audio="consultationNote.audioNote" :smallPlayer="true"></media-player>
                                </div>
                                <div class="col-xs-2">
                                    <div class="svg" @click="consultationNoteClicked(consultationNote.consultationNoteId)">
                                        <svg-icon icon-name="eye" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';

    const store = useStore();
    const router = useRouter();

    const isClinician = computed(() => store.getters.isClinician);
    const consultationNotes = computed(() => {
        let notes = store.getters.getConsultationNotes.map((a) => a);
        return notes.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
    });

    const consultationNoteClicked = (consultationNoteId) => router.push({ name: 'edit note', params: { id: consultationNoteId } });

    const closeDialog = () => router.push({ name: 'patient'});
</script>
