<template>
    <div id="updater" v-if="updateAlert" :class="{active:updateAlert}">
        <span class="info">{{$t('appUpdaterAlert.title')}}</span>
        <button class="install btn__text btn__text--large btn__text--green-border" type="button" @click="updateApp">
            <h3>{{$t('appUpdaterAlert.installButtonText')}}</h3>
        </button>
        <button class="close" @click="cancel">
            <svg-icon icon-name="cross" />
        </button>
    </div>
</template>

<script setup>
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import VueCookies from 'vue-cookies'

    const PWA_UPDATE_COOKIENAME = 'pwa-update';
    const EVT_CONTROLLERCHANGE = 'controllerchange';
    const EVT_SWUPDATED = 'swUpdated';

    let updateAlert = ref(false);
    let refreshing = false;
    let registration = null;

    const showUpdateUI = (e) => {
        console.log('UPDATER: Show Update UI');
        registration = e.detail;
        updateAlert.value = true;
    }

    const updateApp = () => {
        updateAlert.value = false;
        if (!registration || !registration.waiting) return;
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }

    const cancel = () => {
        updateAlert.value = false;
        VueCookies.set(PWA_UPDATE_COOKIENAME, true, "30d")
    }

    const controllerChange = () => {
        console.log('UPDATER: CONTROLLER CHANGE');
        if (refreshing) return;
        console.log('UPDATER: REFRESHING');
        refreshing = true;
        window.location.reload();
    }

    onMounted(() => {
        updateAlert.value = false;
        let updateDeclined = VueCookies.get(PWA_UPDATE_COOKIENAME) || false;
        console.log('UPDATER: Update Declined', updateDeclined);
        if (!updateDeclined) {
            document.addEventListener(EVT_SWUPDATED, showUpdateUI, { once: true });
            navigator.serviceWorker.addEventListener(EVT_CONTROLLERCHANGE, controllerChange);
        }
    });

    onBeforeUnmount(() => {
        document.removeEventListener(EVT_SWUPDATED, showUpdateUI);
        navigator.serviceWorker.removeEventListener(EVT_CONTROLLERCHANGE, controllerChange);
    });
</script>