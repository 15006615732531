<template>
    <div id="clinicianclinicconversations" class="overlay overlay--patient">
        <bar :title="$t('clinicianConversations.title')" @close="closeDialog" />
        <div class="container">
            <div class="row list" data-test="patientConversations">
                <template v-for="conversation in conversations" :key="conversation.messageId">
                    <div class="col-xs-12 list__clickable list__clickable--right" @click="conversationClicked(conversation)" data-test="conversation-item">
                        <div class="title">
                            <span>
                                {{getPatientFullName(conversation)}}
                            </span>
                            <div class="svg-container">
                                <svg-icon icon-name="chevron" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>


<script setup>
    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useMessagingService } from '@/Components/Consultations/Messaging/MessagingService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const messagingService = useMessagingService();
    const caller = useExceptionwrappedCaller();

    const clinicId = computed(() => store.getters.getClinicianClinicId);
    const conversations = computed(() => store.getters.getClinicsConversations);

    watchEffect(async () => {
        if (clinicId.value) {
            await caller.call(async () => {
                await messagingService.loadConversationsForClinic(clinicId.value);
            });
        }
    });

    const getPatientFullName = (conversation) => [conversation.patientGivenNames, conversation.patientFamilyName].join(' ');

    const conversationClicked = (conversation) => {
        router.push({
            name: 'clinic conversation',
            params: {
                patientId: conversation.patientId,
            }
        });
    }

    const closeDialog = () => router.back();
</script>