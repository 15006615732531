<template>
    <div id="installer" v-if="installAlert" :class="{active:installAlert}">
        <span class="info">{{$t('appInstallerAlert.title')}}</span>
        <button class="install btn__text btn__text--large btn__text--green-border" type="button" @click="installApp">
            <h3>{{$t('appInstallerAlert.installButtonText')}}</h3>
        </button>
        <button class="close" @click="cancel">
            <svg-icon icon-name="cross" />
        </button>
    </div>
</template>

<script>
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import VueCookies from 'vue-cookies'

    const PWA_INSTALL_COOKIENAME = 'pwa-install';
    const EVT_BEFOREINSTALLPROMPT = 'beforeinstallprompt';

    export default {
        name: 'Installer',
        setup() {

            let installAlert = ref(false);

            let deferredPrompt;

            const cancel = () => {
                installAlert.value = false;
                VueCookies.set(PWA_INSTALL_COOKIENAME, true, "2d")
            }

            const showInstallPrompt = (e) => {
                e.preventDefault();
                let pwaMode = window.matchMedia('(display-mode: standalone)').matches;
                let installDeclined = VueCookies.get(PWA_INSTALL_COOKIENAME) || false;
                console.log('INSTALLER: Install Declined or PWA-mode', installDeclined, pwaMode);

                if (installDeclined || pwaMode) {
                    installAlert.value = false;
                    return;
                }

                console.log('Showing install message......', e);
                deferredPrompt = e;
                installAlert.value = true;
            }

            const installApp = async () => {
                console.log('Attempt to install app......');
                installAlert.value = false;
                deferredPrompt.prompt();
                let { outcome } = await deferredPrompt.userChoice;
                if (outcome === 'accepted')
                    console.log('App installed');
                else
                    console.log('App NOT installed');
                deferredPrompt = null;
            }

            onMounted(() => {
                installAlert.value = false;
                window.addEventListener(EVT_BEFOREINSTALLPROMPT, showInstallPrompt);
            });

            onBeforeUnmount(() => {
                window.removeEventListener(EVT_BEFOREINSTALLPROMPT, showInstallPrompt);
            });

            return {
                installAlert,
                cancel,
                installApp,
            }
        }
    }
</script>