export const makeRegexSafe = (val) => {
    return val
        .replace('\\', '\\\\')
        .replace('?', '\\?')
        .replace('!', '\\!')
        .replace('.', '\\.')
        .replace('[', '\\[')
        .replace(']', '\\]')
        .replace('{', '\\{')
        .replace('}', '\\}')
        .replace('(', '\\(')
        .replace(')', '\\)')
        .replace('^', '\\^')
        .replace('$', '\\$')
        .replace('+', '\\+')
        .replace('*', '\\*')
        .replace('|', '\\|');
}

const rxGuid = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/gi;
export const isGuid = (value) => {
    rxGuid.lastIndex = 0;
    return rxGuid.test(value);
}

