<template>
    <div class="content">
        <div class="title">
            <slot name="title"></slot>
        </div>

        <div class="value" v-if="!editValue">
            <slot name="value"></slot>
        </div>

        <div class="edit" v-show="editValue">
            <!-- must use v-show to ensure contents of slot is rendered at mount -->
            <slot></slot>
            <div class="confirm-buttons">
                <button class="btn btn__text btn__text--red-border btn__text--short left"
                        @click="cancel"
                        data-test="cancel-button">
                    <h4>{{$t('general.cancel')}}</h4>
                </button>

                <button class="btn btn__text btn__text--green btn__text--short right online-only"
                        :class="{disabled:!valid}"
                        :disabled="!valid"
                        @click="save"
                        data-test="accept-button">
                    <h4>{{$t('general.accept')}}</h4>
                </button>
            </div>
        </div>

        <div class="edit-icons online-only" v-if="!editValue && (!lockValid || !valid) && !customButton">
            <button class="btn btn__round btn__round--medium btn__round--red-outline"
                    @click="cancel"
                    data-test="edit-pen-button">
                <div class="svg-container">
                    <svg-icon icon-name="pen" />
                </div>
            </button>
        </div>

        <div class="edit-icons online-only" v-if="(!lockValid || !valid) && customButton">
            <button class="btn btn__round btn__round--medium btn__round--red-outline"
                    @click="customClick"
                    data-test="edit-pen-button">
                <div class="svg-container">
                    <svg-icon icon-name="pen" />
                </div>
            </button>
        </div>
    </div>
</template>

<script setup>
    import { defineEmits, defineProps, ref } from 'vue';

    const props = defineProps( {
        valid: { type: Boolean, default: true },
        lockValid: { type: Boolean, default: false },
        customButton: { type: Boolean, default: false },
    });

    const emit = defineEmits(['edit', 'save', 'cancel', 'custom']);

    let editValue = ref(false);

    const toggle = () => {
        editValue.value = !editValue.value;
        if (editValue.value)
            emit('edit');
    }

    const cancel = () => {
        emit('cancel');
        toggle();
    }

    const save = () => {
        emit('save');
        toggle();
    }

    const customClick = () => {
        emit('custom');
    }
</script>