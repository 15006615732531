<template>
    <div>
        <div class="overlay overlay--dashboard" id="myRelations">
            <bar title="" @close="closeDialog" />
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h2 v-if="userType != 'Clinician'" class="full-width center-text pb-small"><b>{{$t('myContacts.title')}}</b></h2>
                        <h2 v-if="userType == 'Clinician'" class="full-width center-text pb-small"><b>{{$t('myContacts.titleClinician')}}</b></h2>
                        <h5 class="full-width center-text pb-small">{{$t('myContacts.subTitle')}}</h5>
                        <div class="search">
                            <input :placeholder="$t('myContacts.inputPlaceholder')" v-model="filter" />
                            <svg-icon icon-name="search" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <list-relations :filter="filter" />
                </div>
                <div class="button-container-bottom animate-in-delay">
                    <button type="button" class="btn btn__text btn__text btn__text--green-border online-only" @click="addNewRelation" data-test="relations-addnew">
                        <h3>{{$t('myContacts.addContact')}}</h3>
                    </button>
                </div>
            </div>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>


<script setup>
    import ListRelations from '@/Components/Patients/Patient/ListRelations.vue'

    import { ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const store = useStore();
    const router = useRouter();
    const userType = computed(() => store.getters.getUserType);
    let filter = ref('');

    const addNewRelation = () => router.push({ name: 'add related person' });

    const closeDialog = () => router.back();
</script>