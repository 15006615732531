<template>
    <div class="overlay overlay--dashboard" id="AddRelatedPersonPatientData">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small" v-if="verifying == false"><b>{{$t('addRelatedPersonPatientData.title')}}</b></h2>
                    <h2 class="full-width center-text pb-small" v-if="verifying"><b>{{$t('addRelatedPersonPatientData.titleConfirm')}}</b></h2>
                    <h5 class="full-width center-text pb-small" v-if="verifying == false">{{$t('addRelatedPersonPatientData.subTitle')}}</h5>
                </div>
                <!-- is new or request access ?-->
                <div class="col-xs-12 selectPatientForm" v-if="verifying == false" style="display:none">
                    <dropdownbox v-model="relationSelected"
                                 :elements="relationExists"
                                 name="relationExists"
                                 :label="$t('addRelatedPersonPatientData.relationExistsLabel')"
                                 :helperText="$t('addRelatedPersonPatientData.relationExistsHelperText')"
                                 :errorText="$t('addRelatedPersonPatientData.relationExistsErrorText')"
                                 @updated="stateChanged" />
                </div>
                <!-- Request acces -->
                <div class="request col-xs-12" v-if="relationSelected == 1">
                    <text-box v-model="relationRequest"
                              name="relationRequest"
                              type="email"
                              :required="false"
                              :label="$t('addRelatedPersonPatientData.relationRequestLabel')"
                              :errorText="$t('addRelatedPersonPatientData.relationRequestErrorText')"
                              :helper="$t('addRelatedPersonPatientData.relationRequestHelperText')"
                              :placeholder="$t('addRelatedPersonPatientData.relationRequestPlaceholder')"
                              @updated="stateChanged"
                              v-if="verifying == false" />
                    <div class="pt-small pb-medium center-text" v-if="verifying == true"><h4>{{$t('addRelatedPersonPatientData.relationRequestVerify')}}</h4></div>
                </div>
                <div class="request col-xs-6" v-if="relationSelected == 1 && verifying == false">
                    <button class="btn btn__text btn__text--green-border left" type="button" @click="closeDialog">
                        <h4>{{$t('general.accept')}}</h4>
                    </button>
                </div>
                <div class="request col-xs-6" v-if="relationSelected == 1 && verifying ==   false">
                    <button class="btn btn__text btn__text--green right" :class="{ locked : !isValid }" type="button" @click="submitRelatedRequest">
                        <h4>{{$t('general.send')}}</h4>
                    </button>
                </div>
                <div class="request col-xs-6" v-if="relationSelected == 1 && verifying == true">
                    <button class="btn btn__text btn__text--green right" :class="{ locked : !isValid }" type="button" @click="closeDialog">
                        <h4>{{$t('general.back')}}</h4>
                    </button>
                </div>
                <!-- Craete new patient -->
                <div class="new col-xs-12" v-if="relationSelected == 2">
                    <text-box v-model="newRelatedPerson.givenNames"
                              name="givenNames"
                              type="text"
                              :required="true"
                              :label="$t('addRelatedPersonPatientData.givenNamesLabel')"
                              :errorText="$t('addRelatedPersonPatientData.givenNamesErrorText')"
                              :helper="$t('addRelatedPersonPatientData.givenNamesHelperText')"
                              :placeholder="$t('addRelatedPersonPatientData.givenNamesPlaceholder')"
                              @updated="stateChanged" />

                    <text-box v-model="newRelatedPerson.familyName"
                              name="familyName"
                              type="text"
                              :required="true"
                              :label="$t('addRelatedPersonPatientData.familyNameLabel')"
                              :errorText="$t('addRelatedPersonPatientData.familyNameErrorText')"
                              :helper="$t('addRelatedPersonPatientData.familyNameHelperText')"
                              :placeholder="$t('addRelatedPersonPatientData.familyNamePlaceholder')"
                              @updated="stateChanged" />

                    <dropdownbox v-model="newRelatedPerson.relationType"
                                 name="relationType"
                                 :elements="relationTypeList"
                                 :label="$t('addRelatedPersonPatientData.relationTypeLabel')"
                                 :helperText="$t('addRelatedPersonPatientData.relationTypeHelperText')"
                                 :errorText="$t('addRelatedPersonPatientData.relationTypeErrorText')"
                                 @updated="stateChanged" />

                    <dropdownbox v-model="newRelatedPerson.patientDataAccessType"
                                 name="patientDataAccessType"
                                 :elements="patientDataAccessTypeList"
                                 :label="$t('addRelatedPersonPatientData.relationAccessTypeLabel')"
                                 :helperText="$t('addRelatedPersonPatientData.relationAccessTypeHelperText')"
                                 :errorText="$t('addRelatedPersonPatientData.relationAccessTypeErrorText')"
                                 @updated="stateChanged" />
                </div>
                <div class="col-xs-12 pb-medium error" v-if="errorMsg">
                    {{errorMsg}}
                </div>
                <div class="new col-xs-6" v-if="relationSelected == 2">
                    <button class="btn btn__text btn__text--green-border left" type="button" @click="closeDialog">
                        <h4>{{$t('general.cancel')}}</h4>
                    </button>
                </div>
                <div class="new col-xs-6" v-if="relationSelected == 2">
                    <button class="btn btn__text btn__text--green right" :class="{ disabled : !isValid }" :disabled="!isValid" type="button" @click="createRelatedPersonPatient">
                        <h4>{{$t('general.create')}}</h4>
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import Dropdownbox from '@/Components/Shared/Form/DropDownBox.vue';

    import { computed, defineProps, nextTick, onMounted, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { usePatientRelatedPersonService } from '@/Components/Patients/Patient/PatientRelatedPersonService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useLanguageController } from '@/Components/Shared/LanguageController.js';

    const props = defineProps({
        relatedPersonId: String,
    });

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, isValid } = useFormValidator();
    const errorMsg = ref('');
    const { getLang } = useLanguageController();
    const patientService = usePatientService();
    const patientRelSvc = usePatientRelatedPersonService();
    const caller = useExceptionwrappedCaller();

    //let relationfirstName = ref('');
    //let relationlastName = ref('');
    let relationRequest = ref('');
    let relationSelected = ref('2'); // set to 0
    let verifying = ref(false);
    let relationExists = ref([]);
    let relationTypeList = ref([]);
    let patientDataAccessTypeList = ref([]);

    const storeRelatedPerson = computed(() => store.getters.getPatientRelatedPerson(props.relatedPersonId));

    let newRelatedPerson = reactive({
        relatedPersonId: storeRelatedPerson.value.relatedPersonId,
        givenNames: storeRelatedPerson.value.givenNames,
        familyName: storeRelatedPerson.value.familyName,
        relationType: storeRelatedPerson.value.relationType.toString(),
        patientDataAccessType: '1'  // initialize to parent
    });

    onMounted(() => {
        nextTick(() => {
            relationExists.value = [
                { "key": "0", "value": t('addRelatedPersonPatientData.relationExistsDropdownEmpty') },
                { "key": "1", "value": t('addRelatedPersonPatientData.relationExistsDropdownExists') },
                { "key": "2", "value": t('addRelatedPersonPatientData.relationExistsDropdownNew') },
            ];
            relationTypeList.value = [
                { "key": "0", "value": t('relationTypeList.parentToPatientLabel') },
                { "key": "1", "value": t('relationTypeList.childOfPatientLabel') },
                { "key": "2", "value": t('relationTypeList.spouseOfPatientLabel') },
                { "key": "3", "value": t('relationTypeList.siblingToPatientLabel') },
                { "key": "4", "value": t('relationTypeList.otherFamilyOfPatientLabel') },
                { "key": "5", "value": t('relationTypeList.friendOfPatientLabel') },
                { "key": "6", "value": t('relationTypeList.otherRelationToPatientLabel') }
            ];
            patientDataAccessTypeList.value = [
                { "key": "0", "value": t('accessTypeList.noAccess') },          // used to be 3
                { "key": "1", "value": t('accessTypeList.parent') },            // used to be 0
                { "key": "2", "value": t('accessTypeList.guardian') },          // used to be 1
                { "key": "3", "value": t('accessTypeList.grantedByPatient') }   // used to be 2
            ];
        });
    });


    const submitRelatedRequest = () => verifying.value = true;

    const createRelatedPersonPatient = async () => {
        try {
            await caller.call(async () => {
                const { patientId } = await patientService.createPatient(
                    newRelatedPerson.givenNames,
                    newRelatedPerson.familyName,
                    '',
                    '',
                    '',
                    '',
                    getLang(),
                    false);

                await patientRelSvc.addHealthInformationToRelatedPerson(
                    props.relatedPersonId,
                    patientId,
                    newRelatedPerson.patientDataAccessType);

                router.go(-1);
            });
        }
        catch (e) {
            errorMsg.value = t('addRelatedPersonPatientData.ErrorUserMightExist');
            console.log(e);
        }
    }

    const closeDialog = () => router.go(-1);
</script>