<template>
    <div class="overlay overlay--dashboard" id="myPatients">
        <bar title="" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>{{$t('myPatients.title')}}</b></h2>
                    <h5 class="full-width center-text pb-small">{{$t('myPatients.subTitle')}}</h5>
                    <div class="search">
                        <input :placeholder="$t('myPatients.inputPlaceholder')" 
                               v-model="filter" 
                               v-on:keyup.enter="refreshList"/>
                        <svg-icon icon-name="search" />
                        <button class="btn btn__round btn__round--large btn__round--green" type="button" @click="refreshList">
                            <div class="svg-container">
                                <svg-icon icon-name="refresh" />
                            </div>
                        </button>
                    </div>
                </div>
                <div class="col-xs-push-3 col-xs-6  filter" style="display:none">
                    <!-- TEMP REMOVE ! -->
                    <button class="btn btn__text-icon btn__text-icon--green-dark full-width" @click="openFilter">
                        <div>
                            <svg-icon icon-name="lock" />
                            <h4><span>{{$t('myPatients.filters')}}</span></h4>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <list-patients :patients="filteredPatientAccess" @buttonclicked="selectPatient" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import ListPatients from '@/Components/Patients/Patient/ListPatients.vue';

    import { computed, inject, onMounted, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { makeRegexSafe } from '@/Components/Shared/RegexUtils.js';

    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const patientAccess = computed(() => store.getters.getCliniciansPatientAccess);
    const infoDialog = inject('infoDialog');
    const caller = useExceptionwrappedCaller();
    const patientService = usePatientService();
    const patientAccessService = usePatientAccessService();

    let filter = ref('');
    let filteredPatientAccess = ref([]);

    onMounted(() => {
        console.log('setting up patients for clinician', patientAccess.value);
        filteredPatientAccess.value = patientAccess.value.map((p) => {
                return {
                    patientId: p.patientId,
                    givenNames: p.patientGivenNames,
                    familyName: p.patientFamilyName,
                    image: p.image,
                    date: p.date,
                }
            })
            .sort((a,b) => a.date > b.date ? -1 : 1);
    });

    watch(() => [filter.value, patientAccess.value], () => {
        let termslist = (filter.value || '').replace(/\s+/gi, ' ');
        termslist = makeRegexSafe(termslist);
        let terms = termslist.replace(' ', '|');
        console.log('filter changed', terms);
        try {
            let rx = new RegExp('(' + terms + ')', 'gi');
            filteredPatientAccess.value = patientAccess.value
                .filter((v) => {
                    rx.lastIndex = 0;
                    let givenTest = rx.test(v.patientGivenNames);
                    rx.lastIndex = 0;
                    let familyTest = rx.test(v.patientFamilyName);

                    console.log('filtering', v.patientGivenNames, givenTest, familyTest);

                    return givenTest || familyTest;
                })
                .map((p) => {
                    return {
                        patientId: p.patientId,
                        givenNames: p.patientGivenNames,
                        familyName: p.patientFamilyName,
                        image: p.image,
                        date: p.date,
                    }
                })
                .sort((a,b) => a.date > b.date ? -1 : 1);
        }
        catch (e) {
            console.log('ERROR', e);
            // do nothing
        }
    });

    const selectPatient = async (e) => {
        if (e.action == 'view') {
            await navigateToPatient(e.patient);
        }
        else if (e.action == 'resend') {
            await caller.call(async () => {
                await patientAccessService.resendRequestPatientAccessTo(e.patient.patientId)
            });
        }
    };

    // internal to setup
    const navigateToPatient = async (patient) => {
        try {
            await caller.call(async () => {
                await patientService.switchPatient(patient.patientId);
                router.push({ name: 'patient' });
            });
        }
        catch (e) { 
            const msg = e.message || e || '';
            if (msg.toLowerCase().includes('patientMissingOffline')) 
                await infoDialog({
                    header: t('myPatients.patientNotAvailableAlertHeader'),
                    body: t('myPatients.patientNotAvailableAlertBody'),
                });
            else
                throw e;
        }
    }

    const refreshList = async () => {
        await caller.call(async () => {
            await patientAccessService.refreshPatientAccessForClinician();
        });
    }

    const closeDialog = () => router.push({ name: 'dashboard' });
</script>