import { onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as strings from '@/Shared/Strings.js';

export const usePopups = () => {
    const { t } = useI18n();

    let popupData = reactive({
        open: false,
        header: '',
        body: '',
        accept: '',
        cancel: '',
        buttons: 'accept,cancel',
    });
    let popupPromise = null;
    let popupAccept = ref(null);

    onMounted(() => {
        popupData.header = t('general.information');
        popupData.accept = t('general.ok');
        popupData.cancel = t('general.cancel');
    });

    const acceptPopup = () => {
        popupData.open = false;
        popupAccept.value = true;
    }

    const cancelPopup = () => {
        popupData.open = false;
        popupAccept.value = false;
    }

    const alertDialog = (options) => {
        popupAccept.value = null;
        popupData.type = strings.POPUPTYPE_ALERT;
        popupData.header = options.header;
        popupData.body = options.body;
        popupData.buttons = options.buttons || 'accept,cancel';
        popupData.accept = options.accept || t('general.accept');
        popupData.cancel = options.cancel || t('general.cancel');
        popupData.open = true;
        popupPromise = new Promise((resolve) => {
            watch(() => popupAccept.value, (nv) => { resolve(nv); });
        });
        return popupPromise;
    }

    const confirmDialog = (options) => {
        popupAccept.value = null;
        popupData.type = strings.POPUPTYPE_CONSENT;
        popupData.header = options.header;
        popupData.body = options.body;
        popupData.buttons = 'accept,cancel';
        popupData.accept = options.accept || t('general.yes');
        popupData.cancel = options.cancel || t('general.no');
        popupData.open = true;
        popupPromise = new Promise((resolve) => {
            watch(() => popupAccept.value, (nv) => { resolve(nv); });
        });
        return popupPromise;
    }

    const errorDialog = (options) => {
        popupAccept.value = null;
        popupData.type = strings.POPUPTYPE_ALERT;
        popupData.header = options.header || t('errors.generalErrorTitle');
        popupData.body = options.body || t('errors.generalError');
        popupData.buttons = options.buttons || 'accept';
        popupData.accept = options.accept || t('general.ok');
        popupData.open = true;
        popupPromise = new Promise((resolve) => {
            watch(() => popupAccept.value, (nv) => { resolve(nv); });
        });
        return popupPromise;
    }

    const infoDialog = (options) => {
        popupAccept.value = null;
        popupData.type = strings.POPUPTYPE_INFO;
        popupData.header = options.header;
        popupData.body = options.body;
        popupData.buttons = 'accept';
        popupData.accept = options.accept || t('general.ok');
        popupData.open = true;
        popupPromise = new Promise((resolve) => {
            watch(() => popupAccept.value, (nv) => { resolve(nv); });
        });
        return popupPromise;
    }

    return {
        popupData,
        alertDialog,
        confirmDialog,
        errorDialog,
        infoDialog,
        acceptPopup,
        cancelPopup,
    };
}
