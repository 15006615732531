import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';


const getAzureToken = async (patientId, scope, clinicId) => {
    let data = {};
    if (clinicId)
        data = { scope, clinicId };
    let result = await dispatcher.postToApi(`azure/token/${patientId}`, data);
    console.log('AzureToken: ', result)

    handleApiError(result, 'Error while fetching token');

    return result.data;
}

const uploadBase64File = async (containerId, base64File, fileName, fileExtension, isPrivate) => {
    let uploadObject = {
        id: containerId,
        Base64: base64File,
        fileName,
        fileExtension,
        isPrivate: isPrivate
    };

    let result = await dispatcher.postToApi(`azure/upload/`, uploadObject);

    handleApiError(result, 'Error while uploading');

    console.log("server returned :" + result.data);
    return result.data;
}


export default {
    name: 'UploadApi',
    getAzureToken,
    uploadBase64File,
}