import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';


const fnCreateDiagnosis = async (patientId, diagnosis) => {
    let result = await dispatcher.postToApi(`patients/${patientId}/diagnoses`, diagnosis);
    handleApiError(result, 'Error while creating diagnosis.');
    return result.data.diagnosisId;
}

const fnChangeDiagnosisChronical = async (patientId, diagnosisId, diagnosisChange) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/diagnoses/${diagnosisId}/chronical`, diagnosisChange);
    handleApiError(result, 'Error while changing chronical on diagnosis.');
}

const fnChangeDiagnosisAudioNote = async (patientId, diagnosisId, diagnosisChange) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/diagnoses/${diagnosisId}/audionote`, diagnosisChange);
    handleApiError(result, 'Error while changing audio note on diagnosis.');
}

const fnAbateDiagnosis = async (patientId, diagnosisId, diagnosisChange) => {
    let result = await dispatcher.putToApi(`patients/${patientId}/diagnoses/${diagnosisId}/abate`, diagnosisChange);
    handleApiError(result, 'Error while abating diagnosis.');
}

const fnGetDiagnoses = async (patientId) => {
    let result = await dispatcher.getFromApi(`patients/${patientId}/diagnoses`);
    handleApiError(result, 'Error while reading diagnoses.');
    return result.data.diagnoses;
}

//NB: diagnosisDelete = { clinicianId }
//const fnDeleteDiagnosis = async (patientId, diagnosisId, diagnosisDelete) => {
//    let result = await dispatcher.deleteFromApi(`patients/${patientId}/diagnoses/${diagnosisId}`, diagnosisDelete);
//    handleApiError(result, 'Error while deleting diagnosis.');
//}


// ============================================================
// ============================================================
// ============================================================
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const useDiagnosisService = () => {
    const store = useStore();

    const loadDiagnoses = async () => {
        await serviceCall(
            async () => {
                const patientId = store.getters.getPatientId;
                const diagnoses = await fnGetDiagnoses(patientId);
                store.commit('setDiagnoses', diagnoses);
            }
        );
    }


    const createDiagnosis = async (diagnosisCode, diagnosisText, diagnosisSystem, chronical, note, onset, audioNote) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;

        const diagnosis = {
            clinicianId,
            diagnosisCode,
            diagnosisText,
            diagnosisSystem,
            note,
            chronical,
            onset,
            audioNote,
        };

        await serviceCall(
            async () => {
                diagnosis.diagnosisId = await fnCreateDiagnosis(patientId, diagnosis);
                diagnosis.date = new Date().toISOString();
                diagnosis.clinicianGivenNames = store.state.clinician.givenNames;
                diagnosis.clinicianFamilyName = store.state.clinician.familyName;
                diagnosis.patientId = patientId;
                diagnosis.patientGivenNames = store.state.patient.givenNames;
                diagnosis.patientFamilyName = store.state.patient.familyName;

                store.commit('addDiagnosis', diagnosis);
            }
        );
    }


    const changeDiagnosisChronical = async (diagnosisId, chronical) => {
        let patientId = store.getters.getPatientId;
        let clinicianId = store.getters.getClinicianId;
        let change =
        {
            diagnosisId,
            clinicianId,
            chronical,
        };

        await serviceCall(
            async () => {
                await fnChangeDiagnosisChronical(patientId, diagnosisId, change);

                change.date = new Date().toISOString();
                change.clinicianGivenNames = store.state.clinician.givenNames;
                change.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('changeDiagnosisChronical', change);
            });
    }


    const abateDiagnosis = async (diagnosisId) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;
        const change =
        {
            diagnosisId,
            clinicianId,
        };

        await serviceCall(
            async () => {
                await fnAbateDiagnosis(patientId, diagnosisId, change);
                
                change.date = new Date().toISOString();
                change.clinicianGivenNames = store.state.clinician.givenNames;
                change.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('abateDiagnosis', change);
            });
    }


    const changeDiagnosisAudioNote = async(diagnosisId, audioNote) => {
        const patientId = store.getters.getPatientId;
        const clinicianId = store.getters.getClinicianId;
        const change = {
            diagnosisId,
            clinicianId,
            audioNote,
        };

        console.log('changing diagnosis audio note in store', change);

        await serviceCall(
            async () => {
                await fnChangeDiagnosisAudioNote(patientId, diagnosisId, change);

                change.date = new Date().toISOString();
                change.clinicianGivenNames = store.state.clinician.givenNames;
                change.clinicianFamilyName = store.state.clinician.familyName;

                store.commit('changeDiagnosisAudioNote', change);
            });
    }

    return {
        loadDiagnoses,
        createDiagnosis,
        changeDiagnosisChronical,
        abateDiagnosis,
        changeDiagnosisAudioNote,
    }
}
