<template>
    <div class="container" id="notImplemented">
        <div class="row">
            <div class="col-xs-12">
                <div class="bar bar--white">
                    <div class="bar bar__content">
                        <div class="center">
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <h2 class="full-width center-text pb-small"><b>Ups!</b></h2>
                    <h5 class="full-width center-text pb-medium">
                        <span>The page you are looking for was not found.</span>
                    </h5>
                    <div class="col-xs-push-2 col-xs-8 center-text">
                        <button class="btn btn__link" @click="goToRoot">
                            <h3>Back to safety</h3>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { useRouter } from 'vue-router';
    const router = useRouter();

    const goToRoot = () => {
        router.push('/');
    }
</script>