<template>
    <router-view></router-view>
    <Loading></Loading>
    <SvgIcons></SvgIcons>
    <!--<Settings></Settings>-->
    <Notifications></Notifications>
    <Popup v-bind="popupData" @accepted="acceptPopup" @declined="cancelPopup"></Popup>
    <Offline></Offline>
    <LogonPIN v-bind="pinDialogData" @done="acceptPinDialog" @canceled="cancelPinDialog"></LogonPIN>
    <Installer></Installer>
    <Updater></Updater>
    <ImageViewer v-bind="imageViewerData" @close="closeImagePopup"></ImageViewer>
</template>

<script>
    import Loading from '@/Components/Shared/Loading';
    import Settings from '@/Components/Shared/Settings';
    import SvgIcons from '@/Components/Shared/SvgIcons';
    import Notifications from '@/Components/Shared/Notifications';
    import Popup from '@/Components/Shared/Popup';
    import Offline from '@/Components/Shared/Offline'
    import LogonPIN from '@/Components/Users/User/LogonPIN'
    import Installer from '@/Components/Shared/Installer'
    import Updater from '@/Components/Shared/Updater'
    import ImageViewer from '@/Components/Shared/ImageViewer'

    import { onBeforeUnmount, onErrorCaptured, onMounted, provide } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { usePopups } from '@/Components/Shared/PopupController.js';
    import { useImageViewer } from '@/Components/Shared/ImageViewerController.js';
    import { usePinDialogs } from '@/Components/Users/User/PinDialogsController.js';
    import dispatcher from '@/Connections/Dispatcher.js';
    import { USERID, CURRENTLOCALSTORAGEVERSION } from '@/Shared/Strings.js';
    import { usePollingNotifications } from '@/ServerNotifications/PollingNotifications.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { offlineServiceCall } from '@/Shared/ServiceWrapper.js';
    import { useErrorDialogs } from '@/Shared/ErrorDialogs.js';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useRouter } from 'vue-router';

    export default {
        name: 'App',
        components: {
            SvgIcons,
            Loading,
            /*Settings,*/
            Notifications,
            Popup,
            Offline,
            LogonPIN,
            Installer,
            Updater,
            ImageViewer,
        },
        setup() {
            const store = useStore();
            const { t } = useI18n();
            const { showImagePopup, closeImagePopup, imageViewerData } = useImageViewer();
            const { alertDialog, confirmDialog, errorDialog, infoDialog, acceptPopup, cancelPopup, popupData } = usePopups();
            const { showInvalidRefreshTokenDialog, showMissingPatientAccessDialog } = useErrorDialogs(errorDialog);
            const { enterPinDialog, acceptPinDialog, cancelPinDialog, pinDialogData } = usePinDialogs();
            const { notHandlerActions } = usePollingNotifications(showInvalidRefreshTokenDialog, showMissingPatientAccessDialog);
            const caller = useExceptionwrappedCaller(enterPinDialog, notHandlerActions);

            const userService = useUserService();
            const router = useRouter();

            provide('alertDialog', alertDialog);
            provide('confirmDialog', confirmDialog);
            provide('errorDialog', errorDialog);
            provide('infoDialog', infoDialog);

            provide('showImagePopup', showImagePopup);

            // this is called by the global error handler event (via a custom event handler)
            const showErrorDialog = async (data) => {
                store.commit('setIsLoading', false);
                const msg = data.detail && data.detail.err && data.detail.err.message ? data.detail.err.message : '';
                // or maybe: 
                // const msg = data.detail?.err?.message || '';

                notHandlerActions.terminate();
                if (msg == 'invalidrefreshtoken') {
                    await showInvalidRefreshTokenDialog();
                }
                else if (msg == 'patientaccess') {
                    await showMissingPatientAccessDialog();
                    notHandlerActions.start();
                }
                else {
                    await errorDialog({ body: t('errors.' + msg) })
                    notHandlerActions.start();
                }

                console.log('AFTER ERROR DIALOG');
            }

            const setBusy = () => store.commit('setIsLoading', true);
            const setIdle = () => store.commit('setIsLoading', false);

            const goOnline = () => store.commit('setOnlineStatus', true);
            const goOffline = () => store.commit('setOnlineStatus', false);

            if (dispatcher.getFromSession(USERID))
                store.commit('setUserId', dispatcher.getFromSession(USERID));

            const checkAndCleanLocalStoreVersion = () => {
                console.log('LS data versioncheck');
                const version = window.localStorage.getItem("ver");
                if (version != CURRENTLOCALSTORAGEVERSION) {
                    window.localStorage.clear();
                    window.localStorage.setItem('ver', CURRENTLOCALSTORAGEVERSION);
                }
            }

            onMounted(async () => {
                checkAndCleanLocalStoreVersion();

                popupData.header = t('general.information');
                popupData.accept = t('general.ok');
                popupData.cancel = t('general.cancel');

                window.addEventListener('setBusy', setBusy);
                window.addEventListener('setIdle', setIdle);
                window.addEventListener('showErrorDialog', showErrorDialog);
                window.addEventListener('online', goOnline);
                window.addEventListener('offline', goOffline);
                if ('onLine' in navigator) {
                    if (navigator.onLine) {
                        goOnline();
                    }
                    else {
                        goOffline();
					}
                }

                await caller
                    .call(async () => {
                        console.log('app mounted - caller.call');
                        await offlineServiceCall(() => {
                            console.log('app mounted - serviceCall');
                            store.commit('initStore');
                        });
                    })
                    .catch(async (e) => {
                        const msg = e.message || e || '';
                        console.log('ERROR', msg, e);
                        if (msg.includes('pinCanceled')) {
                            await userService.logoffUser();
                            await router.push({ name: 'welcome' });
                        }
                    });
            });

            onBeforeUnmount(() => {
                window.removeEventListener('online', goOnline);
                window.removeEventListener('offline', goOffline);
                window.removeEventListener('showErrorDialog', showErrorDialog);
                window.removeEventListener('setBusy', setBusy);
                window.removeEventListener('setIdle', setIdle);
            });

            onErrorCaptured(async (err) => {
                const msg = err.message || err || '';
                if (msg.includes('encryptedPinMissing') || msg.includes('pinCanceled')) {
                    if (store.getters.getIsLoggedOn) {  // avoid repeating logoff
                        await userService.logoffUser();
                        await router.push({ name: 'welcome' });
                    }
                }
                else {
                    showErrorDialog({ detail: { err } });
                }
            });

            // make sure sidemenu is closed after navigations
            router.afterEach(() => store?.commit('closeSideNavigation'));


            return {
                popupData,
                acceptPopup,
                cancelPopup,
                acceptPinDialog,
                cancelPinDialog,
                pinDialogData,
                imageViewerData,
                closeImagePopup,
            }
        }
    }
    require('@/Assets/Styles/App.min.css?' + 'build=9');
</script>

