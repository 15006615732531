<template>
    <div v-if="isClinician" id="createDiagnosis" class="overlay overlay--patient">
        <bar :title="$t('createDiagnosis.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content">
                            <div class="col-xs-4 no-padding">
                                <div class="title">
                                    {{$t('createDiagnosis.codeLabel')}}
                                </div>
                                <div class="value">
                                    {{code}}
                                </div>
                            </div>
                            <div class="col-xs-8 no-padding">
                                <div class="title">
                                    {{$t('createDiagnosis.textLabel')}}
                                </div>
                                <div class="value">
                                    {{text}}
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <date-time v-model="diagnosis.onset"
                                       name="onset"
                                       :label="$t('createDiagnosis.onsetLabel')"
                                       :errorText="$t('createDiagnosis.onsetErrorText')"
                                       :helper="$t('createDiagnosis.onsetHelperText')"
                                       :placeholder="$t('createDiagnosis.onsetPlaceholder')"
                                       @updated="stateChanged" />

                            <text-area v-model="diagnosis.note"
                                       name="note"
                                       :label="$t('createDiagnosis.noteLabel')"
                                       :errorText="$t('createDiagnosis.noteErrorText')"
                                       :helper="$t('createDiagnosis.noteHelperText')"
                                       :placeholder="$t('createDiagnosis.notePlaceholder')"
                                       @updated="stateChanged" />
                        </div>
                    </div>
                </div>
                <media-player :audio="diagnosis.audioNote" @toggle="toggleRecording" @remove="removeAudio"></media-player>
                <div class="col-xs-12">
                    <div class="switch left online-only">
                        <label>
                            <span class="control-box">
                                <input v-model="diagnosis.chronical" type="checkbox">
                                <span class="slider round"></span>
                            </span>
                            <span class="control-label">
                                {{$t('createDiagnosis.chronicalLabel')}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--green-border left" type="button" @click="closeDialog">
                        <h4>{{$t('general.cancel')}}</h4>
                    </button>
                </div>
                <div class="col-xs-6">
                    <button class="btn btn__text btn__text--green right online-only" type="button" @click="createDiagnosis">
                        <h4>{{$t('general.create')}}</h4>
                    </button>
                </div>
            </div>
        </div>
        <media-recorder :class="{active : audioOverlayActive}" userType="patient" @uploadGuid="newAudio" @close="toggleRecording"></media-recorder>
    </div>
</template>

<script setup>
    import { computed, defineProps, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import TextArea from '@/Components/Shared/Form/TextArea.vue';
    import DateTime from '@/Components/Shared/Form/DateTime.vue';
    import MediaRecorder from '@/Components/Shared/Form/MediaRecorder.vue';
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';

    import { useDiagnosisService } from '@/Components/HealthData/Diagnosis/DiagnosisService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        code: String,
        text: String,
        system: String,
    });

    const store = useStore();
    const router = useRouter();
    const service = useDiagnosisService();
    const caller = useExceptionwrappedCaller();
    const { stateChanged } = useFormValidator();
    const isClinician = computed(() => store.getters.isClinician);

    let diagnosis = reactive({
        diagnosisCode: props.code,
        diagnosisText: props.text,
        diagnosisSystem: props.system,
        note: '',
        chronical: false,
        onset: null,
        abated: false,
        deleted: false,
        audioNote: ''
    });

    // Audio 
    let audioOverlayActive = ref(false);

    const newAudio = (uploadGuid) => {
        diagnosis.audioNote = uploadGuid;
    }

    const removeAudio = () => {
        diagnosis.audioNote = '';
    }

    const toggleRecording = () => {
        audioOverlayActive.value = !audioOverlayActive.value;
        console.log('opening recorder', audioOverlayActive.value)
    };

    // Audio End 

    const closeDialog = () => router.push({ name: 'diagnosis' });

    const createDiagnosis = async () => {
        await caller.call(async () =>
            await service.createDiagnosis(
                diagnosis.diagnosisCode,
                diagnosis.diagnosisText,
                diagnosis.diagnosisSystem,
                diagnosis.chronical,
                diagnosis.note,
                diagnosis.onset,
                diagnosis.audioNote));

        closeDialog();
    };
</script>