import dispatcher from '@/Connections/Dispatcher.js'
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';


const registerClinician = async (givenNames, familyName, email, password, phone, clinicId, language) => {
    givenNames = givenNames || "";
    familyName = familyName || "";
    email = email || "";
    password = password || "";
    language = language || "en";

    let clinician = {
        givenNames: givenNames.split(' '),
        familyName,
        email,
        password,
        phone,
        clinicId,
        language,
    };

    let result = await dispatcher.postToApi('clinicians', clinician);
    handleApiError(result, 'Error while registering clinician.');

    return result.data.clinicianId;
}


export default {
    name: "ClinicianService",
    registerClinician,
}


export const useClinicianService = () => {
    const store = useStore();

    const getClinician = async (clinicianId) => {
        let clinicianIdToGet = clinicianId || store.getters.getClinicianId;

        let clinician = await serviceCall(
            async () => {
                const result = await dispatcher.getFromApi(`clinicians/${clinicianIdToGet}`);
                handleApiError(result, 'Error when reading clinician.');
                return result.data;
            }
        );

        return clinician
    }

    const changeClinicianName = async (givenNames, familyName) => {
        let clinicianId = store.getters.getClinicianId;

        let clinicianName = {
            givenNames: givenNames.split(' '),
            familyName,
        }

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/${clinicianId}/name`, clinicianName)
                handleApiError(result, 'Error while changing clinician name');
                store.commit('setClinicianName', { givenNames, familyName });
            }
        );
    }

    const requestClinicianPhoneChange = async (newPhone) => {
        let clinicianPhone = { newPhone };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/requestphonechange`, clinicianPhone)
                handleApiError(result, 'Error while requesting change of clinician phone');
                store.commit('setClinicianPendingPhone', clinicianPhone);   
            }
        );
    }

    const verifyClinicianPhoneChange = async (token) => {
        let data = { token };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/verifyphonechange`, data)
                handleApiError(result, 'Error while verifying change of clinician phone');
                store.commit('setPendingClinicianPhoneAsActive', {});       
            }
        );
    }

    const cancelClinicianPhoneChange = async () => {
        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/cancelphonechange`, {})
                handleApiError(result, 'Error while canceling change of clinician phone');
                store.commit('cancelPendingClinicianPhoneChange', {});       
            }
        );
    }

    const requestClinicianEmailChange = async (newEmail) => {
        let clinicianEmail = { newEmail };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/requestemailchange`, clinicianEmail)
                handleApiError(result, 'Error while requesting change of clinician email');
                store.commit('setClinicianPendingEmail', clinicianEmail);
            }
        );
    }

    const verifyClinicianEmailChange = async (token) => {
        let data = { token };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/verifyemailchange`, data)
                handleApiError(result, 'Error while verifying change of clinician email');
                store.commit('setPendingClinicianEmailAsActive', {});
            }
        );
    }

    const cancelClinicianEmailChange = async () => {
        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/cancelemailchange`, {})
                handleApiError(result, 'Error while canceling change of clinician email');
                store.commit('cancelPendingClinicianEmailChange', {});
            }
        );
    }

    const changeClinicianGender = async (gender) => {
        let clinicianId = store.getters.getClinicianId;

        let clinicianGender = { gender };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/${clinicianId}/gender`, clinicianGender)
                handleApiError(result, 'Error while changing clinician gender');
                store.commit('setClinicianGender', clinicianGender);
            }
        );
    }

    const changeClinicianDescription = async (description) => {
        let clinicianId = store.getters.getClinicianId;

        let clinicianDescription = { description };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/${clinicianId}/description`, clinicianDescription)
                handleApiError(result, 'Error while changing clinician description');
                store.commit('setClinicianDescription', clinicianDescription);
            }
        );
    }

    const changeClinicianImage = async (image) => {
        let clinicianId = store.getters.getClinicianId;

        let clinicianImage = { image };

        await serviceCall(
            async () => {
                const result = await dispatcher.putToApi(`clinicians/${clinicianId}/image`, clinicianImage)
                handleApiError(result, 'Error while changing clinician image');
                store.commit('setClinicianImage', clinicianImage);
            }
        );
    }


    return {
        getClinician,
        changeClinicianName,

        requestClinicianPhoneChange,
        verifyClinicianPhoneChange,
        cancelClinicianPhoneChange,

        requestClinicianEmailChange,
        verifyClinicianEmailChange,
        cancelClinicianEmailChange,

        changeClinicianGender,
        changeClinicianDescription,
        changeClinicianImage,
    }
}
