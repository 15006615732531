<template>
    <div class="overlay overlay--patient" id="patientData">
        <bar :title="$t('patientData.title')" @close="closeDialog" />
        <div class="container" id="patientData">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info">
                        <editor-content :valid="true"
                                        :lockValid="true"
                                        v-if="patientUsername"
                                        data-test="showPatientUsername">
                            <template v-slot:title>
                                {{$t('patientData.username')}}
                            </template>
                            <template v-slot:value>
                                {{patientUsername}}
                            </template>
                        </editor-content>

                        <editor-content @save="saveValues(['givenNames','familyName'], 'changeName')"
                                        @cancel="cancelValues(['givenNames','familyName'])"
                                        data-test="changePatientName">
                            <template v-slot:title>
                                {{$t('patientData.name')}}
                            </template>
                            <template v-slot:value>
                                <span data-test="view-name">{{patientData.givenNames}} {{patientData.familyName}}</span>
                            </template>
                            <template v-slot:default>
                                <text-box v-model="editing.givenNames"
                                          name="givenNames"
                                          :label="$t('patientData.givenNamesLabel')"
                                          :helper="$t('patientData.givenNamesHelper')"
                                          :placeholder="$t('patientData.givenNamesPlaceholder')"
                                          data-test="edit-given-names"></text-box>
                                <text-box v-model="editing.familyName"
                                          name="familyName"
                                          :label="$t('patientData.familyNameLabel')"
                                          :helper="$t('patientData.familyNameHelper')"
                                          :placeholder="$t('patientData.familyNamePlaceholder')"
                                          data-test="edit-family-name"></text-box>
                            </template>
                        </editor-content>


                        <editor-content @edit="editValues(['birthdate'])"
                                        @save="saveValues(['birthdate'], 'changeBirthdate')"
                                        @cancel="cancelValues(['birthdate'])"
                                        data-test="changePatientBirthdate">
                            <template v-slot:title>
                                {{$t('patientData.birthdate')}}
                            </template>
                            <template v-slot:value>
                                <date-display :iso-date="patientData.birthdate" display-as="date" data-test="view-birthdate" />
                            </template>
                            <template v-slot:default>
                                <date-time v-model="editing.birthdate"
                                           name="birthdate"
                                           :helper="$t('patientData.birthdateHelper')"
                                           :placeholder="$t('patientData.birthdatePlaceholder')"
                                           data-test="edit-birthdate"></date-time>
                            </template>
                        </editor-content>


                        <editor-content @save="saveValues(['gender'], 'changeGender')"
                                        @cancel="cancelValues(['gender'])"
                                        data-test="changePatientGender">
                            <template v-slot:title>
                                {{$t('patientData.gender')}}
                            </template>
                            <template v-slot:value>
                                <span data-test="view-gender">{{genderText(patientData.gender)}}</span>
                            </template>
                            <template v-slot:default>
                                <radio-list v-model="editing.gender"
                                            name="gender"
                                            :elements="genders"
                                            :helper="$t('patientData.genderHelper')"
                                            :placeholder="$t('patientData.genderPlaceholder')"
                                            data-test="edit-gender"></radio-list>
                            </template>
                        </editor-content>


                        <editor-content @custom="customPhoneClick"
                                        :customButton="true"
                                        :lockValid="isClinician"
                                        data-test="changePatientPhone">
                            <template v-slot:title>
                                {{$t('patientData.phone')}}
                            </template>
                            <template v-slot:value>
                                {{patientData.phone}}
                            </template>
                        </editor-content>


                        <editor-content @custom="customEmailClick"
                                        :customButton="true"
                                        :lockValid="isClinician"
                                        data-test="changePatientEmail">
                            <template v-slot:title>
                                {{$t('patientData.email')}}
                            </template>
                            <template v-slot:value>
                                {{patientData.email}}
                            </template>
                        </editor-content>


                        <editor-content @save="saveValues(['image'], 'changeImage')"
                                        @cancel="cancelValues(['image'])"
                                        data-test="changePatientImage">
                            <template v-slot:title>
                                {{$t('patientData.image')}}
                            </template>
                            <template v-slot:value>
                                <div class="image-container">
                                    <profile-image :profile-id="patientData.id" />
                                </div>
                            </template>
                            <template v-slot:default>
                                <div class="image-container">
                                    <profile-image :profile-id="patientData.id" />
                                </div>
                                <label class="pb-small">{{$t('patientData.imageLabel')}}:</label>
                                <Upload userType="patient"
                                        imageName="profilepicture"
                                        v-model="editing.image"
                                        data-test="edit-image"></Upload>
                            </template>
                        </editor-content>
                    </div>
                </div>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>


<script setup>
    import Upload from '@/Components/Shared/Form/Upload.vue';
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import DateTime from '@/Components/Shared/Form/DateTime.vue';
    import RadioList from '@/Components/Shared/Form/RadioList.vue';
    import DateDisplay from '@/Components/Shared/DateDisplay.vue';
    import EditorContent from '@/Components/Shared/EditorContent.vue';

    import { computed, onMounted, onUpdated, reactive, ref, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
    import { useUserService } from '@/Components/Users/User/UserService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, invalidFields } = useFormValidator();
    const patientService = usePatientService();
    const userService = useUserService();
    const caller = useExceptionwrappedCaller();

    const patientUsername = computed(() => store.getters.getPatientUsername);

    let patientData = reactive({
        id: store.getters.getPatientId,
        givenNames: '',
        familyName: '',
        birthdate: '',
        gender: '',
        phone: '',
        email: '',
        image: ''
    });
    let displayImage = ref(patientData.image + "?cb=" + new Date().getTime());

    const isClinician = computed(() => store.getters.isClinician);

    // copy current values into a new structure used for editing mode to enable undoing edited values
    // NB: only handles flat structures - alternatively use a similar standard/lib clone-function
    let editing = reactive({});
    for (var key in patientData)
        editing[key] = patientData[key];
    //console.log('PATIENTDATA editing-object', editing);

    // defines the actions used for updating values in backend
    let actions = {
        async changeName(data) {
            await caller.call(async () => {
                await patientService.changePatientName(
                    data.givenNames,
                    data.familyName);
            });
        },
        async changeGender(data) {
            await caller.call(async () => {
                await patientService.changePatientGender(data.gender);
            });
        },
        async changeBirthdate(data) {
            await caller.call(async () => {
                await patientService.changePatientBirthdate(data.birthdate);
            });
        },
        async changeImage(data) {
            await caller.call(async () => {
                await patientService.changePatientImage(data.image);
                displayImage.value = data.image + "?cb=" + new Date().getTime();
            });
        }
    };


    // used to check if a group of fields are valid or not (to deactivate or activate save-button)
    const checkValid = (fields) => {
        return invalidFields.value.filter((v) => fields.includes(v)).length == 0;
    };


    const editValues = (fields) => {
        //console.log('PATIENT DATA start editing fields', fields);
        fields.forEach((fld) => editing[fld] = patientData[fld]);
        //console.log('PATIENT DATA edit values', editing);
    }

    const saveValues = async (fields, actionName) => {
        fields.forEach((fld) => patientData[fld] = editing[fld]);
        if (actionName && actionName !== '' && actionName in actions) {
            let data = {};
            fields.forEach((fld) => data[fld] = patientData[fld]);
            await actions[actionName](data);
        }
        else {
            console.log(`${actionName} NOT FOUND!`);
        }
    };


    // cancel changes in an editor
    const cancelValues = (fields) => {
        fields.forEach((fld) => editing[fld] = patientData[fld]);
    };



    let genders = ref([
        { value: 'None', label: 'Unspecified' },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ]);

    const refreshTranslations = () => {
        genders.value.filter((g) => g.value == 'None')[0].label = t('patientData.nonSpecifiedGenderTitle');
        genders.value.filter((g) => g.value == 'Male')[0].label = t('patientData.maleGenderTitle');
        genders.value.filter((g) => g.value == 'Female')[0].label = t('patientData.femaleGenderTitle');
    }
    onMounted(() => refreshTranslations());
    onUpdated(() => refreshTranslations())

    const genderText = (gender) => {
        const translatedGender = genders.value.filter((g) => g.value == gender);
        if (!translatedGender || translatedGender.length == 0)
            return gender;
        return translatedGender[0].label;
    }



    let loadingPatient = false;
    watchEffect(async () => {
        if (!store.getters.getPatientId) return;

        // break out of endless loop which occurs due to updating reactive properties in state
        // and at the same time reacting to statechanges in dependent property
        if (loadingPatient) return;
        loadingPatient = true;

        const patientId = store.getters.getPatientId;
        await caller.call(async () => {
            // run patient and username loaders in parallel (so don't await those calls when executed!)
            const patientUsernameLoader = userService.loadPatientUsername(patientId)
            const patientLoader = patientService.loadPatient(patientId);
            await Promise.all([patientUsernameLoader, patientLoader]);
        }).catch(e => {
            console.log('Error when loading patient data', e);
        });

        loadingPatient = false;
    });

    watchEffect(() => {
        const patient = store.getters.getPatient;
        if (patient) {
            patientData.id = patient.id;
            patientData.givenNames = patient.givenNames;
            patientData.familyName = patient.familyName;
            patientData.birthdate = patient.birthdate;
            patientData.gender = patient.gender;
            patientData.phone = patient.phone;
            patientData.email = patient.email;
            patientData.image = patient.image;

            //console.log('PATIENT - UPDATED', patient);

            // refresh editing structure with new data
            for (var key in patientData)
                editing[key] = patientData[key];
        }
    });

    const closeDialog = () => router.push({ name: 'patient' });

    const customEmailClick = () => {
        router.push({ name: 'patient change email' })
    }

    const customPhoneClick = () => {
        router.push({ name: 'patient change phone' })
    }
</script>