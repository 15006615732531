<template>
    <div>
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" />
        <input :type="type" 
               v-model="value" 
               :placeholder="placeholder" 
               :data-state="dataState" 
               @keyup.enter="accepted"
               formnovalidate />
    </div>
</template>

<script>
    import Label from './Label.vue';
    import { EmptyString, NullableString, FalseBoolean } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';

    export default {
        name: 'TextBox',
        components: {
            Label,
        },
        props: {
            modelValue: EmptyString,
            type: {
                type: String,
                default: 'text',
                validator(value) {
                    return ['text', 'email', 'tel', 'password'].includes(value);
                }
            },
            placeholder: EmptyString,
            label: EmptyString,
            errorText: EmptyString,
            helper: EmptyString,
            pattern: NullableString,
            patternCaseSensitive: FalseBoolean,
            name: {
                type: String,
                default: '_' + Math.floor(Math.random() * 1000000000)
            },
            class: EmptyString,
            required: FalseBoolean,
        },
        emits: ['update:modelValue', 'updated', 'accepted'],
        setup(props, context) {
            const { value, error, dataState } = useInputValidator(props, context);

            const accepted = (e) => {
                context.emit('accepted')
            }

            return {
                value,
                error,
                dataState,
                accepted,
            };
        }
    }
</script>