<template>
    <div>
        <Label :label="label" :error="error" :errorText="errorText" :helper="helper" />
        <input type="text" v-model="search" :placeholder="placeholder" :data-state="dataState" autocomplete="off" formnovalidate />
    </div>
    <div class="table-layout folded" :class="{open: results.length}">
        <div class="body" :class="{expand : results.length}">
            <div class="item" v-for="result in results" :key="result.organizationId" @click="chooseOrganization(result)">
                <div>{{result.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue';
    import Label from './Label.vue';
    import { EmptyString } from './PropTypes.js';
    import { useInputValidator } from './InputValidator.js';
    import service from '@/Components/Organizations/Organization/OrganizationService.js';

    export default {
        name: 'OrganizationSearch',
        components: {
            Label,
        },
        props: {
            modelValue: EmptyString,
            placeholder: EmptyString,
            label: EmptyString,
            errorText: EmptyString,
            helper: EmptyString,
            name: {
                type: String,
                default: '_' + Math.floor(Math.random() * 1000000000)
            },
            type: {
                type: String,
                default: 'guid',
                validator: (value) => value == 'guid'
            }
        },
        emits: ['update:modelValue','updated'],
        setup(props, context) {
            const { value, error, dataState } = useInputValidator(props, context);
            let results = ref([]);
            let search = ref('');
            let selected = ref('');
            let newSearch = ref(true);

            watch(() => search.value, async (nv) => {
                if (selected.value != nv) {
                    value.value = '';
                    newSearch.value = true;
                    if (nv != '')
                        results.value = await service.findOrganizations(nv);
                    else
                        results.value = [];
                }
                else {
                    results.value = [];
                }
            });

            const chooseOrganization = (organization) => {
                newSearch.value = false;
                value.value = organization.organizationId;
                selected.value = organization.name;
                search.value = organization.name;
            }

            return {
                search,
                error,
                dataState,
                results,
                chooseOrganization,
            };
        }
    }
</script>