<template>
    <div v-if="isClinician" id="createMedication" class="overlay overlay--patient">
        <bar :title="$t('createMedication.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info ">
                        <div class="content">
                            <div class="col-xs-4 no-padding code">
                                <div class="title">
                                    {{$t('createMedication.groupCodeLabel')}}
                                </div>
                                <div class="value">
                                    {{props.code}}
                                </div>
                            </div>
                            <div class="col-xs-8 no-padding">
                                <div class="title">
                                    {{$t('createMedication.groupTextLabel')}}
                                </div>
                                <div class="value">
                                    {{props.text}}
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <text-box v-model="drug"
                                      name="drug"
                                      :required="true"
                                      :label="$t('createMedication.drugLabel')"
                                      :errorText="$t('createMedication.drugErrorText')"
                                      :helper="$t('createMedication.drugHelperText')"
                                      :placeholder="$t('createMedication.drugPlaceholder')"
                                      @updated="stateChanged" />

                            <text-area v-model="simpleOrdination"
                                       name="simpleOrdination"
                                       :required="true"
                                       :label="$t('createMedication.simpleOrdinationLabel')"
                                       :errorText="$t('createMedication.simpleOrdinationErrorText')"
                                       :helper="$t('createMedication.simpleOrdinationHelperText')"
                                       :placeholder="$t('createMedication.simpleOrdinationPlaceholder')"
                                       @updated="stateChanged" />

                            <text-area v-model="instruction"
                                       name="instruction"
                                       :label="$t('createMedication.instructionLabel')"
                                       :errorText="$t('createMedication.instructionErrorText')"
                                       :helper="$t('createMedication.instructionHelperText')"
                                       :placeholder="$t('createMedication.instructionPlaceholder')"
                                       @updated="stateChanged" />
                            <media-player :audio="audio" @toggle="toggleRecording" @remove="removeAudio"></media-player>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6">
                <button class="btn btn__text btn__text--green-border left" type="button" @click="closeDialog">
                    <h4>{{$t('general.cancel')}}</h4>
                </button>
            </div>
            <div class="col-xs-6">
                <button class="btn btn__text btn__text--green right online-only" :class="{ disabled : !isValid }" :disabled="!isValid" type="button" @click="createMedication">
                    <h4>{{$t('general.create')}}</h4>
                </button>
            </div>
        </div>
        <media-recorder :class="{active : audioOverlayActive}" userType="patient" @uploadGuid="newAudio" @close="toggleRecording"></media-recorder>
    </div>
</template>

<script setup>
    import { computed, defineProps, ref } from 'vue';
	import { useStore } from 'vuex';
	import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import TextArea from '@/Components/Shared/Form/TextArea.vue';
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import MediaRecorder from '@/Components/Shared/Form/MediaRecorder.vue';
    import MediaPlayer from '@/Components/Shared/MediaPlayer.vue';

    import { useMedicationService } from '@/Components/Medications/Medication/MedicationService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';


    const props = defineProps({
        code: String,
        text: String,
        system: String,
    });

    const store = useStore();
	const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const medicationService = useMedicationService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);
    //const clinicianName = computed(() => store.getters.getClinicianName);

    let drug = ref('');
    let dosage = ref('-');
    let duration = ref('-');
    let frequency = ref('-');
    let quantity = ref('-');
    let instruction = ref('');
    let simpleOrdination = ref('');

    // Audio 
    let audioOverlayActive = ref(false);
    let audio = ref('');   

    const newAudio = (uploadGuid) => audio.value = uploadGuid;

    const removeAudio = () => audio.value = '';

    const toggleRecording = () => audioOverlayActive.value = !audioOverlayActive.value;

    // Audio End 

    const closeDialog = () => router.push({ name: 'medication' });

    const createMedication = async () => {
        await caller.call(async () =>
            await medicationService.createMedication(
                props.code,
                props.text,
                props.system,
                drug.value,
                dosage.value,
                duration.value,
                frequency.value,
                quantity.value,
                instruction.value,
                simpleOrdination.value,
                audio.value
            ));

		closeDialog();
    };
</script>