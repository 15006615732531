import dispatcher from '@/Connections/Dispatcher.js';
import * as strings from '@/Shared/Strings.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';
import { inject } from 'vue';
import { getPin, setPin } from '@/Shared/PinCache.js';
import { serviceCall } from '@/Shared/ServiceWrapper.js';
import { useStore } from 'vuex';
import accessTokens from '@/Connections/AccessTokens.js';
import { useClinicianService } from '@/Components/Organizations/Clinician/ClinicianService.js';
import { usePatientService } from '@/Components/Patients/Patient/PatientService.js';
import { usePatientAccessService } from '@/Components/Organizations/PatientAccess/PatientAccessService.js';
import { useEncryptedPin } from '@/Shared/EncryptedPin.js';


const fnLogonUser = async (user) => {
    const result = await dispatcher.postToApiAnonymous('logon', user);
    handleApiError(result, 'noUserdata');
    return result.data;
}

const fnCheckRefreshToken = async (userId, token) => {
    const result = await dispatcher.postToApiAnonymous('check-refresh-token', { userId, token });
    handleApiError(result, 'Error while checking your token');
    return result.data.isMatch;
}

const fnGetSavedUsers = () => {
    return dispatcher.getFromLocalClear(strings.USERS) || [];
}
const fnSetSavedUsers = (savedUsers) => {
    return dispatcher.postToLocalClear(strings.USERS, savedUsers || []);
}


const fnGetUserRefreshToken = (userId) => {
    return dispatcher.getFromLocalClear(`${userId}_${strings.REFRESHTOKEN}`);
}

const fnSetUserRefreshToken = (userId, token) => {
    dispatcher.postToLocalClear(`${userId}_${strings.REFRESHTOKEN}`, token);
}

const fnClearUserRefreshToken = (userId) => {
    dispatcher.deleteFromLocal(`${userId}_${strings.REFRESHTOKEN}`);
}


const fnStorePinHash = async (pinHash) => {
    let result = await dispatcher.postToApi(`users/pin`, { pinHash });
    handleApiError(result, 'Error while storing pin data');
}


const fnSetUserAccessToken = (token) => {
    dispatcher.postToSession(strings.ACCESSTOKEN, token);
}

const fnClearUserAccessToken = () => {
    dispatcher.deleteFromSession(strings.ACCESSTOKEN);
}

const fnClearSessionUserId = () => {
    dispatcher.deleteFromSession(strings.USERID);
}


export const useUserService = () => {
    const store = useStore();
    const clinicianService = useClinicianService();
    const patientService = usePatientService();
    const patientAccessService = usePatientAccessService();
    const createPinDialog = inject('createPinDialog');
    const enterPinDialog = inject('enterPinDialog');
    const encryptedPin = useEncryptedPin();

    const updateOrAddUserToUsersList = (data) => {
        let user = null;
        let users = fnGetSavedUsers();

        let filteredUsers = users.filter((u) => u.userId == data.userId);
        if (!filteredUsers || filteredUsers.length == 0) {
            user = {
                userId: data.userId,
                name: data.userName,
                phone: data.phone,
                email: data.email,
                type: data.userType,
                language: data.language,
                image: '',  //TODO: get image of user (and save somewhere else)...
                date: new Date().toISOString()
            };
            users.push(user);
        }
        else {
            user = filteredUsers[0];
            user.date = new Date().toISOString();
        }

        fnSetSavedUsers(users);
    }

    const loadClinicianDataForUser = async (clinicianId) => {
        const clinician = await clinicianService.getClinician(clinicianId);
        store.commit('setClinicianInfo', clinician);

        const patientsAccess = await patientAccessService.getPatientAccessForClinician(clinicianId);
        store.commit('setPatientsAccess', patientsAccess);
    }

    const loadPatientDataForUser = async (patientId) => {
        const patient = await patientService.getPatient(patientId);
        store.commit('setPatientInfo', patient);

        const patientsAccess = await patientAccessService.getPatientAccessForPatient(patientId);
        store.commit('setPatientsAccess', patientsAccess);
    }

    const logonUser = async (username, password) => {
        let userId;
        try {
            console.log('LOGIN WITH CREDS');
            const user =
            {
                username,
                password
            };

            const data = await fnLogonUser(user);

            userId = data.userId;

            store.commit('setUserId', data.userId);
            store.commit('setUsername', data.userName);

            fnSetUserRefreshToken(data.userId, data.refreshToken);
            fnSetUserAccessToken(data.accessToken);

            updateOrAddUserToUsersList(data);


            const hasEncPin = encryptedPin.hasEncryptedPin(data.userId);
            if (!hasEncPin) {
                let ok = await createPinDialog();
                if (!ok) {
                    throw new Error('canceledPin');
                }
            }
            else {
                let ok = await enterPinDialog();
                if (!ok) {
                    throw new Error('canceledPin');
                }
            }


            store.commit('setUserInfo', data);

            if (data.userType == 'clinician') {
                await loadClinicianDataForUser(data.entityId);
            }
            else if (data.userType == 'patient') {
                await loadPatientDataForUser(data.entityId);
            }

            return data;
        }
        catch (e) {
            store.commit('resetUserInfo');
            store.commit('resetPatientInfo');
            store.commit('resetClinicianInfo');
            if (userId) {
                fnClearUserRefreshToken(userId);
                fnClearUserAccessToken();
                fnClearSessionUserId();
            }
            throw e;
        }
    }


    const getClinicianDataFromCache = (userId) => {
        const keys = dispatcher.getLocalKeys(`${userId}_c_`);
        if (!keys || keys.length == 0)
            throw new Error('noClinicianData');
        return dispatcher.getFromLocal(keys[0]);
    }

    const getPatientDataFromCache = (userId) => {
        const keys = dispatcher.getLocalKeys(`${userId}_p_`);
        if (!keys || keys.length == 0)
            throw new Error('noPatientData');
        return dispatcher.getFromLocal(keys[0]);
    }

    const validatePin = (userId, pin) => {
        console.log(`validate pin ${userId}`);
        if (!encryptedPin.hasEncryptedPin(userId))
            throw new Error('noCachedPin');

        // compare entered pin with encrypted pin
        const hashedPin = encryptedPin.hashPin(userId, pin);

        // set pin in memory for use in a short moment...
        setPin(hashedPin);

        let storedEncryptedPin = '';
        try {
            storedEncryptedPin = encryptedPin.getEncryptedPin(userId);
        }
        catch (e) {
            var msg = e.message || e;
            if (msg == 'Malformed UTF-8 data')
                throw new Error('invalidPin');
        }

        if (hashedPin != storedEncryptedPin) {
            setPin(null);
            throw new Error('invalidPin');
        }
        return hashedPin;
    }

    const refreshPin = async (userId, pin) => {
        console.log('REFRESH PIN', userId);
        const hashedPin = validatePin(userId, pin);
        if (store.getters.getIsOnline) {
            await storePinHash(hashedPin);
        }
    }

    const logonWithPin = async (userId, pin) => {
        console.log('LOGIN WITH PIN', userId);
        const hashedPin = validatePin(userId, pin);

        store.commit('setUserId', userId);

        // get user's data from LS-cache
        const user = dispatcher.getFromLocal(userId);
        if (user == null)
            throw new Error('noUserData');

        store.commit('setUserInfo', user);

        // get entity's data from LS-cache
        const entity = store.getters.isClinician
            ? getClinicianDataFromCache(userId)
            : getPatientDataFromCache(userId);

        if (store.getters.getIsOnline) {
            // load data from api to active user's LS-cache
            if (store.getters.isClinician) 
                await loadClinicianDataForUser(entity.id);
            else 
                await loadPatientDataForUser(entity.id)
            await storePinHash(hashedPin);
        }
        else {
            // load data from LS-cache to active user's LS-cache
            if (store.getters.isClinician) 
                store.commit('restoreClinicianInfoStoreAndCache', entity);
            else 
                store.commit('restorePatientInfoStoreAndCache', entity);
        }
    }


    const logoffUser = async () => {
        const userId = dispatcher.getFromSession(strings.USERID);
        console.log('LOGOFF USER', userId);
        let result = {};
        let data = {
            username: store.getters.getUsername,
            refreshToken: fnGetUserRefreshToken(userId)
        };
        try {
            result = await dispatcher.postToApi("logoff", data);
            handleApiError(result, 'Error while logging you off');
        }
        catch (e) {
            result = { status: 200 };
        }

        dispatcher.deleteFromSession(strings.ACCESSTOKEN);
        dispatcher.deleteFromSession(strings.USERID);

        store.commit('resetUserInfo');
        store.commit('resetClinicianInfo');
        store.commit('resetPatientInfo');
        store.commit('resetGlobalFlyIns');
    }


    const requestResetUserPassword = async (userIdentifier) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/requestresetpassword`, { userIdentifier });
                handleApiError(result, 'Error while requesting password reset token');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }

    const resetUserPassword = async (userId, resetPasswordToken, newPassword) => {
        let data = {
            resetPasswordToken,
            newPassword,
        }
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/${userId}/resetpassword`, data);
                handleApiError(result, 'Error while resetting user password');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }


    const changeUserLanguage = async (language) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/language`, { language });
                handleApiError(result, 'Error while changing user language');
                store.commit('setUserLanguage', language);
            }
        );
    }

    const getUserLanguage = async () => {
        return await serviceCall(
            async () => {
                let result = await dispatcher.getFromApi(`users/language`);
                if (result.status > 299)
                    return "en";
                return result.data.language;
            }
        );
    }


    const verifyEmailChange = async (userId, token) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/${userId}/verifyemailchange`, { token });
                handleApiError(result, 'Error while verifying user email change');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }


    const confirmUserEmail = async (userId, confirmationToken) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/${userId}/confirmemail`, { confirmationToken });
                handleApiError(result, 'Error while confirming user e-mail');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }

    const confirmUserPhoneNumber = async (userId, confirmationToken) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/${userId}/confirmphone`, { confirmationToken });
                handleApiError(result, 'Error while confirming user phone number');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }

    const resendEmailConfirmation = async (userId) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/${userId}/resendemailconfirmation`, {});
                handleApiError(result, 'Error while requesting new e-mail confirmation');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }

    const resendPhoneConfirmation = async (userId) => {
        await serviceCall(
            async () => {
                let result = await dispatcher.putToApi(`users/${userId}/resendphoneconfirmation`, {});
                handleApiError(result, 'Error while requesting new phone number confirmation');
            },
            { checkLoggedIn: false, checkPin: false }
        );
    }

    const getPinHash = async () => {
        let pin = getPin();
        if (pin)
            return pin;

        return await serviceCall(
            async () => {
                let result = await dispatcher.getFromApi(`users/pin`);
                handleApiError(result, 'Error while getting pin data');
                return result.data.pinHash;
            }
        );
    }

    const storePinHash = async (pinHash) => {
        // Note: this should not check for pin before calling
        await serviceCall(
            async () => {
                await fnStorePinHash(pinHash);
            },
            //{ checkPin: false }
        );
    }


    const loadPatientUsername = async (patientId) => {
        await serviceCall(
            async () => {
                const result = await dispatcher.getFromApi(`users/patient?patientId=${patientId}`);
                handleApiError(result, 'Error while getting patient\'s username');
                const patientUsername = result.data.username;
                store.commit('setPatientUsername', { patientUsername });
            }
        );
    }



    const getSavedUsers = () => {
        return fnGetSavedUsers();
    }

    const getSavedUserByUserId = (userId) => {
        return fnGetSavedUsers().reduce((p,c) => c.userId == userId ? c : p, null);
    }

    const getSavedUserByUserIdentifier = (username) => {
        const usernameLower = username.toLowerCase();
        const users = fnGetSavedUsers().filter((u) =>
            (u.email && u.email.toLowerCase() == usernameLower) ||
            (u.phone && u.phone == usernameLower) ||
            u.name.toLowerCase() == usernameLower);
        if(users.length > 0)
            return users[0];
        return null;
    }

    const removeSavedUser = (userId) => {
        const savedUsers = fnGetSavedUsers().filter((u) => u.userId != userId);
        fnSetSavedUsers(savedUsers);
    }



    const hasValidUserToken = (userId) => {
        const res = fnGetUserRefreshToken(userId);
        return (res && 'expireAt' in res && accessTokens.refreshTokenValid(res)) || false;
    }

    const getUserToken = (userId) => {
        return fnGetUserRefreshToken(userId);
    }

    const setUserToken = (userId, token) => {
        fnSetUserRefreshToken(userId, token);
    }

    const deleteUserToken = (userId) => {
        fnClearUserRefreshToken(userId);
    }


    const hasMatchingServerToken = async (userId) => {
        if (!store.getters.getIsOnline) return true;
        const localRefreshToken = fnGetUserRefreshToken(userId);
        return await fnCheckRefreshToken(userId, localRefreshToken.tokenString);
    }


    return {
        logonUser,
        logonWithPin,
        refreshPin,
        logoffUser,
        requestResetUserPassword,
        resetUserPassword,
        changeUserLanguage,
        getUserLanguage,
        verifyEmailChange,
        confirmUserEmail,
        confirmUserPhoneNumber,
        resendEmailConfirmation,
        resendPhoneConfirmation,
        getPinHash,
        storePinHash,
        loadPatientUsername,
        getSavedUsers,
        getSavedUserByUserId,
        getSavedUserByUserIdentifier,
        removeSavedUser,

        hasValidUserToken,
        getUserToken,
        setUserToken,
        deleteUserToken,

        hasMatchingServerToken,

    }
}