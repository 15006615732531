import dispatcher from '@/Connections/Dispatcher.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';

import { useStore } from 'vuex';
import { serviceCall } from '@/Shared/ServiceWrapper.js';

export const usePatientRecordService = () => {
    const store = useStore();

    const loadRecord = async () => {
        let patientId = store.getters.getPatientId;

        await serviceCall(
            async () => {
                let result = await dispatcher.getFromApi(`patients/${patientId}/records`);
                handleApiError(result, 'Error while fetching record');

                let recordId = result.data.recordId;
                let entriesResult = await dispatcher.getFromApi(`patients/${patientId}/records/${recordId}/entries`);
                handleApiError(entriesResult, 'Error while fetching record entries');

                let record = {
                    entries: entriesResult.data.entries || []
                };
                store.commit('setRecord', record);
            }
        );
    }

    return {
        loadRecord
    }
}
