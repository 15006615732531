<template>
	<div id="userNavigation" class="container">
		<UserInfoBar :profileId="patientId" 
					:profileName="patientName" 
					titleKey="patientBarView.title" 
					route-name="patient" />
		<!--<div class="row full-height">
			<div class="col-xs-9 full-height">
				<div class="img" @click="navigateTo('patient')">
					<div class="img-container">
						<profile-image :profile-id="patientId" :alt="patientName" />
					</div>
				</div>
				<div class="name" @click="navigateTo('patient')">
					<h3>{{$t('patientBarView.title')}}</h3>
					<h3><b>{{patientName}}</b></h3>
				</div>
			</div>-->
			<!--<div class="col-xs-3 full-height">
			<button class="burger" @click="toggleNav" :data-page="navToggle">
				<svg xmlns="http://www.w3.org/2000/svg" class="close">
					<use xlink:href="#cross" />
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" class="open">
					<use xlink:href="#burger" />
				</svg>
			</button>
		</div>-->
		<!--</div>-->
	</div>
	<!--User Menu -->
	<!--<nav id="navigation" :data-page="navToggle">
		<ul class="list-reset">
			<li>
				<h1>{{$t('patientBurgerMenu.dataTitle')}}</h1>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('dashboard')" data-test="nav-dashboard">{{$t('patientBurgerMenu.dashboard')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('record')" data-test="nav-record">{{$t('patientBurgerMenu.record')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('diagnosis')" data-test="nav-diagnosis">{{$t('patientBurgerMenu.diagnosis')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('medication')" data-test="nav-medication">{{$t('patientBurgerMenu.medication')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('immunization')" data-test="nav-immunization">{{$t('patientBurgerMenu.immunization')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('measurements')" data-test="nav-measurements">{{$t('patientBurgerMenu.measurements')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('note')" data-test="nav-note">{{$t('patientBurgerMenu.note')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('episodeofcare')" data-test="nav-note">{{$t('patientBurgerMenu.episodeofcare')}}</button>
			</li>
			<li>
				<h1>{{$t('patientBurgerMenu.adminTitle')}}</h1>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('data')" data-test="nav-patientdata">{{$t('patientBurgerMenu.data')}}</button>
			</li>
			<li v-if="!isClinician && !isRelatedPatient">
				<button class="btn btn__round" @click="navigateTo('myrelations')" data-test="nav-relations">{{$t('patientBurgerMenu.myFamily')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('appointments')" data-test="nav-appointments">{{$t('patientBurgerMenu.appointments')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('patientbilling')" data-test="nav-billing">{{$t('patientBurgerMenu.billing')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('patient conversations')" data-test="nav-billing">{{$t('patientBurgerMenu.patientConversations')}}</button>
			</li>
			<li>
				<button class="btn btn__round" @click="navigateTo('patient consents')" data-test="nav-billing">{{$t('patientBurgerMenu.patientConsents')}}</button>
			</li>
		</ul>
	</nav>-->
</template>

<script setup>
	import UserInfoBar from '@/Components/Shared/UserInfoBar';

	import { computed } from 'vue';
    //import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';

	//const router = useRouter();
	const store = useStore();

	const isClinician = computed(() => store.getters.isClinician);
	const isRelatedPatient = computed(() => store.getters.isRelatedPatient);

	const patientId = computed(() => store.getters.patientId);
	const patientName = computed(() => store.getters.getPatientName);

	//let navToggle = ref(false);

	//const toggleNav = () => {
	//	navToggle.value = !navToggle.value;
	//}

 //   const navigateTo = (name) => {
 //       navToggle.value = false;
 //       router.push({ name: name });
 //   }
</script>
