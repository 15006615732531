<template>
    <nav id="navigation" :data-page="props.dp" class="container">
        <div v-if="showPatientMenu" class="patient-menu-container">
            <UserInfoBar :profileId="patientId"
                         :profileName="patientName"
                         titleKey="patientBarView.title"
                         route-name="patient"
                         class="light" />

            <div class="patient-menu">
                <ul class="list-reset" style="padding-bottom: 15px">
                    <li>
                        <div class="category-header">
                            <button type="button" class="btn btn__round right" @click="patientDataMenuOpen = !patientDataMenuOpen">
                                <svg-icon :class="patientDataMenuOpen ? 'open' : 'closed'" icon-name="chevron-down" width="20px" height="20px"></svg-icon>
                            </button>
                            <h1 class="all-caps">{{$t('patientBurgerMenu.dataTitle')}}</h1>
                        </div>
                        <ul class="patient-data" :class="{open:patientDataMenuOpen}">
                            <li>
                                <button class="btn btn__round" @click="navigateTo('dashboard')" data-test="nav-dashboard">{{$t('patientBurgerMenu.dashboard')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('record')" data-test="nav-record">{{$t('patientBurgerMenu.record')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('diagnosis')" data-test="nav-diagnosis">{{$t('patientBurgerMenu.diagnosis')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('medication')" data-test="nav-medication">{{$t('patientBurgerMenu.medication')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('immunization')" data-test="nav-immunization">{{$t('patientBurgerMenu.immunization')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('measurements')" data-test="nav-measurements">{{$t('patientBurgerMenu.measurements')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('note')" data-test="nav-note">{{$t('patientBurgerMenu.note')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('episodeofcare')" data-test="nav-episodeofcare">{{$t('patientBurgerMenu.episodeofcare')}}</button>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <hr style="border-top: 2px solid #ccc;margin-top:15px" />
                    </li>
                    <li>
                        <div class="category-header">
                            <button type="button" class="btn btn__round right" @click="patientAdminMenuOpen = !patientAdminMenuOpen">
                                <svg-icon :class="patientAdminMenuOpen ? 'open' : 'closed'" icon-name="chevron-down" width="20px" height="20px"></svg-icon>
                            </button>
                            <h1 class="all-caps">{{$t('patientBurgerMenu.adminTitle')}}</h1>
                        </div>
                        <ul class="patient-admin" :class="{open:patientAdminMenuOpen}">
                            <li>
                                <button class="btn btn__round" @click="navigateTo('data')" data-test="nav-patientdata">{{$t('patientBurgerMenu.data')}}</button>
                            </li>
                            <li v-if="!isClinician && !isRelatedPatient">
                                <button class="btn btn__round" @click="navigateTo('myrelations')" data-test="nav-relations">{{$t('patientBurgerMenu.myFamily')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('appointments')" data-test="nav-appointments">{{$t('patientBurgerMenu.appointments')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('patientbilling')" data-test="nav-billing">{{$t('patientBurgerMenu.billing')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('patient conversations')" data-test="nav-conversations">{{$t('patientBurgerMenu.patientConversations')}}</button>
                            </li>
                            <li>
                                <button class="btn btn__round" @click="navigateTo('patient consents')" data-test="nav-consents">{{$t('patientBurgerMenu.patientConsents')}}</button>
                            </li>
                            <li v-if="isPatient">
                                <button class="btn btn__round" @click="navigateTo('findclinic')" data-test="nav-findclinic">{{$t('patientBurgerMenu.findClinic')}}</button>
                            </li>
                            <li v-if="isPatient">
                                <button class="btn btn__round" @click="navigateTo('myclinicians')" data-test="nav-myclinicians">{{$t('patientBurgerMenu.myClinicians')}}</button>
                            </li>
                            <li v-if="isPatient">
                                <button class="btn btn__round" @click="navigateTo('settings')" data-test="nav-settings">{{$t('patientBurgerMenu.settings')}}</button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="showClinicianMenu" class="clinician-menu-container">
            <UserInfoBar :profileId="clinicianId"
                         :profileName="clinicianName"
                         titleKey="clinicianBarView.title"
                         route-name="dashboard"
                         class="white"
                         style="margin-bottom:10px" />

            <div class="clinician-menu" style="background-color:#fff;margin:15px -50px; padding: 0 25px">
                <DashboardLinkList :links="clinicianLinks" 
                                   :show-icons="false"></DashboardLinkList>
            </div>
        </div>
    </nav>
</template>

<script setup>
    import UserInfoBar from '@/Components/Shared/UserInfoBar';
    import DashboardLinkList from '@/Components/Shared/DashboardLinkList';

    import { computed, defineProps, reactive, ref, watchEffect } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import clinicianLinkStructure from './NavigationClinician.json';

    const props = defineProps({
        dp: Boolean
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isPatient = computed(() => store.getters.isPatient);
    const isClinician = computed(() => store.getters.isClinician);
    const isRelatedPatient = computed(() => store.getters.isRelatedPatient);

    const showPatientMenu = computed(() => isPatient.value || route.path.includes('/patient'));
    const showClinicianMenu = computed(() => isClinician.value);

    const bookingRequests = computed(() => store.getters.getClinicBookingRequests.filter((br) => br.rejectedState == 0));
    const bookingsCount = computed(() => bookingRequests.value.length);

    const messages = computed(() => store.getters.getClinicsConversations);
    const messagesCount = computed(() => messages.value.length);

    // set up active counters for booking req. and conversations
    clinicianLinkStructure.filter(m => m.route.name == 'clinician booking requests')[0].count = computed(() => bookingsCount.value);
    clinicianLinkStructure.filter(m => m.route.name == 'clinic conversations')[0].count = computed(() => messagesCount.value);
    const clinicianLinks = reactive(clinicianLinkStructure);

    const patientName = computed(() => store.getters.getPatientName);
    const patientId = computed(() => store.getters.getPatientId);

    const clinicianName = computed(() => store.getters.getClinicianName);
    const clinicianId = computed(() => store.getters.getClinicianId);

    let patientDataMenuOpen = ref(null);
    let patientAdminMenuOpen = ref(null);

    watchEffect(() => {
        console.log('IS PATIENT', isPatient.value)
        if (patientDataMenuOpen.value == null) patientDataMenuOpen.value = isPatient.value;
        if (patientAdminMenuOpen.value == null) patientAdminMenuOpen.value = isPatient.value;
    });

    const navigateTo = (name) => {
        router.push({ name });
    }
</script>

